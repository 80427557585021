import React, { useState,useEffect } from "react";
import { styled } from '@mui/material/styles';
import MUIDataTable from "mui-datatables";
import { Button, Typography, Link ,Tooltip as Tooltip1} from "@mui/material";
import {  ThemeProvider } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { connect } from 'react-redux';
import NormalCard from "src/components/Cards/NormalCard";
import IconButton from "@mui/material/IconButton";
import SnackBar from "src/components/SnackBar/SnackBar";
import ModalCentered from "src/components/Modals/ModalCentered";
import AlertModal from 'src/components/AlertModal/AlertModal'
import HolidayCreateForm from "./HolidayCreateForm"
import moment from 'moment'
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal"
import GetMuiTheme from "src/components/theme";
import { DashboardData } from "src/_services/dashboard";
import ToolTip from "src/components/Tooltip/tooltip";
import { InfoRounded } from "@mui/icons-material";
import BulkUpload from "./BulkUploadHoliday";
import CloudDownload from "@mui/icons-material/CloudDownload";
const PREFIX = 'Index';

const classes = {
  tableEllips: `${PREFIX}-tableEllips`,
  root: `${PREFIX}-root`,
  btnright: `${PREFIX}-btnright`,
  container: `${PREFIX}-container`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  button: `${PREFIX}-button`,
  editdeletbtn: `${PREFIX}-editdeletbtn`,
  btnsdltedt: `${PREFIX}-btnsdltedt`,
  iconbtn: `${PREFIX}-iconbtn`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.tableEllips}`]: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "11rem",
    [theme.breakpoints.down("sm")]: {
      width: "8rem",
    },
    [theme.breakpoints.up("sm")]: {
      width: "11rem",
    },
  },

  [`& .${classes.root}`]: {
    margin: theme.spacing(2),
    "& .MuiTableHead-root": {
      background: "blue",
    },
  },

  [`& .${classes.btnright}`]: {
    textAlign: "end",
  },

  [`& .${classes.container}`]: {
    padding: "24px",
  },

  [`& .${classes.popDownBtn}`]: {
    marginTop: '-11px',
    textTransform: 'capitalize',
    [theme.breakpoints.down('xs')]:{
      fontSize:'0.65rem'
    },
    borderRadius: "4px",
    padding: "4px 8px",
  },

  [`& .${classes.button}`]: {
    marginBottom: "1%",
    borderRadius: "14px 14px 0 0",
    boxShadow: "none",
    textTransform: "capitalize",
  },

  [`& .${classes.editdeletbtn}`]: {
    color: "#3363cb",
    display: 'flex'
  },

  [`& .${classes.btnsdltedt}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.iconbtn}`]: {
    padding: theme.spacing(0.8),
  }
}));

const Holiday = (props) => {

  const [holiday, setHoliday] = useState([])
  const [modalOpen, setModalOpen] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [titleModal, setTitleModal] = useState("");
  const [severity, setSeverity] = React.useState("");
  const [modalContainer, setModalContainer] = React.useState("");
  const [loading, setLoading] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const [formMode, setFormMode] = useState(null)
  const [editRowData, setEditRowData] = useState(null)
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);
  // const [rowId, setRowId] = useState(null)
  const [spinner, setSpinner] = useState(false)
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)
  const [bulkUploadDilaog,setBulkUploadDilaog] = useState(false)
  const [csvData, setCsvData] = React.useState([]);


  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarOpen(false);
  };

  var isAdmin = props?.authReducer?.auth?.user?.default_role_code;
  const handleOpenModal = (data, mode) => {
    if (mode === "Add") {
      setFormMode("create")
      setModalContainer(data);
      setTitleModal("Add Holiday ");
      setModalOpen(true);
    }
    else if (mode === "edit") {
      setFormMode("edit")
      setModalContainer("Add");
      setTitleModal("Edit Holiday");
      setModalOpen(true);
    }
  };

  const handleBulkHolidayUpdate =((values)=>{
    const data={
      holiday_data:values
    }
    DashboardData.bulkHolidayUpdate(data).then(res => {
      if (res) {
      setLoading(false)
                  setBulkUploadDilaog(false)

          if (res.status === 200) {
              setBulkUploadDilaog(false)
              setSeverity("success")
              setSnackbarTitle("Holiday Updated Successfully")
              setSnackBarOpen(true);
              getHolidayList()
          } else {
              if (res.status === 401 && res.data.name === "TokenExpiredError") {
                  setBulkUploadDilaog(false)
                  setOpenSessionExpiredModal(true)
              }else{
              setSeverity("error")
              setSnackbarTitle("Failed to update")
              setSnackBarOpen(true);
              }
          }
      }
  })
})

  const handleShowClose = () => {
    setModalOpen(false);
    setBulkUploadDilaog(false)
  };

  const submitHandle = (values) => {
    const data = {
      id:formMode==='edit' && values?.id,
      holiday_name:values.holiday_name,
      holiday_date:values.holiday_date,
      message:values.message,
    }
    if(formMode === "create"){ 
      DashboardData.postHoliday(data).then(res =>{  
        console.log(res)
        if(res.status === 200){
          getHolidayList();
          setModalOpen(false);            
          setBtnLoading(false)
          setSeverity("success")
          setSnackbarTitle("Added Successfully")
          setSnackBarOpen(true);
        }       
      }).catch(error=>console.log(error))
    }  
    else if(formMode === "edit"){
      DashboardData.updateHoliday(data).then(res =>{
        if(res.status===200){
          getHolidayList();
          setModalOpen(false);            
          setBtnLoading(false)
          setSeverity("success")
          setSnackbarTitle("Updated Successfully")
          setSnackBarOpen(true);  
        } 
      }).catch(error=>console.log(error))
    }  
  }    
  
  function sortByDate(a, b) {
    if (a.date < b.date) {
        return 1;
    }
    if (a.date > b.date) {
        return -1;
    }
    return 0;
}

  const handleDeleteTableRow = () => { 
    setSpinner(true)
    DashboardData.deleteHolidayByID(deleteId)
      .then(res => {
        if (res.status === 200) {
          getHolidayList();
          setDeleteOpen(false)
          setSpinner(false)
          setSeverity("success")
          setSnackBarOpen(true)
          setSnackbarTitle("Holiday deleted")  
        }
        else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setDeleteOpen(false);
            setSpinner(false)
            setOpenSessionExpiredModal(true)
          }else{
            setDeleteOpen(false)
            setSpinner(false)
            setSeverity("error")
            setSnackbarTitle("Something Went Wrong!")
            setSnackBarOpen(true)
          
          }
        }
      }).catch(error => {
        setDeleteOpen(false)
        setSpinner(false)
        setSeverity("error")
        setSnackbarTitle("Invalid Token")
        setSnackBarOpen(true)
       
      })
  };


  const getHolidayList = () => {
    setLoading(true)
    DashboardData.getHolidayList()
      .then(res => {
        if (res) {
          if (res.status === 200) {
            setHoliday(res.data)
            setLoading(false)
            let array =[]
            res.data.map((item)=>{
              return(
                  array.push({
                      id:item.id,
                      holiday_date:moment(item.holiday_date).format("YYYY-MM-DD"),
                      holiday_name:item.holiday_name,
                      description:item.description,
                  })
              )
          })
          const data =array.filter((item) =>  item.date !== 'Invalid date').sort(sortByDate)
          setCsvData(data)
          }
          else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setLoading(false)
              setOpenSessionExpiredModal(true)
            }else{
              setSeverity("error")
              setSnackbarTitle("Something Went Wrong!")
              setSnackBarOpen(true);
              setLoading(false)
            }
          }
        }
      }).catch(error => {
        console.log(error)
        setLoading(false)
      })
  }

  useEffect(() => {
    getHolidayList();
  }, []) 
  // eslint-disable-next-line


  const getMuiTheme = GetMuiTheme

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "holiday_date",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "holiday_name",
      label: "Holiday Title",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {

          return (
            <div className={classes.tableEllips}>
              <Link
                onClick={() => {
                  handleOpenModal(tableMeta.rowData, "details");
                }}
                // component="button"
                variant="subtitle1"
                style={{ textTransform: 'capitalize', textDecoration:'none' }}
              >
                {value}
              </Link>
            </div>
          );
        },
      },
    },
    {
      name: "holiday_date",
      label: "Holiday Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <div className={classes.tableEllips}>
              <Typography variant="body2" noWrap>
                {moment(value).format('YYYY-MM-DD')}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: "description",
      label: "Message",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (             
            <ToolTip
                variant='body2'
                // noWrap
                title={<Typography variant='body2' p={1}>
                  {value}
                </Typography>}
            >
              <InfoRounded  sx={{width:'20px', color:'grey',ml:2}}/>
            </ToolTip>
          )
        }
      },
    },
    {
      name: "modified_time",
      label: "Last Updated",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          let valueMoment= moment(value).format("DD-MM-YYYY LT")
          return (             
            <Typography
                variant='body2'
                noWrap
            >{valueMoment}</Typography>
          )
        }
      },
    },  
    {
      name: "",
      label: "Action",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return  isAdmin === "ADMIN" ? (
            <span className={classes.editdeletbtn}>
              <IconButton
                className={classes.iconbtn}
                aria-label="edit"
                onClick={() => { handleEdit(tableMeta.rowData); }}
              >
                <EditIcon />
              </IconButton>

              <IconButton
                className={classes.iconbtn}
                aria-label="delete"
                onClick={() => { setDeleteOpen(true); setDeleteId(tableMeta.rowData[0]) }}
              >
                <DeleteIcon />
              </IconButton>
            </span>
          ) : null;
        },
      },
    },
  ];

  const handleEdit =  (rowData) => {
    setFormMode("edit");
    setEditRowData(rowData)
    handleOpenModal(rowData, "edit")
  }

  const downloadCSV = (array, filename) => {
    const headers = Object.keys(array[0]);
    const csvContent = array.reduce((csvString, row) => {
        const values = Object.values(row);
        const rowString = values.map(value => `"${value}"`).join(',');
        return csvString + rowString + '\n';
      }, headers.join(',') + '\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    link.click();
    URL.revokeObjectURL(url);
  };

  const handleDownload = () => {
    downloadCSV(csvData, 'holidays.csv');
  };




  return (
    <Root>
      <NormalCard
        title={"Trading Holidays"}
        subtitle={"List of Trading Holidays"}
        btn={
         isAdmin !== 'ADMIN_VIEWER' ?
          <div style={{display:'flex', gap:4,margin:'8px 0'}}>
          <Button
            color="primary"
            className={classes.popDownBtn}
            size="medium"
            onClick={() => handleOpenModal("Add", "Add")}
            variant="contained"
          >
            Add Holiday
          </Button>
           <Button
           color="primary"
           className={classes.popDownBtn}
           size="medium"
           onClick={() => setBulkUploadDilaog(true)}
           variant="contained"
         >
           Bulk Upload
         </Button>
          </div>: null}
        
      >
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            data={holiday}
            headerSort={false}
            columns={columns}
            options={{
              customToolbar: () => (
                <Tooltip1 title="Download">
                  <IconButton disableRipple disabled={holiday.length ===0}  onClick={handleDownload} filename="report.csv">
                    <CloudDownload style={{margin:'0px 8px -4px',fontSize:'1.65rem', color:'grey'}}/>
                 </IconButton>
                </Tooltip1>
               ),
              download: false,
              setRowProps: (row) => {
                const category = moment(row[1]).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD');
                return {
                  style: {
                    backgroundColor: category
                      ? "#e7eaff"
                      : "inherit",
                    borderBottom: "1px solid grey",
                  },
                };
              },
              print: false,
              filter: false,
              viewColumns: false,
              rowsPerPage: 20,
              rowsPerPageOptions: [5, 10, 20],
              // responsive: 'scrollMaxHeight',
              selectableRows: "none",
              sortOrder: {
                name: 'last_updated',
                direction: 'desc'
              },
              textLabels: {
                body: {
                  noMatch: loading ?
                    'Loading...' : 'Sorry, there are no matching data to display'
                },
              }
            }}
          />
        </ThemeProvider>
      </NormalCard>
      <ModalCentered
        style={modalContainer !== "Add" ? { padding: 0 } : {}}
        title={titleModal}
        open={modalOpen}
        toggle={handleShowClose}
        children={
          <HolidayCreateForm
            spinner={btnLoading}
            formSubmit={submitHandle}
            type={modalContainer}
            mode={formMode}
            data={editRowData}
          />
        }
      />
      <SnackBar
        open={snackBarOpen}
        severity={severity}
        close={handleClose}
        snackbarTitle={snackbarTitle}
      />
      <AlertModal
        title="Are you sure you want to delete this?"
        open={deleteOpen}
        setOpen={setDeleteOpen}
        toggle={() => setDeleteOpen(false)}
        onConfirm={() => handleDeleteTableRow()}
        openLoading={spinner}
      >
      </AlertModal>
      <SessionExpiredModal
        open={openSessionExpiredModal}

      />
        <ModalCentered
                title="Bulk Upload"
                open={bulkUploadDilaog}
                toggle={handleShowClose}
                children={(<BulkUpload loading={loading} formSubmit={handleBulkHolidayUpdate}  />)}
            />
    </Root>
  );
};

const mapStateToProps = state => {
  return ({
    force: state, // force state from reducer
    authReducer: state.authReducer,
  })
};

export default connect(mapStateToProps)(Holiday);

