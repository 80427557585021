import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { Typography, Tooltip } from "@mui/material";
import Page from "src/components/Page";
import SnackBar from "src/components/SnackBar/SnackBar";
import { useParams } from "react-router-dom";
import NormalCard from "src/components/Cards/NormalCard";
import { userService } from "src/_services/users";
import ReactSpinner from "src/components/ReactSpinner/ReactSpinner";
import { stubFalse } from "lodash";
import { connect } from "react-redux";
import { logoutSuccess, switchRole } from "src/store/bugs";
import DematAccountData from "src/containers/Profile/DematAccount";
import BankAccountData from "../Profile/BankAccount";
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal";

const PREFIX = 'PartnerDetails';

const classes = {
  hdrlblbtn: `${PREFIX}-hdrlblbtn`,
  tooltip: `${PREFIX}-tooltip`,
  individual: `${PREFIX}-individual`,
  labelname: `${PREFIX}-labelname`,
  details: `${PREFIX}-details`,
  detail: `${PREFIX}-detail`,
  formgrgroup: `${PREFIX}-formgrgroup`,
  btndocuments: `${PREFIX}-btndocuments`,
  btnprimary: `${PREFIX}-btnprimary`,
  reactSpinner: `${PREFIX}-reactSpinner`,
  iconpdf: `${PREFIX}-iconpdf`,
  editbutton: `${PREFIX}-editbutton`,
  first_letter: `${PREFIX}-first_letter`,
  iconClass: `${PREFIX}-iconClass`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.hdrlblbtn}`]: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(0),
    alignItems: "center",
    marginBottom: "1%",
  },

  [`& .${classes.tooltip}`]: {
    cursor: "copy",
  },

  [`& .${classes.individual}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.labelname}`]: {
    [theme.breakpoints.up(576)]: {
      width: "22%",
    },
    width: "32%",
    textAlign: "end",
    fontWeight: "500",
  },

  [`& .${classes.details}`]: {
    // width: "68%",
    marginLeft: theme.spacing(2),
  },

  [`& .${classes.detail}`]: {
    marginTop: "1rem",
    display: "flex",
    marginLeft: theme.spacing(2),
  },

  [`& .${classes.formgrgroup}`]: {
    display: "flex",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.btndocuments}`]: {
    display: "flex",
    alignItems: "center",
    marginLeft: "16px",
  },

  [`& .${classes.btnprimary}`]: {
    borderRadius: "12px 12px 12px 0px",
    marginRight: theme.spacing(2),
    textTransform: "capitalize",
  },

  [`& .${classes.reactSpinner}`]: {
    position: "absolute",
    height: "100%",
    width: "100%",
    background: "#f4f6f870",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  [`& .${classes.iconpdf}`]: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(-1),
  },

  [`& .${classes.editbutton}`]: {
    borderRadius: "14px 14px 0 0",
    boxShadow: "none",
    textTransform: "capitalize",
  },

  [`& .${classes.first_letter}`]: {
    fontSize: "0.87rem",
    marginTop: "3px",
    "&:first-letter": {
      textTransform: "capitalize",
    },
  },

  [`& .${classes.iconClass}`]: {
    cursor: "pointer",
  }
}));

const ShowUserDetails = ({ authReducer }) => {


  let params = useParams();
  const [userBasicInfo, setUserBasicInfo] = useState();
  const [spinner, setSpinner] = useState(true);
  const userID = params.id;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(
    false
  );
  const [severity, setSeverity] = useState("");
  const [snackbarTitle, setSnackbarTitle] = useState("");

  const getBasicInfo = () => {
    userService.getUserDetails(userID).then((res) => {
      if (res) {
        if (res.status === 200) {
          setUserBasicInfo(res.data[0]);
          setSpinner(stubFalse);
        } else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setSpinner(stubFalse);
            setOpenSessionExpiredModal(true);
          } else {
            setSpinner(stubFalse);
            setSeverity("error");
            setSnackbarTitle("The user doesn't exist!");
            setSnackbarOpen(true);
          }
        }
      }
    });
  };

  const [hover, setHover] = useState(false);

  const onHover = (value) => {
    setHover(true);
    navigator.clipboard.writeText(value);
  };

  const CopyText = (value) => {
    return (
      <Tooltip placement="right"
        className={classes.tooltip}
        title={hover ? "Copied" : "Click to Copy"}
      >
        <p
          className={classes.details}
          onClick={() => {
            onHover(value);
          }}
          onMouseLeave={onLeave}
        >
          {value}
        </p>
      </Tooltip>
    );
  };

  useEffect(() => {
    getBasicInfo();
  }, []);

  const onLeave = () => {
    setHover(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Root>
    <Page title="Unlistedkart | UserdetailsPage">
      <div className={classes.hdrlblbtn}>
        <Typography className={classes.heder} variant="h4">
          Users Details
        </Typography>
      </div>
      <NormalCard title="User Details">
        {spinner && (
          <div className={classes.reactSpinner}>
            <ReactSpinner />
          </div>
        )}
        {userBasicInfo && (
          <div className={classes.individual}>
            <div className={classes.formgrgroup}>
              <label className={classes.labelname}>First Name</label>
              {CopyText(userBasicInfo?.first_name)}
            </div>
            <div className={classes.formgrgroup}>
              <label className={classes.labelname}>Last Name</label>
              {CopyText(userBasicInfo?.last_name)}
            </div>
            <div className={classes.formgrgroup}>
              <label className={classes.labelname}>Email</label>
              {CopyText(userBasicInfo?.email)}
            </div>
            <div className={classes.formgrgroup}>
              <label className={classes.labelname}>Phone</label>
              {CopyText(userBasicInfo?.contact_no)}
            </div>

            <div className={classes.formgrgroup}>
              <label className={classes.labelname}>PAN</label>
              {CopyText(userBasicInfo?.pan)}
            </div>
          </div>
        )}
      </NormalCard>
      <DematAccountData userID={userID} edit={false} />
      <BankAccountData userID={userID} edit={false} />

      <SnackBar
        open={snackbarOpen}
        severity={severity}
        close={handleClose}
        snackbarTitle={snackbarTitle}
      />

      <SessionExpiredModal open={openSessionExpiredModal} />
    </Page>
    </Root>
  );
};
const mapStateToProps = (state) => {
  return {
    force: state, // force state from reducer
    authReducer: state.authReducer,
  };
};
const dispatchToProps = (dispatch) => ({
  logoutSuccess: () => dispatch(logoutSuccess()),
  switchRole: (role) => dispatch(switchRole(role)),
});

export default connect(mapStateToProps, dispatchToProps)(ShowUserDetails);
