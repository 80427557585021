import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import ErrorFocus from "../../components/ErrorFocus/ErrorFocus";
import {
  Container,
  TextField,
  Grid,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import moment from "moment";
import loadingSpinner from "src/images/loading-spinner.gif";
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal";
import { getLookUpDataService } from "src/_services/lookUp";
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
const PREFIX = "OrderStatusReport";

const classes = {
  container: `${PREFIX}-container`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  buttonProgress: `${PREFIX}-buttonProgress`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.container}`]: {
    justifyContent: "center",
    // justifyItems: "center",
    marginTop: "10%",
  },
  [`& .${classes.popDownBtn}`]: {
    // borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
    textTransform: "none",
  },
  [`& .${classes.buttonProgress}`]: {
    marginRight: "4px",
  },
}));

const OrderStatusReport = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [internalRM, setInternalRM] = React.useState([]);
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(
    false
  );
  const role = useSelector(state => state?.authReducer?.auth?.user?.default_role_code);
  const userId = useSelector(state => state?.authReducer?.auth?.user?.id);
  const [documentLookUpData, setDocumentLookUpData] = React.useState([]);

  useEffect(() => {
    getLookUpDataService
      .getLookUpUser("SALES,OPERATION")
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            let item = res.data.map((items) => {
              return {
                id: items.id,
                code: items.user_code,
                lable: `${
                  items.user_name
                } ( ${items.user_pan ?items.user_pan?.toUpperCase() :items.user_code.toUpperCase()} )`,
              };
            });

            setInternalRM(item);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
            }
          }
        }
      })
      .catch((error) => {
        console.log("error");
      });
     
  }, []);

  useEffect(() => {
  
    getLookUpDataService.getLookUpData("service_order_status").then((res) => {
      if (res) {
        if (res.status === 200) {
            let item = res.data[0].service_order_status.map((item) => {
                return { id: item.id, lable: item.description,code:item.code };
              });
          setDocumentLookUpData(item);
        } else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenSessionExpiredModal(true);
          }
        }
      }
    });
  }, []);


  const startDate = new Date();
  const initValues = {
    fromDate: moment(
      new Date(startDate.getUTCFullYear(), startDate.getUTCMonth(), 1)
    ).format("YYYY-MM-DD"),
    toDate: moment(new Date()).format("YYYY-MM-DD"),
    internalRM: [],
    externalRM:"",
    client: "",
    status:'',
    role:role
  };

  const selectOption = (
    value,
    options,
    setFieldValue,
    name,
    label,
    touched,
    errors
  ) => {
    return (
      <Grid item xs={12}>
        <Autocomplete
        //   id={name}
          name="investorOrVendor"
          multiple ={name ==='internalRM'}
          value={value}
          options={options}
          getOptionLabel={(option) => option.lable || ""}
          onChange={async (e, value) => {
            if (value !== null) {
              setFieldValue(name, value);
            }else{
              setFieldValue(name, "");
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              helperText={touched && errors}
              error={Boolean(touched && errors)}
            />
          )}
        />
        <ErrorFocus />
      </Grid>
    );
  };

  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialValues={initValues}
        validationSchema={Yup.object().shape({
          fromDate: Yup.string().required("Required"),
          toDate: Yup.string().required("Required"),
          // internalRM: Yup.string().required("Required"),
          internalRM: Yup.string().when("role", {
            is: (role) => role ==='SALES',  
            then: Yup.string(),
            otherwise: Yup.string().required("Required"),
          }),
          status: Yup.string().required("Required")
        })}
        onSubmit={(values) => {
          setLoading(true);
        
          const data = {
            internalRM_id: role ==='SALES' ? userId: values.internalRM.map((item)=>item.id),
            client_id: values.client.id,
            from_date: values.fromDate,
            to_date: values.toDate,
            order_status:values.status.id
          };
          props.formSubmit(data);

        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
            <Container className={classes.container}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="fromDate"
                    label="From Date"
                    type="date"
                    name="fromDate"
                    error={Boolean(touched.fromDate && errors.fromDate)}
                    helperText={touched.fromDate && errors.fromDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      inputProps: {
                        max: `${moment(new Date()).format("YYYY-MM-DD")}`,
                      },
                    }}
                    value={values.fromDate}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <ErrorFocus />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="toDate"
                    label="End Date"
                    type="date"
                    name="toDate"
                    value={values.toDate}
                    InputProps={{
                      inputProps: {
                        min: values.fromDate,
                        max: `${moment(new Date()).format("YYYY-MM-DD")}`,
                      },
                    }}
                    error={Boolean(touched.toDate && errors.toDate)}
                    helperText={touched.toDate && errors.toDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <ErrorFocus />
                </Grid>
                {role !=='SALES' && <Grid item xs={12} sm={12} md={12} lg={12}>
                  {selectOption(
                    values.internalRM,
                    internalRM,
                    setFieldValue,
                    "internalRM",
                    "Internal RM Name *",
                    touched.internalRM,
                    errors.internalRM
                  )}
                </Grid>}
               
                <Grid item xs={12} sm={12} md={12}>
                {selectOption(
                    values.status,
                    documentLookUpData,
                    setFieldValue,
                    "status",
                    `Order status`,
                    touched.status,
                    errors.status
                  )}
                  </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ display: "flex", gap: 2, justifyContent: "end" }}
                >
                  <Button
                    color="primary"
                    className={classes.popDownBtn}
                    size="medium"
                    type="submit"
                    name="submitType"
                    value="report"
                    variant="contained"
                  >
                    {loading === false ? (
                      <> {"Download Report"}</>
                    ) : (
                      <>
                        <img
                          alt="loader"
                          src={loadingSpinner}
                          className={classes.buttonProgress}
                        />{" "}
                        Download Report{" "}
                      </>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </form>
        )}
      </Formik>
      <SessionExpiredModal open={openSessionExpiredModal} />
    </StyledContainer>
  );
};

export default OrderStatusReport;
