import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Container,
  Grid,
} from '@mui/material';

import Page from 'src/components/Page';
import DashboardView from './DashboardView'
import TrafficByDevice from '../../views/reports/DashboardView/TrafficByDevice'
import TopFiveProcureShares from "./TopFiveProcureShares"

const PREFIX = 'InventoryClientDashboard';

const classes = {
  root: `${PREFIX}-root`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const InventoryClientDashboard = () => {


  return (
    <Root>
    <Page
      className={classes.root}
      title="Unlistedkart | Client Dashboard"
    >
      <Container maxWidth={false} style={{padding:'0'}}>
        <Grid container spacing={2}>


        <Grid item lg={9} md={9} xl={8} xs={12} sm={12}>
        <TopFiveProcureShares/> 
        </Grid>

          <Grid item lg={3} md={3} xl={4} xs={12}>
          <TrafficByDevice/> 
          </Grid>

          <Grid item lg={8} md={8} xl={8} xs={12}>
           <DashboardView/>
          </Grid>

        
         
          
         
         
        </Grid>
       

      </Container>
    </Page>
    </Root>
  );
};

export default InventoryClientDashboard;
