import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import {  Button } from "@mui/material";
import Page from "src/components/Page";
import { useNavigate } from "react-router-dom";
import { userService } from "src/_services/users";
import AlertModal from "src/components/AlertModal/AlertModal";
import SnackBar from "src/components/SnackBar/SnackBar";
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal";
import DataTableView from '../../components/Tables/DataTableView'
import { useSelector } from "react-redux";
const PREFIX = 'UsersData';

const classes = {
  tableEllips: `${PREFIX}-tableEllips`,
  root: `${PREFIX}-root`,
  btnright: `${PREFIX}-btnright`,
  container: `${PREFIX}-container`,
  button: `${PREFIX}-button`,
  editdeletbtn: `${PREFIX}-editdeletbtn`,
  btnsdltedt: `${PREFIX}-btnsdltedt`,
  chipMargin: `${PREFIX}-chipMargin`,
  iconbtn: `${PREFIX}-iconbtn`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.tableEllips}`]: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "7rem",
    [theme.breakpoints.down("sm")]: {
      width: "8rem",
    },
    [theme.breakpoints.up("sm")]: {
      width: "7rem",
    },
  },

  [`& .${classes.root}`]: {
    margin: theme.spacing(2),
    "& .MuiTableHead-root": {
      background: "blue",
    },
  },

  [`& .${classes.btnright}`]: {
    textAlign: "end",
  },

  [`& .${classes.container}`]: {
    minHeight: "100%",
    padding: theme.spacing(3),
  },

  [`& .${classes.button}`]: {
    marginBottom: "1%",
    borderRadius: "14px 14px 0 0",
    boxShadow: "none",
    textTransform: "capitalize",
  },

  [`& .${classes.editdeletbtn}`]: {
    color: "#3363cb",
    display: "flex",
  },

  [`& .${classes.btnsdltedt}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.chipMargin}`]: {
    marginRight: "5px",
    marginBottom: "3px",
    fontSize: "12px !important",
    padding: 0,
    height: "20px",
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
  },

  [`& .${classes.iconbtn}`]: {
    padding: theme.spacing(0.8),
  }
}));

const UsersData = () => {
  const role  = useSelector((item)=>item?.authReducer?.auth?.user?.default_role_code)
  const navigate = useNavigate();
  // const [userData, setUserData] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [deleteId, setDeleteId] = useState(null);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [spinner, setSpinner] = useState(false);
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(
    false
  );
  
  useEffect(() => {
  //  getUsers();
  }, []);

  


  // const handleEditTableRow = (id, name, requestId) => {
  //   navigate("/app/user-create?mode=edit", {
  //     replace: true,
  //     state: { userID: id, userName: name, requestId:requestId },
  //   });
  // };

  const goToCreateUserPage = () => {
    navigate("/app/user-create?mode=create", { replace: true });
  };

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleDeleteTableRow = () => {
    setSpinner(true);
    userService
      .deleteUserByID(deleteId)
      .then((res) => {
        if (res.status === 200) {
          // getUsers();
          setDeleteOpen(false);
          setSpinner(false);
          setSeverity("success");
          setSnackbarTitle("User deleted");
          setSnackbarOpen(true);
        } else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setDeleteOpen(false);
            setSpinner(false);
            setOpenSessionExpiredModal(true);
          } else {
            setDeleteOpen(false);
            setSpinner(false);
            setSeverity("error");
            setSnackbarTitle("Something Went Wrong !");
            setSnackbarOpen(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setDeleteOpen(false);
        setSpinner(false);
        setSeverity("error");
        setSnackbarTitle("Invalid Token");
        setSnackbarOpen(true);
      });
  };
  const handleRowDelete = (deleteID)=>{
    setDeleteOpen(true);
    setDeleteId(deleteID);
    
  }

  return (
    <Root>
    <Page title="Unlistedkart | Users List">
      {role ==='ADMIN_VIEWER' ? null: <div className={classes.btnright}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          size="medium"
          onClick={goToCreateUserPage}
        >
          Create User
        </Button>
      </div>}
    <DataTableView  handleRowDelete = {handleRowDelete}/>

      <SnackBar
        open={snackbarOpen}
        severity={severity}
        close={handleClose}
        snackbarTitle={snackbarTitle}
      />

      <AlertModal
        title="Are you sure you want to delete this?"
        open={deleteOpen}
        setOpen={setDeleteOpen}
        toggle={() => setDeleteOpen(false)}
        onConfirm={() => handleDeleteTableRow()}
        openLoading={spinner}
      ></AlertModal>

      <SessionExpiredModal open={openSessionExpiredModal} />
    </Page>
    </Root>
  );
};

export default UsersData;
