import React,{useEffect, useState} from "react";
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import loadingSpinner from 'src/images/loading-spinner.gif'

import {
  FormControl,
  FormHelperText,
  Select,
  Container,
  InputLabel,
  MenuItem,
  TextField,
 
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import ErrorFocus from "../../../components/ErrorFocus/ErrorFocus";

const PREFIX = 'ContactInfoCreateForm';

const classes = {
  zeroPadding: `${PREFIX}-zeroPadding`,
  cardBorderRadius: `${PREFIX}-cardBorderRadius`,
  cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
  fileInput: `${PREFIX}-fileInput`,
  imageSizes: `${PREFIX}-imageSizes`,
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  marginInputLabel: `${PREFIX}-marginInputLabel`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  resendBtnMargin: `${PREFIX}-resendBtnMargin`,
  inputMargin: `${PREFIX}-inputMargin`,
  paddingInput: `${PREFIX}-paddingInput`,
  buttonProgress: `${PREFIX}-buttonProgress`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.zeroPadding}`]: {
    padding: 0,
  },

  [`& .${classes.cardBorderRadius}`]: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },

  [`& .${classes.cardHeaderMargin}`]: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    // justifyContent:"center",
    borderRadius: "0 0  10px 0",
  },

  [`& .${classes.fileInput}`]: {
    display: "none",
  },

  [`& .${classes.imageSizes}`]: {
    width: "40%",
    margin: "auto",
    display: "block",
  },

  [`& .${classes.container}`]: {
    justifyContent: "center",
    // justifyItems: "center",

    marginTop: "10%",
  },

  [`& .${classes.label}`]: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },

  [`& .${classes.marginInputLabel}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.resendBtnMargin}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.inputMargin}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.paddingInput}`]: {
    padding: "0px 0px",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: '4px'
  }
}));





function ContactInfoCreateForm(props) {

  const [loading, setLoading] = useState(false)
  
  const [initValues, setInitValues]=useState({
    name: "",
    email: "",
    mobileNumber: "",
    contactType: "",
  })
 

  useEffect(()=>{
    
    if(props.mode==="edit"){
      setInitValues(props.data)
    }
  },[])
  

  const phoneRegex = RegExp(/^[0-9]{10}$/);

  function capitalizeFirstLetter(str) {
    return str.toLowerCase().split(' ').map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ');
  }

  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialValues={initValues}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Required"),
          email: Yup.string()
            .email("Invalid Email")
            .required("Required"),
          mobileNumber: Yup.string().matches(phoneRegex, "Invalid Mobile Number").required("Required"),
          contactType: Yup.string().required("Required"),
        })}
        onSubmit={(values) => {
          setLoading(true)

          props.formSubmit(values)
           
          
        }}
      >
        {({
          errors,
          handleBlur,
          setFieldValue,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        
        }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>
              <TextField
                className={classes.inputMargin}
                variant="outlined"
                label="Name"
                id="name"
                name="name"
                onBlur={handleBlur}
                onChange={(e)=>setFieldValue('name',capitalizeFirstLetter(e.target.value))}
                fullWidth
                value={values.name}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                autoComplete="off"
                aria-describedby="outlined-weight-helper-text"
              />
              <ErrorFocus />

              <TextField
                className={classes.inputMargin}
                variant="outlined"
                label="Email"
                id="email"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                fullWidth
                value={values.email}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                autoComplete="off"
                aria-describedby="outlined-weight-helper-text"
              />
              <ErrorFocus />

              <TextField
                className={classes.inputMargin}
                variant="outlined"
                label="Mobile Number"
                id="mobileNumber"
                name="mobileNumber"
                onBlur={handleBlur}
                onChange={handleChange}
                fullWidth
                value={values.mobileNumber}
                error={Boolean(touched.mobileNumber && errors.mobileNumber)}
                helperText={touched.mobileNumber && errors.mobileNumber}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                inputProps={{ maxLength: 10 }}
                autoComplete="off"
                aria-describedby="outlined-weight-helper-text"
              />
              <ErrorFocus />              

              <FormControl variant="outlined" fullWidth={true}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Contact Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="contactType"
                  className={classes.inputMargin}
                  label="Contact Type"
                  // select
                  // SelectProps={{ native: true }}
                  variant="outlined"
                  error={Boolean(touched.contactType && errors.contactType)}
                  // helperText={touched.contactType && errors.contactType}
                  name="contactType"
                  value={values.contactType}
                  aria-describedby="outlined-weight-helper-text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  autoComplete="off"
                >
                  <MenuItem key="1" value="PRIMARY">
                    Primary
                  </MenuItem>
                  <MenuItem key="2" value="SECONDARY">
                    Secondary
                  </MenuItem>
                  <MenuItem key="3" value="OTHERS">
                    Other
                  </MenuItem>
                </Select>
                <FormHelperText style={{ color: "#F44336", marginTop:'-8px' }}>
                  {(errors.contactType &&
                    touched.contactType) &&
                    errors.contactType}
                </FormHelperText>
                <ErrorFocus />
              </FormControl>


              <Button
                color="primary"
                disabled={isSubmitting}
                className={classes.popDownBtn}
                size="medium"
                type="submit"
                variant="contained"
              >
               {loading === false ? "Add" : <><img src={loadingSpinner} alt="src" className={classes.buttonProgress}/> Add </>} 
              </Button>
            </Container>
          </form>
        )}
      </Formik>
    </StyledContainer>
  );
}

export default ContactInfoCreateForm;
