import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import MUIDataTable from "mui-datatables";
import { Typography,Tooltip } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {  ThemeProvider } from '@mui/material/styles';

import IconButton from "@mui/material/IconButton";
import AlertModal from 'src/components/AlertModal/AlertModal'
import Chip from '@mui/material/Chip';
import GetMuiTheme from '../theme';
const PREFIX = 'ContactInfo';

const classes = {
    primaryBtn: `${PREFIX}-primaryBtn`,
    root: `${PREFIX}-root`,
    btnright: `${PREFIX}-btnright`,
    container: `${PREFIX}-container`,
    button: `${PREFIX}-button`,
    tooltip: `${PREFIX}-tooltip`,
    editdeletbtn: `${PREFIX}-editdeletbtn`,
    btnsdltedt: `${PREFIX}-btnsdltedt`,
    tableEllips: `${PREFIX}-tableEllips`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.primaryBtn}`]: {
        border: "1px solid #F4F6F8",
        padding: "5px",
        borderRadius: "5px",
        color: "#223464",
        textTransform: "capitalize",
        outline: "none",
        "&:hover": {
            border: "1px solid #fff",
        },

    },

    [`& .${classes.root}`]: {
        margin: theme.spacing(2),
        '& .MuiTableHead-root': {
            background: 'blue'
        }
    },

    [`& .${classes.btnright}`]: {
        textAlign: 'end'
    },

    [`& .${classes.container}`]: {
        // minHeight: '100%',
        padding: '24px'

    },

    [`& .${classes.button}`]: {
        marginBottom: '1%',
        borderRadius: '14px 14px 0 0',
        boxShadow: 'none',
        textTransform: 'capitalize'
    },

    [`& .${classes.tooltip}`]: {
        cursor:'copy'
    },

    [`& .${classes.editdeletbtn}`]: {
        color: '#3363cb'
    },

    [`& .${classes.btnsdltedt}`]: {
        marginRight: theme.spacing(1)
    },

    [`&.${classes.tableEllips}`]: {
        display:"flex",
      }
}));

const getMuiTheme = GetMuiTheme

export default function ContactInfo(props) {
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [accountID,setAccountID] = useState()

    const [hover, setHover] = useState(false);
  const onHover = (value) => {
      setHover(true);
      navigator.clipboard.writeText(value);
    };
  
    const onLeave = () => {
      setHover(false);
    };
  
const customrender=((value)=>{
    return (
        <Root onClick={()=>{onHover(value)}} onMouseLeave={onLeave} className={classes.tableEllips} >
             <Tooltip  className={classes.tooltip} title={hover ? "Copied" :"Click to Copy"}>
         
              <Typography
                  variant='body2'
                  style={{ textTransform: 'capitalize' }}
                 noWrap
                   >
                  {value}
              </Typography>
              </Tooltip>
        </Root>
    );
})
    const contactInfoHeading = [
        {
            name: "name",
            label: "Name",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => customrender(value)

            }
        },
        {
            name: "email",
            label: "Email",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => customrender(value)
            }
        },
        {
            name: "contact_no",
            label: "Mobile Number",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => customrender(value)

            }
        },
        {
            name: "contact_type",
            label: "Default",
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return (
                        <Chip size="small" label={value} color={value==="Primary" ? "primary" : "default"}/>
                    );
                }
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
                customBodyRender: ( value,tableMeta ,) => {
                    return props.edit ? (
                        <span className={classes.editdeletbtn} >
                            <IconButton
                                className={classes.iconbtn}
                                aria-label="edit"
                                onClick={() => props.editHandler(tableMeta.rowData)}
                            >
                                <EditIcon />
                            </IconButton>

                            <IconButton
                                className={classes.iconbtn}
                                aria-label="delete"
                                onClick={() => handleConfirm(tableMeta.rowData[7])}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </span>
                    ) : null;
                }
            }
        },
        {
            name: "last_updated",
            label: "Last Updated",
            options: {
                filter: false,
                sort: false,
                display: false
            }
        },
        {
            name: "contact_type_code",
            label: "Contact",
            options: {
                filter: false,
                sort: false,
                display: false
            }
        },
        {
            name: "id",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display: false
            }
        },
    ];

    const handleConfirm = (id) => {
        setDeleteConfirm(true);
        setAccountID(id)        
        // props.delete
    }

    const handleDeleteTableRow = () => {
        props.deleteRowHandler(accountID);
        setDeleteConfirm(false);
    }
 

    return (
        <div>
            <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    type={props.type}
                    data={props.data}
                    headerSort={false}
                    columns={contactInfoHeading}
                    options={{
                        search: false,
                        viewColumns: false,
                        filter: false,
                        pagination: false,
                        download: true,
                       
                        print: false,
                        rowsPerPage: 5,
                        rowsPerPageOptions: [5, 10, 25],
                        selectableRows: "none",
                        textLabels: {
                            body: {
                                noMatch:  'No contacts found'
                            },
                        },
                        sortOrder: {
                            name: 'last_updated',
                            direction: 'asc'
                        },

                    }}
                />
                <AlertModal
                title="Are you sure you want to delete this contact?"
                open={deleteConfirm}
                toggle={() => setDeleteConfirm(false)}
                onConfirm={handleDeleteTableRow}
                openLoading = {props.openLoading}
            >
            </AlertModal>
            </ThemeProvider>
        </div>
    )
}
