import axios from 'axios'
import { helpers } from 'src/_helpers'
 

export const additionalTradeService = {
    getAdditionalTrade,
    postAdditionalTrade,
    deleteAdditionalTradeByID,
    getAdditionalTradeById,
    updateAdditionTrade,
    getSecondaryTradeList,
    postSecondaryTrade,
    getSecondaryTradeByID,
    putAdditionalTrade,
    updateSecondaryTrade,
    deleteSecondaryTradeByID
};

function getAdditionalTrade() {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/additional_trade`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function getAdditionalTradeById(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/additional_trade/${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function postAdditionalTrade(postAdditionalTradeData) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/additional_trade `,
        JSON.stringify(postAdditionalTradeData), requestOptions)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}

function putAdditionalTrade(data){
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.put(`/additional_trade`,JSON.stringify(data),requestOptions)
        .then(res => res)
        .catch(error => error.response)
}


function deleteAdditionalTradeByID(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.delete(`/additional_trade/${id}`, requestOptions)
}

function updateAdditionTrade(data){
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.put(`/additional_trade`,JSON.stringify(data),requestOptions)
        .then(res => res)
        .catch(error => error.response)
}


//---------------------- Secondary Trade ---------------

function getSecondaryTradeList() {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/secondary_transfer`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function postSecondaryTrade(putTradeData) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/secondary_transfer `,
        JSON.stringify(putTradeData), requestOptions)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}

function getSecondaryTradeByID(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/secondary_transfer/${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function updateSecondaryTrade(data){
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.put(`/secondary_transfer`,JSON.stringify(data),requestOptions)
        .then(res => res)
        .catch(error => error.response)
}

function deleteSecondaryTradeByID(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.delete(`/secondary_transfer/${id}`, requestOptions)
}
