import React from 'react'
import { styled } from '@mui/material/styles';
import { MenuItem,Select } from '@mui/material';
import InputBase from '@mui/material/InputBase';

const PREFIX = 'SelectComponent';

const classes = {
  root: `${PREFIX}-root`,
  input: `${PREFIX}-input`
};

const StyledSelect = styled(Select)((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },

  [`& .${classes.input}`]: {
    borderRadius: 4,
    position: 'relative',
    // backgroundColor: theme.palette.background.paper,
    border: '1px solid black',
    fontSize: 16,
    width:'8rem',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  }
}));

const BootstrapInput = InputBase;

const SelectComponent = (props) => {
  const handleChange=((event)=>{
    props.setValue(event.target.value);
  })
  // console.log(props)
  return (
    <StyledSelect 
    style={{margin:'0 1rem 1rem'}}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.value ? props.value : props.mainValue}
              onChange={handleChange}
              input={<BootstrapInput
                classes={{
                  root: classes.root,
                  input: classes.input
                }} />}
            >
              <MenuItem value={1}>Today</MenuItem>
              <MenuItem value={2}>Yesterday</MenuItem>
              <MenuItem value={3}>Last 7 Days</MenuItem>
              <MenuItem value={4}>Current Month</MenuItem>
              <MenuItem value={5}>Last Month</MenuItem>
              <MenuItem value={6}>Last 3 Months</MenuItem>
              <MenuItem value={7}>Quarter to Date</MenuItem>
              <MenuItem value={8}>Last 12 Months</MenuItem>
              <MenuItem value={9}>Year to Date</MenuItem>
              <MenuItem value={10}>Custom...</MenuItem>
    </StyledSelect>
  );
}

export default SelectComponent