
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import React, { useState, useEffect } from "react";
import { AllFaq } from "src/_services/faq's";
import {
    Button,
    Table,
    TableHead,
    TableRow,TableCell,
    Typography,TableBody,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogContent,Grow
} from "@mui/material";
import { useSelector } from "react-redux";

const FaqSortIndex = ()=> {
    // const [value, setValue] = React.useState('');
    const role = useSelector((user)=>user.authReducer.auth.user.default_role_code)
    const [open, setOpen] = React.useState(false);
    const [allFaqData, setAllFaqData] = useState([]);
    const [dragComplete,setDragComplete] = useState(false);
    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
    const handleDragEnd = (e) => {
        if (!e.destination) return;
        let tempData = Array.from(allFaqData);
        let [source_data] = tempData.splice(e.source.index, 1);
        tempData.splice(e.destination.index, 0, source_data);
        setAllFaqData(tempData);
        setDragComplete(true);
    };
    const getAllFaq = () => {
        AllFaq.getAllFaq()
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                setAllFaqData(res.data.data);
              }
            }
          })
          .catch((error) => console.log(error));
      };
      useEffect(() => {
        getAllFaq();
      }, [])


    const saveData = () => {
        const newArray = []
        for(let i=0;i<allFaqData.length;i++){
            newArray.push({
                "id":allFaqData[i].id,
                "faq_sort":i+1,
            })
        }
        AllFaq.updateFaqSort(newArray).then(res => {
            getAllFaq();
            setOpen(false)
        })
            .catch(error => {
                console.log("error");
            })
    }
    return (
        <div className="App mt-4" style={{ fontFamily: "sans-serif", textAlign: "center" }} >
        {role ==='ADMIN_VIEWER' ? null :<div style={{ fontFamily: "sans-serif", textAlign: "right",margin:"14px",paddingLeft:"15px" }}>
            <Button style={{backgroundColor:"#3f51b5",color:"white"}} disabled={dragComplete === false}  onClick={handleClickOpen}>
                Save
            </Button><br></br>
        </div>}
        <DragDropContext onDragEnd={handleDragEnd}>
            <Grow in={true} style={{transitionDelay:100}}>
            <Table className="table borderd">
                <TableHead style={{ textAlign: "left" }}>
                    <TableRow>
                        <TableCell />
                        <TableCell>Category</TableCell>
                        <TableCell>Question</TableCell>
                    </TableRow>
                </TableHead>
                <Droppable droppableId="droppable-1">
                    {(provider) => (
                        <TableBody
                            className="text-capitalize"
                            ref={provider.innerRef}
                            {...provider.droppableProps}
                            style={{ textAlign: "left" }}
                        >
                            {allFaqData?.map((item, index) => (
                                <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}

                                >
                                    {(provider, snapshot) => (
                                        <TableRow {...provider.draggableProps}
                                            {...provider.dragHandleProps}
                                            ref={provider.innerRef}
                                        >
                                            <TableCell {...provider.dragHandleProps}
                                            >  </TableCell>
                                            <TableCell style={{textTransform:"capitalize"}} {...provider.dragHandleProps}>{item.name}</TableCell>
                                            <TableCell {...provider.dragHandleProps}>{item.question}</TableCell>
                                          
                                        </TableRow>
                                    )}
                                </Draggable>
                            ))}
                            {provider.placeholder}
                        </TableBody>
                    )}
                </Droppable>
            </Table>
            </Grow>
        </DragDropContext>
        <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
<Typography component="span">Do you want to Save ?</Typography>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}      color="primary">No</Button>
      <Button onClick={saveData} autoFocus   color="primary">
        Yes
      </Button>
    </DialogActions>
  </Dialog>
    </div>
    );
}

export default FaqSortIndex;
