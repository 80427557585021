import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import Button from "@mui/material/Button";

import ErrorFocus from "../../components/ErrorFocus/ErrorFocus";
import Autocomplete from "@mui/material/Autocomplete";
import {
  FormControl,
  FormGroup,
  Checkbox,
  Container,
  TextField,
  Grid,
  FormHelperText,
  FormControlLabel,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import { serviceOrderService } from "src/_services/serviceOrder";
import { getLookUpDataService } from "src/_services/lookUp";
import loadingSpinner from "src/images/loading-spinner.gif";
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal";

const PREFIX = "VerifiedChecklistAndDetails";

const classes = {
  zeroPadding: `${PREFIX}-zeroPadding`,
  cardBorderRadius: `${PREFIX}-cardBorderRadius`,
  cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
  fileInput: `${PREFIX}-fileInput`,
  imageSizes: `${PREFIX}-imageSizes`,
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  marginInputLabel: `${PREFIX}-marginInputLabel`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  resendBtnMargin: `${PREFIX}-resendBtnMargin`,
  inputMargin: `${PREFIX}-inputMargin`,
  paddingInput: `${PREFIX}-paddingInput`,
  buttonProgress: `${PREFIX}-buttonProgress`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.zeroPadding}`]: {
    padding: 0,
  },

  [`& .${classes.cardBorderRadius}`]: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },

  [`& .${classes.cardHeaderMargin}`]: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    // justifyContent:"center",
    borderRadius: "0 0  10px 0",
  },

  [`& .${classes.fileInput}`]: {
    display: "none",
  },

  [`& .${classes.imageSizes}`]: {
    width: "40%",
    margin: "auto",
    display: "block",
  },

  [`& .${classes.container}`]: {
    justifyContent: "center",
    // justifyItems: "center",

    marginTop: "10%",
  },

  [`& .${classes.label}`]: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },

  [`& .${classes.marginInputLabel}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    backgroundColor: "#324c90",
    marginBottom: 16,
  },

  [`& .${classes.resendBtnMargin}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.inputMargin}`]: {
    marginTop: 16,
    marginBottom: 16,
  },

  [`& .${classes.paddingInput}`]: {
    padding: "0px 0px",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: "4px",
  },
}));

function VerifiedChecklistAndDetails(props) {
  const [loading, setLoading] = React.useState(false);

  const getMuiTheme = () =>
    createTheme({
      palette: {
        primary: {
          main: "#314B8F",
        },
      },
      overrides: {
        MuiTypography: {
          body1: {
            width: "max-content",
          },
        },
      },
    });

  let [verificationData, setVerificationData] = React.useState({
    is_client_details_verified: false,
    is_demat_account_verified: false,
    is_security_recieved_or_transferred_verified: false,
    security_transaction_id: null,
    is_payment_recieved_or_transferred_verified: false,
    payment_transaction_id: null,
    is_signed_deal_term_uploaded_verified: false,
    is_shared_credit_confirmed_verified: false,
    order_type: props.service_order_type,
    is_sell_buy_order_mapped: false,
  });

  const clientDetails = { id: 1, lable: "Client Details" };
  const dematAccount = { id: 1, lable: "Demat Account" };

  const signedDealTermUploaded = { id: 1, lable: "Signed Deal Term Uploaded" };
  const [salesAndOperation, setSalesAndOperation] = React.useState([]);
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(
    false
  );

  function getVerifiedData(id) {
    serviceOrderService
      .getVerifiedData(id)
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            setVerificationData({
              service_order_id: props.service_order_id,
              is_client_details_verified:
                res.data.is_client_details_verified.value || false,
              is_demat_account_verified:
                res.data.is_demat_account_verified.value || false,
              is_security_recieved_or_transferred_verified:
                res.data.is_security_recieved_or_transferred_verified.value ||
                false,
              security_transaction_id:
                res.data.security_transaction_id.value || null,
              is_payment_recieved_or_transferred_verified:
                res.data.is_payment_recieved_or_transferred_verified.value ||
                false,
              payment_transaction_id:
                res.data.payment_transaction_id.value || null,

              is_signed_deal_term_uploaded_verified:
                res.data.is_signed_deal_term_uploaded_verified.value || false,
              is_shared_credit_confirmed_verified:
                res.data.is_shared_credit_confirmed_verified.value || false,
              assign: "",
              stepLevel: props.step,
              order_type: props.service_order_type,
              is_sell_buy_order_mapped: res.data.is_sell_buy_order_mapped,
            });
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getVerifiedData(props.service_order_id);

    getLookUpDataService
      .getLookUpUser("SALES,OPERATION")
      .then((res) => {
        if (res) {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenSessionExpiredModal(true);
          }

          if (res.status === 200) {
            let item = res.data.map((items) => {
              return {
                id: items.id,
                code: items.user_code,
                lable: `${items.user_name}`,
              };
            });
            setSalesAndOperation(item);
          }
        }
      })
      .catch((error) => {
        console.log("error");
      });
  }, []);

  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialValues={verificationData}
        validationSchema={
          props.mode === "create"
            ? Yup.object().shape({
                is_client_details_verified: Yup.boolean()
                  .nullable()
                  .when("stepLevel", {
                    is: "4",
                    then: Yup.boolean()
                      .oneOf([true], "The Field is Required.")
                      .nullable()
                      .required("The Field is Required."),

                    otherwise: Yup.boolean().nullable(),
                  }),
                is_demat_account_verified: Yup.boolean()
                  .nullable()
                  .when("stepLevel", {
                    is: "4",
                    then: Yup.boolean()
                      .oneOf([true], "The Field is Required.")
                      .nullable()
                      .required("The Field is Required."),

                    otherwise: Yup.boolean().nullable(),
                  }),
                is_security_recieved_or_transferred_verified: Yup.boolean()
                  .nullable()
                  .when("stepLevel", {
                    is: "4",
                    then: Yup.boolean()
                      .oneOf([true], "The Field is Required.")
                      .nullable()
                      .required("The Field is Required."),

                    otherwise: Yup.boolean().nullable(),
                  }),
                is_payment_recieved_or_transferred_verified: Yup.boolean()
                  .nullable()
                  .when("stepLevel", {
                    is: "4",
                    then: Yup.boolean()
                      .oneOf([true], "The Field is Required.")
                      .nullable()
                      .required("The Field is Required."),

                    otherwise: Yup.boolean().nullable(),
                  }),
                is_signed_deal_term_uploaded_verified: Yup.boolean()
                  .nullable()
                  .when("stepLevel", {
                    is: "4",
                    then: Yup.boolean()
                      .oneOf([true], "The Field is Required.")
                      .nullable()
                      .required("The Field is Required."),

                    otherwise: Yup.boolean().nullable(),
                  }),
                is_shared_credit_confirmed_verified: Yup.boolean()
                  .nullable()
                  .when("stepLevel", {
                    is: "4",
                    then: Yup.boolean()
                      .oneOf([true], "The Field is Required.")
                      .nullable()
                      .required("The Field is Required."),

                    otherwise: Yup.boolean().nullable(),
                  }),
                security_transaction_id: Yup.string()
                  .nullable()
                  .when("is_security_recieved_or_transferred_verified", {
                    is: true,
                    then: Yup.string().required("Required"),
                    else: Yup.string().nullable(true),
                  }),
                payment_transaction_id: Yup.string()
                  .nullable()
                  .when("is_payment_recieved_or_transferred_verified", {
                    is: true,
                    then: Yup.string().required("Required"),
                    else: Yup.string().nullable(true),
                  }),

                is_sell_buy_order_mapped:
                  props.service_order_type === "SELL"
                    ? Yup.boolean()
                        .nullable()
                        .when("stepLevel", {
                          is: "4",
                          then: Yup.boolean()
                            .oneOf([true], "Buy and Sell must be Mapped.")
                            .nullable()
                            .required("Buy and Sell must be Mapped."),

                          otherwise: Yup.boolean().nullable(),
                        })
                    : Yup.boolean(),
              })
            : Yup.object().shape({
                security_transaction_id: Yup.string()
                  .nullable()
                  .when("is_security_recieved_or_transferred_verified", {
                    is: true,
                    then: Yup.string().required("Required"),
                    else: Yup.string().nullable(true),
                  }),
                payment_transaction_id: Yup.string()
                  .nullable()
                  .when("is_payment_recieved_or_transferred_verified", {
                    is: true,
                    then: Yup.string().required("Required"),
                    else: Yup.string().nullable(true),
                  }),
              })
        }
        onSubmit={(values) => {
          setLoading(true);
          delete values.stepLevel;
          delete values.order_type;

          props.formSubmit(values);
        }}
      >
        {({
          errors,
          handleBlur,

          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>
              <ThemeProvider theme={getMuiTheme}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl component="fieldset" color="primary">
                      <FormGroup value={values.is_client_details_verified}>
                        <FormControlLabel
                          value={values.is_client_details_verified}
                          checked={values.is_client_details_verified}
                          disabled={
                            verificationData.is_client_details_verified ===
                              true && props.mode === "create"
                          }
                          color="primary"
                          control={
                            <Checkbox
                              error={Boolean(
                                errors.is_client_details_verified &&
                                  touched.is_client_details_verified
                              )}
                              helperText={
                                errors.is_client_details_verified &&
                                touched.is_client_details_verified
                              }
                              color="primary"
                              onChange={(e, value) => {
                                setFieldValue(
                                  "is_client_details_verified",
                                  value
                                );
                              }}
                              name="is_client_details_verified"
                            />
                          }
                          label={clientDetails.lable}
                        />
                      </FormGroup>
                    </FormControl>
                    <FormHelperText
                      style={{ color: "#F44336", paddingLeft: 16 }}
                    >
                      {errors.is_client_details_verified &&
                        touched.is_client_details_verified &&
                        errors.is_client_details_verified}
                    </FormHelperText>
                    <ErrorFocus />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl component="fieldset" color="primary">
                      <FormGroup value={values.is_demat_account_verified}>
                        <FormControlLabel
                          value={values.is_demat_account_verified}
                          checked={values.is_demat_account_verified}
                          disabled={
                            verificationData.is_demat_account_verified ===
                              true && props.mode === "create"
                          }
                          color="primary"
                          control={
                            <Checkbox
                              error={Boolean(
                                errors.is_demat_account_verified &&
                                  touched.is_demat_account_verified
                              )}
                              helperText={
                                errors.is_demat_account_verified &&
                                touched.is_demat_account_verified
                              }
                              color="primary"
                              onChange={(e, value) => {
                                setFieldValue(
                                  "is_demat_account_verified",
                                  value
                                );
                              }}
                              name="is_demat_account_verified"
                            />
                          }
                          label={dematAccount.lable}
                        />
                      </FormGroup>
                    </FormControl>
                    <FormHelperText
                      style={{ color: "#F44336", paddingLeft: 16 }}
                    >
                      {errors.is_demat_account_verified &&
                        touched.is_demat_account_verified &&
                        errors.is_demat_account_verified}
                    </FormHelperText>
                    <ErrorFocus />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl component="fieldset" color="primary">
                      <FormGroup
                        value={
                          values.is_security_recieved_or_transferred_verified
                        }
                      >
                        <FormControlLabel
                          value={
                            values.is_security_recieved_or_transferred_verified
                          }
                          checked={
                            values.is_security_recieved_or_transferred_verified
                          }
                          disabled={
                            verificationData.is_security_recieved_or_transferred_verified ===
                              true && props.mode === "create"
                          }
                          color="primary"
                          control={
                            <Checkbox
                              error={Boolean(
                                errors.is_security_recieved_or_transferred_verified &&
                                  touched.is_security_recieved_or_transferred_verified
                              )}
                              helperText={
                                errors.is_security_recieved_or_transferred_verified &&
                                touched.is_security_recieved_or_transferred_verified
                              }
                              color="primary"
                              onChange={(e, value) => {
                                if (value === true) {
                                  setFieldValue(
                                    "is_security_recieved_or_transferred_verified",
                                    value
                                  );
                                } else {
                                  setFieldValue(
                                    "is_security_recieved_or_transferred_verified",
                                    value
                                  );
                                }
                              }}
                              name="is_security_recieved_or_transferred_verified"
                            />
                          }
                          label={
                            props.service_order_type === "SELL"
                              ? "Scrip Transferred"
                              : "Scrip Received"
                          }
                        />
                      </FormGroup>
                    </FormControl>
                    <FormHelperText
                      style={{ color: "#F44336", paddingLeft: 16 }}
                    >
                      {errors.is_security_recieved_or_transferred_verified &&
                        touched.is_security_recieved_or_transferred_verified &&
                        errors.is_security_recieved_or_transferred_verified}
                    </FormHelperText>
                    <ErrorFocus />
                  </Grid>

                  {values.is_security_recieved_or_transferred_verified ===
                  true ? (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        className={classes.inputMargin}
                        disabled={
                          verificationData.is_security_recieved_or_transferred_verified ===
                            true && props.mode === "create"
                        }
                        multiline
                        variant="outlined"
                        label="Scrip Transaction Id *"
                        id="security_transaction_id"
                        type="text"
                        name="security_transaction_id"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue(
                            "security_transaction_id",
                            e.target.value
                          );
                        }}
                        fullWidth
                        value={values.security_transaction_id}
                        error={Boolean(
                          touched.security_transaction_id &&
                            errors.security_transaction_id
                        )}
                        helperText={
                          touched.security_transaction_id &&
                          errors.security_transaction_id
                        }
                        aria-describedby="outlined-weight-helper-text"
                        autoComplete="off"
                        // onInput={(e) => { e.target.value = e.target.value.replace(/^\d*\.?\d*$/g, "") }}
                        // onKeyPress={(e)=>{e.target.value = e.target.value.replace(/^\d+\.\d{0,2}$/,"")}}
                      />
                      <ErrorFocus />
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl component="fieldset" color="primary">
                      <FormGroup
                        value={
                          values.is_payment_recieved_or_transferred_verified
                        }
                      >
                        <FormControlLabel
                          value={
                            values.is_payment_recieved_or_transferred_verified
                          }
                          checked={
                            values.is_payment_recieved_or_transferred_verified
                          }
                          disabled={
                            verificationData.is_payment_recieved_or_transferred_verified ===
                              true && props.mode === "create"
                          }
                          color="primary"
                          control={
                            <Checkbox
                              color="primary"
                              error={Boolean(
                                errors.is_payment_recieved_or_transferred_verified &&
                                  touched.is_payment_recieved_or_transferred_verified
                              )}
                              helperText={
                                errors.is_payment_recieved_or_transferred_verified &&
                                touched.is_payment_recieved_or_transferred_verified
                              }
                              onChange={(e, value) => {
                                if (value === true) {
                                  setFieldValue(
                                    "is_payment_recieved_or_transferred_verified",
                                    value
                                  );
                                } else {
                                  setFieldValue(
                                    "is_payment_recieved_or_transferred_verified",
                                    value
                                  );
                                }
                              }}
                              name="is_payment_recieved_or_transferred_verified"
                            />
                          }
                          label={
                            props.service_order_type === "SELL"
                              ? "Payment Received"
                              : "Payment Transferred"
                          }
                        />
                      </FormGroup>
                    </FormControl>
                    <FormHelperText
                      style={{ color: "#F44336", paddingLeft: 16 }}
                    >
                      {errors.is_payment_recieved_or_transferred_verified &&
                        touched.is_payment_recieved_or_transferred_verified &&
                        errors.is_payment_recieved_or_transferred_verified}
                    </FormHelperText>
                    <ErrorFocus />
                  </Grid>
                  {values.is_payment_recieved_or_transferred_verified ===
                  true ? (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        className={classes.inputMargin}
                        disabled={
                          verificationData.is_payment_recieved_or_transferred_verified ===
                            true && props.mode === "create"
                        }
                        multiline
                        variant="outlined"
                        label="Payment Transferred Id *"
                        id="payment_transaction_id"
                        type="text"
                        name="payment_transaction_id"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          if (
                            values.is_payment_recieved_or_transferred_verified ===
                            true
                          ) {
                            setFieldValue(
                              "payment_transaction_id",
                              e.target.value
                            );
                          } else {
                            setFieldValue(
                              "payment_transaction_id",
                              e.target.value
                            );
                          }
                        }}
                        fullWidth
                        value={values.payment_transaction_id}
                        error={Boolean(
                          touched.payment_transaction_id &&
                            errors.payment_transaction_id
                        )}
                        helperText={
                          touched.payment_transaction_id &&
                          errors.payment_transaction_id
                        }
                        aria-describedby="outlined-weight-helper-text"
                        autoComplete="off"
                        // onInput={(e) => { e.target.value = e.target.value.replace(/^\d*\.?\d*$/g, "") }}
                        // onKeyPress={(e)=>{e.target.value = e.target.value.replace(/^\d+\.\d{0,2}$/,"")}}
                      />
                      <ErrorFocus />
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl component="fieldset" color="primary">
                      <FormGroup
                        value={values.is_signed_deal_term_uploaded_verified}
                      >
                        <FormControlLabel
                          value={values.is_signed_deal_term_uploaded_verified}
                          checked={values.is_signed_deal_term_uploaded_verified}
                          disabled={
                            verificationData.is_signed_deal_term_uploaded_verified ===
                              true && props.mode === "create"
                          }
                          color="primary"
                          control={
                            <Checkbox
                              color="primary"
                              error={Boolean(
                                errors.is_signed_deal_term_uploaded_verified &&
                                  touched.is_signed_deal_term_uploaded_verified
                              )}
                              helperText={
                                errors.is_signed_deal_term_uploaded_verified &&
                                touched.is_signed_deal_term_uploaded_verified
                              }
                              onChange={(e, value) => {
                                setFieldValue(
                                  "is_signed_deal_term_uploaded_verified",
                                  value
                                );
                              }}
                              name="is_signed_deal_term_uploaded_verified"
                            />
                          }
                          label={signedDealTermUploaded.lable}
                        />
                      </FormGroup>
                    </FormControl>
                    <FormHelperText
                      style={{ color: "#F44336", paddingLeft: 16 }}
                    >
                      {errors.is_signed_deal_term_uploaded_verified &&
                        touched.is_signed_deal_term_uploaded_verified &&
                        errors.is_signed_deal_term_uploaded_verified}
                    </FormHelperText>
                    <ErrorFocus />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl component="fieldset" color="primary">
                      <FormGroup
                        value={values.is_shared_credit_confirmed_verified}
                      >
                        <FormControlLabel
                          value={values.is_shared_credit_confirmed_verified}
                          checked={values.is_shared_credit_confirmed_verified}
                          disabled={
                            verificationData.is_shared_credit_confirmed_verified ===
                              true && props.mode === "create"
                          }
                          color="primary"
                          control={
                            <Checkbox
                              color="primary"
                              error={Boolean(
                                errors.is_shared_credit_confirmed_verified &&
                                  touched.is_shared_credit_confirmed_verified
                              )}
                              helperText={
                                errors.is_shared_credit_confirmed_verified &&
                                touched.is_shared_credit_confirmed_verified
                              }
                              onChange={(e, value) => {
                                setFieldValue(
                                  "is_shared_credit_confirmed_verified",
                                  value
                                );
                              }}
                              name="is_shared_credit_confirmed_verified"
                            />
                          }
                          label="Shared Credit Confirmed"
                        />
                      </FormGroup>
                    </FormControl>
                    <FormHelperText
                      style={{ color: "#F44336", paddingLeft: 16 }}
                    >
                      {errors.is_shared_credit_confirmed_verified &&
                        touched.is_shared_credit_confirmed_verified &&
                        errors.is_shared_credit_confirmed_verified}
                    </FormHelperText>
                    <ErrorFocus />
                  </Grid>
                  {props.mode === "create" && values.order_type === "SELL" ? (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <FormControl component="fieldset" color="primary">
                        <FormGroup value={values.is_sell_buy_order_mapped}>
                          <FormControlLabel
                            value={values.is_sell_buy_order_mapped}
                            checked={values.is_sell_buy_order_mapped}
                            disabled={true}
                            color="primary"
                            control={
                              <Checkbox
                                color="primary"
                                error={Boolean(
                                  errors.is_sell_buy_order_mapped &&
                                    touched.is_sell_buy_order_mapped
                                )}
                                helperText={
                                  errors.is_sell_buy_order_mapped &&
                                  touched.is_sell_buy_order_mapped
                                }
                                name="is_sell_buy_order_mapped"
                              />
                            }
                            label="Buy, Sell Mapping"
                          />
                        </FormGroup>
                      </FormControl>
                      <FormHelperText
                        style={{ color: "#F44336", paddingLeft: 16 }}
                      >
                        {errors.is_sell_buy_order_mapped &&
                          touched.is_sell_buy_order_mapped &&
                          errors.is_sell_buy_order_mapped}
                      </FormHelperText>
                      <ErrorFocus />
                    </Grid>
                  ) : (
                    <></>
                  )}

                  {props.mode === "create" ? (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Autocomplete
                        id="assign"
                        name="assign"
                        options={salesAndOperation}
                        getOptionLabel={(option) => option.lable}
                        onChange={(e, value) => {
                          if (value !== null) {
                            setFieldValue("assign", value.code);
                          } else {
                            setFieldValue("assign", "");
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="assign"
                            label="Assign *"
                            fullWidth
                            variant="outlined"
                            className={classes.inputMargin}
                          />
                        )}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}

                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      className={classes.popDownBtn}
                      size="medium"
                      type="submit"
                      variant="contained"
                    >
                      {loading === false ? (
                        "Update"
                      ) : (
                        <>
                          <img
                            alt="loader"
                            src={loadingSpinner}
                            className={classes.buttonProgress}
                          />{" "}
                          Update{" "}
                        </>
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </ThemeProvider>
            </Container>
          </form>
        )}
      </Formik>
      <SessionExpiredModal open={openSessionExpiredModal} />
    </StyledContainer>
  );
}

export default VerifiedChecklistAndDetails;
