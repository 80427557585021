import axios from 'axios'




export const RegisterService = {
    registerMobileNumber,
    verifyRegisterData,
    verifyRegisterOTP
};

function registerMobileNumber(mobileNumber) {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/public_user/otpauthentication`, JSON.stringify({ mobile_number: mobileNumber}), requestOptions)
        .then(res => res)
        .catch(error => error.response)
}

function verifyRegisterOTP(data){
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/public_user`, JSON.stringify(data), requestOptions)
        .then(res => {
            return res
        })
        .catch(error => {
            return error.response
        })
}

function verifyRegisterData(attribute,regNo){
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/validate_user?check_attribute=${attribute}&value=${regNo}`, requestOptions)
        .then(res => {
            return res
        })
        .catch(error => {
            return error.response
        })
}