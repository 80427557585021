import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Typography, IconButton } from "@mui/material";
import {  ThemeProvider } from "@mui/material/styles";
import Page from "src/components/Page";
import { userService } from "src/_services/users";
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal";
import NormalCard from "src/components/Cards/NormalCard";
import { useParams, useLocation } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import moment from "moment";
import ModalCentered from "src/components/Modals/ModalCentered";
// import ReactJson from 'react-json-view'
import GetMuiTheme from "src/components/theme";


const PartnerLogs = () => {
    let params = useParams();
    const [title,setTitle] = useState('')
    const [partnerLogs, setPartnerLogs] = useState([]);

    let location = useLocation();

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openSessionExpiredModal, setOpenSessionExpiredModal] = useState(
        false
    ); 
 

    const getMuiTheme = GetMuiTheme

    const getLogs = () => {
        setLoading(true);
        let id = (params.id).toLocaleLowerCase()
        userService
            .getPartnerLogs(id)
            .then((res) => {
                if (res) {
                    if (res.status === 200) {
                        setPartnerLogs(res.data.data)
                        setLoading(false);
                    } else {
                        if (res.status === 401 && res.data.name === "TokenExpiredError") {
                            setLoading(false);
                            setOpenSessionExpiredModal(true);
                        }
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    const columns = [
        {
            name: "_id",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                hide: true,
                display: false,
            },
        },
        {
            name: "_id",
            label: "SL No",
            options: {
                filter: false,
                sort: false,
                hide: false,
                display: true,
                customBodyRender: (value, rowData) => {
                    return (
                        <div>
                            <Typography variant="body2" noWrap>
                                {rowData.rowIndex + 1}
                            </Typography>
                        </div>
                    );
                },
            },
        },
        {
            name: "apiname",
            label: "API Name",
        },
        {
            name: "createdate",
            label: "Created On",
            options: {
                filter: false,
                sort: false,
                hide: false,
                display: true,
                customBodyRender: (value) => {
                    const timezoneOffset = '+05:30';
                    let valueMoment = moment(value).add(timezoneOffset, 'hours').format('DD-MM-YYYY LT')
                    return (
                        <div>
                            <Typography variant="body2" noWrap>
                                {valueMoment}
                            </Typography>
                        </div>
                    );
                },
            },
        },
        {
            name: "apirequest",
            label: "API Data",
            options: {
                filter: false,
                customBodyRender: (value, tableData) => {
                    console.log(tableData)

                    return (
                        <IconButton
                            // className={classes.iconbtn}
                            aria-label="delete"
                            onClick={() => {
                                setOpen(tableData.rowIndex + 1)
                                setTitle(tableData.rowData[2])
                            }}
                        >
                            <VisibilityIcon />
                        </IconButton>
                    );
                },
            },
        }
    ];

    useEffect(() => {
        getLogs()
    }, [])

    

    return (
        <Page title="Unlistedkart | Partners List">
            <NormalCard
                title={`${location?.state?.name ? location?.state?.name : 'Partner'} API Logs`}
            >
                <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        data={partnerLogs}
                        columns={columns}
                        options={{
                            download: true,
                            print: false,
                            rowsPerPage: 10,
                            rowsPerPageOptions: [10, 25, 40],
                            // responsive: 'scrollMaxHeight',
                            selectableRows: "none",
                            sortOrder: {
                                name: "last_updated",
                                direction: "desc",
                            },
                            textLabels: {
                                body: {
                                    noMatch: loading
                                        ? "Loading..."
                                        : "Sorry, there are no matching data to display",
                                },
                            },
                        }}
                    />
                </ThemeProvider>
                <ModalCentered
                    title={title}
                    open={open}
                    toggle={() => setOpen(false)}
                    children={
                        <>
                            <Typography>
                                <b>Request:</b>
                                {/* {isJSON(partnerLogs[open - 1]?.apirequest) && open?
                                    <ReactJson
                                        displayDataTypes={false}
                                        enableClipboard={(e)=> navigator.clipboard.writeText(JSON.stringify(e.src))}
                                        src={JSON.parse(partnerLogs[open - 1]?.apirequest)} />
                                         :
                                    <span>
                                        {partnerLogs[open - 1]?.apirequest}
                                    </span>} */}
                                <br />
                            </Typography>
                            <Typography>
                                <b> Response:</b>
                                {/* {isJSON(partnerLogs[open - 1]?.apiresponse) && open?
                                    <ReactJson
                                        displayDataTypes={false}
                                        enableClipboard={(e)=> navigator.clipboard.writeText(JSON.stringify(e.src))}
                                        src={JSON.parse(partnerLogs[open - 1]?.apiresponse)} /> : <span>
                                        {partnerLogs[open - 1]?.apiresponse}
                                    </span>} */}
                            </Typography>
                        </>

                    }
                />
                <SessionExpiredModal open={openSessionExpiredModal} />
            </NormalCard>
        </Page>

    )
}

export default PartnerLogs