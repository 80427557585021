import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Container,
  Typography,
} from '@mui/material';
import Page from 'src/components/Page';
const PREFIX = 'NotFoundView';

const classes = {
  root: `${PREFIX}-root`,
  image: `${PREFIX}-image`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },

  [`& .${classes.image}`]: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560,
    mixBlendMode:'darken'
  }
}));

const NotFoundView = () => {


  return (
    <Root>
    <Page
      title="404"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        sx={{backgroundColor:'#f4f6f8',padding:0}}
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Typography
            align="center"
            color="textPrimary"
            variant="h1"
          >
            404: The page you are looking for isn’t here
          </Typography>
          <Typography
            align="center"
            color="textPrimary"
            variant="subtitle2"
          >
            You either tried some shady route or you came here by mistake.
            Whichever it is, try using the navigation
          </Typography>
          <Box textAlign="center">
            <img
              alt="Under development"
              className={classes.image}

              src="https://blog.thomasnet.com/hubfs/shutterstock_774749455.jpg"
            />
          </Box>
        </Container>
      </Box>
    </Page>
    </Root>
  );
};

export default NotFoundView;
