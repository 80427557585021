import React,{useState} from 'react'
import { styled } from '@mui/material/styles';
import { Tooltip,Typography } from '@mui/material';
const PREFIX = 'CopyTextTable';

const classes = {
  formgrgroup: `${PREFIX}-formgrgroup`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.formgrgroup}`]: {
   display: "flex",
   marginTop: theme.spacing(1),
   marginBottom: theme.spacing(1),
 }
}));

const CopyTextTable = ({value,escrow}) => {

    const [copy, setCopy] = useState(false);

    const onHover = (value) => {
        setCopy(true);
        navigator.clipboard.writeText(value);
      };
    
      const onLeave = () => {
        setCopy(false);
      };


    return (
      (<Root>
        <div
            onClick={() => onHover(value)}
            onMouseLeave={onLeave}
            // className={classes.formgrgroup}
          >
            <Tooltip
              className={classes.tooltip}
              title={copy ? "Copied" : "Click to Copy"}
            >
              <Typography>{value}</Typography>
            </Tooltip>
          </div>
      </Root>)
    );
  };
export default CopyTextTable

