import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import "./sessionStyle.css"
import { Typography } from '@mui/material';
import { logoutSuccess } from "src/store/bugs";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

const SessionExpiredModal = (props) => {

    const navigate = useNavigate();

    const onConfirmClick = () => {
        props.logoutSuccess();
        navigate("/login", { replace: true });
    }
    return (
        <div>
            <SweetAlert
                title=""
                show={props.open}
                confirmBtnText="OK"
                onConfirm={onConfirmClick}
                confirmBtnBsStyle="primary"
            >
                <Typography variant={"h6"} color="primary">Session has been expired! <br />Please re-login to countinue</Typography>
            </SweetAlert>

        </div>
    );
}

const dispatchToProps = (dispatch) => ({
    logoutSuccess: () => dispatch(logoutSuccess()),
});

export default connect(null, dispatchToProps)(SessionExpiredModal);
