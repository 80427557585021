import React, { useEffect } from "react";
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";

import ErrorFocus from "../../components/ErrorFocus/ErrorFocus";
import {
  Container,
  TextField,
  Grid,
  FormControlLabel,
  Checkbox,Typography
} from "@mui/material";
import * as Yup from "yup";
import { Formik,  FieldArray, getIn } from "formik";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import { getLookUpDataService } from "src/_services/lookUp";
import loadingSpinner from "src/images/loading-spinner.gif";
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { serviceOrderService } from "src/_services/serviceOrder";

const PREFIX = 'ServiceOrderCreateForm';

const classes = {
  zeroPadding: `${PREFIX}-zeroPadding`,
  cardBorderRadius: `${PREFIX}-cardBorderRadius`,
  cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
  fileInput: `${PREFIX}-fileInput`,
  imageSizes: `${PREFIX}-imageSizes`,
  container: `${PREFIX}-container`,
  buttonProgress: `${PREFIX}-buttonProgress`,
  label: `${PREFIX}-label`,
  marginInputLabel: `${PREFIX}-marginInputLabel`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  table: `${PREFIX}-table`,
  tableCell: `${PREFIX}-tableCell`,
  resendBtnMargin: `${PREFIX}-resendBtnMargin`,
  inputMargin: `${PREFIX}-inputMargin`,
  paddingInput: `${PREFIX}-paddingInput`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.zeroPadding}`]: {
    padding: 0,
  },

  [`& .${classes.cardBorderRadius}`]: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },

  [`& .${classes.cardHeaderMargin}`]: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    // justifyContent:"center",
    borderRadius: "0 0  10px 0",
  },

  [`& .${classes.fileInput}`]: {
    display: "none",
  },

  [`& .${classes.imageSizes}`]: {
    width: "40%",
    margin: "auto",
    display: "block",
  },

  [`& .${classes.container}`]: {
    justifyContent: "center",
    // justifyItems: "center",

    marginTop: "10%",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: "4px",
  },

  [`& .${classes.label}`]: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },

  [`& .${classes.marginInputLabel}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "20%",
  },

  [`& .${classes.table}`]: {
    minWidth: 450,
  },

  [`& .${classes.tableCell}`]: {
    paddingBottom: 5,
    paddingTop: 5,
    color: "#314b8f",
  },

  [`& .${classes.resendBtnMargin}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.inputMargin}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.paddingInput}`]: {
    padding: "0px 0px",
  }
}));

function ServiceOrderCreateForm(props) {


  const [securityData, setSecurityData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(
    false
  );


  const deleteProcuredBy =((id)=>{
    serviceOrderService.deleteProcuredBy(id).then((res)=>{
      if(res.status ===200){
        props.getSecurityData()
      }else{
        console.log('Error')
      }

    })
  })

  useEffect(() => {
    getLookUpDataService
      .getLookSecurity()
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            let item = res.data.map((data) => {
              return { id: data.id, label: data.name ,hide_trading_status:data.hide_trading_status,hiding_reason:data.hiding_reason};
            });

            const newItem = props.securityData.filter((item) => {
              return item.id !== props.item?.id;
            });
            let filteredArray = item.filter(function(data) {
              return (
                newItem.filter(function(item) {
                  return item.id === data.id;
                }).length === 0
              );
            });
            setSecurityData(filteredArray);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Root>
    <Container>
      <Formik
        initialValues={{
          security: props.item
            ? {
                id: props.item.id,
                label: props.item.security_name,
              }
            : [],
          security_name: props.item ? props.item.security_name : "",
          id: props.item ? props.item?.id : "",
          quantity: props.item ? props.item?.quantity : "",
          price: props.item ? props.item?.price : "",
          commission: props.item ? props.item?.commission : "",
          buyPrice: props.item ? props.item?.buyPrice : "",
          transactionType: props.transactionType,
          facilitator:props.facilitator,
          serviceOrderType: props.serviceOrderType,
          procuredBy: props.item ? props.item?.procuredBy : [],
          hasProcuredBy:props.item && props.item.procuredBy.length>0 ?true : false,
          orderId:props.item ? props.item?.order_transaction_id : false,
          facilitatorPrice:props.item ? props.item?.facilitatorPrice : "",
        }}
        // validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object().shape({
          security_name: Yup.string().required("Required"),
          quantity: Yup.string()
            .required("Required")
            .test(
              "test less than",
              "Quantity must be greater than 0",
              (value) => value > 0
            ),

          price: Yup.number()
            .positive()
            .nullable()
            .required("Required"),
          procuredBy: Yup.array(
            Yup.object({
              name: Yup.string().required("Name required"),
              value: Yup.number().required("Required"),
              price: Yup.number().required("Required"),
            })
          ),
          commission: Yup.number().when("transactionType", {
            is:"INTERMEDIATE",
            then: Yup.number()
              // .positive()
              .nullable()
              .required("Required"),
            otherwise: Yup.number(),
          }),
          facilitatorPrice: Yup.number().when("facilitator", {
            is:"Others",
            then: Yup.number()
              // .positive()
              .nullable()
              .required("Required"),
            otherwise: Yup.number().nullable(),
          }),
        })}
        onSubmit={(values) => {
          setLoading(true);


          if (values.commission === "") {
            values.commission = 0;
          }

          values.price = Number(values.price).toFixed(3);

          values.quantity = Number(values?.quantity);
          values.commission = Number(values.commission).toFixed(3);
          values.commision = Number(values.commission).toFixed(3);

          delete values.serviceOrderType;
          delete values.transactionType;

          props.formSubmit(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Container className={classes.container}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Autocomplete
                    options={securityData}
                    classes={{
                      option: classes.option,
                    }}
                    value={values.security}
                    autoHighlight
                    id="security_name"
                    getOptionLabel={(option) => option.label || ""}
                    onChange={(e, value) => {
                      if (value !== null) {
                        setFieldValue("id", value.id);
                        setFieldValue("security", value);
                        setFieldValue("security_name", value.label);
                      } else {
                        setFieldValue("id", "");
                        setFieldValue("security_name", "");
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        className={classes.inputMargin}
                        {...params}
                        fullWidth={true}
                        label="Select Scrip Name *"
                        variant="outlined"
                        name="security_name"
                        id="security_name"
                        error={Boolean(
                          touched.security_name && errors.security_name
                        )}
                        helperText={
                          touched.security_name && errors.security_name
                        }
                        autoComplete="off"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                  <ErrorFocus />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    className={classes.inputMargin}
                    variant="outlined"
                    label="Quantity *"
                    id="quantity"
                    name="quantity"
                    onBlur={handleBlur}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, "");
                    }}
                    onChange={(event) => {
                      setFieldValue("quantity", event.target.value);
                    }}
                    fullWidth
                    value={values.quantity}
                    error={Boolean(touched.quantity && errors.quantity)}
                    helperText={touched.quantity && errors.quantity}
                    aria-describedby="outlined-weight-helper-text"
                    autoComplete="off"
                    // onInput={(e) => { e.target.value = e.target.value.replace(/^\d*\.?\d*$/g, "") }}
                    // onKeyPress={(e)=>{e.target.value = e.target.value.replace(/^\d+\.\d{0,2}$/,"")}}
                  />
                  <ErrorFocus />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    className={classes.inputMargin}
                    variant="outlined"
                    label={
                      props.serviceOrderType === "BUY"
                        ? "Buy Price *"
                        : "Sold Price *"
                    }
                    id="price"
                    type="number"
                    step=".01"
                    name="price"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth
                    value={values.price}
                    error={Boolean(touched.price && errors.price)}
                    helperText={touched.price && errors.price}
                    aria-describedby="outlined-weight-helper-text"
                    autoComplete="off"
                    inputProps={{ min: "0" }}
                    // onInput={(e) => { e.target.value = e.target.value.replace(/^\d*\.?\d*$/g, "") }}
                    // onKeyPress={(e)=>{e.target.value = e.target.value.replace(/^\d+\.\d{0,2}$/,"")}}
                  />
                  <ErrorFocus />
                </Grid>
                
                {props.facilitator ==='Others' ? (
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        className={classes.inputMargin}
                        variant="outlined"
                        label= {"Facilitator Price *"}
                        id="facilitatorPrice"
                        type="number"
                        step=".01"
                        name="facilitatorPrice"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        fullWidth
                        value={values.facilitatorPrice}
                        error={Boolean(touched.facilitatorPrice && errors.facilitatorPrice)}
                        helperText={touched.facilitatorPrice && errors.facilitatorPrice}
                        aria-describedby="outlined-weight-helper-text"
                        autoComplete="off"
                        inputProps={{ min: "0" }}
                        // onInput={(e) => { e.target.value = e.target.value.replace(/^\d*\.?\d*$/g, "") }}
                        // onKeyPress={(e)=>{e.target.value = e.target.value.replace(/^\d+\.\d{0,2}$/,"")}}
                      />
                      <ErrorFocus />
                    </Grid>
                  </Grid>
                ) : (
                  <div></div>
                )}
                {props.transactionType === "INTERMEDIATE" ? (
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        className={classes.inputMargin}
                        variant="outlined"
                        label= { "RM Price *"}
                        id="commission"
                        type="number"
                        step=".01"
                        name="commission"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        fullWidth
                        value={values.commission}
                        error={Boolean(touched.commission && errors.commission)}
                        helperText={touched.commission && errors.commission}
                        aria-describedby="outlined-weight-helper-text"
                        autoComplete="off"
                        inputProps={{ min: "0" }}
                        // onInput={(e) => { e.target.value = e.target.value.replace(/^\d*\.?\d*$/g, "") }}
                        // onKeyPress={(e)=>{e.target.value = e.target.value.replace(/^\d+\.\d{0,2}$/,"")}}
                      />
                      <ErrorFocus />
                    </Grid>
                  </Grid>
                ) : (
                  <div></div>
                )}
                {props.serviceOrderType === "SELL" ? (
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        // disabled={props.mode ==="edit"}
                        className={classes.inputMargin}
                        variant="outlined"
                        label="Buy Price "
                        id="buyPrice"
                        type="number"
                        step=".01"
                        name="buyPrice"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        fullWidth
                        value={values.buyPrice}
                        error={Boolean(touched.buyPrice && errors.buyPrice)}
                        helperText={touched.buyPrice && errors.buyPrice}
                        aria-describedby="outlined-weight-helper-text"
                        autoComplete="off"
                        inputProps={{ min: "0" }}
                        // onInput={(e) => { e.target.value = e.target.value.replace(/^\d*\.?\d*$/g, "") }}
                        // onKeyPress={(e)=>{e.target.value = e.target.value.replace(/^\d+\.\d{0,2}$/,"")}}
                      />
                      <ErrorFocus />
                    </Grid>
                  </Grid>
                ) : (
                  <div></div>
                )}
                <FieldArray name="procuredBy">
                  {({ push, remove }) => (
                    <Grid container>
                      {values.procuredBy.map((_, index) => {
                        const touchedName = getIn(
                          touched,
                          `procuredBy.${index}.name`
                        );
                        const errorName = getIn(
                          errors,
                          `procuredBy.${index}.name`
                        );
                        const touchedValue = getIn(
                          touched,
                          `procuredBy.${index}.value`
                        );
                        const errorValue = getIn(
                          errors,
                          `procuredBy.${index}.value`
                        );
                        const touchedPrice = getIn(
                          touched,
                          `procuredBy.${index}.price`
                        );
                        const errorPrice = getIn(
                          errors,
                          `procuredBy.${index}.price`
                        );
                        return (
                          <Grid
                            container
                            spacing={1}
                            alignItems="flex-start"
                            key={index}
                          >
                            <Grid item xs={5}>
                              <Autocomplete
                                // disabled={props.mode ==="edit"}
                                options={props?.procuredBy}
                                classes={{
                                  option: classes.option,
                                }}
                                autoHighlight
                                id="security_name"
                                getOptionLabel={(option) => option.lable || ""}
                                value={values.procuredBy[index].name}
                                onBlur={handleBlur}
                                // onChange={handleChange}
                                onChange={(e, value) => {
                                  if (value !== null) {
                                    setFieldValue(
                                      `procuredBy.${index}.name`,
                                      value
                                    );
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    className={classes.inputMargin}
                                    {...params}
                                    fullWidth={true}
                                    label={`Procured By (${index + 1}) *`}
                                    variant="outlined"
                                    name={`procuredBy.${index}.name`}
                                    id="procuredBy"
                                    error={Boolean(touchedName && errorName)}
                                    helperText={touchedName && errorName}
                                    autoComplete="off"
                                    inputProps={{
                                      ...params.inputProps,
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={3} className={classes.gridPadding}>
                              <TextField
                                // disabled={props.mode ==="edit"}
                                label="Quantity"
                                id="value"
                                variant="outlined"
                                name={`procuredBy.${index}.value`}
                                value={values.procuredBy[index].value}
                                autoComplete="off"
                                fullWidth
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(touchedValue && errorValue)}
                                helperText={touchedValue && errorValue}
                              />
                              <ErrorFocus />
                            </Grid>
                            <Grid item xs={3} className={classes.gridPadding}>
                              <TextField
                                // disabled={props.mode ==="edit"}
                                label="Price"
                                id="price"
                                variant="outlined"
                                name={`procuredBy.${index}.price`}
                                value={values.procuredBy[index].price}
                                autoComplete="off"
                                fullWidth
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(touchedPrice && errorPrice)}
                                helperText={touchedPrice && errorPrice}
                              />
                              <ErrorFocus />
                            </Grid>
                            <Grid item xs={1} className={classes.gridPadding}>
                              <CloseIcon
                                onClick={() => {
                                  if(props.mode ==="edit" && values.procuredBy[index]?.id){
                                    deleteProcuredBy(values.procuredBy[index]?.id)
                                  }
                                  remove(index);
                                }}
                                style={{
                                  cursor: "pointer",
                                  margin: "1rem 8px",
                                }}
                              />
                            </Grid>
                          </Grid>
                        );
                      })}
                    {props.serviceOrderType === "SELL" &&  <Grid
                        container
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.hasProcuredBy}
                              name="hasProcuredBy"
                              id="hasProcuredBy"
                              aria-describedby="outlined-weight-helper-text"
                              onBlur={handleBlur}
                              // disabled={props.mode ==="edit"}
                              onChange={(e) => {
                                setFieldValue(
                                  "hasProcuredBy",
                                  e.target.checked
                                );
                                push({
                                  name: "",
                                  value: "",
                                  price:""
                                })
                                if (!e.target.checked) {
                                  setFieldValue("procuredBy", []);
                                }
                              }}
                              color="primary"
                            />
                          }
                          label={"Has Procured By"}
                        />
                        {values.hasProcuredBy && (
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddCircleOutlineIcon />}
                            style={{ float: "right", textTransform: "none" }}
                            onClick={() => {
                              push({
                                name: "",
                                value: "",
                                price:""
                              });
                            }}
                          >
                            Procured by
                          </Button>
                        )}
                      </Grid>}
                    </Grid>
                  )}
                </FieldArray>
              </Grid>
              {
                values.security.hide_trading_status ? 
               ( <Grid container>
                  <Grid item xs={2}>
                  <span style={{color:'black', fontWeight:'bold'}}>Warning : </span>
                  </Grid>
                  <Grid item xs={10}>
                  <Typography style={{color:'red', textAlign:'justify'}}>    {`This scrip is not available for trading due to ${values.security.hiding_reason.toLowerCase()} and requires approval from the operations team, but you can still create an order for now. `} </Typography>
                  </Grid>
                </Grid>): null
              }
              <Button
                color="primary"
                className={classes.popDownBtn}
                size="medium"
                disabled={isSubmitting}
                type="submit"
                variant="contained"
              >
                {loading === false ? (
                  props.item ? (
                    "Update"
                  ) : (
                    "Add"
                  )
                ) : (
                  <>
                    <img
                      alt=""
                      src={loadingSpinner}
                      className={classes.buttonProgress}
                    />
                    {props.item ? "Update" : "Add"}{" "}
                  </>
                )}
              </Button>
            </Container>
          </form>
        )}
      </Formik>

      <SessionExpiredModal open={openSessionExpiredModal} />
    </Container>
    </Root>
  );
}

export default ServiceOrderCreateForm;
