
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import React, { useState, useEffect } from "react";
import { securityService } from 'src/_services/security';
import {
    Button,
    RadioGroup,
    Radio,
    FormControlLabel,
    Typography,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogContent,
    Table,
    TableHead,
    TableRow,TableCell,TableBody
} from "@mui/material";

const  SortIndex = () =>{
    const [value, setValue] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [openNew,setOpenNew] =  React.useState(false);
    const [dragComplete,setDragComplete] = useState(false);
    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClickOpenNew = () => {
        setOpenNew(true);
      };
    const handleClose = () => {
      setOpen(false);
      setOpenNew(false);
    };
    const handleChange = (event) => {
        setValue(event.target.value);
        if(event.target.value === 'Name'){
            securityService.clearSortIndexForAllScrips()
        }
    };
    const [securityData, setSecurityData] = useState([])
    // const [loading, setLoading] = useState(false);
    // const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)
    const getSecurity = () => {
        // setLoading(true)
        securityService.getSecurity()
            .then(res => {
                if (res) {
                    if (res.status === 200) {
                        setSecurityData(res.data.unTaggedArray)
                        if(res.data.unTaggedArray[0].last_sorted_by === 1){
                            setValue('Name')
                        }
                        else{
                            setValue('AdvanceDecline')
                        }
                        // setLoading(false)
                    } else {
                        // if (res.status === 401 && res.data.unTaggedArray.name === "TokenExpiredError") {
                        //     setOpenSessionExpiredModal(true)
                        //     setLoading(false)
                        // }
                    }

                }
            }).catch(error => console.log(error))
    }
    useEffect(() => {
        getSecurity()
    }, [])

    const handleDragEnd = (e) => {
        if (!e.destination) return;
        let tempData = Array.from(securityData);
        let [source_data] = tempData.splice(e.source.index, 1);
        tempData.splice(e.destination.index, 0, source_data);
        setSecurityData(tempData);
        setDragComplete(true);
    };
    const saveData = () => {
        var last_sort_by = 1;
        if(value ==='AdvanceDecline'){
            last_sort_by = 2
        }
        else{
            last_sort_by = 1   
        }
        
        securityService.updateSecurityLastUpdateSort(last_sort_by).then(res => {
            if(dragComplete === true){
            const newArray = []
            for(let i=0;i<securityData.length;i++){
            newArray.push({
                "security_id":securityData[i].id,
                "sort_index":i+1,
            })
            }
            securityService.updateSecuritySortIndex(newArray).then(res => {
                getSecurity()
                setOpen(false)
            })
                .catch(error => {
                    console.log("error");
                })
            }
            else{
                getSecurity()
                setOpen(false)
            }
        })
            .catch(error => {
                console.log("error");
            })
    }
    const clearSort =()=>{
securityService.clearSortIndexForAllScrips().then(res=>{
    getSecurity()
    setOpenNew(false);
})
.catch(error => {
    console.log("error");
})
    }
    return (
        <div className="App mt-4" style={{ fontFamily: "sans-serif", textAlign: "center" }}>
            <div style={{ fontFamily: "sans-serif", textAlign: "left", margin: "14px", paddingLeft: "15px" }}>
                <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={value}
                    onChange={handleChange}
                >
                    <Typography style={{ padding: '10px' }}>  Sort By :-   </Typography>
                    <FormControlLabel value="Name" control={<Radio />} label="Name (A-Z)" />
                    <FormControlLabel value="AdvanceDecline" control={<Radio />} label="Advance & Decline" />
                </RadioGroup><br></br>
                <Button style={{ backgroundColor: "#3f51b5", color: "white" }} onClick={handleClickOpen}>
                    Save
                </Button>  <Button style={{ backgroundColor: "#3f51b5", color: "white" }} onClick={handleClickOpenNew}>
                   Clear Sort
                </Button><br></br>
            </div>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Table className="table borderd">
                    <TableHead style={{ textAlign: "left" }}>
                        <TableRow>
                            <TableCell />
                            <TableCell>Scrip Name</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Advance & Decline</TableCell>
                            {/* <TableCell>Index</TableCell> */}
                            <TableCell>Tag</TableCell>
                            {/* <TableCell>Sort Index</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <Droppable droppableId="droppable-1">
                        {(provider) => (
                            <TableBody
                                className="text-capitalize"
                                ref={provider.innerRef}
                                {...provider.droppableProps}
                                style={{ textAlign: "left" }}
                            >
                                {securityData?.map((item, index) => (
                                    <Draggable
                                        key={item.name}
                                        draggableId={item.name}
                                        index={index}

                                    >
                                        {(provider, snapshot) => (
                                            <TableRow {...provider.draggableProps}
                                                {...provider.dragHandleProps}
                                                ref={provider.innerRef}
                                            >
                                                <TableCell {...provider.dragHandleProps}
                                                >  </TableCell>
                                                <TableCell {...provider.dragHandleProps}>{item.name}</TableCell>
                                                <TableCell {...provider.dragHandleProps}>{item.price}</TableCell>
                                                <TableCell {...provider.dragHandleProps}> {item.percentage_of_change}</TableCell>
                                                {/* <TableCell {...provider.dragHandleProps}>{index + 1}</TableCell> */}
                                                <TableCell {...provider.dragHandleProps}>{item.chipName}</TableCell>
                                                {/* <TableCell {...provider.dragHandleProps}>{item.sort_index}</TableCell> */}
                                            </TableRow>
                                        )}
                                    </Draggable>
                                ))}
                                {provider.placeholder}
                            </TableBody>
                        )}
                    </Droppable>
                </Table>
            </DragDropContext>
            <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
    <Typography>Do you want to Save ?</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}      color="primary">No</Button>
          <Button onClick={saveData} autoFocus   color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openNew}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
    <Typography>Do you want to clear sort for all scrips ?</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}      color="primary">No</Button>
          <Button onClick={clearSort} autoFocus   color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
        </div>
    );
}

export default SortIndex;
