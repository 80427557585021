import axios from 'axios'
import { helpers } from 'src/_helpers' 

// axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.token.slice(4, store.state.token.length);

//default header and api endpoint config
export const userService = {
    getUsers,
    createUser,
    getUserDetails,
    updateUser,
    getUserAddress,
    updateUserAddress,
    getUserDemat,
    deleteUserDemat,
    createDemat,
    updateDemat,
    getUserBankAccounts,
    createBankAccount,
    editBankAccount,
    getChannelPartners,
    getContactInfoAccounts,
    createContactInfo,
    editContactInfo,
    deleteContactInfo,
    deleteBankAccount,
    makePrimaryDemat,
    makePrimaryBank,
    deleteUserByID,
    getKYCStatus,
    updateKYCStatus,
    getExternalRmsForLoggedinUser,
    idVerification,
    pennyDropVerification,
    updateUserTag,
    getInvestorsTotalSellOrBuy,
    updateKycSignatureStauts,
    checkPartnerCode,
    getPartners,
    getPartnerUsers,
    getPartnerLogs,
    getPartnerKey,
    checkIsDematPasswordProtected,
    updateBeneficiaryStatus,
    getAllTickets,
    updateTicket,
    createTicket,
    updateVerifiedStatus,
    updatePreApprovedtatus,
    getTicketComments
};


function getUsers(limit=10,offset=0,searchText='',filterText='',userType=0,rmId='',) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/user_info_detail?limit=${limit}&offset=${offset}&searchText=${searchText}&filter=${filterText}&UserType=${userType}&rmId=${rmId}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function createUser(payloads) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/user`, JSON.stringify(payloads), requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function getUserDetails(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/users/basic_info/${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function checkPartnerCode(code) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/checkPartnerCodeExist/?code=${code}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function updateUser(id, payloads) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/users/basic_info_update/${id}`, JSON.stringify(payloads), requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function getUserAddress(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/users/address/${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function updateUserAddress(id, data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/users/address_update/${id}`, JSON.stringify(data), requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function getUserDemat(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/demat/?user_id=${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function deleteUserDemat(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.delete(`/demat/${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function createDemat(userID, formData){
    helpers.setHeader();
    return axios.post(`/demat/?user_id=${userID}`,formData)
        .then(res => {            
            return res
        })
        .catch(error => {           
            return error.response
        })
}

function updateDemat(dematId, formData){
    helpers.setHeader();
    return axios.post(`/demat/update_demat/${dematId}`,formData)
        .then(res => {            
            return res
        })
        .catch(error => {           
            return error.response
        })
}

function getUserBankAccounts(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/user/bank/?user_id=${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function createBankAccount(userID, formData){
    helpers.setHeader();
    return axios.post(`/user/bank/?user_id=${userID}`,formData)
        .then(res => {            
            return res
        })
        .catch(error => {           
            return error.response
        })
}

function editBankAccount(userID, formData){
    helpers.setHeader();
    return axios.post(`/user/bank_userbank_details/?user_id=${userID}`,formData)
        .then(res => {            
            return res
        })
        .catch(error => {           
            return error.response
        })
}


function getChannelPartners(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/get_reporting_channel_partners`, requestOptions)
        .then(res => res)
        .catch(error => error.response)
}

function getContactInfoAccounts(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/user/company_contact?user_id=${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function createContactInfo(userID, data){
    helpers.setHeader();
    return axios.post(`/user/company_contact?user_id=${userID}`,data)
        .then(res => {            
            return res
        })
        .catch(error => {           
            return error.response
        })
}

function editContactInfo(id,data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/user/company_contact_details?user_id=${id}`, JSON.stringify(data), requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function deleteContactInfo(userId,id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.delete(`/user/company_contact/?user_id=${userId}&id=${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}


function deleteBankAccount(userId,id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.delete(`/user/bank/?user_id=${userId}&id=${id}`, requestOptions)
        .then(res => res)
        .catch(error => error.response)
}

function makePrimaryDemat(userId,id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/setdemataccountprimery/?user_id=${userId}&Id=${id}`, requestOptions)
        .then(res => res)
        .catch(error => error.response)
}

function makePrimaryBank(id, userId) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/user/bankUpdate/?bank_id=${id}&user_id=${userId}`, requestOptions)
        .then(res => res)
        .catch(error => error.response)
}


function deleteUserByID(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.delete(`/users/${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function getExternalRmsForLoggedinUser() {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/get_external_channel_partners`, requestOptions)
        .then(res => res)
        .catch(error => error.response)
}

function getKYCStatus(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/get_user_kyc_details`, data,requestOptions)
        .then(res => res)
        .catch(error => error.response)
}

function updateKYCStatus(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.put(`/update_user_kyc_status`, data,requestOptions)
        .then(res => res)
        .catch(error => error.response)
}

async function idVerification  (formData,userID) {
    try {
      const from="service"
      helpers.setHeader();
      return axios
        .post(`/uploadKycDocs/?user_id=${userID}&from=${from}`, formData)
        .then((res) => {
          return res;
        })
        .catch((error) => {
          return error.response;
        });
    } catch (error) {
        return error
    }  
};

async function pennyDropVerification (formData,userID)  {
    try {
       helpers.setHeader();
      return axios
        .post(`/bankDetailsUpload`, {
          userId: userID,
          accountNo: formData.beneficiary_account_no,
          ifcsCode:formData.beneficiary_ifsc,
          accountHolderName:formData.beneficiary_name,
        })
         .then((res) => {
          return res;
        })
        .catch((error) => {
          return error.response;
        });
    } catch (error) {}
  };

  function updateUserTag(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.put(`/update_user_tags`, JSON.stringify(data), requestOptions)
        .then(data => data)
        .catch(error => error.response)
}
function getInvestorsTotalSellOrBuy(investorId,orderType,orderId) {
    
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/userTotalSellOrBuy/?userId=${investorId}&orderType=${orderType}&orderId=${orderId}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}


  function updateKycSignatureStauts(data,s1,s2) {
        helpers.setHeader();
        const requestOptions = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        return axios.put(`/updateUserSignature/?user_id=${data}&status1=${s1}&status2=${s2}`, requestOptions)
            .then(data => data)
            .catch(error => error.response)
    }


function getPartners() {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/getAllPartnerList`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function getPartnerUsers(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/getAllPartnerUserList/?partner_code=${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function getPartnerLogs(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/getApiLogByID?Id=${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function getPartnerKey(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/genetateApiKey`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

async function checkIsDematPasswordProtected(formData){
 
    helpers.setHeader();
   
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios
      .post(`/checkPdfProtected`,formData, requestOptions)
      .then((data) => data)
      .catch((error) => error.response);
  }

  async function updateBeneficiaryStatus(formData){
 
    helpers.setHeader();
   
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios
      .post(`/updateBeneficiaryAddedStatus`,formData, requestOptions)
      .then((data) => data)
      .catch((error) => error.response);
  }


  function getAllTickets(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/user/allTickets`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}



function updateTicket(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.put(`/updateTicket`, data,requestOptions)
        .then(res => res)
        .catch(error => error.response)
}


function createTicket(data){
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/user/tickets`, data,requestOptions)
        .then(res => res)
        .catch(error => error.response)
  }

function updateVerifiedStatus(data){
    helpers.setHeader();
    return axios.put(`/update_user_verified_status`,data)
        .then(res => {            
            return res
        })
        .catch(error => {           
            return error.response
        })
}

function updatePreApprovedtatus(data){
    helpers.setHeader();
    return axios.put(`/update_user_pre_approved_status`,data)
        .then(res => {            
            return res
        })
        .catch(error => {           
            return error.response
        })
}

function getTicketComments(id){
    helpers.setHeader();
    return axios.get(`/getComments/${id}`)
        .then(res => {            
            return res
        })
        .catch(error => {           
            return error.response
        })
}
