import React, { useEffect } from "react";
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import {
  Container,
  TextField,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';

import * as Yup from "yup";
import { Formik } from "formik";
import loadingSpinner from 'src/images/loading-spinner.gif'

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { getLookUpDataService } from 'src/_services/lookUp'
import SnackBar from "src/components/SnackBar/SnackBar";
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal"
import GetMuiTheme from "src/components/theme";


const PREFIX = 'ServiceOrderCreateForm';

const classes = {
  zeroPadding: `${PREFIX}-zeroPadding`,
  cardBorderRadius: `${PREFIX}-cardBorderRadius`,
  cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
  fileInput: `${PREFIX}-fileInput`,
  imageSizes: `${PREFIX}-imageSizes`,
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  marginInputLabel: `${PREFIX}-marginInputLabel`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  table: `${PREFIX}-table`,
  tableCell: `${PREFIX}-tableCell`,
  resendBtnMargin: `${PREFIX}-resendBtnMargin`,
  inputMargin: `${PREFIX}-inputMargin`,
  paddingInput: `${PREFIX}-paddingInput`,
  buttonProgress: `${PREFIX}-buttonProgress`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.zeroPadding}`]: {
    padding: 0,
  },

  [`& .${classes.cardBorderRadius}`]: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },

  [`& .${classes.cardHeaderMargin}`]: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    // justifyContent:"center",
    borderRadius: "0 0  10px 0",
  },

  [`& .${classes.fileInput}`]: {
    display: "none",
  },

  [`& .${classes.imageSizes}`]: {
    width: "40%",
    margin: "auto",
    display: "block",
  },

  [`& .${classes.container}`]: {
    justifyContent: "center",
    // justifyItems: "center",

    marginTop: "10%",
  },

  [`& .${classes.label}`]: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },

  [`& .${classes.marginInputLabel}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "20%",
  },

  [`& .${classes.table}`]: {
    minWidth: 450,
  },

  [`& .${classes.tableCell}`]: {
    paddingBottom: 5,
    paddingTop: 5,
    color: "#314b8f",
  },

  [`& .${classes.resendBtnMargin}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.inputMargin}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.paddingInput}`]: {
    padding: "0px 0px",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: '4px'
  }
}));



const theme = GetMuiTheme







function ServiceOrderCreateForm(props) {



  const [dataArrayIndex, setDataArrayIndex] = React.useState(0);
  const [sumOfRequiredQuantity, setSumOfRequiredQuantity] = React.useState(0);
  const [buyOrder, setBuyOrder] = React.useState([ ]);
  const [loading, setLoading] = React.useState(false)
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [snackbarTitle, setSnackbarTitle] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)







  let data = [{
    "id": null,
    "service_id": "",
    "available_quantity": "",

  }

  ]


  const [availableStocks, setAvailableStocks] = React.useState(data);
  const getAvailableQuantity = (securityId) => {
    getLookUpDataService.getAvailableSecurityQuantity(securityId)
      .then((res) => {
        if (res) {



          if (res.status === 200) {

            if (props.serviceOrderType === "SELL") {
              if (res.data.length > 0) {
                setAvailableStocks(res.data)
              } else {
                setAvailableStocks(data)
                setSeverity("info")
                setSnackbarTitle("There is no Buy Order For This Scrips")
                setSnackBarOpen(true)
              }
            }
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true)
            }
          }
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    getAvailableQuantity(props.securityId)

  }, [])




  const handleSumOfRequiredQuantity = () => {

    const requiredQuantity = availableStocks.filter((item) => {
      return item.required_quantity !== undefined

    })

    const totalRequiredQuantity = requiredQuantity.reduce((count, item) => {
      return count + item.required_quantity
    }, 0)

    const required_quantity = requiredQuantity.map((item) => {
      return { buy_order_id: item.buy_order_id, required_quantity: item.required_quantity }
    })



    setSumOfRequiredQuantity(totalRequiredQuantity)
    setBuyOrder(required_quantity)




  }
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarOpen(false);
  };







  return (
    <StyledContainer>
      <Formik
        initialValues={{


          [availableStocks[dataArrayIndex].service_id]: "",
          requiredQuantity: props.requiredQuantity,
          quantity: "",
          security_id: props.securityId,
          service_order_id: props.service_order_id,
          serviceOrderType: props.serviceOrderType

        }}
        // validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object().shape({



          [availableStocks[dataArrayIndex].service_id]: Yup.string().when("serviceOrderType", {
              is: "SELL",
              then: Yup.string().test('requiredQuality', 'value must be equal or less than Available Quantity',
                  val => {
                    return (Number(val) <= availableStocks[dataArrayIndex].available_quantity)
                  }
                ),
              otherwise: Yup.string(),
            }),




        })}
        onSubmit={(values) => {
          setLoading(true)

          values.order_supplies = buyOrder

          delete values.serviceOrderType
          delete values.quantity
          delete values.requiredQuantity
          delete values[availableStocks[dataArrayIndex].service_id]


          props.formSubmit(values)
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>
              <Grid container>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField

                    className={classes.inputMargin}
                    variant="outlined"
                    label="Required Quantity *"
                    id="requiredQuantity"
                    type="number"
                    step=".01"
                    name="quantity"
                    disabled={true}
                    onBlur={handleBlur}
                    onChange={(event) => {
                      setFieldValue("requiredQuantity", event.target.value)
                    }}
                    fullWidth
                    value={values.requiredQuantity}
                    error={Boolean(touched.requiredQuantity && errors.requiredQuantity)}
                    helperText={touched.requiredQuantity && errors.requiredQuantity}
                    aria-describedby="outlined-weight-helper-text"
                    autoComplete="off"
                  // onInput={(e) => { e.target.value = e.target.value.replace(/^\d*\.?\d*$/g, "") }}
                  // onKeyPress={(e)=>{e.target.value = e.target.value.replace(/^\d+\.\d{0,2}$/,"")}}
                  />

                </Grid>



                {(values.serviceOrderType === "SELL") ?
                  <Grid container>



                    <Grid item xs={12} sm={12} md={12} lg={12}>

                      {availableStocks[0].id === null ? <Typography variant="h6" style={{ marginTop: 24, color: "#F44336" }}>There is no Buy Order For This Security</Typography> :
                        <TableContainer component={Paper}>
                          <ThemeProvider theme={theme}>

                            <Table className={classes.table}    >
                              <TableHead>
                                <TableRow>
                                  <TableCell style={{ width: '40%' }}>Available Quantity</TableCell>
                                  <TableCell style={{ width: '40%' }}>Enter Quantity</TableCell>
                                  <TableCell style={{ width: '40%' }} >Order Id</TableCell>

                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {availableStocks
                                  .map((item, i) => {
                                    return (
                                      <TableRow>

                                        <TableCell >{item.available_quantity}</TableCell>
                                        <TableCell  >
                                          <TextField

                                            style={{ padding: "8px 8px" }}
                                            variant="outlined"
                                            label=""
                                            id={item.service_id}
                                            // type="number"
                                            step=".01"
                                            onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                                            name={item.service_id}
                                            value={values[item.service_id]}
                                            inputProps={{ min:"1" }}
                                            // type="number"
                                            onBlur={handleBlur}
                                            error={Boolean(errors[item.service_id])}
                                            helperText={errors[item.service_id]}
                                            onClick={() => {
                                              setDataArrayIndex(i)
                                            }}
                                            onChange={(e, value) => {
                                              // setAvailableQuantity(item.available_quantity);
                                              handleChange(e)
                                              item.required_quantity = Number(e.target.value)

                                              //  setBuyOrder[i].push({id:item.service_id,required_quantity:Number(e.target.value)})

                                              //  console.log(buyOrder,);
                                              //  setSumOfRequiredQuantity(sumOfRequiredQuantity + availableStocks[i].required_quantity)
                                              handleSumOfRequiredQuantity()
                                            }}
                                            fullWidth={true}


                                            aria-describedby="outlined-weight-helper-text"
                                            autoComplete="off"

                                          />

                                        </TableCell>
                                        <TableCell  >{item.service_id}</TableCell>
                                      </TableRow>
                                    );
                                  })}
                                <TableRow>
                                  <TableCell style={{ width: '50%' }}>Total Quantity</TableCell>
                                  <TableCell style={{ textAlign: "center" }}>{sumOfRequiredQuantity}</TableCell>
                                  <TableCell style={{ display: "none" }}>{sumOfRequiredQuantity > 0 ? values.quantity = sumOfRequiredQuantity :
                                    values.quantity = ""}</TableCell>
                                </TableRow>
                              </TableBody>


                            </Table>

                          </ThemeProvider>
                        </TableContainer>
                      }
                    </Grid>
                  </Grid> :
                  <div></div>
                }

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography variant="caption" style={{ marginTop: 24, color: "#F44336" }}>{Number(values.quantity) <= 0 || Number(values.quantity) !== Number(values.requiredQuantity) ? "Total Quantity Must be Equal to Required Quantity" : ""}</Typography>
                </Grid>

              </Grid>
              <Button
                color="primary"
                disabled={values.quantity !== Number(values.requiredQuantity) || isSubmitting}
                className={classes.popDownBtn}
                size="medium"
                type="submit"
                variant="contained"
              >
                {loading === false ? "Add" : <><img alt="loader" src={loadingSpinner} className={classes.buttonProgress} /> Add </>}
              </Button>
            </Container>
          </form>
        )}
      </Formik>

      <SessionExpiredModal
        open={openSessionExpiredModal}
      />

      <SnackBar
        open={snackBarOpen}
        severity={severity}
        close={handleClose}
        snackbarTitle={snackbarTitle}
      />


    </StyledContainer>
  );
}

export default ServiceOrderCreateForm;
