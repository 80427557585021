import axios from 'axios'
import { helpers } from 'src/_helpers'

 
export const DashboardData = {
    getChannelPartnerData,
    getDashboardSecurities,
    getDashboardRevenue,
    getServiceOrderStatus,
    getLastSevenDaysStatus,
    getLastMonthStatus,
    getCurrentMonthStatus,
    getTopFiveData,
    getDashboardLogs,
    getHolidayList,
    postHoliday,
    deleteHolidayByID,
    updateHoliday,
    bulkHolidayUpdate
}

function getChannelPartnerData() {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/dashboard/users`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}


function getTopFiveData() {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/dashboard/get_random_procurements`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}



function getDashboardSecurities() {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/dashboard/securities`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function getDashboardRevenue() {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/dashboard/revenue`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}


function getServiceOrderStatus() {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/dashboard/service_order_status`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function getLastSevenDaysStatus() {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/dashboard/sales_details/LAST_7_DAYS`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function getLastMonthStatus() {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/dashboard/sales_details/PREVIOUS_MONTH`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function getCurrentMonthStatus() {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/dashboard/sales_details/CURRENT_MONTH`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function getDashboardLogs(fromDate,toDate,actionId,typeId,userId,relatedId) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/getDashboardDBLogs/?fromDate=${fromDate}&toDate=${toDate}&actionID=${actionId}&logTypeId=${typeId}&userId=${userId}&relatedId=${relatedId}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function getHolidayList() {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/getHolidayList`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function postHoliday(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/holiday`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function deleteHolidayByID(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.delete(`/holiday/${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function updateHoliday(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/updateHoliday`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function bulkHolidayUpdate(data) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/bulkHolidayUpdate`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}
