import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {  ThemeProvider } from '@mui/material/styles';
import ReactSpinner from "src/components/ReactSpinner/ReactSpinner";
import GetMuiTheme from '../theme';

const theme=GetMuiTheme


const ConfirmDialog = (props) => {
    const handleClose = () => {
        props.toggle()
      };

  const { title, children, open,onConfirm } = props;
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      { props.openLoading &&  <div style={{textAlign:"center"}}>
                  <ReactSpinner />
                </div>
              }
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleClose}
          color="primary"
        >
          No
        </Button>
        <Button
          variant="contained"
          onClick={() =>{
            // handleAddClick()
            onConfirm()}}          
          color="primary"
        >
          {props.yes ? props.yes : "Yes"}          
        </Button>
      </DialogActions>
    </Dialog>
    </ThemeProvider>
      </React.Fragment>
  );
};
export default ConfirmDialog;