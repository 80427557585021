import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,

  Card,
  CardContent,
  Grid,
  Typography,
  colors,
} from '@mui/material';

import MoneyIcon from '@mui/icons-material/Money';
import { DashboardData } from 'src/_services/dashboard';
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal"

const PREFIX = 'Budget';

const classes = {
  root: `${PREFIX}-root`,
  boxHeader: `${PREFIX}-boxHeader`,
  avatar: `${PREFIX}-avatar`,
  revenueRate: `${PREFIX}-revenueRate`,
  upwardIcon: `${PREFIX}-upwardIcon`,
  italic: `${PREFIX}-italic`,
  differenceIcon: `${PREFIX}-differenceIcon`,
  differenceValue: `${PREFIX}-differenceValue`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    height: '100%'
  },

  [`& .${classes.boxHeader}`]: {
    fontWeight: 'normal',
  },

  [`& .${classes.avatar}`]: {
    backgroundColor: colors.red[600],
    height: 56,
    width: 56
  },

  [`& .${classes.revenueRate}`]: {
    fontWeight: '400',
  },

  [`& .${classes.upwardIcon}`]: {
    color: colors.lightGreen[900]
  },

  [`& .${classes.italic}`]: {
    fontStyle: "italic",
    fontWeight: '400',
    fontSize: '0.8rem'
  },

  [`& .${classes.differenceIcon}`]: {
    color: colors.red[900]
  },

  [`& .${classes.differenceValue}`]: {
    color: colors.red[900],
    marginRight: theme.spacing(1)
  }
}));

const Budget = ({ className, ...rest }) => {

  const [currentMonth, setCurrentMonth] = useState(0)
  const [lastMonth, setLastMonth] = useState(0)
  // const [percentage, setPercentage] = useState(0)
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)

  useEffect(() => {
    DashboardData.getDashboardSecurities()
      .then(res => {
        if (res) {
          if (res.status === 200) {
            res.data.forEach((item) => {
              setCurrentMonth(item.present_month_securities)
              setLastMonth(item.previous_month_securities_count)
              // let total = (item.previous_month_securities_count - item.present_month_securities) / (item.total_securities_count);
              // setPercentage(Math.round(total * 100))
            })
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true)
            }
          }
        }
      })
  }, [])

  return (
    (<Root>
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <CardContent>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="h6"
                className={classes.boxHeader}
              >
                SCRIPS
            </Typography>
              <Typography
                color="textPrimary"
                variant="h4"
                className={classes.revenueRate}
              >
                {currentMonth} <span className={classes.italic}>Current Month </span>
              </Typography>
              <Typography
                color="textPrimary"
                variant="h4"
                className={classes.revenueRate}
              >
                {lastMonth} <span className={classes.italic}> Last Month </span>
              </Typography>

            </Grid>
            <Grid item>
              <Avatar className={classes.avatar}>
                <MoneyIcon />
              </Avatar>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <SessionExpiredModal
        open={openSessionExpiredModal}
      />
    </Root>)
  );
};

Budget.propTypes = {
  className: PropTypes.string
};

export default Budget;
