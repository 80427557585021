import React, { useState, useEffect, createRef } from 'react'
import { styled } from '@mui/material/styles';
import Page from 'src/components/Page';
import GraphTable from 'src/components/Tables/GraphTable';
import {
  Box,
  Chip,
  Grid,
  CardContent,Button,
    TextField
} from '@mui/material';

import moment from 'moment';
// import GetAppIcon from '@mui/icons-material/GetApp';
import Select from 'src/components/Tables/Select';
// import { Line } from 'react-chartjs-2';
// import { useScreenshot, createFileName } from "use-react-screenshot";
import * as Yup from "yup";
import { Formik } from "formik";
import { analyticsReport } from "src/_services/analytics";

const PREFIX = 'Analytics';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  dateGrid: `${PREFIX}-dateGrid`,
  textField: `${PREFIX}-textField`,
  submit: `${PREFIX}-submit`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },

  [`& .${classes.container}`]: {
    padding: 0,
    paddingTop: theme.spacing(1)
  },

  [`& .${classes.dateGrid}`]: {
    paddingRight: "1rem",
    display:"flex",
    justifyContent:"center"
  },

  [`& .${classes.textField}`]: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },

  [`& .${classes.submit}`]: {
    height:"48px" 
  }
}));

const Analytics = () => {

  const [userTabIndex, setUserTabIndex] = useState(0)
  const [revenueTabIndex, setRevenueTabIndex] = useState(0)
  const [selectValue, setSelectValue] = useState(4)
  const [userSelectValue, setUserSelectValue] = useState(0)
  const [userCount, setUserCount] = useState()
  // const [userDayCount, setUserDayCount] = useState([])

  const [revenueSelectValue, setRevenueSelectValue] = useState(0)
  // const [open, setOpen] = useState(false)
  // const [submit,setSubmit] = useState(true);

  const today = new Date()
  const range = [{
    label: "Today",
    start: moment(today).format("YYYY-MM-DD"),
    end: moment(today).format("YYYY-MM-DD"),
  },
  {
    label: "Yesterday",
    start: moment(today).subtract(1, 'days').format("YYYY-MM-DD"),
    end: moment(today).subtract(1, 'days').format("YYYY-MM-DD"),
  },
  {
    label: "Last 7 Days",
    start: moment(today).subtract(7, 'days').format("YYYY-MM-DD"),
    end: moment(today).subtract(1, 'days').format("YYYY-MM-DD"),
  },
  {
    label: "Current Month",
    start: moment(new Date(new Date(today.setDate(1)).toDateString())).format("YYYY-MM-DD"),
    end: moment(new Date(new Date(new Date(today.setMonth(today.getMonth() + 1)).setDate(0)).toDateString())).format("YYYY-MM-DD")
  },
  {
    label: "Last Month",
    start: moment(today).subtract(2, 'months').startOf('month').format("YYYY-MM-DD"),
    end: moment(today).subtract(2, 'months').endOf('month').format("YYYY-MM-DD"),
  },
  {
    label: "Last 3 Months",
    start: moment(today).subtract(4, 'months').startOf('month').format("YYYY-MM-DD"),
    end: moment(today).subtract(2, 'months').endOf('month').format("YYYY-MM-DD"),
  },
  {
    label: "Quarter to Date",
    start: moment(new Date()).quarter(moment().quarter()).startOf('quarter').format("YYYY-MM-DD"),
    end: moment(new Date()).format("YYYY-MM-DD"),
  },
  {
    label: "Last 12 Months",
    start: moment(today).subtract(13, 'months').startOf('month').format("YYYY-MM-DD"),
    end: moment(today).subtract(2, 'months').endOf('month').format("YYYY-MM-DD"),
  },
  {
    label: "This Year (Jan - Today)",
    start: moment(today).startOf('year').format('YYYY-MM-DD'),
    end: moment(new Date()).format("YYYY-MM-DD"),
  }]

  // const [image, takeScreenShot] = useScreenshot({
  //   type: "image/jpeg",
  //   quality: 1.0
  // });
  console.log(range[selectValue - 1])
  // console.log(range)
  const userData = [
    {
      title: "Total Users",
      count: userCount && userCount.total_users
    },
    {
      title: "New Users",
      count: userCount && userCount.total_new_users_in_given_time_range
    },
    {
      title: "Transacted Users",
      count: userCount && userCount.total_transacted_users
    },
  ]

  const revenueData = [
    {
      title: "Total Revenue",
      count: "22.8Cr",
    },
    {
      title: "Total Transactions",
      count: "12K"
    },
    {
      title: "Unique Buyers",
      count: "11K"
    },
    {
      title: "New Buyers",
      count: "1.3K"
    },
    {
      title: "Returning Buyers",
      count: "7K"
    }
  ]
  // const barWidth = 6;
  // const [days, setDays] = useState()
  // let array = [];

  // const options = {
  //   animation: false,
  //   cornerRadius: 1,
  //   layout: { padding: 0 },
  //   legend: { display: false },
  //   maintainAspectRatio: false,
  //   responsive: true,
  //   scales: {
  //     xAxes: [
  //       {
  //         fontSize: 10,
  //         barThickness: barWidth,
  //         maxBarThickness: 10,
  //         barPercentage: 0.5,
  //         categoryPercentage: 0.5,
  //         ticks: {
  //           fontColor: theme.palette.text.secondary
  //         },
  //         gridLines: {
  //           display: true,
  //           drawBorder: true
  //         }
  //       }
  //     ],
  //     yAxes: [
  //       {
  //         ticks: {
  //           fontColor: theme.palette.text.secondary,
  //           beginAtZero: true,
  //           min: 0
  //         },
  //         gridLines: {
  //           borderDash: [2],
  //           borderDashOffset: [2],
  //           color: theme.palette.divider,
  //           drawBorder: false,
  //           zeroLineBorderDash: [2],
  //           zeroLineBorderDashOffset: [2],
  //           zeroLineColor: theme.palette.divider
  //         }
  //       }
  //     ]
  //   },
  //   tooltips: {
  //     backgroundColor: theme.palette.background.default,
  //     bodyFontColor: theme.palette.text.secondary,
  //     borderColor: theme.palette.divider,
  //     borderWidth: 1,
  //     enabled: true,
  //     footerFontColor: theme.palette.text.secondary,
  //     intersect: false,
  //     mode: 'index',
  //     titleFontColor: theme.palette.text.primary
  //   }
  // };

  useEffect(()=>{
    setUserSelectValue(0)
    setRevenueSelectValue(0)
  },[selectValue])
  // const data = {
  //   datasets: [
  //     {
  //       fill: false,
  //       data: userDayCount.count,
  //       label: 'Users',
  //       borderColor: 'rgb(31, 120, 180)',
  //       tension: 0.5
  //     },
  //   ],
  //   labels: userDayCount.date
  // };
  useEffect(() => {
    if (selectValue === 10 || userSelectValue === 10 || revenueSelectValue === 10) {
      // setOpen(true)
    }
  }, [selectValue,userSelectValue, revenueSelectValue])

  const getAllUsersCount = (start,end) => {
    analyticsReport
      .getUserCounts(start,end)
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            setUserCount(res.data.data);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              // setOpenSessionExpiredModal(true);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUserTableData = (start,end) => {
    analyticsReport
      .getUserData(start,end)
      .then((res) => {
        console.log(res)
        if (res) {
          if (res.status === 200) {
            let array = res.data.date_wise_users_count
            let xAxis =[]
            let yAxis =[]
            array.forEach((item)=>{
              xAxis.push(moment(item.date).format("DD MMM YY"))
              yAxis.push(item.registrations_count)
            })
            // setUserDayCount({date:xAxis,count:yAxis});
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              // setOpenSessionExpiredModal(true);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if(selectValue <10){
    const CurrentRateRange = range[selectValue - 1]
      getAllUsersCount(CurrentRateRange.start,CurrentRateRange.end)
      getUserTableData(CurrentRateRange.start,CurrentRateRange.end)
    }
  }, [selectValue])

  // const downloadScreenshot = () => takeScreenShot(ref.current).then(download);
  const ref = createRef(null);
  // const download = (image, { name = "img", extension = "jpg" } = {}) => {
  //   const a = document.createElement("a");
  //   a.href = image;
  //   a.download = createFileName(extension, name);
  //   a.click();
  // };

  const handleOnSubmit =((values)=>{
    // setOpen(false)
    getAllUsersCount(values.fromDate,values.toDate)
    getUserTableData(values.fromDate,values.toDate)
  })
  return (
    <Root ref={ref} >
      <Page
        className={classes.root}
        title="Unlistedkart | User Dashboard"

      >
        <Grid container style={{ display: "flex", justifyContent: "space-between" }}>
          <Chip
            style={{ marginTop: "10px" }}
            label="Overview"
            clickable
            color="primary"
          />
          <Grid style={{ display: "flex" }}>

            <Select value={selectValue} setValue={setSelectValue} />
           {selectValue ===10 &&
            <Formik
            initialValues={{
              fromDate: "",
              toDate:""
            }}
            validationSchema={Yup.object().shape({
              fromDate: Yup.string().required("Required"),
              toDate: Yup.string().required("Required")
            })}
            onSubmit={(values) => {
              handleOnSubmit(values)
              // setSubmit(true)
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
        <Grid container className={classes.dateGrid}>
          <TextField
            id="fromDate"
            label="From Date"
            type="date"
            name="fromDate"
            error={Boolean(touched.fromDate && errors.fromDate)}
            helperText={touched.fromDate && errors.fromDate}
            onChange={handleChange}
            onBlur={handleBlur}
            InputProps={{ inputProps: { max: `${moment(new Date()).format("YYYY-MM-DD")}` } }}
            value={values.fromDate}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="toDate"
            label="End Date"
            type="date"
            name="toDate"
            value={values.toDate}
            InputProps={{ inputProps: { min: values.fromDate, max: `${moment(new Date()).format("YYYY-MM-DD")}` } }}
            error={Boolean(touched.toDate && errors.toDate)}
            helperText={touched.toDate && errors.toDate}
            onChange={handleChange}
            onBlur={handleBlur}
            // InputProps={{ inputProps: { min: `${moment(values.fromDate).format("YYYY-MM-DD")}` } }}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        {/* </Grid>
        <Grid container className={classes.dateGrid}>
          <Button variant="contained" color="primary" style={{marginRight:'1rem'}} 
          onClick={()=>setOpen(false)}>
            Cancel
          </Button> */}
          <Button className={classes.submit} type="submit" variant="contained" color="primary" >
            Submit
          </Button>
        </Grid>
        </form>
          )}
          </Formik>}
            <Box mr={1} mb={2} style={{ borderLeft: "1px solid black" }}></Box>
            {/* <GetAppIcon style={{ marginTop: '8px', cursor: "pointer" }} onClick={downloadScreenshot} /> */}
          </Grid>
        </Grid>
        <Box style={{ borderBottom: "1px solid #324C90" }}></Box>
        <Box my={5}>
          <GraphTable mainValue={selectValue} selectValue={userSelectValue} setSelectValue={setUserSelectValue} id={userTabIndex} setId={setUserTabIndex} data={userData}>
            <CardContent>
              <Box height={250} position="relative">
                {/* <Line
                  data={data}
                  options={options}
                /> */}
              </Box>
            </CardContent>
          </GraphTable>
          <GraphTable mainValue={selectValue} selectValue={revenueSelectValue} setSelectValue={setRevenueSelectValue} id={revenueTabIndex} setId={setRevenueTabIndex} data={revenueData}>
            <CardContent>
              <Box height={250} position="relative">
                {/* <Line
                  data={data}
                  options={options}
                /> */}
              </Box>
            </CardContent>
          </GraphTable>
        </Box>
        {/* <Dialog open={open} onClose={() => setOpen(false)}>
      
        </Dialog> */}
      </Page>
    </Root>
  );
}

export default Analytics