import axios from 'axios'
import { helpers } from 'src/_helpers'


// axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.token.slice(4, store.state.token.length);

//default header and api endpoint config
export const securityService = {
    getSecurity,
    postSecurity,
    getSecurityByID,
    deleteSecurityByID,
    putSecurity,
    deleteSecurityLogo,
    deleteSecurityOverViewFile,
    deleteSecurityFullReportFile,
    getSecurityTags,
    postSecurityTags,
    deleteSecurityTag,
    updateSecuirtyTags,
    getNews,
    getAllNews,
    postNews,
    updateNews,
    deleteNews,
    getPriceTrendData,
    updatePriceOnPriceTrend,
    updateSecuritySortIndex,
    updateSecurityLastUpdateSort,
    getSecurityLogs,
    getSecurityPriceTrend,
    priceHistory,
    clearSortIndexForAllScrips
};
 


function getPriceTrendData(values) {
    if (typeof (values.month) == 'string') {
        var monthObj = {
            "monthNo": values.month
        }
        values.month = monthObj
    }

    const data =  {
        "year": JSON.parse(values.year),
        "month": JSON.parse(values.month.monthNo),
        "related_security_id": JSON.parse(values.related_security_id)
    }
//  console.log(data, "inputObj");
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/getPriceTrendData`,data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function updatePriceOnPriceTrend(values, id, approve,create_datetime) {
    const inputObj = {

        "price": JSON.parse(values.price),
        "is_split_announced": JSON.stringify(approve),
        "id": JSON.parse(id),
        "create_datetime":create_datetime

    }
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.put(`/updatePriceOnPriceTrend`, JSON.stringify({ inputObj }), requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function updateSecuritySortIndex(security_data){
    const securityData = {
        security_data:security_data
    }

    helpers.setHeader(); 
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/updateSecuritySortIndex`, JSON.stringify(securityData), requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function getSecurity() {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/security`, requestOptions)
        .then(data => data)
        .catch(error => error.response)  
}

function getSecurityPriceTrend() {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/getAllLatestPriceTrend`, requestOptions)
        .then(data => data)
        .catch(error => error.response)  
}

function updateSecurityLastUpdateSort(last_sort_by){
    const securityLastSortBy = {
        "last_sort_by":last_sort_by
    }

    helpers.setHeader(); 
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/updateSecurityLastSortBy`, JSON.stringify(securityLastSortBy), requestOptions)
        .then(data => data)
        .catch(error => error.response)
}
function getSecurityByID(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/security/${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function postSecurity(postSecurityData) {
    helpers.setHeader();
    // const requestOptions = {
    //     headers: {
    //         'Content-Type': 'application/json',
    //     }
    // };
    return axios.post(`/security `, postSecurityData)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}

function deleteSecurityByID(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.delete(`/security/${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function deleteSecurityLogo(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.delete(`/security_logo/${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function deleteSecurityOverViewFile(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.delete(`/security_overview_report/${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function deleteSecurityFullReportFile(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.delete(`/security_full_report/${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function putSecurity(id, putSecurityData) {
    helpers.setHeader();
    return axios.post(`/securityUpdate/${id} `, putSecurityData)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}

function getSecurityTags() {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/getAllSecurityTags`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function postSecurityTags(postSecurityData) {
    helpers.setHeader();
    // const requestOptions = {
    //     headers: {
    //         'Content-Type': 'application/json',
    //     }
    // };
    return axios.post(`/setSecurityTag `, postSecurityData)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}

function deleteSecurityTag(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/deleteSecurityTags`, id, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}


function updateSecuirtyTags(postSecurityData) {
    helpers.setHeader();
    return axios.put(`/updateSecuirtyTags `, postSecurityData)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}

function getNews(data, id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/getSecurityNews/${id}`, data, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function postNews(postNewsData) {
    helpers.setHeader();
    return axios.post(`/setSecurityNews `, postNewsData)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}

function updateNews(postNewsData) {
    console.log(postNewsData,"putNewsData");
    helpers.setHeader();
    return axios.post(`/updateSecurityNews `, postNewsData)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}

function deleteNews(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.delete(`/deleteSecurityNews/${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function getAllNews() {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/getAllSecurityWithNews`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}


function getSecurityLogs(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/getScripLogsByScripId/${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}


function clearSortIndexForAllScrips(){

    helpers.setHeader(); 
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.put(`/clearSortIndexForAllScrips`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function priceHistory(data) {
    helpers.setHeader();
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios
      .post(`/priceGraphForDashboard`, data, requestOptions)
      .then((data) => data)
      .catch((error) => error.response);
  }