import { createTheme } from "@mui/material/styles";
const GetMuiTheme = () =>
    createTheme({
      palette:{
        primary: {
          main: '#314B8F'
        },
      },
      components: {
        MuiPaper: {
          styleOverrides:{
          elevation4: {
            boxShadow: "0 0 0 0 ",
          },
        }
        },
        
     
        editdeletbtn: {
          styleOverrides:{
          display: "flex",
          color: "#3363cb",
          }
        },
        MuiTableCell: {
          styleOverrides:{
          root: {
            // textWrap:'nowrap',
            padding: "8px 4px",
          },
      }
    },
        MuiAppBar: {
          styleOverrides:{
            colorPrimary: {
              boxShadow: "none",
              backgroundColor: "none",
              color: "#324C90",
              marginTop: "4px",
              marginBottom: "-15px",
            },
            
                root: {
                  boxShadow: "none",
                  backgroundColor: "white",
                  color: "$primary.main",
                  marginTop: "4px",
                  marginBottom: "-15px",
                },
          }
          },
          MuiStepIcon: {
            styleOverrides:{
            root: {
              color: "#f44336",
              "&.Mui-completed	": {
                color: "#00C914",
              },
              "&$active": {
                cursor: "pointer",
              },
            },
          }
          },
          MuiStepper: {
            root: {
              background: "none",
              padding: "8px 0px",
            },
          },
          MuiStepLabel: {
            styleOverrides:{
            label: {
              fontSize: "0.67rem",
            },
          }
          },
          MuiTabs: {
          styleOverrides:{
            indicator: {
              background: "#324C90",
            },
          }
          },
        MUIDataTablePagination: {
          styleOverrides:{
          tableCellContainer: {
            border: "none",
            padding: 0
          },
        }
        },
        MuiIconButton: {
          root: {},
        },

        MuiButton: {
          styleOverrides:{
          label: {

            fontSize: '0.65rem'
          }
        }
        },
        MuiButtonBase:{
          styleOverrides:{
            root: {
              textTransform:'none !important',
              // padding:'0px !important'
            },
        },
      },
        MUIDataTableHeadCell: {
          styleOverrides:{
          root: {
            textWrap:'nowrap',          
          },
          hintIconAlone: {
            marginLeft: "4px",
            marginTop: '2px',
            color: ' gray'
          }
        }
        },
      },
    });

    export default GetMuiTheme