import React from "react";
import { styled } from '@mui/material/styles';
import loadingSpinner from "src/images/loading-spinner.gif";
import {
  FormControl,
  Grid,
  FormLabel,
  Container,
  FormGroup,
  Checkbox,
  TextField,
  FormControlLabel,
  Button,
  FormHelperText,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import moment from "moment";
const PREFIX = 'CommissionStatus';

const classes = {
  container: `${PREFIX}-container`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  buttonProgress: `${PREFIX}-buttonProgress`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.container}`]: {
    justifyContent: "center",
    // justifyItems: "center",

    marginTop: "10%",
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: "4px",
  }
}));

function CommissionStatus(props) {

  const [loading, setLoading] = React.useState(false);

  return (
    <StyledContainer>
      <Formik
        initialValues={{
          commission_paid_status: props.data.status,
          order_id: props.order_id,
          commission_paid_date: props.data.date ? moment(props.data.date).format("YYYY-MM-DD") : "",
        }}
        validationSchema={Yup.object().shape({
          commission_paid_date: Yup.string().when("commission_paid_status", {
            is: true,
            then:Yup.string().required("Required"),
          })
        })}
        onSubmit={(values) => {
          setLoading(true);
          props.formSubmit(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl
                    sx={{ display: "flex" }}
                    component="fieldset"
                    variant="standard"
                  >
                    <FormLabel component="legend">Commission status</FormLabel>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.commission_paid_status}
                            name="commission_paid_status"
                            id="commission_paid_status"
                            aria-describedby="outlined-weight-helper-text"
                            onBlur={handleBlur}
                            onChange={(e)=>{
                                setFieldValue('commission_paid_status',e.target.checked )
                                if(!e.target.checked){
                                    setFieldValue('commission_paid_date','')
                                }
                            }}
                            color="primary"
                          />
                        }
                        label="Paid"
                      />
                    </FormGroup>
                    <FormHelperText
                      style={{ color: "#F44336", paddingLeft: 2 }}
                    >
                      {touched.commission_paid_status ? errors.commission_paid_status : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6} className={classes.gridPadding}>
                  <TextField
                    fullWidth
                    id="commission_paid_date"
                    label="Commission Paid Date"
                    type="date"
                    error={Boolean(
                      touched.commission_paid_date &&
                        errors.commission_paid_date
                    )}
                    disabled={!values.commission_paid_status}
                    helperText={
                      touched.commission_paid_date &&
                      errors.commission_paid_date
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="commission_paid_date"
                    value={values.commission_paid_date}
                    variant="outlined"
                    InputLabelProps={{
                      readOnly: true,
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
              <Button
                color="primary"
                disabled={isSubmitting}
                className={classes.popDownBtn}
                size="medium"
                type="submit"
                variant="contained"
              >
                {loading === false ? (
                  "Update"
                ) : (
                  <>
                    <img
                      src={loadingSpinner}
                      alt="Logo"
                      className={classes.buttonProgress}
                    />{" "}
                    Update{" "}
                  </>
                )}
              </Button>
            </Container>
          </form>
        )}
      </Formik>
    </StyledContainer>
  );
}

export default CommissionStatus;
