import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import {
  Button,
  Autocomplete,
  Container,
  Grid,
  
} from "@mui/material";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import ReactSpinner from "src/components/ReactSpinner/ReactSpinner";
import Page from "src/components/Page";
import NormalCard from "src/components/Cards/NormalCard";
import TextField from "@mui/material/TextField";
import { AllFaq } from "src/_services/faq's";
import { useNavigate, useLocation } from "react-router-dom";
import FormHelperText from "@mui/material/FormHelperText";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const PREFIX = 'FAQsPage';

const classes = {
  root: `${PREFIX}-root`,
  addressBlock: `${PREFIX}-addressBlock`,
  gridPadding: `${PREFIX}-gridPadding`,
  accountBlock: `${PREFIX}-accountBlock`,
  reactSpinner: `${PREFIX}-reactSpinner`,
  accountTitle: `${PREFIX}-accountTitle`,
  addressBlocktitle: `${PREFIX}-addressBlocktitle`,
  removeLink: `${PREFIX}-removeLink`,
  buttonProgress: `${PREFIX}-buttonProgress`,
  wrapper: `${PREFIX}-wrapper`,
  btnroot: `${PREFIX}-btnroot`,
  btnright: `${PREFIX}-btnright`,
  textfield: `${PREFIX}-textfield`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },

  [`& .${classes.addressBlock}`]: {
    padding: "1rem",
    color: "#314B8F",
  },

  [`& .${classes.gridPadding}`]: {
    paddingBottom: 0,
  },

  [`& .${classes.accountBlock}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem",
    margin: "1rem 0",
  },

  [`& .${classes.reactSpinner}`]: {
    position: "absolute",
    height: "100%",
    width: "100%",
    background: "#f4f6f870",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  [`& .${classes.accountTitle}`]: {
    fontSize: "1.2rem",
    fontWeight: "500",
    color: "#314B8F",
  },

  [`& .${classes.addressBlocktitle}`]: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(2),
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },

  [`& .${classes.removeLink}`]: {
    cursor: "pointer",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: "8px",
  },

  [`& .${classes.wrapper}`]: {
    margin: theme.spacing(1),
    position: "relative",
  },

  [`& .${classes.btnroot}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },

  [`& .${classes.btnright}`]: {
    textAlign: "end",
  },

  [`& .${classes.textfield}`]: {
    "& .MuiInputBase-input": {
      position: "relative",
      width: "100%",
      color: " black !important",
      fontStyle: "normal  !important",
      fontWeight: "500 !important",
      fontSize: "16px  !important",
      lineHeight: "24px  !important",
    },
  }
}));

const FAQsPage = () => {

  const location = useLocation();
  const spinner = false
  const faqcData ={
    question: location.state.type === "update" ? location.state.question : "",
    answer: location.state.type === "update" ? location.state.answer : "",
    category: location.state.type === "update" ? location.state.category : "",
  }
  const [allFaqCategories, setAllFaqCategories] = useState([]);

  const updateId=
    location !== undefined
      ? location.state !== undefined
        ? location.state.itemid
        : null
      : null;
  

  const navigate = useNavigate();
  const getAllFaqCategories = () => {
    AllFaq.getAllFaqCategories()
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            setAllFaqCategories(res.data.data);
            // const initValues = {
            //   category: { description: res.data.data },
            // };
            // setFaqcData(...faqcData);
          }
        }
      })
      .catch((error) => console.log(error));
  };

  const handleOnSubmit = (values) => {
    if (location.state.type === "create") {
      AllFaq.insertFaq(values)

        .then((res) => {
          navigate("/app/FAQs", { replace: true });
        })
        .catch((error) => console.log(error));
    } else if (location.state.type === "update") {
      AllFaq.updateFaq(values, updateId)
        .then((res) => {
          navigate("/app/FAQs", { replace: true });
        })
        .catch((error) => console.log(error));
    }
  };

  useEffect(() => {
    getAllFaqCategories();
  }, []);

  return (
    <Root>
    <Page className={classes.root} title="Unlistedkart | FAQ's">
      <Container maxWidth={false} className={classes.container}>
        <div className={classes.btnright}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            size="medium"
            onClick={() => navigate(`/app/FAQs`, { replace: true })}
          >
            Back to FAQ's
          </Button>
        </div>
        <Grid container alignItems="center" justifyContent="center">
          <Formik
            enableReinitialize={true}
            initialValues={faqcData}
            validationSchema={Yup.object().shape({
              question: Yup.string()
                .nullable()
                .required("question is required"),

              answer: Yup.string()
                .nullable()
                .required("Answer is required"),
              category: Yup.string()
                .nullable()
                .required("Category is required"),
            })}
            onSubmit={(values) => {
              handleOnSubmit(values);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              touched,
              values,
            }) => (
              <form
                onSubmit={handleSubmit}
                style={{ padding: "1rem" }}
                autoComplete="off"
                noValidate
              >
                <NormalCard title="FAQ's">
                  {spinner && (
                    <div className={classes.reactSpinner}>
                      <ReactSpinner />
                    </div>
                  )}
                  <div style={{ padding: "1rem" }}>
                    <Grid
                      container
                      spacing={5}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className={classes.gridPadding}
                      >
                        <TextField
                          id="question"
                          placeholder={
                            location.state.type === "update"
                              ? location.state.question
                              : "Question"
                          }
                          className={classes.textfield}
                          name="question"
                          autoComplete="off"
                          value={values.question}
                          fullWidth
                          onChange={handleChange}
                          error={Boolean(touched.question && errors.question)}
                          helperText={touched.question && errors.question}
                        />
                        {/* <FormHelperText style={{ color: "red" }}>
                            {errors.question &&
                              touched.question &&
                              errors.question}
                          </FormHelperText> */}
                      </Grid>

                      {/* <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className={classes.gridPadding}
                      >
                        <TextField
                          id="answer"
                          placeholder={
                            location.state.type === "update"
                              ? location.state.answer
                              : "Answer"
                          }
                          className={classes.textfield}
                          name="answer"
                          autoComplete="off"
                          fullWidth={true}
                          value={values.answer}
                          onChange={handleChange}
                          error={Boolean(touched.answer && errors.answer)}
                          helperText={touched.answer && errors.answer}
                        />
                      </Grid> */}

                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className={classes.gridPadding}
                      >

                      <Field
                        name="answer">
                          {({ field, form }) => {
                          return (
                            <>
                               <CKEditor
                                editor={ClassicEditor}
                                data={field.value}
                                config={{
                                  toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'numberedList', 'bulletedList', 'insertTable',
                                    '|', 'undo', 'redo']
                                }}
                                onChange={(e, editor) => {
                                  form.setFieldValue("answer", editor.getData());
                                }}
                              />
                            </>
                          )
                        }}
                      </Field>
                              <FormHelperText style={{  color:  "#F44336" , marginBottom:'1rem'}}>{errors.answer}</FormHelperText>
                        <Autocomplete
                          id="tag"
                          options={allFaqCategories}
                          getOptionLabel={(option) =>
                            option.name ? option.name : ""
                          }
                          value={values.category}
                          onChange={(e, value) => {
                            setFieldValue("category", value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              placeholder={
                                location.state.type === "update"
                                  ? location.state.name
                                  : "Category"
                              }
                              className={classes.textfield}
                              name="category"
                              error={Boolean(
                                touched.category && errors.category
                              )}
                              helperText={touched.category && errors.category}
                            />
                          )}
                        />
                      </Grid>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{ margin: "2rem 0" }}
                      >
                        {location.state.type === "update"
                          ? " Update FAQ's"
                          : "  Add FAQ's"}
                      </Button>
                    </Grid>
                  </div>
                </NormalCard>
              </form>
            )}
          </Formik>
        </Grid>
      </Container>
    </Page>
    </Root>
  );
};

export default FAQsPage;
