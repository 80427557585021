import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import { FormControl, FormHelperText, FormGroup, Checkbox, Container, Grid, FormControlLabel } from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import { ChannelGroupService } from 'src/_services/groupSelector';
import loadingSpinner from 'src/images/loading-spinner.gif'
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal"



const PREFIX = 'SelectGroupForm';

const classes = {
  zeroPadding: `${PREFIX}-zeroPadding`,
  cardBorderRadius: `${PREFIX}-cardBorderRadius`,
  cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
  fileInput: `${PREFIX}-fileInput`,
  imageSizes: `${PREFIX}-imageSizes`,
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  marginInputLabel: `${PREFIX}-marginInputLabel`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  resendBtnMargin: `${PREFIX}-resendBtnMargin`,
  inputMargin: `${PREFIX}-inputMargin`,
  buttonProgress: `${PREFIX}-buttonProgress`,
  paddingInput: `${PREFIX}-paddingInput`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.zeroPadding}`]: {
    padding: 0,
  },

  [`& .${classes.cardBorderRadius}`]: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },

  [`& .${classes.cardHeaderMargin}`]: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    // justifyContent:"center",
    borderRadius: "0 0  10px 0",
  },

  [`& .${classes.fileInput}`]: {
    display: "none",
  },

  [`& .${classes.imageSizes}`]: {
    width: "40%",
    margin: "auto",
    display: "block",
  },

  [`& .${classes.container}`]: {
    justifyContent: "center",
    // justifyItems: "center",

    marginTop: "10%",
  },

  [`& .${classes.label}`]: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },

  [`& .${classes.marginInputLabel}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.resendBtnMargin}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.inputMargin}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: '4px'
  },

  [`& .${classes.paddingInput}`]: {
    padding: "0px 0px",
  }
}));



// const DialogTitle = ((props) => {
//   const { children,  onClose, ...other } = props;
//   console.log('formmmmmm')
//   return (
//     <MuiDialogTitle disableTypography className={classes.root} {...other}>
//       <Typography variant="h6">{children}</Typography>
//       {onClose ? (
//         <IconButton
//           aria-label="close"
//           className={classes.closeButton}
//           onClick={onClose}
//         >
//           <CloseIcon />
//         </IconButton>
//       ) : null}
//     </MuiDialogTitle>
//   );
// });

// const DialogContent =  => ({
//   root: {
//     root: {
//       padding: theme.spacing(2),
//     },
//   },
// }))(MuiDialogContent);

// const DialogActions =  => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(1),
//   },
// }))(MuiDialogActions);

function SelectGroupForm(props) {


  const [groupList, setGroupList] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)


 
  const getGroupSelectorDatafromAPI = () => {
    ChannelGroupService.getCPgroupsData()
      .then(res => {
        if (res) {


          if (res.status === 200) {
            setGroupList(res.data)
          }else{
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true)
            }
          }

        }
      }).catch(error => {

      })
  }

  useEffect(() => {
    getGroupSelectorDatafromAPI();
  }, [])
  return (
    <StyledContainer>
      <Formik
        initialValues={{
          groups: [],
        }}
        validationSchema={Yup.object().shape({
          groups: Yup.array().required("Required atleast One Group"),
        })}
        onSubmit={(values) => {
          setLoading(true)
          props.formSubmit(values)

        }}
      >
        {({
          errors,

          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,

        }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>
              <Grid container spacing={5} alignItems="center">
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControl component="fieldset" color="primary">
                    <FormGroup value={values.groups} error={Boolean(touched.groups && errors.groups)}>
                      {
                        groupList.map((item, i) => {
                          return (
                            <FormControlLabel value={item.id} color="primary" control={<Checkbox color="primary" onChange={handleChange} name="groups" />} label={item.group_name} />
                          )
                        })
                      }
                    </FormGroup>
                    <FormHelperText style={{ color: "#F44336" }}>
                      {errors.groups &&
                        touched.groups &&
                        errors.groups}
                    </FormHelperText>
                  </FormControl>
                  {/* <ErrorFocus/> */}
                </Grid>
              </Grid>
              <Button
                color="primary"
                disabled={isSubmitting}
                className={classes.popDownBtn}
                size="medium"
                type="submit"
                variant="contained"
              >
                {loading === false ? "Send" : <><img alt="loader" src={loadingSpinner} className={classes.buttonProgress} /> Send </>}
              </Button>
            </Container>
          </form>
        )}
      </Formik>
      <SessionExpiredModal
        open={openSessionExpiredModal}
      />
    </StyledContainer>
  );
}

export default SelectGroupForm;
