import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import {
  Container,
  TextField,
  Typography,
  OutlinedInput,
  FormHelperText,Box
} from "@mui/material";
import loadingSpinner from "src/images/loading-spinner.gif";
import * as Yup from "yup";
import { Formik } from "formik";
import ModalCentered from "src/components/Modals/ModalCentered";
import ImageRotate from "../Profile/Rotate";

const PREFIX = "CreateTicket";

const classes = {
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  inputMargin: `${PREFIX}-inputMargin`,
  buttonProgress: `${PREFIX}-buttonProgress`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.container}`]: {
    justifyContent: "center",
    // justifyItems: "center",
    marginBottom: "20%",
  },

  [`& .${classes.label}`]: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.inputMargin}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: "4px",
  },
}));

function CreateTicket(props) {
  const [loading, setLoading] = React.useState(false);
  const [preview, setPreview] = React.useState(false);
  const [initValues, setInitValues] = useState({
    subject: "",
    description: "",
    url: "",
    file: null,
  });

  useEffect(() => {
    if (props.mode === "edit") {
      setInitValues(props.data);
    }
  }, []);

  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
    "image/svg+xml",
    "application/pdf"
  ];



  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialValues={initValues}
        validationSchema={Yup.object().shape({
          subject: Yup.string().required("Required"),
          description: Yup.string().required("Required"),
          file:Yup.mixed()
              .test(
                "fileFormat",
                "Unsupported Format",
                (value) => value === null ||  SUPPORTED_FORMATS.includes(value.type)
              )
              .test(
                "fileSize",
                "Maximum file size is 10MB",
                (value) => value === null ||  value.size < 10242880
              ).nullable(),
              
        })}
        onSubmit={(values) => {
          setLoading(true);
          props.formSubmit(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>
              <TextField
                className={classes.inputMargin}
                variant="outlined"
                label="Subject*"
                id="subject"
                name="subject"
                onBlur={handleBlur}
                onChange={handleChange}
                autoComplete="off"
                fullWidth
                value={values.subject}
                error={Boolean(touched.subject && errors.subject)}
                helperText={touched.subject && errors.subject}
                aria-describedby="outlined-weight-helper-text"
              />
              <TextField
                className={classes.inputMargin}
                variant="outlined"
                label="Description*"
                id="description"
                name="description"
                onBlur={handleBlur}
                onChange={handleChange}
                autoComplete="off"
                fullWidth
                multiline
                minRows={4}
                value={values.description}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
                aria-describedby="outlined-weight-helper-text"
              />
              <div>
                <Typography className={classes.label}>
                  Related File
                </Typography>
                <OutlinedInput
                  accept="image/*"
                  // className={classes.input}
                  id="icon-button-file"
                  name="file"
                  fullWidth={true}
                  error={touched.file && Boolean(errors.file)}
                  helperText={touched.file ? errors.file : ""}
                  onChange={(e) => {
                    setFieldValue("file", e.currentTarget.files[0]);
                  }}
                  type="file"
                />
                {values.file && !errors.file ? (
                        <FormHelperText
                          onClick={() => {
                            const url = URL.createObjectURL(values.file);
                            setPreview({
                              url: url,
                              type:
                                values.file.type === "application/pdf"
                                  ? true
                                  : false,
                            });
                          }}
                          style={{
                            textDecoration: "underline",
                            fontWeight: "bold",
                          }}
                        >
                          {"Preview"}
                        </FormHelperText>
                      ) : (
                        <FormHelperText
                          style={{ color: "#F44336", paddingLeft: 16 }}
                        >
                          {errors.file}
                        </FormHelperText>
                      )}
              </div>
              <Button
                color="primary"
                disabled={isSubmitting}
                className={classes.popDownBtn}
                size="medium"
                type="submit"
                variant="contained"
              >
                {loading === false && props.mode === "create" ? (
                  "Add"
                ) : loading === false && props.mode === "edit" ? (
                  "Update"
                ) : (
                  <>
                    <img
                      src={loadingSpinner}
                      alt="Logo"
                      className={classes.buttonProgress}
                    />{" "}
                    {props.mode === "create" ? "Add" : "Update"}
                  </>
                )}
              </Button>
            </Container>
          </form>
        )}
      </Formik>
        <ModalCentered
        title={"Preview"}
        open={preview}
        toggle={() => setPreview(false)}
        // maxWidth="md"
        fullScreen="true"
        children={
          <Box sx={{ textAlign: "center" }}>
            {preview.type ? (
              <iframe
                title="iframe"
                style={{ width: "100%", height: "calc(100vh - 100px)" }}
                src={preview.url}
              />
            ) : (
              <ImageRotate
                alt="cool image"
                type="users"
                image={preview && preview.url}
              />
            )}
          </Box>
        }
      />
    </StyledContainer>
  );
}

export default CreateTicket;
