import React, {useState, useEffect} from 'react'
import { styled } from '@mui/material/styles';
import {  Typography, IconButton} from '@mui/material';
import Page from 'src/components/Page';
import ServiceCard from "src/components/Cards/ServiceCard";
import { securityService } from 'src/_services/security';
import {  ThemeProvider } from '@mui/material/styles';
import MUIDataTable from "mui-datatables";
import { useNavigate, NavLink } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal";
import GetMuiTheme from 'src/components/theme';
import NormalCard from 'src/components/Cards/NormalCard';

const PREFIX = 'Allnews';

const classes = {
    tableEllips: `${PREFIX}-tableEllips`,
    card: `${PREFIX}-card`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.tableEllips}`]: {
        display: "flex",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: '11rem',
        [theme.breakpoints.down('sm')]: {
            width: '8rem',
        },
        [theme.breakpoints.up('sm')]: {
            width: '11rem',
        },
    },

    [`& .${classes.card}`]: {
        borderBottom: "none",
        padding: 0,
      }
}));

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) { // eslint-disable-line
    return <NavLink to={props.to} {...props}  />; // eslint-disable-line
});

const Allnews = () => {

    const [loading, setLoading] = useState(false);
    const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)
    const [securityData, setSecurityData] = useState([])
    const navigate = useNavigate();

    const navigatetoNews = (id)  =>{
        navigate(`/app/security-details/${id}`, 
        {
            replace:true,
            state:{tabvalue:2}
        }

        )
    }
    const columns = [
        {
            name: "id",
            label: "Id",
            options: {
                filter: false,
                sort: false,
                display: false
            }
        },
        {
            name: "name",
            label: "Scrip Name",
            options: {
                hint: 'Name of the Scrips',
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    return (
                            <Typography
                                variant='body2'
                                style={{ textTransform: 'capitalize',cursor:"pointer" }}
                                component={LinkBtn}
                                to={`/app/security-details/${tableMeta.rowData[0]}`}
                                noWrap
                                color="primary"
                            >
                                {value}
                            </Typography>
                    );
                  },
            }
        },
       
        {
            name: "security_news_count",
            label: "News Count ",
        },
        {
            name: "last_news_added_date",
            label: "Last Updated ",
            options: {
                hint: 'Last news added',
                filter: false,
                sort: true,
                customBodyRender: (value,) => {
                    let valueMoment = moment(value).format("DD-MM-YYYY LTS")
                    return (
                        <div className={classes.tableEllips} >
                            <Typography
                                variant='body2'
                                noWrap
                            >
                                {valueMoment !== "Invalid date"  && valueMoment}
                            </Typography>
                        </div>
                    )
                }
            }
          
        },
        {
            name: "",
            label: "Action",
            options: {
                sort: false,
                filter: false,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <span className={classes.editdeletbtn} >
                            <IconButton 
                            onClick={()=>{navigatetoNews(tableMeta.rowData[0])}}
                            className={classes.iconbtn}
                             aria-label="edit" >
                                <EditIcon />
                            </IconButton>
                        </span>
                    );
                }
            }
        }
    ];

    const getMuiTheme = GetMuiTheme


    const getSecurity = () => {
        setLoading(true)
        securityService.getAllNews()
        .then(res => {
                if (res) {
                    if (res.status === 200) {

                        setSecurityData(res.data.data)
                        setLoading(false)
                    } else {
                        if (res.status === 401 && res.data.name === "TokenExpiredError") {
                            setOpenSessionExpiredModal(true)
                            setLoading(false)
                        }
                    }

                }
            }).catch(error => console.log(error))
    }
    useEffect(() => {
        getSecurity()
    }, [])

    return (
        <Root>
            <Page title="Unlistedkart | Security News">
                <NormalCard title="All scrip news">
            <ServiceCard className={classes.card}>
            <ThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable

                            data={securityData}
                            headerSort={false}
                            columns={columns}
                            options={{
                                rowsPerPage: 10,
                                rowsPerPageOptions: [5, 10, 25],
                                // responsive: 'scrollMaxHeight',
                                viewColumns: false,
                                download: true,
                                print: false,            
                                selectableRows: "none",
                                sortOrder: {
                                    name: 'last_updated',
                                    direction: 'desc'
                                },
                                textLabels: {
                                    body: {
                                        noMatch: loading ?
                                            'Loading...' : 'Sorry, there is no matching data to display'
                                    },
                                },
                            }}
                        />
                    </ThemeProvider>
            </ServiceCard>
            </NormalCard>
      <SessionExpiredModal open={openSessionExpiredModal} />
            </Page>
        </Root>
    );
}

export default Allnews