import React from "react";
import { styled } from '@mui/material/styles';
import {  Button, Dialog,DialogContent,DialogActions,DialogTitle, Zoom,TextField,Chip } from "@mui/material";
import { userService } from "src/_services/users";
const PREFIX = 'TagModal';

const classes = {
  wrap: `${PREFIX}-wrap`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.wrap}`]: {
      overflowWrap:'break-word', 
      wordWrap:'break-word', 
      width:'350px', 
      margin:'1rem auto'
  }
}));

const TagModal = ({getData,open,setOpen,userId ,userName,tagName,setTagName,tags,setTags,setSnackbarTitle,setOpenSessionExpiredModal,setSeverity,setOpenSnackBar}) => {



    const handleAddTag =(e) =>{
        if (e.key === "Enter" && e.target.value.trim() !== "") {
          e.preventDefault();
          tags.push(e.target.value);
          setTags([...tags], e.target.value);
          e.target.value = "";
          setTagName('')
        }
    }
    const handleDelete = (i) =>{
        setTags((tag) =>
        tag.filter((name,index) => index !== i)
      );
    }

    const updateUserTag = () => {
        const data= {
        userid:userId,
        tagsData:tagName ? [...tags, tagName] :tags.length > 0 ? tags : null,
        updatedValues:JSON.stringify([{
          name:'tag',
          oldValue:tags,
          newValue:tagName ? [...tags, tagName] :tags.length > 0 ? tags : null
        }])
        }
 

        userService.updateUserTag(data).then((res) => {
            if (res.status === 200) {
              getData('', 0)
              setOpenSnackBar(true);
              setSeverity("success");
              setSnackbarTitle("Successfully updated user tags");
            } else if (
              res.status === 401 &&
              res.data.name === "TokenExpiredError"
            ) {
              setOpenSessionExpiredModal(true);
            } else {
              setSeverity("error");
              setSnackbarTitle("Something went wrong !");
              setOpenSnackBar(true);
            }
        })
    }
  return (
    <Root>
    <Dialog
    TransitionComponent={Zoom}   transitionDuration={500}
      maxWidth="md"
      sx={{
        "& .MuiPaper-root":{
          width:400
        }
      }}
      aria-labelledby="confirmation-dialog-title"
      open={open}
    >
      <DialogTitle id="confirmation-dialog-title">Edit tags</DialogTitle>
      <DialogContent dividers>
        <TextField
          fullWidth
          variant="standard"
          helperText="Press Enter to add tags"
          label={`Add tags to ${userName}`}
          onKeyPress={handleAddTag}
          onChange={(e) => {
            setTagName(e.target.value);
          }}
        />
        <div className={classes.wrap}>
          {tags &&
            tags.map((item, index) => (
              <Chip
                style={{ margin: "4px" }}
                key={index}
                tabIndex={-1}
                label={item}
                onDelete={() => handleDelete(index)}
              />
            ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          // disabled={!tagName && tags.length === 0}
          onClick={() => {
            updateUserTag();
            setOpen(false);
          }}
          color="primary"
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
    </Root>
  );
};

export default TagModal;
