import axios from "axios";
// const apiEndpoint = "https://c9d3-27-7-169-198.in.ngrok.io/api/v1";

export const analyticsReport = {
    getUserCounts,
    getUserData      
};
 

function getUserCounts(fromDate,toDate) {
    const requestOptions = {
        headers: { 
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`?fromdate=${fromDate}&todate=${toDate}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function getUserData(fromDate,toDate) {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/registrations_per_day_count?fromdate=${fromDate}&todate=${toDate}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}
