import * as React from "react";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import {
  Typography,
  AppBar,
  Box,
  Tabs,
  Tab,
  Zoom,
} from "@mui/material";

import SecuritySortIndexTagged from "./SecuritySortIndexTagged";
import SecuritySortIndexUntagged from "./SecuritySortIndexUntagged";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const getMuiTheme =
    createTheme({
      palette: {
        primary: {
          main: "#324C90",
        },
      },
      components: {
        MuiAppBar: {
          styleOverrides: {
            root: {
              boxShadow: "none",
              backgroundColor: "white",
              color: "$primary.main",
              marginTop: "4px",
              marginBottom: "-15px",
            },
          },
        },
        MuiTabs: {
          styleOverrides: {
            indicator: {
              background: "$primary.main",
            },
          },
        },
      },
    });

  return (
    <Zoom in={true}>
      <Box sx={{ bgcolor: "background.paper", width: "100%" }}>
        <ThemeProvider theme={getMuiTheme}>
          <AppBar position="static" sx={{
            "&.MuiAppBar-root": {
              color: "#324C90"
            }
          }}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example"
              centered
            >
              <Tab label="Tagged Scrips" {...a11yProps(0)} />
              <Tab label="Un-Tagged Scrips" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
        </ThemeProvider>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <SecuritySortIndexTagged />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <SecuritySortIndexUntagged />
          </TabPanel>
        </SwipeableViews>
      </Box>
    </Zoom>
  );
}
