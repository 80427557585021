import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles';
import MUIDataTable from "mui-datatables";
import {  Button, Typography } from '@mui/material'

import { ThemeProvider } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';

import ModalCentered from 'src/components/Modals/ModalCentered'
import SelectGroupForm from './SelectGroupForm'
import SnackBar from "src/components/SnackBar/SnackBar"
import NormalCard from "src/components/Cards/NormalCard"
import Page from 'src/components/Page';
import { inventoryPriceService } from 'src/_services/inventoryPrice';
import moment from 'moment'
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal"
import GetMuiTheme from 'src/components/theme';
const PREFIX = 'IrmInventoryAndPrice';

const classes = {
    root: `${PREFIX}-root`,
    container: `${PREFIX}-container`,
    editdeletbtn: `${PREFIX}-editdeletbtn`,
    btnsdltedt: `${PREFIX}-btnsdltedt`,
    footerbtn: `${PREFIX}-footerbtn`,
    footerbtnrit: `${PREFIX}-footerbtnrit`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.root}`]: {
        margin: theme.spacing(2),
        '& .MuiTableHead-root': {
            background: 'blue'
        }
    },

    [`& .${classes.container}`]: {
        padding: '24px'
    },

    [`& .${classes.editdeletbtn}`]: {
        color: '#3363cb'
    },

    [`& .${classes.btnsdltedt}`]: {
        marginRight: theme.spacing(1)
    },

    [`& .${classes.footerbtn}`]: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.down(453)]: {
            textAlign: 'center',
            width: '95%'
        },
    },

    [`& .${classes.footerbtnrit}`]: {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down(453)]: {
            marginLeft: theme.spacing(0),
            marginTop: theme.spacing(2),

        },
    }
}));

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) { // eslint-disable-line
    return <NavLink to={props.to} {...props}  />; // eslint-disable-line
});


const IrmInventoryAndPrice = () => {

    const [openSnakbar, setOpenSnakbar] = React.useState(false);
    const [severity, setSeverity] = React.useState("");
    const [snackbarTitle, setSnackbarTitle] = React.useState("");
    const [showModal, setShowModal] = React.useState(false)
    const [loading, setLoading] = useState(false);
    const [iRMInventoryPriceData, setIRMInventoryPriceData] = useState([])
    const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)






    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnakbar(false);
    };


    const handleShow = () => {
        setShowModal(true);
    }

    const handleShowClose = () => {
        setShowModal(false);
    }

    const submitHandle = (values) => {

        setShowModal(false)
        setSeverity("success")
        // setSnackbarTitle("Inventory And Price Updated Successfully")
        setOpenSnakbar(true);
    }

    useEffect(() => {
        setLoading(true)
        inventoryPriceService.getIRMInventoryPrice()
            .then(res => {
                if (res) {



                    if (res.status === 200) {

                        setIRMInventoryPriceData(res.data)
                        setLoading(false)
                    }
                    else {
                        if (res.status === 401 && res.data.name === "TokenExpiredError") {
                            setOpenSessionExpiredModal(true)
                            setLoading(false)
                        } else {
                            setLoading(false)
                            setSeverity("error")
                            setSnackbarTitle("Invalid Token")
                            setOpenSnakbar(true);
                        }

                    }
                }
            }).catch(error => {
                console.log(error)
                setLoading(false)
            })
    }, [])






    const getMuiTheme = GetMuiTheme

    const columns = [
        {
            name: "security_id",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display: false,

            }
        },
        {
            name: "name",
            label: "Scrip Name",
            options: {
                hint: "Name of the scrips",
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <Typography
                            style={{ textTransform: 'capitalize' }}
                            variant='body2'
                            component={LinkBtn}
                            noWrap={false}
                            to={`/app/security-details/${tableMeta.rowData[0]}`}
                            color="primary"
                        >
                            {value}
                        </Typography>
                    )
                }
            }
        },
        {
            name: "parent_company_name",
            label: "Company",
            options: {
                hint: "Scrip Associated Company",
                filter: true,
                sort: true,
            }
        },
        {
            name: "sector",
            label: "Sector",
            options: {
                hint: "Industry Sectors",
                filter: false,
                sort: false,
            }
        },

        {
            name: "price",
            label: "Price",
            options: {
                hint: "Current Share Price",
                filter: false,
                sort: false,
            }
        },
        {
            name: "quantity",
            label: "Stock",
            options: {
                hint: "Current Quantity of Available Shares",
                filter: false,
                sort: false,
            }
        },

        {
            name: "last_updated",
            label: "Last Updated",
            options: {
                hint: "Last Update to the Inventory",
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    let valueMoment = moment(value).format("DD-MM-YYYY LTS")
                    return (
                        <div className={classes.tableEllips} >
                            <Typography
                                variant='body2'
                                noWrap
                            >
                                {value !== null ? valueMoment : null}
                            </Typography>
                        </div>
                    )
                }
            }
        },

    ];

    const publishToCp = () => {

        handleShow()
    }



    return (
        <Root>
        <Page title="Unlistedkart | IRM Inventory and Price">

            <NormalCard title={"IRM Inventory and Price"}
                subtitle={"List of Scrips, Current Inventory and Price"}
            >
                <ThemeProvider theme={getMuiTheme}>
                    <MUIDataTable
                    
                        data={iRMInventoryPriceData}
                        headerSort={false}
                        columns={columns}
                        options={{
                            selectableRows: "none",
                            viewColumns: false,
                            download: true,
                            print: false,        
                            // disableToolbarSelect: true,
                            delete: false,
                            rowsPerPage: 10,
                            rowsPerPageOptions: [5, 10, 25],
                            sortOrder: {
                                name: 'last_updated',
                                direction: 'desc'
                            },
                            textLabels: {
                                body: {
                                    noMatch: loading ?
                                        'Loading...' : 'Sorry, there are no matching data to display'
                                },
                            },
                        }}
                    />
                    </ThemeProvider>
                <div className={classes.footerbtn}>

                    <Button className={classes.footerbtnrit}
                        color='primary'
                        size='small'
                        disabled={true}
                        onClick={publishToCp}
                        style={{ marginRight: 16 }}
                        variant='contained'>
                        Publish To  Channel Partner
                    </Button>
                </div>
                <ModalCentered
                    title="Select Groups"
                    open={showModal}
                    toggle={handleShowClose}
                    children={(<SelectGroupForm formSubmit={submitHandle} />)}
                />
                <SnackBar open={openSnakbar} severity={severity} close={handleClose} snackbarTitle={snackbarTitle} />

                <SessionExpiredModal
                    open={openSessionExpiredModal}
                />

            </NormalCard>
        </Page>
        </Root>
    );
}
export default IrmInventoryAndPrice
