import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import MUIDataTable from "mui-datatables";
import clsx from 'clsx';
import { ThemeProvider, } from '@mui/material/styles';
import moment from 'moment'
import { Button, Card, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import { NavLink } from 'react-router-dom';
import { serviceOrderService } from 'src/_services/serviceOrder';
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal"
import GetMuiTheme from 'src/components/theme';

const PREFIX = 'DashboardView';

const classes = {
    active: `${PREFIX}-active`,
    inactive: `${PREFIX}-inactive`,
    closed: `${PREFIX}-closed`,
    processing: `${PREFIX}-processing`,
    completed: `${PREFIX}-completed`,
    boxHeader: `${PREFIX}-boxHeader`,
    viewAll: `${PREFIX}-viewAll`,
    stageOneProcessing: `${PREFIX}-stageOneProcessing`,
    stageOneProcessed: `${PREFIX}-stageOneProcessed`,
    stageTwoProcessed: `${PREFIX}-stageTwoProcessed`,
    stageTwoProcessing: `${PREFIX}-stageTwoProcessing`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.active}`]: {
        color: 'white',
        backgroundColor: "#00C914",
        fontSize: '11px',
    },

    [`& .${classes.inactive}`]: {
        color: 'white',
        fontSize: '11px',
        backgroundColor: "#D50000"
    },

    [`& .${classes.closed}`]: {
        color: 'white',
        fontSize: '11px',
        backgroundColor: "#314B8F"
    },

    [`& .${classes.processing}`]: {
        color: 'white',
        fontSize: '11px',
        backgroundColor: "#A48800"
    },

    [`& .${classes.completed}`]: {
        color: 'white',
        fontSize: '11px',
        backgroundColor: "#A40094"
    },

    [`& .${classes.boxHeader}`]: {
        fontWeight: 'normal',
        padding: '4px 14px',
        borderBottom: "1px solid #e0e0e0"
    },

    [`& .${classes.viewAll}`]: {
        float: 'right',
        padding: '4px 8px',
        fontSize: '12px',
        margin: '14px',
    },
    [`& .${classes.stageOneProcessing}`]: {
        color: "white",
        backgroundColor: "#87C4FF",
        fontWeight: "bold",
      },
    
      [`& .${classes.stageOneProcessed}`]: {
        color: "white",
        backgroundColor: "#39A7FF",
        fontWeight: "bold",
      },
    
      [`& .${classes.stageTwoProcessed}`]: {
        color: "white",
        backgroundColor: "#427D9D",
        fontWeight: "bold",
      },
    
      [`& .${classes.stageTwoProcessing}`]: {
        color: "white",
        backgroundColor: "#9BBEC8",
        fontWeight: "bold",
      },
}));

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) { // eslint-disable-line
    return <NavLink to={props.to} {...props}  />; // eslint-disable-line
});

const DashboardView = () => {

    // const [loading, setLoading] = React.useState(false);

    const [latestOrders, setLatestOrders] = useState([])
    const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)




    const getMuiTheme = GetMuiTheme



    const getLatestServiceOrder = () => {
        serviceOrderService.getLatestServiceOrder().then(res => {
            if (res) {
                if (res.status === 200) {
                    setLatestOrders(res.data)
                } else {
                    if (res.status === 401 && res.data.name === "TokenExpiredError") {
                        setOpenSessionExpiredModal(true)
                    }
                }
            }

        })

    }

    useEffect(() => {
        // getServiceOrder();
        getLatestServiceOrder();
    }, [])

    const sellColumnData = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display: false
            }
        },
        {
            name: "service_id",
            label: "Order Id",
            options: {
                hint: 'Unique Order Number',
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <Typography
                            variant='body2'
                            component={LinkBtn}
                            // noWrap={false}
                            to={`/app/investor-client-service-order-details/${tableMeta.rowData[0]}`}
                            color="primary"
                        >
                            {value}
                        </Typography>
                    )
                }
            }
        },
        {
            name: "create_datetime",
            label: "Date of Transaction",
            options: {
                hint: 'Date of Transaction Initiation',
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    let valueMoment = moment(value).format("DD-MM-YYYY LTS")
                    return (
                        <div className={classes.tableEllips} >
                            <Typography
                                variant='body2'
                                noWrap
                            >
                                {valueMoment}
                            </Typography>
                        </div>
                    )
                }
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                hint: 'Transaction Service Progress',
                filter: true,
                sort: false,
                customBodyRender: (value) => {
                    return (
                        <Typography>
                            <Chip size="small" label={value} className={clsx({
                                [classes.active]: value === "ACTIVE",
                                [classes.inactive]: value === "INACTIVE",
                                [classes.processing]: value === "PROCESSING",
                                [classes.completed]: value === "COMPLETED",
                                [classes.closed]: value === "CLOSED",
                                [classes.stageOneProcessing]: value === "STAGE_ONE_PROCESSING",
                                [classes.stageOneProcessed]: value === "STAGE_ONE_PROCESSED",
                                [classes.stageTwoProcessing]: value === "STAGE_TWO_PROCESSING",
                                [classes.stageTwoProcessed]: value === "STAGE_TWO_PROCESSED",
                            })} />
                        </Typography>
                    )
                }
            }
        },

    ];

    return (
        <Root>
            <Card>
                <Typography color="primary" className={classes.boxHeader} variant="h6">
                    RECENT TRANSACTIONS
            </Typography>
                <div className={classes.container}>
                    <ThemeProvider theme={getMuiTheme}>
                        <MUIDataTable
                            data={latestOrders}
                            headerSort={false}
                            columns={sellColumnData}
                            options={{
                                search: false,
                                download: true,
                                print: false,
                                filter: false,
                                viewColumns: false,
                                pagination: false,
                                rowsPerPage: 10,
                                rowsPerPageOptions: [5, 10, 25],

                                selectableRows: "none",
                                sortOrder: {
                                    name: 'create_datetime',
                                    direction: 'desc'
                                },
                                textLabels: {
                                    body: {
                                        noMatch:  'Sorry, there is no matching data to display'
                                    },
                                },
                            }}
                        />
                    </ThemeProvider>
                </div>
                {latestOrders.length > 0 ?
                    <Button variant="contained"
                        className={classes.viewAll}
                        component={LinkBtn}
                        to="/app/investor-client-service-order-lists"
                    >
                        View ALL
                </Button>
                    : <></>}

            </Card>
            <SessionExpiredModal
                open={openSessionExpiredModal}
            />
        </Root>
    );
}

export default DashboardView