import React from "react";
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import {
    Container,
    TextField,
    Grid,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";

import ErrorFocus from "src/components/ErrorFocus/ErrorFocus";
import loadingSpinner from 'src/images/loading-spinner.gif'

const PREFIX = 'CreateLotForm';

const classes = {
    zeroPadding: `${PREFIX}-zeroPadding`,
    cardBorderRadius: `${PREFIX}-cardBorderRadius`,
    cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
    fileInput: `${PREFIX}-fileInput`,
    imageSizes: `${PREFIX}-imageSizes`,
    container: `${PREFIX}-container`,
    label: `${PREFIX}-label`,
    marginInputLabel: `${PREFIX}-marginInputLabel`,
    popDownBtn: `${PREFIX}-popDownBtn`,
    resendBtnMargin: `${PREFIX}-resendBtnMargin`,
    inputMargin: `${PREFIX}-inputMargin`,
    paddingInput: `${PREFIX}-paddingInput`,
    buttonProgress: `${PREFIX}-buttonProgress`
};

const StyledContainer = styled(Container)((
    {
        theme
    }
) => ({
    [`& .${classes.zeroPadding}`]: {
        padding: 0,
    },

    [`& .${classes.cardBorderRadius}`]: {
        borderRadius: "0 10px 10px 10px",
        width: "100%",
    },

    [`& .${classes.cardHeaderMargin}`]: {
        margin: 0,
        backgroundColor: theme.palette.primary.main,
        padding: 12,
        color: "#fff",
        border: "1px solid #324c90",
        width: "max-content",
        // justifyContent:"center",
        borderRadius: "0 0  10px 0",
    },

    [`& .${classes.fileInput}`]: {
        display: "none",
    },

    [`& .${classes.imageSizes}`]: {
        width: "40%",
        margin: "auto",
        display: "block",
    },

    [`& .${classes.container}`]: {
        justifyContent: "center",
        // justifyItems: "center",

        marginTop: "10%",
    },

    [`& .${classes.label}`]: {
        marginBottom: theme.spacing(1),
        color: theme.palette.primary.main,
    },

    [`& .${classes.marginInputLabel}`]: {
        marginBottom: theme.spacing(3),
    },

    [`& .${classes.popDownBtn}`]: {
        borderRadius: "0 0 12px 12px",
        padding: "8px",
        float: "right",
        marginTop: theme.spacing(3),
        marginBottom: "10%",
    },

    [`& .${classes.resendBtnMargin}`]: {
        marginTop: theme.spacing(3),
    },

    [`& .${classes.inputMargin}`]: {
        marginBottom: theme.spacing(2),
    },

    [`& .${classes.paddingInput}`]: {
        padding: "0px 0px",
    },

    [`& .${classes.buttonProgress}`]: {
        marginRight: '4px'
      }
}));


function CreateLotForm(props) {

    const [loading , setLoading] = React.useState(false)
    return (
        <StyledContainer>
            <Formik
                initialValues={{
                    lot: "",
                    security_id:props.security_id
                }}
                validationSchema={Yup.object().shape({

                    lot: Yup.string().required("Required")

                })}
                onSubmit={(values) => {
                    setLoading(true)
                    values.lot = Number(values.lot)
                    props.formSubmit(values)
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                  
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Container className={classes.container}>
                            <Grid container>

                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name="lot"
                                        className={classes.inputMargin}
                                        id="lot"
                                        value={values.lot}
                                        label="Quantity * "
                                        fullWidth
                                        variant="outlined"
                                        onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                                        error={Boolean(touched.lot && errors.lot)}
                                        helperText={touched.lot && errors.lot}
                                        autoComplete="off"
                                    />
                                </Grid>
                                <ErrorFocus />
                            </Grid>


                            <Button
                                color="primary"
                                disabled={isSubmitting}
                                className={classes.popDownBtn}
                                size="medium"
                                type="submit"
                                variant="contained"
                            >
                               {loading === false ? "Add" : <><img src={loadingSpinner} alt="LOgo" className={classes.buttonProgress}/> Add </>}
              </Button>
                        </Container>
                    </form>
                )}
            </Formik>
        </StyledContainer>
    );
}

export default CreateLotForm;
