import React, {useState, useEffect} from "react";
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import ErrorFocus from "../../components/ErrorFocus/ErrorFocus";
import { InputAdornment, Container, TextField, Grid } from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import Autocomplete from "@mui/material/Autocomplete";
import { getLookUpDataService } from "src/_services/lookUp";
import { ChannelGroupService } from "src/_services/groupSelector";
import loadingSpinner from 'src/images/loading-spinner.gif'
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal"

const PREFIX = 'GroupSelectorCreateForm';

const classes = {
  zeroPadding: `${PREFIX}-zeroPadding`,
  cardBorderRadius: `${PREFIX}-cardBorderRadius`,
  cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
  fileInput: `${PREFIX}-fileInput`,
  imageSizes: `${PREFIX}-imageSizes`,
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  marginInputLabel: `${PREFIX}-marginInputLabel`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  resendBtnMargin: `${PREFIX}-resendBtnMargin`,
  inputMargin: `${PREFIX}-inputMargin`,
  paddingInput: `${PREFIX}-paddingInput`,
  buttonProgress: `${PREFIX}-buttonProgress`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.zeroPadding}`]: {
    padding: 0,
  },

  [`& .${classes.cardBorderRadius}`]: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },

  [`& .${classes.cardHeaderMargin}`]: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    // justifyContent:"center",
    borderRadius: "0 0  10px 0",
  },

  [`& .${classes.fileInput}`]: {
    display: "none",
  },

  [`& .${classes.imageSizes}`]: {
    width: "40%",
    margin: "auto",
    display: "block",
  },

  [`& .${classes.container}`]: {
    justifyContent: "center",
    justifyItems: "center",

    marginTop: "10%",
  },

  [`& .${classes.label}`]: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },

  [`& .${classes.marginInputLabel}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.resendBtnMargin}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.inputMargin}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.paddingInput}`]: {
    padding: "0px 0px",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: '4px'
  }
}));


function GroupSelectorCreateForm(props) {

  const [lookUpforCP, setLookUpforCP] = useState([])
  const [loading , setLoading] = React.useState(false)
  const [formInitValues ,setFormInitValues] = useState({
    groupName: "",
    percentage: "",
    group_members: []
  })

  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)

  formInitValues.isDropdown = props.isDropdown
  const getCPlookUP = () =>{
    getLookUpDataService.getLookUpChannelPartner()
    .then(res => {
      if (res) {
        if (res.status === 200) {  
          setLookUpforCP(res.data.map( data => {     
          return { id: data.user_unique_code, label:data.member_name }
        }))
        }else{
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenSessionExpiredModal(true)
          }
        }
      }
    })
  }

  useEffect( () =>{
    getCPlookUP();

    if(props.isDropdown === "NO"){
      ChannelGroupService.getGroupSelectoById(props.rowID).then(res => {
        if(res){
          if(res.status === 200){
            const formEditValues = {
              groupName: res.data[0].group_name,
              percentage: res.data[0].percentage,
            }
            setFormInitValues(formEditValues)
          }else{
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true)
            }
          }
        }
      })
    }

  }, [])

  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialValues={formInitValues}
        validationSchema={Yup.object().shape({
          groupName: Yup.string().required("Required"), 
          percentage: Yup.number()
          .positive().nullable()
          .test('test less than', `max 5`, value => value <= 5)
          .required("Required"),
          group_members: Yup.string().when("isDropdown", {
            is: "YES",
            then: Yup.string()
              .required(" required"),
            otherwise: Yup.string(),
          }),
        })}
        onSubmit={(values) => {
          console.log(values)
          delete values.isDropdown
          setLoading(true)
          props.formSubmit(values)
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    className={classes.inputMargin}
                    variant="outlined"
                    label="Group Name *"
                    id="groupName"
                    type="text"
                    name="groupName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth
                    value={values.groupName}
                    error={Boolean(touched.groupName && errors.groupName)}
                    helperText={touched.groupName && errors.groupName}
                    aria-describedby="outlined-weight-helper-text"
                    autoComplete="off"
                  />
                  <ErrorFocus />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    className={classes.inputMargin}
                    variant="outlined"
                    label="Percentage*"
                    id="percentage"
                    name="percentage"
                    type="number"
                    step=".01"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth
                    value={values.percentage}
                    error={Boolean(touched.percentage && errors.percentage)}
                    helperText={touched.percentage && errors.percentage}
                    aria-describedby="outlined-weight-helper-text"
                    autoComplete="off"
                    color="primary"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start" variant="h4">
                          %
                        </InputAdornment>
                      ),
                    }}
                  />
                  <ErrorFocus />
                </Grid>
                {
                  props.isDropdown === "YES" && 
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Autocomplete
                    multiple
                    id="ChannelPartners"
                    options={lookUpforCP}
                    getOptionLabel={(option) => option.label}
                    value={values.group_members}
                    onChange={(e, value) => {
                   
                      setFieldValue("group_members", value);
                    }}
                    // classes={{ option: classes.option }}
              
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth={true}
                        label="Channel Partners *"
                        variant="outlined"
                        name="group_members"
                        id="group_members"
                        error={Boolean(
                          touched.group_members &&
                            errors.group_members
                        )}
                        helperText={
                          touched.group_members &&
                          errors.group_members
                        }
                   
                      />
                    )}
                  />
                  <ErrorFocus />
                </Grid>
                }

              
              </Grid>
              <Button
                color="primary"
                disabled={isSubmitting}
                className={classes.popDownBtn}
                size="medium"
                type="submit"
                variant="contained"
              >
                {loading === false ? "Create" : <><img alt="" src={loadingSpinner} className={classes.buttonProgress}/> Create </>} 
              </Button>
            </Container>
          </form>
        )}
      </Formik>
      <SessionExpiredModal
                open={openSessionExpiredModal}
            />
    </StyledContainer>
  );
}

export default GroupSelectorCreateForm;
