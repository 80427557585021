import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import ErrorFocus from "../../components/ErrorFocus/ErrorFocus";
import {
  Container,
  TextField,
  Grid,
  Checkbox,
  InputLabel,
  Select,
  Input,
  MenuItem,FormLabel,FormControl,
  ListItemText,Chip,FormControlLabel,Radio,RadioGroup
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import moment from "moment";
import loadingSpinner from "src/images/loading-spinner.gif";
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal";
import { getLookUpDataService } from "src/_services/lookUp";
import Autocomplete from "@mui/material/Autocomplete";
import { serviceOrderService } from "src/_services/serviceOrder";
import SnackBar from "src/components/SnackBar/SnackBar";
const PREFIX = 'OrderExportForm';

const classes = {
  zeroPadding: `${PREFIX}-zeroPadding`,
  cardBorderRadius: `${PREFIX}-cardBorderRadius`,
  cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
  fileInput: `${PREFIX}-fileInput`,
  imageSizes: `${PREFIX}-imageSizes`,
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  marginInputLabel: `${PREFIX}-marginInputLabel`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  resendBtnMargin: `${PREFIX}-resendBtnMargin`,
  inputMargin: `${PREFIX}-inputMargin`,
  paddingInput: `${PREFIX}-paddingInput`,
  buttonProgress: `${PREFIX}-buttonProgress`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.zeroPadding}`]: {
    padding: 0,
  },

  [`& .${classes.cardBorderRadius}`]: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },

  [`& .${classes.cardHeaderMargin}`]: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    borderRadius: "0 0  10px 0",
  },

  [`& .${classes.fileInput}`]: {
    display: "none",
  },

  [`& .${classes.imageSizes}`]: {
    width: "40%",
    margin: "auto",
    display: "block",
  },

  [`& .${classes.container}`]: {
    justifyContent: "center",
    // justifyItems: "center",
    marginTop: "10%",
  },

  [`& .${classes.label}`]: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },

  [`& .${classes.marginInputLabel}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    textTransform: "none",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.resendBtnMargin}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.inputMargin}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.paddingInput}`]: {
    padding: "0px 0px",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: "4px",
  }
}));

const OrderExportForm = (props) => {

  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(
    false
  );
  const [loading, setLoading] = React.useState(false);
  const [client, setClient] = React.useState([]);
  const [channelPartner, setChannelPartner] = React.useState([]);
  const [orders, setOrders] = React.useState(false);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [orderList, setOrderList] = React.useState([]);
  const [severity, setSeverity] = React.useState("");
  // const [tags,setTags] = React.useState([]);
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [open, setOpen] = React.useState(false);
  const initValues = {
    fromDate: moment(new Date()).format("YYYY-MM-DD"),
    toDate: moment(new Date()).format("YYYY-MM-DD"),
    client: "",
    orders: [],
    bank: [],
    invoiceType: "",
    tagName:'',
    type:'date'
    // ownedBy: { id: props.authReducer.auth.user.user_unique_code, label: props.authReducer.auth.user.display_name },
  };

  // const ITEM_HEIGHT = 48;
  // const ITEM_PADDING_TOP = 8;

  // const MenuProps = {
  //   PaperProps: {
  //     style: {
  //       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
  //       width: 250,
  //     },
  //   },
  // };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getLookUpDataService
      .getLookUpUser('CHANNEL_PARTNER')
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            let item = res.data.map((items) => {
              return {
                id: items.id,
                code: items.user_code,
                lable: `${
                  items.user_name
                } ( ${items.user_code.toUpperCase()} )`,
              };
            });

            setChannelPartner(item);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
            }
          }
        }
      })
      .catch((error) => {
        console.log("error");
      });
    getLookUpDataService
      .getLookUpUser("INVESTER_CLIENT")
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            let item = res.data.map((items) => {
              return {
                id: items.id,
                code: items.user_code,
                lable: `${
                  items.user_name
                } ( ${items.user_code.toUpperCase()} )`,
              };
            });
            setClient(item);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const type = [
    {
      value: 1,
      lable: "Buy Invoice",
    },
    {
      value: 2,
      lable: "Sell Invoice",
    },
    {
      value: 3,
      lable: "Sell Commission Invoice",
    },
    {
      value: 4,
      lable: "Buy Commission Invoice",
    },
  ];

  const selectOption = (
    value,
    multiple,
    options,
    setFieldValue,
    name,
    label,
    touched,
    errors
  ) => {
    return (
      <Grid item xs={12}>
        <Autocomplete
          multiple={multiple}
          id="client"
          // name="investorOrVendor"
          value={value}
          options={options}
          onChange={async (e, value) => {
            if (value !== null) {
              setFieldValue(name, value);
              if(name === 'invoiceType'){
              setFieldValue('client', "");
              }
            } else {
              setFieldValue(name, "");
            }
          }}
          disabled={orders}
          getOptionLabel={(option) => option.lable || ""}
          renderInput={(params) => (
            <TextField
              error={Boolean(touched && errors)}
              helperText={touched && errors}
              {...params}
              label={label}
            />
          )}
        />
        <ErrorFocus />
      </Grid>
    );
  };

  const handleAddTag = (e, setFieldValue,values) => {
    if (e.key === "Enter" && e.target.value.trim() !== "") {
      e.preventDefault();
      const value =e.target.value
       
      // tags.push(e.target.value);
      const filterText = value.replace('UNLSORDER','')
      // setTags([...tags], e.target.value);
      setFieldValue("orders",[...values.orders,filterText] );
      e.target.value = "";
    }
    setFieldValue("tagName", "");

  };

  const handleDelete = (i,setFieldValue,order) => {
  const filteredData = order.filter((name, index) => index !== i)
    setFieldValue('orders',filteredData);
  };

  const isAllSelected =
    orderList.length > 0 && selectedItems.length === orderList.length;
  const optionValue = orderList.map((item) => item.id);

  const getOrders = (values) => {
    const data = {
      investors: [values.client.id],
      orderType: values.invoiceType.value,
      from_date: values.fromDate,
      to_date: values.toDate,
    };
    serviceOrderService.getInvoiceOrders(data).then((res) => {
      if (res.data.success) {
        if(res.data.data.length> 0){
          setOrders(true);
          setOrderList(res.data.data);
        }else{
          setSnackbarTitle("No orders found");
          setSeverity("success");
          setOpen(true);
        }
      } else {
        setSnackbarTitle("Something went wrong");
        setSeverity("error");
        setOpen(true);
      }
    });
  };

  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialValues={initValues}
        validationSchema={Yup.object().shape({
          fromDate: Yup.string().required("Required"),
          toDate: Yup.string().required("Required"),
          client: Yup.string().required("Required"),
          invoiceType: Yup.string().required("Required"),
        })}
        onSubmit={(values) => {
          setLoading(true);
          props.formSubmit(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <div></div>
            <Container className={classes.container}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {selectOption(
                    values.invoiceType,
                    false,
                    type,
                    setFieldValue,
                    "invoiceType",
                    "Invoice Type",
                    touched.invoiceType,
                    errors.invoiceType
                  )}
                </Grid>
                <Grid item xs={12}>
                  {selectOption(
                    values.client,
                    false,
                    (values.invoiceType?.value ===1 || values.invoiceType?.value ===2) ? client : channelPartner,
                    setFieldValue,
                    "client",
                    values.invoiceType?.value ===1 ? "Vendor"  :values.invoiceType?.value ===2 ? "Investor" :"Channel Partner",
                    touched.client,
                    errors.client
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                      <FormControl
                        component="fieldset"
                        error={Boolean(touched.status && errors.status)}
                      >
                        <FormLabel component="legend">Filter by</FormLabel>
                        <RadioGroup  
                          row
                          aria-label="payment_mode"
                          name="type"
                          value={values.type}
                          id="payment_mode"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value={"date"}
                            disabled={orders}
                            control={<Radio color="primary" />}
                            label="Date"
                          />
                          <FormControlLabel
                            value={"order"}
                           disabled={orders}
                            control={<Radio color="primary" />}
                            label="Orders"
                          />
                          </RadioGroup>
                      </FormControl>
                      <ErrorFocus />
                    </Grid>
                {values.type === 'order' ?<Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        helperText=""
                        label={`Search by order id`}
                        onKeyPress={(e) => handleAddTag(e, setFieldValue,values)}
                        name="tagName"
                        onChange={handleChange}
                      />
                      <div className={classes.wrap}>
                        {values.orders &&
                          values.orders.map((item, index) => (
                            <Chip
                            size='small'
                              disabled={orders}
                              style={{ margin: "4px" }}
                              key={index}
                              tabIndex={-1}
                              label={item}
                              onDelete={() => handleDelete(index,setFieldValue,values.orders)}
                            />
                          ))}
                      </div>
                    </Grid>:
                    <>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="fromDate"
                    label="From Date"
                    type="date"
                    name="fromDate"
                    disabled={orders}
                    error={Boolean(touched.fromDate && errors.fromDate)}
                    helperText={touched.fromDate && errors.fromDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      inputProps: {
                        max: `${moment(new Date()).format("YYYY-MM-DD")}`,
                      },
                    }}
                    value={values.fromDate}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <ErrorFocus />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="toDate"
                    label="End Date"
                    type="date"
                    name="toDate"
                    disabled={orders}
                    value={values.toDate}
                    InputProps={{
                      inputProps: {
                        min: values.fromDate,
                        max: `${moment(new Date()).format("YYYY-MM-DD")}`,
                      },
                    }}
                    error={Boolean(touched.toDate && errors.toDate)}
                    helperText={touched.toDate && errors.toDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    // InputProps={{ inputProps: { min: `${moment(values.fromDate).format("YYYY-MM-DD")}` } }}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <ErrorFocus />
                </Grid>
                </>
}
                {orders ? (
                  <>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <InputLabel id="demo-mutiple-checkbox-label">
                        Orders
                      </InputLabel>
                      <Select
                        labelId="demo-mutiple-checkbox-label"
                        id="demo-mutiple-checkbox"
                        multiple
                        name="orders"
                        fullWidth
                        value={values.orders}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value.includes("all")) {
                            const orderValue =
                              (values.orders && values.orders.length) ===
                              (orderList && orderList.length)
                                ? []
                                : optionValue;
                            setFieldValue("orders", orderValue);
                            setSelectedItems(orderValue);
                          } else {
                            setFieldValue("orders", value);
                            setSelectedItems(value);
                          }
                        }}
                        input={<Input />}
                        renderValue={(selected) => selected.join(", ")}
                        // MenuProps={MenuProps}
                      >
                        <MenuItem key={"all"} value={"all"}>
                          <Checkbox checked={isAllSelected} />
                          <ListItemText primary={"Select All"} />
                        </MenuItem>
                        {orderList.map((order) => (
                          <MenuItem key={order.id} value={order.id}>
                            <Checkbox
                              checked={values.orders.indexOf(order.id) > -1}
                            />
                            <ListItemText primary={order.service_id} />
                          </MenuItem>
                        ))}
                      </Select>
                      {/* <Grid item xs={12} md={12}>
                      {selectOption(
                        values.orders,
                        true,
                        client,
                        setFieldValue,
                        "orders",
                        "Select orders list",
                        touched.orders,
                        errors.orders
                      )}
                    </Grid> */}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        className={classes.popDownBtn}
                        size="medium"
                        type="submit"
                        variant="contained"
                      >
                        {loading === false ? (
                          <> {"Preview"}</>
                        ) : (
                          <>
                            <img
                              ALT="loader"
                              src={loadingSpinner}
                              className={classes.buttonProgress}
                            />{" "}
                            Preview{" "}
                          </>
                        )}
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button
                      color="primary"
                      disabled={
                        values.client === "" || values.invoiceType === "" ||(values.type ==='order' && values.orders.length ===0)
                      }
                      className={classes.popDownBtn}
                      size="medium"
                      onClick={() => {
                        if(values.type ==='order'){
                          setLoading(true);
                          props.formSubmit(values);
                        }else{
                          getOrders(values)
                        }
                      }}
                      variant="contained"
                    >
                      {loading === false ? (
                        <> {values.type ==='order' ? "Preview" : "Get order list"}</>
                      ) : (
                        <>
                          <img
                            alt="loader"
                            src={loadingSpinner}
                            className={classes.buttonProgress}
                          />{" "}
                          {values.type ==='order' ? "Preview" : "Get order list"}
                        </>
                      )}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Container>
          </form>
        )}
      </Formik>
      <SessionExpiredModal open={openSessionExpiredModal} />
      <SnackBar
            open={open}
            severity={severity}
            close={handleClose}
            snackbarTitle={snackbarTitle}
          />
    </StyledContainer>
  );
};

export default OrderExportForm;
