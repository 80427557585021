import React from "react";
import { Grid, Card, Typography } from "@mui/material";
import moment from "moment";
import CopyTextTable from "src/components/Copy/CopyTextTable";
export default function EscrowCard({escrowResponse}) {



  function ShowEscrowTransactionDetails(EscrowTransactionsArray) {
    let EscrowReturnArray = [];
    for (let index = 0; index < EscrowTransactionsArray.length; index++) {
      EscrowReturnArray.push(
          <Card
          key={index}
            style={{
              padding: "10px",
            }}
          >
            {KeyValueDisplayer(
              "Escrow Status",
              EscrowTransactionsArray[index].type === "Collect"
                ? "Money sent to Escrow"
                : "Money released from Escrow"
            )}
            {KeyValueDisplayer(
              "Transaction date",
              moment(EscrowTransactionsArray[index].tran_date).format(
                "DD-MM-YYYY LTS"
              )
            )}
            {KeyValueDisplayer(
              "Payee Name",
              EscrowTransactionsArray.length === 1
                ? EscrowTransactionsArray[index].remitter_name
                : index === 0
                ? EscrowTransactionsArray[index].payeename
                : EscrowTransactionsArray[index].remitter_name
            )}

            {(EscrowTransactionsArray[index].remitter_vpa === "NA" ||
              EscrowTransactionsArray[index].payeeva === "NA") &&
              KeyValueDisplayer(
                "Account Number",
                EscrowTransactionsArray.length === 1
                  ? EscrowTransactionsArray[index].remitter_acc_no
                  : index === 0
                  ? EscrowTransactionsArray[index].payee_account_no
                  : EscrowTransactionsArray[index].remitter_acc_no
              )}
            {(EscrowTransactionsArray[index].remitter_vpa === "NA" ||
              EscrowTransactionsArray[index].payeeva === "NA") &&
              KeyValueDisplayer(
                "IFSC Code",
                EscrowTransactionsArray.length === 1
                  ? EscrowTransactionsArray[index].remitter_ifsc_code
                  : index === 0
                  ? EscrowTransactionsArray[index].payee_account_ifsc
                  : EscrowTransactionsArray[index].remitter_ifsc_code
              )}
            {(EscrowTransactionsArray.length === 1
              ? index === 0
              : index === 1) &&
              EscrowTransactionsArray[index].remitter_vpa !== "NA" &&
              KeyValueDisplayer(
                "UPI Id",
                EscrowTransactionsArray[index].remitter_vpa
              )}
            {(EscrowTransactionsArray.length === 1
              ? index === 0
              : index === 1) &&
              KeyValueDisplayer(
                "Remarks",
                EscrowTransactionsArray[index].remarks
            )}
            {KeyValueDisplayer("UTR", EscrowTransactionsArray[index].utr)}
          </Card>
      );
    }
    return EscrowReturnArray;
  }
  function KeyValueDisplayer(Key, value) {
    return (
      <Grid  style={{display:'flex', alignItems:'center'}}>
        <Typography variant="body2">
          {Key} :
        </Typography>
        <span style={{marginLeft:'4px'}}><CopyTextTable value={value} escrow={true}/></span>
      </Grid>
    );
  }
  return (
    <div>
      {escrowResponse.Serviceresponse && (
        <Card
          style={{
            padding: "10px",
            fontStyle: "bold",
          }}
        >
          {KeyValueDisplayer(
            "Escrow ID",
            escrowResponse.Serviceresponse[0].escrow_id
          )}
        </Card>
      )}
      {escrowResponse.transactionResponse?.length >= 1 ? (
        <>{ShowEscrowTransactionDetails(escrowResponse.transactionResponse)}</>
      ) : null}
    </div>
  );
}
