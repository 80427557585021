import axios from 'axios'
import { helpers } from 'src/_helpers'


export const ChannelGroupService = {
    getCPgroupsData,
    postCPgroupsData,
    deleteCPgroupsByID,
    updateCPgroupData,
    getGroupSelectoById,
    deleteChannelPartners,
    updateExternalRMgroup
}

function getCPgroupsData(){
    helpers.setHeader();
    const requestOptions ={
        headers:{
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/user_group`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}


function postCPgroupsData(groupData) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/user_group`,
        JSON.stringify(groupData), requestOptions)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}

function deleteCPgroupsByID(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.delete(`/user_group/${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function updateCPgroupData(data){
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.put(`/user_group`,JSON.stringify(data),requestOptions)
        .then(res => res)
        .catch(error => error.response)
}

function getGroupSelectoById(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/user_group/${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function deleteChannelPartners(Grpid,uniqId) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.delete(`/user_group/channel_partner?group_id=${Grpid}&user_unique_code=${uniqId}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function updateExternalRMgroup(data){
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.put(`/user_group_details`,JSON.stringify(data),requestOptions)
        .then(res => res)
        .catch(error => error.response)
}

