import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import MUIDataTable from "mui-datatables";
import {  Typography, Button ,Tooltip} from "@mui/material";
import {  ThemeProvider } from "@mui/material/styles";
import Page from "src/components/Page";
import { NavLink, useLocation, useParams, useNavigate } from "react-router-dom";
import { userService } from "src/_services/users";
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal";
import NormalCard from "src/components/Cards/NormalCard";
import CopytoClipboard from "src/components/ButtonComponents/CopytoClipboard";
import GetMuiTheme from "src/components/theme";
const PREFIX = 'PartnerUsers';

const classes = {
  tableEllips: `${PREFIX}-tableEllips`,
  tooltip: `${PREFIX}-tooltip`,
  root: `${PREFIX}-root`,
  btnright: `${PREFIX}-btnright`,
  container: `${PREFIX}-container`,
  button: `${PREFIX}-button`,
  editdeletbtn: `${PREFIX}-editdeletbtn`,
  btnsdltedt: `${PREFIX}-btnsdltedt`,
  chipMargin: `${PREFIX}-chipMargin`,
  iconbtn: `${PREFIX}-iconbtn`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.tableEllips}`]: {
    display: "flex",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "10rem",
    [theme.breakpoints.down("sm")]: {
      width: "10rem",
    },
    [theme.breakpoints.up("sm")]: {
      width: "10rem",
    },
  },

  [`& .${classes.tooltip}`]: {
    cursor:'copy'
  },

  [`& .${classes.root}`]: {
    margin: theme.spacing(2),
    "& .MuiTableHead-root": {
      background: "blue",
    },
  },

  [`& .${classes.btnright}`]: {
    textAlign: "end",
  },

  [`& .${classes.container}`]: {
    minHeight: "100%",
    padding: theme.spacing(3),
  },

  [`& .${classes.button}`]: {
    marginBottom: "1%",
    borderRadius: "14px 14px 0 0",
    boxShadow: "none",
    textTransform: "capitalize",
  },

  [`& .${classes.editdeletbtn}`]: {
    color: "#3363cb",
    display: "flex",
  },

  [`& .${classes.btnsdltedt}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.chipMargin}`]: {
    marginRight: "5px",
    marginBottom: "3px",
    fontSize: "12px !important",
    padding: 0,
    height: "20px",
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
  },

  [`& .${classes.iconbtn}`]: {
    padding: theme.spacing(0.8),
  }
}));

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) {
  // eslint-disable-line
  return <NavLink to={props.to} {...props} />; // eslint-disable-line
});

const PartnerUsers = () => {

  let params = useParams();
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowindex, setRowindex] = useState();

  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(
    false
  );
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);
  const onHover = (value) => {
    setHover(true);
    navigator.clipboard.writeText(value);
  };

  const onLeave = () => {
    setHover(false);
  };

  const copyOption = (value) => {
    return (
      <div
        className={classes.tableEllips}
        onClick={() => {
          onHover(value);
        }}
        onMouseLeave={onLeave}
      >
        <Tooltip
          className={classes.tooltip}
          title={hover ? "Copied" : "Click to Copy"}
        >
          <Typography variant="body2" noWrap>
            {value}
          </Typography>
        </Tooltip>
      </div>
    );
  };

  let location = useLocation();

  const getMuiTheme = GetMuiTheme
  useEffect(() => {
    getUsers();
  }, []);

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        hide: true,
        display: false,
      },
    },
    {
      name: "user_unique_code",
      label: "Empanelment ID",
      options: {
        hint: "Unlistedkart Unique ID for Users",
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) =>
          copyOption(value.toUpperCase(), tableMeta),
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        hint: "User Name",
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const name =
            value && value.length > 15 ? value.substr(0, 15) + "..." : value;
          return (
            <div className={classes.tableEllips}
            onMouseEnter={() => {
              setHover(true);
              setRowindex(tableMeta.rowData[0])
            }}
            style={{padding:'10px 0'}}
            onMouseLeave={()=>{setRowindex()}}>
              <Typography
                variant="body2"
                component={LinkBtn}
                noWrap
                to={`/app/partneruser-details/${tableMeta.rowData[0]}`}
                color="primary"
                style={{ textTransform: "capitalize", maxWidth: 300 }}
              >
                {name}
              </Typography>
              {rowindex ===tableMeta.rowData[0] &&(
                            <Tooltip style={{cursor:'copy'}} title={hover ? "Copied" :"Click to Copy"}>
                                <CopytoClipboard userBasicInfo={value} state={hover}/> 
                            </Tooltip>
                            )}
            </div>
          );
        },
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        hint: "User Email ID",
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => copyOption(value, tableMeta),
      },
    },
    {
      name: "contact_no",
      label: "Phone",
      options: {
        hint: "User Contact Number",
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => copyOption(value, tableMeta),
      },
    },
    {
      name: "pan",
      label: "Pan",
      options: {
        hint: "User Pan Number",
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) =>
          copyOption(value.toUpperCase(), tableMeta),
      },
    },
  ];

  const getUsers = () => {
    setLoading(true);
    userService
      .getPartnerUsers(params.id)
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            setUserData(res.data.message);
            setLoading(false);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setLoading(false);
              setOpenSessionExpiredModal(true);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <Root>
    <Page title="Unlistedkart | Partners List">
      <NormalCard
        title={`${
          location?.state?.name ? location?.state?.name : "Partner"
        } Users List`}
        btn={
          <Button
            color="primary"
            className={classes.popDownBtn}
            size="medium"
            onClick={() =>
              navigate(`/app/partnerlogs/${params.id}`, {
                replace: true,
                state: location?.state,
              })
            }
            variant="contained"
          >
            Partner logs
          </Button>
        }
      >
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            data={userData}
            columns={columns}
            options={{
              download: true,
              print: false,
              rowsPerPage: 10,
              rowsPerPageOptions: [10, 25, 40],
              // responsive: 'scrollMaxHeight',
              selectableRows: "none",
              sortOrder: {
                name: "last_updated",
                direction: "desc",
              },
              textLabels: {
                body: {
                  noMatch: loading
                    ? "Loading..."
                    : "Sorry, there are no matching data to display",
                },
              },
            }}
          />
        </ThemeProvider>

        <SessionExpiredModal open={openSessionExpiredModal} />
      </NormalCard>
    </Page>
    </Root>
  );
};

export default PartnerUsers;
