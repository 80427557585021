function compareJSON(obj1, obj2) {
    const diffs = [];

    function compare(obj1, obj2, path = '') {
        // If either obj1 or obj2 is not an object, compare directly
        if (!(obj1 instanceof Object) || !(obj2 instanceof Object)) {
            // eslint-disable-next-line eqeqeq
            if (obj1 != obj2) {
                diffs.push({
                    field: path,
                    oldValue: obj1,
                    newValue: obj2
                });
            }
            return;
        }

        // Get the keys of both objects
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        // Iterate over keys and compare values
        for (let key of keys1) {
            // If the corresponding key in obj2 does not exist, or values are not equal, record the difference
            if (!obj2.hasOwnProperty(key)) {
                diffs.push({
                    field: path + '.' + key,
                    oldValue: obj1[key] ?obj1[key] :"",
                    newValue: obj2[key]
                });
            } else {
                compare(obj1[key], obj2[key], path ? path + '.' + key : key);
            }
        }

        // Check for keys in obj2 that are not in obj1
        for (let key of keys2) {
            if (!obj1.hasOwnProperty(key)) {
                diffs.push({
                    field: path + '.' + key,
                    oldValue: obj1[key] ? obj1[key] :"",
                    newValue: obj2[key]
                });
            }
        }
    }

    compare(obj2, obj1);
    return diffs;
}

export default compareJSON