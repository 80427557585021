import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import {  Typography,IconButton,Tooltip } from "@mui/material";

import ServiceCard from "src/components/Cards/ServiceCard";
import MUIDataTable from "mui-datatables";
import Page from "src/components/Page";
import { serviceOrderService } from "src/_services/serviceOrder";
import RestoreIcon from '@mui/icons-material/Restore';
import moment from "moment";
import SnackBar from "src/components/SnackBar/SnackBar";
import AlertModal from "src/components/AlertModal/AlertModal";
import { connect } from "react-redux";
import NormalCard from "src/components/Cards/NormalCard";
import { ThemeProvider } from "@mui/material/styles";
import GetMuiTheme from "src/components/theme";

const PREFIX = 'DeletedOrders';

const classes = {
  card: `${PREFIX}-card`,
  container: `${PREFIX}-container`,
  margin: `${PREFIX}-margin`,
  formgrgroup: `${PREFIX}-formgrgroup`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.card}`]: {
    borderBottom: "none",
    padding: 0,
  },

  [`& .${classes.container}`]: {
    padding: "0px",
  },

  [`& .${classes.margin}`]: {
    '&.MuiIconButton-root':{
      padding:0,
      marginLeft:'1rem'
    }
  },

  [`& .${classes.formgrgroup}`]: {
    display: "flex",
    cursor:'copy',
  }
}));

const DeletedOrders = (props) => {
  const userRole =
  props.authReducer.auth.user &&
  props.authReducer.auth.user.default_role_code;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hover, setHover] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState("")
  const [severity, setSeverity] = React.useState("");
  const [recoverId, setRecoverId] = React.useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const getMuiTheme = GetMuiTheme;

  const onHover = (value) => {
    setHover(true);
    navigator.clipboard.writeText(value)
  };

  const onLeave = () => {
    setHover(false);
  };

    const CopyText = (value) => {
      return (
        <div
        onClick={() => {
          onHover(value);
        }}
        onMouseLeave={onLeave}
        className={classes.formgrgroup}
      >
        <Tooltip title={hover ? "Copied" : "Click to Copy"}>
          <Typography        
            variant="body2"
            noWrap
          >
            {value}
          </Typography>
        </Tooltip>
        </div>
      );
    };

    const recoverOrder =((id)=>{
      serviceOrderService.recoverOrders(recoverId.id).then((res)=>{
        setDeleteConfirm(false)
        if(res.data.success){
          getDeletedOrders()
          setOpen(true)
          setSnackbarTitle("Order recovered successfully")
          setSeverity("success")
        }else{
          setOpen(true)
          setSnackbarTitle("Order recover failed")
          setSeverity("error")
        }
      })
    })


  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: false,
      },
    },
    {
        name: "service_id",
        label: "Order Id",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value) => CopyText(value),
        },
    },
    {
      name: "user_type",
      label: "User Type",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => CopyText(value ? "Online" :'Offline'),
      },
    },
    {
      name: "order_type",
      label: "Order Type",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => CopyText(value ===2 ? 'Sell' : 'Buy'),
      },
    },
    {
      name: "lead_owner",
      label: "Inernal RM",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => CopyText(value),
      },
    },
    {
      name: "investor",
      label: "Investor",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => CopyText(value),
      },
    },
    {
      name: "vendor",
      label: "Vendor",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => CopyText(value),
      },
    },
    {
      name: "deleted_by",
      label: "Deleted By",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => CopyText(value),
      },
    },
    {
      name: "deleted_datetime",
      label: "Deleted On",
      options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta) => {
            let valueMoment =value ? moment(value).format("DD-MM-YYYY LTS") : ''
           return(
            <Typography>{valueMoment}</Typography>
           )}
      }
    },
    {
      name: "user_type",
      label: "Restore",
      
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return(
            userRole ==="ADMIN" ?
            <Tooltip title="Recover" >
           <IconButton 
           onClick={()=>{
            setDeleteConfirm(true)
            setRecoverId({
              id:tableMeta.rowData[0],
              orderId:tableMeta.rowData[1]
            })
           }}
           className={classes.margin}>
               <RestoreIcon fontSize="small"/>
           </IconButton>
         </Tooltip> : null
          )
         
       },
      },
    },
  ];

  const getDeletedOrders=(()=>{
    setLoading(true)
    serviceOrderService.getDeletedOrders().then((res) => {
        setLoading(false)
        if(res.data.success){
            setData(res.data.data)
        }else{
            console.log(res)
        }
    })
  })

  useEffect(()=>{
    getDeletedOrders()
  },[])
  return (
    <Root>
      <Page title="Unlistedkart | Order">
        <div className={classes.container}>
          <ServiceCard className={classes.card}>
            <ThemeProvider theme={getMuiTheme}>
            <NormalCard title="Deleted Orders">
            <MUIDataTable
            
             data={data}
              headerSort={false}
               columns={columns} 
               options={{
                rowsPerPage: 10,
                rowsPerPageOptions: [5, 10, 25],
                // responsive: 'scrollMaxHeight',
                selectableRows: "none",
                // sortOrder: {
                //     name: 'last_updated',
                //     direction: 'desc'
                // },
                download: true,
                viewColumns: false,
                print: false,
                textLabels: {
                    body: {
                        noMatch: loading ?
                            'Loading...' : 'Sorry, there is no matching data to display'
                    },
                },
            }}/>
          </NormalCard>
          </ThemeProvider>
          </ServiceCard>
        </div>
        <SnackBar
                open={open}
                severity={severity}
                close={()=>setOpen(false)}
                snackbarTitle={snackbarTitle}
            />
            <AlertModal
            title={`Are you sure you want to recover ${recoverId?.orderId}?`}
            open={deleteConfirm}
            toggle={() => {
              setDeleteConfirm(false);
              setRecoverId();
            }}
            onConfirm={recoverOrder}
          ></AlertModal>
      </Page>
    </Root>
  );
};
const mapStateToProps = (state) => {
  return {
    force: state, // force state from reducer
    authReducer: state.authReducer,
  };
};

export default connect(mapStateToProps, null)(DeletedOrders);