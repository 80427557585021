import React, { useState,  useEffect } from "react";
import { styled } from '@mui/material/styles';
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import StepButton from "@mui/material/StepButton";
import { useLocation } from "react-router";
import { Formik,  FieldArray } from "formik";
import Autocomplete from "@mui/material/Autocomplete";
import { getLookUpDataService } from "src/_services/lookUp";
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal"
import { serviceOrderService } from "src/_services/serviceOrder";
import moment from "moment";
import SnackBar from "src/components/SnackBar/SnackBar";
import GetMuiTheme from "src/components/theme";


const PREFIX = 'InvoiceFormPreview';

const classes = {
  root: `${PREFIX}-root`,
  backButton: `${PREFIX}-backButton`,
  instructions: `${PREFIX}-instructions`,
  td: `${PREFIX}-td`,
  customTable: `${PREFIX}-customTable`,
  sectionTitle: `${PREFIX}-sectionTitle`,
  noborder: `${PREFIX}-noborder`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    // paddingBottom: theme.spacing(10),
    // paddingTop: theme.spacing(2),
    width: "100%",
    padding: "1rem",
  },

  [`& .${classes.backButton}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.instructions}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    height:'70vh',
    padding:'10px',
    overflow:'scroll',
    scrollbarWidth:'thin'
  },

  [`& .${classes.td}`]: {
    border: "1px solid grey",
    borderCollapse: "collapse",
    padding: "5px",
  },

  [`& .${classes.customTable}`]: {
    borderCollapse: "collapse",
    border: "1px solid grey",
    "& table,th,td": {
      border: "1px solid grey",
      borderCollapse: "collapse",
      padding: "5px",
      whiteSpace: "nowrap",
    },
  },

  [`& .${classes.sectionTitle}`]: {
    textAlign: "center",
    backgroundColor: "#bfbfbf",
  },

  [`& .${classes.noborder}`]: {
    border: "none",
  }
}));

function getSteps() {
  return [
    "Unlistedkart Details",
    "User Details",
    "Order Info",
    "Invoice Total",
    "Preview",
  ];
}


export default function InvoiceFormPreview(props) {

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const location = useLocation();
  const [channelPartner, setChannelPartner] = useState([]);
  const [openSessionExpiredModal,setOpenSessionExpiredModal] = useState(false)
  const [vendorBankAccountList, setVendorBankAccountList] = useState([]);
  const [loading,setLoading] = useState(false)
  const [severity, setSeverity] = React.useState("");
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [open, setOpen] = useState(false);
const [data,setData] = useState()
const userId = JSON.parse(localStorage.getItem('DefaultUnlistedAccount'))
const invoiceType =location.state.invoiceType.value
const InvoiceValue = invoiceType ===1 ? "ULKBUYINV000" : invoiceType === 2 ? 'ULKSELINV000' : invoiceType === 3 ? 'ULKSELCOMINV000' : 'ULKBUYCOMINV000'
  const getOrderDetails =(()=>{
    setLoading(true)
    const data ={
      orderType:location.state.invoiceType.value,
      id:location.state.orders,
      owner_id:userId.map((item)=>item.id),
      channel_partner_id:location.state.client.id
    }
    serviceOrderService.getOrderDetails(data).then((res)=>{
      if(res.data.success){
        setLoading(false)
        let newData = res.data.data
        const updatedOrderInfo = newData.orderInfo.map((order) => ({
          ...order,
          invoiceNumber: `${InvoiceValue}${order.id}`,
          dueDate:order.payment_date ? `${moment(order.payment_date).format("YYYY-MM-DD")}` : "",
          payment_date:order.payment_date ? `${moment(order.payment_date).format("YYYY-MM-DD")}` : "",
          create_datetime:order.create_datetime ? `${moment(order.create_datetime).format("YYYY-MM-DD")}` : "",
          frequency:'',
          approvedBy:'',
          preparedFor:'',
          category:'',
          paymentTerms:'',
          commission_percentage:order.commission_percentage ? order.commission_percentage : 0,
          price:(invoiceType ===1 || invoiceType ===2 ) ? order.price :invoiceType===3 ? (((Number(order.sellprice)-Number(order.commision))*Number(order.quantity)*(order.commission_percentage ? order.commission_percentage/100 :1))).toFixed(2) :((Number(order.commision)-Number(order.sellprice))*Number(order.quantity)*(order.commission_percentage ? order.commission_percentage/100 :1)).toFixed(2)
        }));      
      const subTotal = newData.orderInfo.reduce((sum, item) => {
        return sum + parseFloat(item.price);
      }, 0);
        setData({...newData,orderInfo:updatedOrderInfo,ulkDetails:{...newData.ulkDetails[0],bank:newData.ulkDetails[0].bank.filter((item)=>item.primary===true)[0]},user:newData.ulkDetails, subTotal:subTotal})
        setVendorBankAccountList(newData.ulkDetails ? newData.ulkDetails[0]?.bank : [])
      }else{
        setOpen(true)
            setSeverity('error')
            setSnackbarTitle('Failed to load') 
      }
    })
  })
  const handleNext = (values) => {
    if (activeStep === 4) {
      setLoading(true)
      const data ={...values,orderType:invoiceType ===1 ? "Buy Invoice" : invoiceType === 2 ? 'Sell Invoice' : invoiceType === 3 ? 'Sell Commission Invoice' : 'Buy Commission Invoice',invoiceType:(invoiceType ===1 || invoiceType ===4) ?'Buy Price' : "Sell Price"}
      serviceOrderService.generateInvoicePDF(data).then((res)=>{
        if (res.status === 200) {
          const file = new Blob([res.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = fileURL
          link.download = `Invoice.pdf`;
          link.click();
          setLoading(false)
          setOpen(true)
          setSeverity('success')
          setSnackbarTitle('Download success')
        } else 
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenSessionExpiredModal(true);
            setOpen(true)
            setSeverity('error')
            setSnackbarTitle('Download failed')

          }
        
      })
    }else{
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  useEffect(() => {
    getOrderDetails()
    getChannelPartnerList()
  }, []);

  const getChannelPartnerList =(()=>{
    getLookUpDataService
    .getLookUpUser("CHANNEL_PARTNER")
    .then((res) => {
      if (res) {
        if (res.status === 200) {
          let item = res.data.map((items) => {
              return items.user_name
              
          });

          setChannelPartner(item);
        } else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenSessionExpiredModal(true)
          }

        }
      }
    })
    .catch((error) => {
      console.log("error");
    });
  })


  const getMuiTheme = GetMuiTheme

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  const handleStep = (step) => {
    setActiveStep(step);
  };

  const accountTypeOptions = ["None", "Savings", "Current"];

  const renderSelect = (title, name, handleChange, values, options) => {
    return (
      <FormControl variant="outlined" fullWidth>
        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
          {title}
        </InputLabel>
        <Select
          id="accountType"
          style={{ marginBottom: 8 }}
          label="Account Type"
          variant="outlined"
          name={name}
          onChange={handleChange}
          fullWidth
          value={values}
          autoComplete="off"
        >
          {options.map((item) => {
            return (
              <MenuItem value={item} key={item}>
                {item}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  const filteredOptions = ((options,values)=>{
    return values? options.filter(option =>
      option.toLowerCase().includes(values?.toLowerCase())
    ) : options
  })
  const renderCustomAutoComplete =((value,name,setFieldValue,options,label)=>{
    return(
      <Autocomplete    
      value={value}
      onChange={(event, newValue) => {
        setFieldValue(name,newValue);
      }}
      options={filteredOptions(options.concat("Others"),value)}
      freeSolo
      onInputChange={(e)=>{
        setFieldValue(name, e ?  e.target.value : value);
      }}
      renderInput={(params) => (
        <TextField {...params} 
        variant="outlined"
        label={label} />
      )}
    />
    )
  })

  const inputProps ={
    readOnly: true,
    shrink: true,
  }
  return (
    <Root className={classes.root}>
    {!data ?
    <p style={{padding:'1rem'}}>Loading...</p> :  <ThemeProvider theme={getMuiTheme}>
        <Formik
          initialValues={data}
          onSubmit={(values) => {
            // setLoading(true)
            // props.formSubmit(values)
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
                    <ThemeProvider theme={getMuiTheme}>
           <div className={classes.root}>
              <Stepper activeStep={activeStep} nonLinear alternativeLabel>
                {steps.map((label, index) => (
                  <Step key={label}>
                    {/* <StepLabel>{label}</StepLabel> */}
                    <StepButton
                      onClick={() => {               
                        const totalSum = values.orderInfo.reduce((sum, item) => {
                          return sum + parseFloat(item.price);
                        }, 0)
                        setFieldValue("subTotal", totalSum);
                        setFieldValue(
                          "totalValue",
                          Number(totalSum) +
                            Number(values.stampDuty) +
                            Number(values.taxAmount) +
                            Number(values.tdsOrTcs) +
                            Number(values.others)
                        );
                        handleStep(index);
                      }}
                    >
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
              </div>
              </ThemeProvider>
              <div style={{ margin: "3rem" }}>
                <div>
                  <Typography className={classes.instructions}>
                    {activeStep === 0 ? (
                      <Grid
                        container
                        spacing={2}
                        style={{ marginBottom: "2rem" }}
                      >
                        <Grid item xs={12} md={6}>
                          {/* <TextField
                            InputLabelProps={inputProps}
                            fullWidth
                            variant="outlined"
                            name="ulkDetails.name"
                            value={values.ulkDetails?.name}
                            onChange={handleChange}
                            label="Name"
                          ></TextField> */}
                          <Autocomplete
                            disableClearable
                            id="client"
                            name="ulkDetails"
                            value={values.ulkDetails}
                            options={values.user}
                            onChange={async (e, value) => {
                              console.log(value)
                              if (value !== null) {
                                setFieldValue("ulkDetails", value);
                                setFieldValue(
                                  "ulkDetails.bank",
                                  value.bank.filter((item)=>item.primary ===true)[0]
                                );
                                setVendorBankAccountList(value.bank)
                              } else {
                                setFieldValue("ulkDetails.bank", "");
                              }
                            }}
                            getOptionLabel={(option) => option.name || ""}
                            renderInput={(params) => (
                              <TextField
                                variant="outlined"
                                {...params}
                                label={"Select ULK Account"}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            InputLabelProps={inputProps}
                            variant="outlined"
                            name="ulkDetails.address"
                            value={values.ulkDetails?.address}
                            label="Address"
                            onChange={handleChange}
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            InputLabelProps={inputProps}
                            name="ulkDetails.website"
                            value={values.ulkDetails?.website}
                            label="Website"
                            onChange={handleChange}
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            name="ulkDetails.email"
                            InputLabelProps={inputProps}
                            value={values.ulkDetails?.email}
                            onChange={handleChange}
                            label="Email"
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            name="ulkDetails.contact_no"
                            InputLabelProps={inputProps}
                            value={values.ulkDetails?.contact_no}
                            label="Contact No"
                            onChange={handleChange}
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            name="ulkDetails.pan"
                            InputLabelProps={inputProps}
                            value={values.ulkDetails?.pan}
                            onChange={handleChange}
                            label="PAN"
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            InputLabelProps={inputProps}
                            name="ulkDetails.gstin"
                            onChange={handleChange}
                            value={values.ulkDetails?.gstin}
                            label="GSTIN"
                          ></TextField>
                        </Grid>
                        <Grid item xs={6} md={12}>
                          Account Info
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <Autocomplete
                            disableClearable
                            id="client"
                            name="ulkDetails.bank"
                            value={values.ulkDetails?.bank}
                            options={vendorBankAccountList}
                            onChange={async (e, value) => {
                              if (value !== null) {
                                setFieldValue("ulkDetails.bank", value);
                                setFieldValue(
                                  "ulkDetails.accountType",
                                  value.accountType
                                );
                              } else {
                                setFieldValue("ulkDetails.bank", "");
                              }
                            }}
                            getOptionLabel={(option) => option.bankName || ""}
                            renderInput={(params) => (
                              <TextField
                                variant="outlined"
                                {...params}
                                label={"Select Bank"}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6} md={9}>
                      
                          </Grid>

                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            name="ulkDetails.bankName"
                            InputLabelProps={inputProps}
                            // onChange={handleChange}
                            value={values.ulkDetails?.bank?.bankName}
                            label="Bank Name"
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            InputLabelProps={inputProps}
                            variant="outlined"
                            name="ulkDetails.accountNo"
                            // onChange={handleChange}
                            value={values.ulkDetails?.bank?.accountNo}
                            label="Account Number"
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            name="ulkDetails.ifscCode"
                            // onChange={handleChange}
                            InputLabelProps={inputProps}
                            value={values.ulkDetails?.bank?.ifscCode}
                            label="IFSC Code"
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          {renderSelect(
                            "Account Type",
                            "ulkDetails.accountType",
                            handleChange,
                            values.ulkDetails?.bank?.accountType,
                            accountTypeOptions
                          )}
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            name="ulkDetails.bank.branch"
                            InputLabelProps={inputProps}
                            onChange={handleChange}
                            value={values.ulkDetails?.bank?.branch}
                            label="Branch"
                          ></TextField>
                        </Grid>
                      </Grid>
                    ) : activeStep === 1 ? (
                      <Grid
                        container
                        spacing={2}
                        style={{ marginBottom: "2rem" }}
                      >
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            name="userDetails.name"
                            InputLabelProps={inputProps}
                            onChange={handleChange}
                            value={values.userDetails?.name}
                            label="Name"
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            name="userDetails.address"
                            InputLabelProps={inputProps}
                            onChange={handleChange}
                            value={values.userDetails?.address || ""}
                            label="Address"
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            InputLabelProps={inputProps}
                            name="userDetails.contact_no"
                            onChange={handleChange}
                            value={values.userDetails?.contact_no  || ""}
                            label="Contact"
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            InputLabelProps={inputProps}
                            name="userDetails.email"
                            onChange={handleChange}
                            value={values.userDetails?.email  || ""}
                            label="Email"
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            InputLabelProps={inputProps}
                            name="userDetails.pan"
                            onChange={handleChange}
                            value={values.userDetails?.pan  || ""}
                            label="PAN"
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            InputLabelProps={inputProps}
                            name="userDetails.gstin"
                            onChange={handleChange}
                            value={values.userDetails?.gstin  || ""}
                            label="GSTIN"
                          ></TextField>
                        </Grid>

                        <Grid item xs={12} md={12}>
                          Account Info
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            InputLabelProps={inputProps}
                            name="userDetails.bankName"
                            onChange={handleChange}
                            value={values.userDetails?.bank_name}
                            label="Bank Name"
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            name="userDetails.accountNo"
                            onChange={handleChange}
                            value={values.userDetails?.account_no}
                            label="Account Number"
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            InputLabelProps={inputProps}
                            name="userDetails.ifscCode"
                            onChange={handleChange}
                            value={values.userDetails?.ifsc_code}
                            label="IFSC Code"
                          ></TextField>
                        </Grid>

                        <Grid item xs={12} md={2}>
                        {renderSelect(
                            "Account Type",
                            "userDetails.accountType",
                            handleChange,
                            values.userDetails?.accountType,
                            accountTypeOptions
                          )}
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            InputLabelProps={inputProps}
                            name="userDetails.branch"
                            onChange={handleChange}
                            value={values.userDetails?.branch || ""}
                            label="Branch"
                          ></TextField>
                        </Grid>
                      </Grid>
                    ) : activeStep === 2 ? (
                      <FieldArray name="orderInfo">
                        {() => (
                          <div>
                            {values.orderInfo.map((item, index) => {
                              return (
                                <Grid
                                  container
                                  spacing={2}
                                  style={{ marginBottom: "2rem" }}
                                  key={index}
                                >
                                  <Grid item xs={12}>
                                    <Typography component={'span'}>
                                      Order Id :{" "}
                                      {values.orderInfo[index].service_id}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={12} md={3}>
                                    <TextField
                                      fullWidth
                                      variant="outlined"
                                      type="date"
                                      name={`orderInfo.${index}.date`}
                                      
                                      value={`${moment(values.orderInfo[index].create_datetime).format("YYYY-MM-DD")}`}
                                      onChange={handleChange}
                                      label="Order Date"
                                    ></TextField>
                                  </Grid>
                                  <Grid item xs={12} md={3}>
                                    <TextField
                                      fullWidth
                                      variant="outlined"
                                      name={`orderInfo.${index}.serviceOrderNo`}
                                      value={
                                        values.orderInfo[index].service_id
                                      }
                                      onChange={handleChange}
                                      label="Service Order Number"
                                    ></TextField>
                                  </Grid>

                                  <Grid item xs={12} md={3}>
                                    <TextField
                                      fullWidth
                                      variant="outlined"
                                      name={`orderInfo.${index}.invoiceNo`}
                                      value={values.orderInfo[index].invoiceNumber}
                                      onChange={handleChange}
                                      label="Invoice Number"
                                    ></TextField>
                                  </Grid>
                                  <Grid item xs={12} md={3}>
                                    {renderCustomAutoComplete(values.orderInfo[index].frequency,`orderInfo.${index}.frequency`,setFieldValue,['Daily','Weekly','Monthly','Quarterly','Yearly'],'Frequency')}
                                  </Grid>
                                  <Grid item xs={12} md={3}>
                                    <TextField
                                      fullWidth
                                      variant="outlined"
                                      onChange={handleChange}
                                      name={`orderInfo.${index}.paymentTerms`}
                                      value={
                                        values.orderInfo[index].paymentTerms
                                      }
                                      label="Payment Terms"
                                    ></TextField>
                                  </Grid>
                                  <Grid item xs={12} md={3}>
                                    <TextField
                                      fullWidth
                                      variant="outlined"
                                      type="date"
                                      InputLabelProps={inputProps}
                                      onChange={handleChange}
                                      name={`orderInfo.${index}.payment_date`}
                                      value={`${moment(values.orderInfo[index].payment_date).format("YYYY-MM-DD")}`}

                                      label="Payment Date"
                                    ></TextField>
                                  </Grid>
                                  <Grid item xs={12} md={3}>
                                    <TextField
                                      fullWidth
                                      variant="outlined"
                                      type="date"
                                      InputLabelProps={inputProps}
                                      onChange={handleChange}
                                      name={`orderInfo.${index}.dueDate`}
                                      value={`${moment(values.orderInfo[index].dueDate).format("YYYY-MM-DD")}`}
                                      label="Due Date"
                                    ></TextField>
                                  </Grid>
                                  <Grid item xs={12} md={3}>
                                  {renderCustomAutoComplete(values.orderInfo[index].approvedBy,`orderInfo.${index}.approvedBy`,setFieldValue,['Anish' , 'Suchai' , 'Vikram' , 'Krishna' , 'Accounts' ],'Approved By')}                             
                                  </Grid>
                                  <Grid item xs={12} md={3}>
                                  {renderCustomAutoComplete(values.orderInfo[index].preparedFor,`orderInfo.${index}.preparedFor`,setFieldValue,channelPartner,'Prepared For')}
                                 
                                  </Grid>
                                  <Grid item xs={12} md={3}>
                                  {renderCustomAutoComplete(values.orderInfo[index].category,`orderInfo.${index}.category`,setFieldValue,['Vendor payment', 'Sales' , 'Technology' , 'Operations' , 'Maintenance' , 'NSE Brokerage'],'Category')}
                                  </Grid>
                                  <Grid item xs={12} md={3}>
                                    <TextField
                                      fullWidth
                                      variant="outlined"
                                      onChange={handleChange}
                                      name={`orderInfo.${index}.description`}
                                      value={
                                        values.orderInfo[index].description
                                      }
                                      label="Description"
                                    ></TextField>
                                  </Grid>
                                  <Grid item xs={12} md={3}>
                                    <TextField
                                      fullWidth
                                      variant="outlined"
                                      // onChange={handleChange}
                                      // onChange={(e) => {
                                      //   setFieldValue(
                                      //     `orderInfo.${index}.quantity`,
                                      //     e.target.value
                                      //   );
                                      //   setFieldValue(
                                      //     `orderInfo.${index}.price`,
                                      //     e.target.value *
                                      //       values.orderInfo[index].sellprice
                                      //   );
                                      // }}
                                      type="number"
                                      name={`orderInfo.${index}.quantity`}
                                      value={values.orderInfo[index].quantity}
                                      label="Quantity"
                                    ></TextField>
                                  </Grid>
                                  <Grid item xs={12} md={3}>
                                    <TextField
                                      fullWidth
                                      type="number"
                                      variant="outlined"
                                      InputLabelProps={inputProps}
                                      // onChange={(e) => {
                                      //   setFieldValue(
                                      //     `orderInfo.${index}.sellprice`,
                                      //     e.target.value
                                      //   );
                                      //   setFieldValue(
                                      //     `orderInfo.${index}.price`,
                                      //     e.target.value *
                                      //       values.orderInfo[index].quantity
                                      //   );
                                      // }}
                                      name={`orderInfo.${index}.sellprice`}
                                      value={values.orderInfo[index].sellprice}
                                      label="Sell Price"
                                    ></TextField>
                                  </Grid>
                                  <Grid item xs={12} md={3}>
                                    <TextField
                                      fullWidth
                                      type="number"
                                      variant="outlined"
                                      InputLabelProps={inputProps}
                                      // onChange={handleChange}
                                      name={`orderInfo.${index}.commission_percentage`}
                                      value={
                                        values.orderInfo[index].commission_percentage
                                      }
                                      label="Commission Percentage"
                                    ></TextField>
                                  </Grid>
                                  <Grid item xs={12} md={3}>
                                    <TextField
                                      fullWidth
                                      type="number"
                                      variant="outlined"
                                      InputLabelProps={inputProps}
                                      // onChange={handleChange}
                                      name={`orderInfo.${index}.commision`}
                                      value={values.orderInfo[index].commision}
                                      label="RM Price"
                                    ></TextField>
                                  </Grid>
                                  <Grid item xs={12} md={3}>
                                    <TextField
                                      fullWidth
                                      type="number"
                                      variant="outlined"
                                      InputLabelProps={inputProps}
                                      // onChange={handleChange}
                                      name={`orderInfo.${index}.price`}
                                      value={
                                        values.orderInfo[index].price
                                      }
                                      label="Total Amount"
                                    ></TextField>
                                  </Grid>
                                </Grid>
                              );
                            })}
                          </div>
                        )}
                      </FieldArray>
                    ) : activeStep === 3 ? (
                      <Grid
                        container
                        spacing={2}
                        style={{ marginBottom: "2rem" }}
                      >
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            value={values.subTotal}
                            name="subTotal"
                            type="number"
                            onChange={handleChange}
                            label="Sub Total"
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel
                              shrink
                              id="demo-simple-select-placeholder-label-label"
                            >
                              Taxable
                            </InputLabel>
                            <Select
                              id="accountType"
                              style={{ marginBottom: 8 }}
                              label="Taxable"
                              variant="outlined"
                              fullWidth
                              name="taxable"
                              onChange={(e) => {
                                setFieldValue("taxable", e.target.value);
                                if (e.target.value === "no") {
                                  setFieldValue("taxAmount", 0);
                                  setFieldValue("taxRate", 0);
                                  setFieldValue(
                                    "totalValue",
                                    Number(values.subTotal) +
                                      Number(values.stampDuty) +
                                      0 +
                                      Number(values.tdsOrTcs) +
                                      Number(values.others)
                                  );
                                }
                              }}
                              value={values.taxable}
                              autoComplete="off"
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem key="yes" value="Yes">
                                Yes
                              </MenuItem>
                              <MenuItem key="no" value="No">
                                No
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            name="taxRate"
                            disabled={values.taxable === "no"}
                            type="number"
                            onChange={(e) => {
                              setFieldValue("taxRate", e.target.value);
                              const taxAmount =
                                (e.target.value * values.subTotal) / 100;
                              setFieldValue("taxAmount", taxAmount);
                              setFieldValue(
                                "totalValue",
                                Number(values.subTotal) +
                                  Number(values.stampDuty) +
                                  Number(taxAmount) +
                                  Number(values.tdsOrTcs) +
                                  Number(values.others)
                              );
                            }}
                            value={values.taxRate}
                            label="Tax Rate (%)"
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            name="taxAmount"
                            type="number"
                            // onChange={handleChange}
                            value={values.taxAmount}
                            label="Tax Amount"
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            name="tdsOrTcs"
                            type="number"
                            onChange={(e) => {
                              setFieldValue("tdsOrTcs", e.target.value);
                              setFieldValue(
                                "totalValue",
                                Number(values.subTotal) +
                                  Number(values.stampDuty) +
                                  Number(values.taxAmount) +
                                  Number(e.target.value) +
                                  Number(values.others)
                              );
                            }}
                            value={values.tdsOrTcs}
                            label="TDS or TCS"
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            name="stampDuty"
                            type="number"
                            onChange={(e) => {
                              setFieldValue("stampDuty", e.target.value);
                              setFieldValue(
                                "totalValue",
                                Number(values.subTotal) +
                                  Number(e.target.value) +
                                  Number(values.taxAmount) +
                                  Number(values.tdsOrTcs) +
                                  Number(values.others)
                              );
                            }}
                            value={values.stampDuty}
                            label="Stamp Duty"
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            name="others"
                            type="number"
                            onChange={(e) => {
                              setFieldValue("others", e.target.value);
                              setFieldValue(
                                "totalValue",
                                Number(values.subTotal) +
                                  Number(values.stampDuty) +
                                  Number(values.taxAmount) +
                                  Number(values.tdsOrTcs) +
                                  Number(e.target.value)
                              );
                            }}
                            value={values.others}
                            label="Others"
                          ></TextField>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            name="totalValue"
                            // onChange={handleChange}
                            type="number"
                            value={parseFloat(values.totalValue).toFixed(3)}
                            label="Total Value"
                          ></TextField>
                        </Grid>
                      </Grid>
                    ) : activeStep === 4 ? (
                      <table className={classes.customTable} id="printTable">
                        <tr>
                          <td colspan="2" class="invoice " style={{textAlign:'center'}}>
                            <img
                              src="https://i.ibb.co/0Bzk1ZS/Screenshot-from-2023-09-27-16-18-02.png"
                              alt=""
                            />
                          </td>
                          <td colspan= {values.orderInfo.length >1 ?"15" : "8"} class="invoice" style={{textAlign:'center'}}>
                            <h1> {invoiceType ===1 ? "Buy Invoice" : invoiceType === 2 ? 'Sell Invoice' : invoiceType === 3 ? 'Sell Commission Invoice' : 'Buy Commission Invoice'}</h1>
                          </td>
                        </tr>
                        <tr>
                          <td
                            class="ulkdetails"
                            rowspan={values.orderInfo.length > 1 ? "" : "10"}
                            colspan={values.orderInfo.length > 1 ? "16" : "5"}
                          >
                            <b>{values.ulkDetails.name} </b>
                            <br />
                            {values.ulkDetails.address}
                            <br />
                            {values.ulkDetails.website} |{" "}
                            {values.ulkDetails.email} |{" "}
                            {values.ulkDetails.contact_no}
                            <br />
                            <br />
                            <span style={{fontWeight:'bold'}}>GSTIN:</span> {values.ulkDetails.gstin}
                            <br />
                            <span style={{fontWeight:'bold'}}> PAN:</span> {values.ulkDetails.pan}
                          </td>
                        </tr>
                        <>
                          {values.orderInfo.length === 1 && (
                            <>
                              <tr>
                                <td class="details title" colspan="2">
                                  DATE
                                </td>
                                <td class="details" colspan="3">
                                {`${moment(values.orderInfo[0].create_datetime).format("YYYY-MM-DD")}`}
                                </td>
                              </tr>
                              <tr>
                                <td class="details title" colspan="2">
                                  INVOICE NO
                                </td>
                                <td class="details" colspan="3">
                                {values.orderInfo[0].invoiceNumber}
                                </td>
                              </tr>
                              <tr>
                                <td class="details title" colspan="2">
                                  DUE DATE
                                </td>
                                <td class="details" colspan="3">
                                {values.orderInfo[0].dueDate ? `${moment(values.orderInfo[0].dueDate).format("YYYY-MM-DD")}` : ""}
                                </td>
                              </tr>
                              <tr>
                                <td class="details title" colspan="2">
                                  PAYMENT TERMS
                                </td>
                                <td class="details" colspan="3">
                                {values.orderInfo[0].paymentTerms}
                                </td>
                              </tr>
                              <tr>
                                <td class="details title" colspan="2">
                                  FREQUENCY
                                </td>
                                <td class="details" colspan="3">
                                  {values.orderInfo[0].frequency}
                                </td>
                              </tr>
                              <tr>
                                <td class="details title" colspan="2">
                                  APPROVED BY
                                </td>
                                <td class="details" colspan="3">
                                {values.orderInfo[0].approvedBy}
                                </td>
                              </tr>
                              <tr>
                                <td class="details title" colspan="2">
                                  PREPARED FOR
                                </td>
                                <td class="details" colspan="3">
                                {values.orderInfo[0].preparedFor}
                                </td>
                              </tr>
                              <tr>
                                <td class="details title" colspan="2">
                                  SERVICE ORDER NO
                                </td>
                                <td class="details" colspan="3">
                                {values.orderInfo[0].service_id}
                                </td>
                              </tr>
                              <tr>
                                <td class="details title" colspan="2">
                                  CATEGORY
                                </td>
                                <td class="details" colspan="3">
                                {values.orderInfo[0].category}
                                </td>
                              </tr>
                            </>
                          )}
                        </>
                        <tr>
                          <td class={classes.sectionTitle}  colspan={values.orderInfo.length > 1 ? "16" : "9"}>
                           <b> Bill to</b>
                          </td>
                        </tr>
                        <tr>
                          <td class="userSection billTo"   colspan={values.orderInfo.length > 1 ? "7" : "4"}>
                            <b>Name </b> :{" "}
                            <span>{values.userDetails.name}</span>
                            <br />
                            <b>Address </b> :
                            <span>{values.userDetails.address} </span>
                            <br />
                            <b>GSTIN </b> :{" "}
                            <span>{values.userDetails.gstin}</span>
                            <br />
                            <b>PAN </b> : <span>{values.userDetails.pan}</span>
                            <br />
                            <b>Phone No </b> :{" "}
                            <span>{values.userDetails.contact_no}</span>
                            <br />
                            <b>Mail Id </b> :{" "}
                            <span>{values.userDetails.email}</span>
                            <br />
                          </td>

                          <td
                            class="userSection billTo noborder"
                            style={{ border: "none" }}
                            colspan={values.orderInfo.length > 1 ? "6" : "3"}
                          >
                            <b>Bank Account and Company Details </b> :{" "}
                            <span>{values.userDetails.name}</span>
                            <br />
                            <b>Bank Name </b> :{" "}
                            <span>{values.userDetails.bank_name}</span>
                            <br />
                            <b>Branch </b> :{" "}
                            <span>{values.userDetails.branch}</span>
                            <br />
                            <b>Account No </b> :
                            <span>{values.userDetails.account_no}</span>
                            <br />
                            <b>GSTIN </b> :{" "}
                            <span>{values.userDetails.gstin}</span>
                            <br />
                          </td>
                          <td
                            class="userSection billTo noborder"
                            style={{ borderLeft: "none" }}
                            colspan={values.orderInfo.length > 1 ? "3" : "2"}

                          >
                            <b>IFSC Code </b> :{" "}
                            <span>{values.userDetails.ifsc_code}</span>
                            <br />
                            <b>Account Type </b> :{" "}
                            <span>{values.userDetails.accountType}</span>
                            <br />
                            <b>PAN </b> : <span>{values.userDetails.pan}</span>
                            <br />
                          </td>
                        </tr>
                        <tr>
                          <td className={classes.sectionTitle} colspan={values.orderInfo.length > 1 ? "17" : "9"}>
                            <b>Transaction Details</b>
                          </td>
                        </tr>
                        <tr>
                          <th class="details">Date</th>
                          <th class="details">Description</th>
                          <th class="details">Service Order Number</th>
                          <th class="details">Payment Date</th>
                         {values.orderInfo.length>1 && <>
                          <th class="details">Due Date</th>
                          <th class="details">Invoice Number</th>
                          <th class="details">Frequency</th>
                          <th class="details">Payment Terms</th>
                          <th class="details">Approved By</th>
                          <th class="details">Prepared For </th>
                          <th class="details">Category</th>
                         </>}

                          <th class="details">Quantity</th>
                          <th class="details">{(invoiceType ===1 || invoiceType ===4) ? "Buy" : "Sell"} Price</th>
                          <th class="details">Commission Percentage</th>
                          <th class="details">RM Price</th>
                          <th class="details">Total Amount</th>
                        </tr>
                        {values.orderInfo.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td class="details ">{moment(item.create_datetime).format("YYYY-MM-DD")}</td>
                              <td class="details rightAlign">
                                {item.description}
                              </td>
                              <td class="details rightAlign">
                                {" "}
                                {item.service_id}
                              </td>
                              <td class="details rightAlign">
                                {" "}
                                {item.payment_date ? `${moment(item.payment_date).format("YYYY-MM-DD")}` : ""}
                              </td>
                             { values.orderInfo.length>1 &&
                              <>
                                <td class="details rightAlign">
                                {" "}
                                {item.dueDate ? moment(item.dueDate).format("YYYY-MM-DD") : " "}
                              </td>  <td class="details rightAlign">
                                {" "}
                                {item.invoiceNumber}
                              </td>  <td class="details rightAlign">
                                {" "}
                                {item.frequency}
                              </td>
                              <td class="details rightAlign">
                                {" "}
                                {item.paymentTerms}
                              </td>
                              <td class="details rightAlign">
                                {" "}
                                {item.approvedBy}
                              </td>  <td class="details rightAlign">
                                {" "}
                                {item.preparedFor}
                              </td>  <td class="details rightAlign">
                                {" "}
                                {item.category}
                              </td>
                              </>}
                              <td class="details rightAlign"> {item.quantity}</td>
                              <td class="details rightAlign">
                                {" "}
                                {parseFloat(item.sellprice).toFixed(3)}
                              </td>
                              <td class="details rightAlign">
                                {" "}
                                {item.commission_percentage}
                              </td>
                              <td class="details rightAlign">
                                {" "}
                                {item.commision}
                              </td>
                              <td class="details rightAlign">
                                {" "}
                                {item.price}
                              </td>
                            </tr>
                          );
                        })}

                        <tr>
                          <td rowspan="9"  colspan={values.orderInfo.length > 1 ? "12" : "5"}>
                            <b>Note</b>: * If you have any questions about this
                            invoice, please contact operations@unlistedkart.com
                            <br />
                          </td>
                        </tr>
                        <tr class="ulkdetails">
                          <td class="details title" colspan="2">
                            Sub Total
                          </td>
                          <td class="details rightAlign title" colspan="2">
                            {values.subTotal}
                          </td>
                        </tr>
                        <tr class="ulkdetails">
                          <td class="details title" colspan="2">
                            Taxable
                          </td>
                          <td class="details rightAlign title" colspan="2">
                            {values.taxable}
                          </td>
                        </tr>
                        <tr class="ulkdetails">
                          <td class="details title " colspan="2">
                            Tax rate
                          </td>
                          <td class="details rightAlign title" colspan="2">
                            {values.taxRate ? values.taxRate : 0}%
                          </td>
                        </tr>
                        <tr class="ulkdetails">
                          <td class="details title" colspan="2">
                            Tax amount
                          </td>
                          <td class="details rightAlign title" colspan="2">
                            {values.taxAmount}
                          </td>
                        </tr>
                        <tr class="ulkdetails">
                          <td class="details title" colspan="2">
                            TDS or TCS
                          </td>
                          <td class="details rightAlign title" colspan="2">
                            {values.tdsOrTcs}
                          </td>
                        </tr>
                        <tr class="ulkdetails">
                          <td class="details title" colspan="2">
                            Stamp duty fee
                          </td>
                          <td class="details  rightAlign title" colspan="2">
                            {values.stampDuty}
                          </td>
                        </tr>
                        <tr class="ulkdetails">
                          <td class="details title" colspan="2">
                            Others
                          </td>
                          <td class="details rightAlign title" colspan="2">
                            {values.others}
                          </td>
                        </tr>

                        <tr class="total">
                          <td class="billTo title" colspan="2">
                            <b>Total</b>
                          </td>
                          <td class="details billTo rightAlign" colspan="2">
                            <b> {parseFloat(values.totalValue).toFixed(3)}</b>
                          </td>
                        </tr>
                        <tr>
                          <td
                            colspan={values.orderInfo.length > 1 ? "12" : "6"}
                            class="ulkdetails billTo "
                            style={{ border: "none" }}
                          >
                            <b>Bank Details</b> <br />
                            {values.ulkDetails.name} <br />
                            Bank Name : {values.ulkDetails.bank.bankName} <br/>
                            A/C No : {values.ulkDetails.bank.accountNo} <br />
                            IFSC Code :  {values.ulkDetails.bank.ifscCode} <br />
                            Branch :  {values.ulkDetails.bank.branch} <br />
                          </td>
                          <td
                            colspan="3"
                            class="ulkdetails "
                            style={{ border: "none" ,textAlign:'center'}}
                          >
                            <br />
                            FOR UNLISTEDKART Pvt Ltd
                            <br />
                            <br />
                            <br />
                            [AUTHORISED SIGNATORY]
                          </td>
                        </tr>
                        <tr>
                          <td
                            colspan={values.orderInfo.length > 1 ? "15" : "9"}
                            class="ulkdetails noborder invoice "
                            style={{ border: "none" ,textAlign:'center', fontWeight:'bold'}}
                          >
                            Thank You For Your Business!
                          </td>
                        </tr>
                      </table>
                    ) : null}
                  </Typography>
                  <div
                    style={{
                      alignItems: "end",
                      float: "right",
                      marginBottom: "2rem",
                    }}
                  >
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.backButton}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={loading}
                      onClick={() => {
                        handleNext(values);
                        setFieldValue(
                          "totalValue",
                          Number(values.subTotal) +
                            Number(values.stampDuty) +
                            Number(values.taxAmount) +
                            Number(values.tdsOrTcs) +
                            Number(values.others)
                        );
                      }}
                    >                    
                      {activeStep === steps.length - 1 ?( loading ? "Downloading..." : "Download") : "Next"}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </ThemeProvider>}
      <SessionExpiredModal
          open={openSessionExpiredModal}
        />
            <SnackBar
          open={open}
          severity={severity}
          close={()=>setOpen(false)}
          snackbarTitle={snackbarTitle}
        />
    </Root>
  );
}
