import React,{useState} from 'react'
import { Tooltip ,Typography} from '@mui/material';
  
const Copy = ({value,font}) => {
    const [copy, setCopy] = useState(false);


  const onHover = (value) => {
    setCopy(true);
    navigator.clipboard.writeText(value);
  };

  const onLeave = () => {
    setCopy(false);
  };

    return (
      <div
        onClick={() => onHover(value)}
        onMouseLeave={onLeave}
      >
        <Tooltip
          placement="right"
          sx={{cursor:'copy',fontWeight:font? 'bold':'', paddingLeft:font?'4px':''}}
          title={copy ? "Copied" : "Click to Copy"}
        >
          <Typography>{value}</Typography>
        </Tooltip>
      </div>
    );
  };

  export default Copy