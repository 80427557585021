import React from 'react'
import { styled } from '@mui/material/styles';
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate,useLocation } from "react-router-dom";

const PREFIX = 'Buttons';

const classes = {
  hdrlblbtn: `${PREFIX}-hdrlblbtn`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.hdrlblbtn}`]: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: theme.spacing(0),
      alignItems: "center",
      marginBottom: "1%",
    }
}));

const Buttons = ({setUserParamsId,url}) => {
    const navigate = useNavigate();
    const location = useLocation();
  return (
    <Root className={classes.hdrlblbtn}>
    <IconButton
      disabled={location?.state?.index === 0 || location?.state?.page === undefined}
      // variant="contained"
      // color="primary"
      // fontSize='medium'
      style={{ cursor: "pointer" }}
      variant="contained"
      color="primary"
      fontSize="large"
      onClick={() => {
        var data = location.state.data[location.state.index - 1].data[0];
        setUserParamsId(data);
        navigate(`${url}/${data}`, {
          replace: true,
          state: {
            page: location.state.page,
            data: location.state.data,
            index: location.state.index - 1,
            orderType: location?.state?.orderType,
            advanceSearchForm: location?.state?.advanceSearchForm,
          },
        });
      }}
    >
      <ArrowBackIosIcon />
    </IconButton>
    <IconButton
      disabled={location?.state?.data?.length === location?.state?.index + 1  || location?.state?.page === undefined}
      style={{ cursor: "pointer" }}
      variant="contained"
      color="primary"
      fontSize="large"
      onClick={() => {
        var data = location.state.data[location.state.index + 1].data[0];
        setUserParamsId(data);
        navigate(`${url}/${data}`, {
          replace: true,
          state: {
            page: location.state?.page,
            data: location.state?.data,
            orderType: location?.state?.orderType,
            advanceSearchForm: location?.state?.advanceSearchForm,
            index: location.state.index + 1,
          },
        });
      }}
    >
      <ArrowForwardIosIcon />
    </IconButton>
  </Root>
  );
}

export default Buttons