import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { Container, TextField, Grid } from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import Autocomplete from "@mui/material/Autocomplete";
import loadingSpinner from "src/images/loading-spinner.gif";
import compareJSON from "src/components/comparision";

const PREFIX = "UpdateStatus";

const classes = {
  container: `${PREFIX}-container`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  buttonProgress: `${PREFIX}-buttonProgress`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.container}`]: {
    justifyContent: "center",
    // justifyItems: "center",
    marginTop: "10%",
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: "4px",
  },
}));

function PreApprovedStatus({data,formSubmit}) {
  const [loading,setLoading] = useState(false)
  const initialData ={
    preApprovedStatus: data.status ?"Yes" :"No",
    range:data.range
  }
  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialValues={initialData}
        validationSchema={Yup.object().shape({
          range:Yup.number().when("preApprovedStatus", {
            is:true,
            then: Yup.number().required("Required"),
          })
        })}
        onSubmit={(values) => {
          setLoading(true)
          let updatedValues = compareJSON(values,initialData)
          values={...values,updatedValues:JSON.stringify(updatedValues)}
          formSubmit(values);
        }}
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          setFieldValue,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Autocomplete
                    id="preApprovedStatus"
                    value={values.preApprovedStatus}
                    options={["Yes","No"]}
                    onChange={(e, value) => {
                      if (value !== null) {
                        setFieldValue("preApprovedStatus", value);
                        if(value ==='No'){
                            setFieldValue("range", '');
                        }else{
                            setFieldValue("range", data.range);
                        }
                      } 
                    }}
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="preApprovedStatus"
                        label="Pre Approved Status*"
                        fullWidth
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
               {values.preApprovedStatus ==='Yes' ?
               ( <Grid item xs={12} sm={12} md={6} lg={6}>
                 <TextField
                 name="range"
                 label="Pre apporved range*"
                 fullWidth
                 type="number"
                 value={values.range}
                 onChange={handleChange}
                 error={Boolean(touched.range && errors.range)}
                 helperText={touched.range && errors.range}
                 variant="outlined"
                 />
                </Grid>)
              : null}
              </Grid> 

              <Button
                color="primary"
                disabled={isSubmitting}
                className={classes.popDownBtn}
                size="medium"
                type="submit"
                variant="contained"
              >
                {loading === false ? (
                  "Update"
                ) : (
                  <>
                    <img
                      alt="loader"
                      src={loadingSpinner}
                      className={classes.buttonProgress}
                    />{" "}
                    Update{" "}
                  </>
                )}
              </Button>
            </Container>
          </form>
        )}
      </Formik>
    </StyledContainer>
  );
}

export default PreApprovedStatus;
