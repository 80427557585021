import React from 'react';

import { styled } from '@mui/material/styles';
import {  Button } from '@mui/material'
import moment from "moment"

const PREFIX = 'Details';

const classes = {
    induvidual: `${PREFIX}-induvidual`,
    labelname: `${PREFIX}-labelname`,
    details: `${PREFIX}-details`,
    formgrgroup: `${PREFIX}-formgrgroup`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.induvidual}`]: {
        marginTop: theme.spacing(2)
    },

    [`& .${classes.labelname}`]: {
        [theme.breakpoints.up(576)]: {
            width: "35%",
        },
        width: "50%",
        textAlign: 'end',
        fontWeight: '500'
    },

    [`& .${classes.details}`]: {
        width: '68%',
        marginLeft: theme.spacing(2)
    },

    [`& .${classes.formgrgroup}`]: {
        display: 'flex',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
}));


const Details = (props) => {


    return (
        <Root style = {{marginTop:24,marginBottom:24}}>
        
                
            <div className={classes.induvidual}>
           
                    <div className={classes.formgrgroup} >
                        <label className={classes.labelname}>Status</label>
                        <p className={classes.details}>{props.details.status === "ACCEPT"  ? `${props.details.status}ED`:props.details.status === "REJECT" ? `${props.details.status}ED`:props.details.status }</p>
                    </div>
                    
                    <div className={classes.formgrgroup} >
                        <label className={classes.labelname}>Requested Quantity</label>
                        <p className={classes.details}>{props.details.requestedQuantity}</p>
                    </div>
                    <div className={classes.formgrgroup} >
                        <label className={classes.labelname}>Order Placed On</label>
                        <p className={classes.details}>{moment(props.details.orderPlacedDate).format("DD-MM-YYYY LTS")}</p>
                    </div>
                    <div className={classes.formgrgroup} >
                        <label className={classes.labelname}>Remarks</label>
                        <p className={classes.details}>{props.details.remarks}</p>
                    </div>
                    
                    {props.details.status === "REJECT" ? 
                    <Button color="primary" variant="outlined" style={{float:"right",marginTop:16,marginBottom:16} } onClick={() => {props.callBack(props.details.id)}}  >Procure Again</Button>:<></>}
                </div>
                

        </Root>
    );
}

export default Details;
