import React,{ useState } from "react";
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";

import {
  FormControl,
  FormHelperText,
  Select,
  Checkbox,
  Container,
  InputLabel,
  MenuItem,
  FormControlLabel,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import loadingSpinner from 'src/images/loading-spinner.gif'

const PREFIX = 'SelectUserAccountForm';

const classes = {
  zeroPadding: `${PREFIX}-zeroPadding`,
  cardBorderRadius: `${PREFIX}-cardBorderRadius`,
  cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
  fileInput: `${PREFIX}-fileInput`,
  imageSizes: `${PREFIX}-imageSizes`,
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  marginInputLabel: `${PREFIX}-marginInputLabel`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  resendBtnMargin: `${PREFIX}-resendBtnMargin`,
  inputMargin: `${PREFIX}-inputMargin`,
  paddingInput: `${PREFIX}-paddingInput`,
  buttonProgress: `${PREFIX}-buttonProgress`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.zeroPadding}`]: {
    padding: 0,
  },

  [`& .${classes.cardBorderRadius}`]: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },

  [`& .${classes.cardHeaderMargin}`]: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    // justifyContent:"center",
    borderRadius: "0 0  10px 0",
  },

  [`& .${classes.fileInput}`]: {
    display: "none",
  },

  [`& .${classes.imageSizes}`]: {
    width: "40%",
    margin: "auto",
    display: "block",
  },

  [`& .${classes.container}`]: {
    justifyContent: "center",
    // justifyItems: "center",

    marginTop: "10%",
  },

  [`& .${classes.label}`]: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },

  [`& .${classes.marginInputLabel}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.resendBtnMargin}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.inputMargin}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.paddingInput}`]: {
    padding: "0px 0px",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: '4px'
  }
}));



function SelectUserAccountForm(props) {

  const [loading , setLoading] = useState(false)
 
  return (
    <StyledContainer>
      <Formik
        initialValues={{
          role: props.defaultRole || "",
          setDefault: false,
        }}
        validationSchema={Yup.object().shape({
          role: Yup.string().required("Required"),
        })}
        onSubmit={(values) => { 
          setLoading(true)
          const selectedObject = props.data.filter(item => item.code === values.role);    
        const submittedValues = {...values, description: selectedObject[0].description}
        props.formSubmit(submittedValues)
         
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>
              <FormControl variant="outlined" fullWidth={true}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Account Type*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="role"
                  className={classes.inputMargin}
                  label="Document Type"
                  select
                  SelectProps={{ native: true }}
                  variant="outlined"
                  error={Boolean(touched.role && errors.role)}
                  helperText={touched.role && errors.role}
                  name="role"
                  value={values.role}
                  aria-describedby="outlined-weight-helper-text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  autoComplete="off"
                >                  
                  {props.data && props.data.length>0 && props.data.map(item=>(
                    <MenuItem key={item.code} value={item.code}>
                    {item.description}
                  </MenuItem>
                  ))}
                </Select>
                <FormHelperText style={{ color: "#F44336",position:"absolute",top:53 }}>
                  {errors.role && touched.role && errors.role}
                </FormHelperText>
              </FormControl>

              <FormControlLabel
                control={
                  <Checkbox
                    id="setDefault"
                    name="setDefault"
                    value={values.setDefault}
                    aria-describedby="outlined-weight-helper-text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    color="primary"
                  />
                }
                label="Set default"
              />

              <Button
                color="primary"
                disabled={isSubmitting}
                className={classes.popDownBtn}
                size="medium"
                type="submit"
                variant="contained"
              >
                 {loading === false ? "Update" : <><img src={loadingSpinner} alt="Logo" className={classes.buttonProgress}/> Update </>} 
              </Button>
            </Container>
          </form>
        )}  
      </Formik>
    </StyledContainer>
  );
}

export default SelectUserAccountForm;
