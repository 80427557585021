import { createAction, createReducer } from '@reduxjs/toolkit'

//action creater
export const bugAdded = createAction("bugAdded")
// export function bugAdded(description){
//     return {
//         type:BUG_ADDED,
//         payload:{
//             description
//         }
//     }
// }
//arrow function syntex
export const bugRemoved = createAction("bugRemoved");
export const bugResolved = createAction("bugResolved");
// export const bugRemoved =  id => ({   
//     type:BUG_REMOVED,
//     payload:{
//         id
//     }    
// })

// export const bugResolved = id => ({   
//     type:BUG_RESOLVED,
//     payload:{
//         id
//     }    
// })

const stete = {
    token:null,    
    auth:null,
    bugs:[],
}


export const setToken = createAction("setToken")
export const loginSuccess = createAction("loginSuccess")
export const logoutSuccess = createAction("logoutSuccess") 
export const switchRole = createAction("switchRole")


let lastId = 0;
export default createReducer(stete,{
    bugAdded:(state, action) =>{
        state.bugs.push({
            id:++lastId,
            description:action.payload.description,
            resolved:false
        })
    },

    setToken:(state, action) =>{
        state.token=action.payload.token
    },

    loginSuccess:(state, action) =>{
        state.auth=action.payload.auth
    },
    logoutSuccess:(state, action) =>{
        state.auth.loggedIn=false;
        state.auth.user=null;
        state.token=null
    },

    switchRole:(state, action) =>{     
        state.auth.user.default_role_code=action.payload.role;
        state.auth.user.default_description=action.payload.description;        
    },

    [bugResolved.type]:(state, action)=>{
        const index = state.bugs.findIndex(bug => bug.id===action.payload.id);
        state.bugs[index].resolved=true;
    },

    bugRemoved:(state, action) => {
        return state.bugs.filter(bug => bug.id !== action.payload.id)
    }

})


// export default function reducer(state = [], action){
//   switch(action.type){
//         case bugResolved.type:
//             return state.map(bug => 
//                 bug.id !== action.payload.id ? bug : {...bug, resolved:true}
//             )
            
//         case bugAdded.type:
//             return [
//                 ...state,  
//                 {
//                     id:++lastId,
//                     description:action.payload.description,
//                     resolved:false
//                 }
//             ];
//         case bugRemoved.type:
//             return state.filter(bug => bug.id !== action.payload.id)
      
//         default:
//             return state;
          
//   }

// }