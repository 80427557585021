import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import MUIDataTable from "mui-datatables";
import { Typography, IconButton, CircularProgress, Tooltip,  Button, Dialog, DialogContent,Stack } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { ThemeProvider } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, NavLink } from "react-router-dom";
import Chip from "@mui/material/Chip";
import moment from "moment";
import { userService } from "src/_services/users";
import { Select, MenuItem } from "@mui/material";
import SnackBar from "src/components/SnackBar/SnackBar";
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TagModal from '../../components/Modals/TagModal'
import CopytoClipboard from "../ButtonComponents/CopytoClipboard";
import orderIcon from './../../images/shopping-cart.png'
import AdvanceSearchForm from "src/containers/Users/AdvanceSearchForm";
import ModalCentered from "src/components/Modals/ModalCentered";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import BeneficiaryForm from './BeneficiaryFrom';
import GetMuiTheme from "../theme";
import { useSelector } from "react-redux";
import NormalCard from "../Cards/NormalCard";
import ExcelJS from 'exceljs';
import UpdateStatus from "src/containers/Users/UpdateStatus";

const PREFIX = 'DatatableView';

const classes = {
    tableEllips: `${PREFIX}-tableEllips`,
    root: `${PREFIX}-root`,
    btnright: `${PREFIX}-btnright`,
    container: `${PREFIX}-container`,
    button: `${PREFIX}-button`,
    tooltip: `${PREFIX}-tooltip`,
    editdeletbtn: `${PREFIX}-editdeletbtn`,
    btnsdltedt: `${PREFIX}-btnsdltedt`,
    chipMargin: `${PREFIX}-chipMargin`,
    iconbtn: `${PREFIX}-iconbtn`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.tableEllips}`]: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "7rem",
        [theme.breakpoints.down("sm")]: {
            width: "8rem",
        },
        [theme.breakpoints.up("sm")]: {
            width: "7rem",
        },
    },

    [`& .${classes.root}`]: {
        margin: theme.spacing(2),
        "& .MuiTableHead-root": {
            background: "blue",
        },
    },

    [`& .${classes.btnright}`]: {
        textAlign: "end",
    },

    [`& .${classes.container}`]: {
        minHeight: "100%",
        padding: theme.spacing(3),
    },

    [`& .${classes.button}`]: {
        marginBottom: "1%",
        borderRadius: "14px 14px 0 0",
        boxShadow: "none",
        textTransform: "capitalize",
    },

    [`& .${classes.tooltip}`]: {
        cursor: 'copy'
    },

    [`& .${classes.editdeletbtn}`]: {
        color: "#3363cb",
        display: "flex",
    },

    [`& .${classes.btnsdltedt}`]: {
        marginRight: theme.spacing(1),
    },

    [`& .${classes.chipMargin}`]: {
        marginRight: "5px",
        marginBottom: "3px",
        fontSize: "12px !important",
        padding: 0,
        height: "20px !important",
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
    },

    [`& .${classes.iconbtn}`]: {
        // padding: 0,
    }
}));

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) {
    // eslint-disable-line
    return <NavLink to={props.to} {...props} />; // eslint-disable-line
});
const dataForFilter = ['All', 'Pending', 'In progress', 'Completed']

const DatatableView = (props) => {


    const navigate = useNavigate();
    const [page, setPage] = useState(0)
    const [count, setcount] = useState(0)
    const [perPageRow, setrowsPerPage] = useState(10)
    const [sortOrder, setsortOrder] = useState({})
    const [data, setsdata] = useState([])
    const [open, setOpen] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState("All");
    const [isLoading, setsisLoading] = useState(false)
    const [searchAbleText, setSearchText] = useState("")
    const [tags, setTags] = useState([]);
    const [tagName, setTagName] = useState('')
    const [userId, setUserId] = useState('')
    const [userName, setUserName] = useState('');
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [rowindex, setRowindex] = useState();
    const [advanceFilter, setAdvanceFilter] = useState(false)
    const [snackbarTitle, setSnackbarTitle] = useState("")
    const [severity, setSeverity] = React.useState("");
    const [openSessionExpiredModal, setOpenSessionExpiredModal] = useState(
        false
    );
    const [statusModal, setStatusModal] = useState({
        open:false,
        status:'',
        reason:''
      })
    // const [download, setDownload] = React.useState('');
    // const [value, setValue] = React.useState('');
    const [timer, setTimer] = useState(null)

    // var today = new Date();
    // const initialDate = {
    //     fromDate: '',
    //     toDate: `${moment(today).format("YYYY-MM-DD")}`
    // }
    const stateValue = useSelector(state => state?.authReducer?.auth);
    const role = stateValue?.user?.default_role_code
    // const [date, setDate] = useState(initialDate);
    const [copy, setCopy] = useState(false);
    const onHover = (value) => {
        setCopy(true);
        navigator.clipboard.writeText(value);
    };


    const status = {
        open: false,
        id: '',
        status: '',
        name: ''
    }
    const [beneficiaryDialog, setBeneficiaryDialog] = useState(status)

    const handleBeneficiary = ((open, id, status, name) => {
        setBeneficiaryDialog({
            open: open,
            id: id,
            status: status,
            name: name
        })
    })

    const onLeave = () => {
        setCopy(false);
    };

    const handleStatusModal=((value,tableData)=>{
        setStatusModal({
            open:true,
            status:value,
            reason:tableData.rowData[15],
            id:tableData.rowData[0]
        })
    })
    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                hide: true,
                display: false,
            },
        },

        {
            name: "user_unique_code",
            label: "Empanelment ID",
            options: {
                hint: "Unlistedkart Unique ID for Users",
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    return (
                        <Root
                            onClick={() => {
                                onHover(value)
                            }} onMouseLeave={onLeave}
                        >
                            <Tooltip className={classes.tooltip} title={copy ? "Copied" : "Click to Copy"}>
                                <Typography
                                    variant="body2"
                                    noWrap
                                    style={{ maxWidth: 300 }}
                                >
                                    {value}
                                </Typography>
                            </Tooltip>
                        </Root>
                    );
                },
            },
        },
        {
            name: "name",
            label: "Name",
            options: {
                hint: "User Name",
                filter: false,
                customBodyRender: (value, tableMeta) => {
                    const name =
                        value && value.length > 20 ? value.substr(0, 20) + "..." : value;
                    return (
                        <div
                            onMouseEnter={() => {
                                setCopy(true);
                                setRowindex(tableMeta.rowIndex)
                            }}
                            onMouseLeave={() => setRowindex()}
                            style={{ display: 'flex', alignItems: 'center', width: "12rem" }}
                        >
                            <Typography
                                variant="body2"
                                component={LinkBtn}
                                noWrap

                                to={`/app/user-details/${tableMeta.rowData[0]}`}
                                color="primary"
                                style={{ textTransform: "capitalize", maxWidth: 300 }}
                            >
                                {name}
                            </Typography>
                            {rowindex === tableMeta.rowIndex && (
                                <Tooltip style={{ cursor: 'copy' }} title={copy ? "Copied" : "Click to Copy"}>
                                    <CopytoClipboard userBasicInfo={value} state={copy} />
                                </Tooltip>
                            )}
                        </div>
                    );
                },
            },
        },
        {
            name: "verified_status",
            label: "Status",
            options: {
                hint: "Verified status",
                filter: false,
                hide: true,
                sort:false,
                customBodyRender: (value,tableMeta) => {
                    return (
                        <Stack style={{cursor:'pointer'}} onClick={()=> role !== 'ADMIN_VIEWER' ? handleStatusModal(value,tableMeta) : null}>
                        <Tooltip title={value===3 ? tableMeta.rowData[15]:''} >
                            <Chip  sx={{background:value===2 ? "#ffaf34" : value===1 ? "#314B8F" : '#f96464',color:'#fff', fontWeight:'bold'}} size="small" label={value ===1 ?'Verified' :value ===2 ?'Pending': 'Rejected'}/>
                        </Tooltip>
                        </Stack>
                    )
                }
            },
        },
        {
            name: "is_beneficiary_added",
            label: "Beneficiary Status",
            options: {
                hint: "Permanent Account Number",
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() =>role !== 'ADMIN_VIEWER' ? handleBeneficiary(true, tableMeta.rowData[0], value, tableMeta.rowData[3]) : null}>
                            <Typography variant="span" style={{ padding: '4px' }}>{value ? "Yes" : "No"}</Typography>
                            {value ? <CheckCircleOutlineIcon style={{ color: 'green' }} /> : <CancelIcon color={'error'} />}
                        </div>
                    )
                }

            }
        },
        {
            name: "user_type",
            label: "Type",
            options: {
                hint: "User Type",
                filter: false,
                sort: true,
            },
        },



        {
            name: "email",
            label: "Email",
            options: {
                hint: "User Email ID",
                filter: false,
                sort: false,
                customBodyRender: (value) => {

                    return (
                        <div
                            onClick={() => onHover(value)}
                            onMouseLeave={onLeave}
                        // className={classes.tableEllips}
                        >
                            <Tooltip className={classes.tooltip} title={copy ? "Copied" : "Click to Copy"}>
                                <Typography
                                    variant="body2"
                                    noWrap
                                    style={{ maxWidth: 300 }}
                                >
                                    {value}
                                </Typography>
                            </Tooltip>
                        </div>
                    );
                },
            },
        },

        {
            name: "contact_no",
            label: "Phone",
            options: {
                hint: "User Contact Number",
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {

                    return (
                        <div
                            onClick={() => {
                                onHover(value)
                            }} onMouseLeave={onLeave}
                        // className={classes.tableEllips}
                        >
                            <Tooltip className={classes.tooltip} title={copy ? "Copied" : "Click to Copy"}>
                                <Typography
                                    variant="body2"
                                    noWrap
                                    style={{ textTransform: "capitalize", maxWidth: 300 }}
                                >
                                    {value}
                                </Typography>
                            </Tooltip>
                        </div>
                    );
                },
            },
        },

        {
            name: "ulk_kyc_status",
            label: "KYC Status",
            options: {
                sort: true,
                customBodyRender: value => {
                    return value === "Approved" ? "Completed" : value;
                },
                filter: true,
                filterType: "custom",
                filterList: [],
                customFilterListOptions: {
                    render: value => {
                        return value;
                    },
                    update: (filterList, filterPos, index) => {
                        if (filterPos === 0) {
                            filterList[index].splice(filterPos, 1, "");
                        } else if (filterPos === 1) {
                            filterList[index].splice(filterPos, 1);
                        } else if (filterPos === -1) {
                            filterList[index] = [];
                        }
                        return filterList;
                    }
                },
                filterOptions: {
                    names: [],
                    logic(value, filters) {
                        return true;
                    },
                    display: (filterList, onChange, index, column) => (
                        <div>
                            <Select onChange={onFilter} >
                                {dataForFilter.map((x) => (
                                    <MenuItem key={x} value={x}>
                                        {x}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    )
                }
            }
        },
        {
            name: "pan",
            label: "PAN",
            options: {
                hint: "Permanent Account Number",
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {

                    return (
                        <div
                            onClick={() => {
                                onHover(value ? value.toUpperCase() : value)
                            }} onMouseLeave={onLeave}
                        // className={classes.tableEllips}
                        >
                            <Tooltip className={classes.tooltip} title={copy ? "Copied" : "Click to Copy"}>
                                <Typography
                                    variant="body2"
                                    noWrap
                                    style={{ textTransform: "capitalize", maxWidth: 300 }}
                                >
                                    {value ? value.toUpperCase() : value}
                                </Typography>
                            </Tooltip>
                        </div>
                    );
                },

            },
        },
        // {
        //     name: "user_role",
        //     label: "Roles",
        //     options: {
        //         hint: "User Roles",
        //         filter: false,
        //         sort: false,
        //         customSort: (data, colIndex, order) => {
        //             return data.sort((a, b) => {
        //                 a = a.data[colIndex] || "";
        //                 b = b.data[colIndex] || "";
        //                 if (order === "asc") {
        //                     return a
        //                         .toString()
        //                         .localeCompare(b, undefined, { numeric: true });
        //                 } else if (order === "desc") {
        //                     return b
        //                         .toString()
        //                         .localeCompare(a, undefined, { numeric: true });
        //                 }
        //             });
        //         },

        //         customBodyRender: (value) => {
        //             const resValueRole = value.map((value, i) => {
        //                 return (
        //                     <Chip
        //                         className={classes.chipMargin}
        //                         label={value}
        //                         variant="outlined"
        //                     />
        //                 );
        //             });
        //             return resValueRole;
        //         },
        //     },
        // },
        {
            name: "total_order_count",
            label: "Orders",
            options: {
                hint: "Orders history",
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    const count = Number(value)
                    return (
                        <span className={classes.editdeletbtn} style={{ justifyContent: 'center' }}>
                            {count > 0 && <IconButton
                                onClick={() => goToTransactionHistory(tableMeta.rowData[0])}
                                className={classes.iconbtn}
                            >
                                <img src={orderIcon} width={30} alt="" />
                            </IconButton>}
                        </span>
                    );
                },
            },
        },
        {
            name: "last_updated",
            label: "Last Updated",
            options: {
                filter: false,
                sort: false,
                hide: false,
                display: true,
                customBodyRender: (value) => {
                    let valueMoment = moment(value).format("DD-MM-YYYY LTS");
                    return (
                        <div>
                            <Typography variant="body2" noWrap>
                                {valueMoment}
                            </Typography>
                        </div>
                    );
                },
            },
        },
        {
            name: "tags",
            label: "Tags",
            options: {
                // hint: "User Tags",
                filter: false,
                sort: false,
                customSort: (data, colIndex, order) => {
                    // eslint-disable-next-line array-callback-return
                    return data.sort((a, b) => {
                        a = a.data[colIndex] || "";
                        b = b.data[colIndex] || "";
                        if (order === "asc") {
                            return a
                                .toString()
                                .localeCompare(b, undefined, { numeric: true });
                        } else if (order === "desc") {
                            return b
                                .toString()
                                .localeCompare(a, undefined, { numeric: true });
                        }
                    });
                },

                customBodyRender: (value, tableMeta) => {
                    const resValueRole = (() => {
                        return (
                            <div
                                style={{ display: 'flex' }}>
                                {
                                    (value && value[0] !== null) ?
                                        <>
                                            <Tooltip
                                                title={value.toString()}
                                            >
                                                <Chip
                                                    style={{ width: '15ch', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipse' }}
                                                    className={classes.chipMargin}
                                                    label={value && value.toString()}
                                                    variant="outlined"
                                                />
                                            </Tooltip>
                                            <Tooltip
                                                title="Edit tags"
                                            >
                                                <IconButton
                                                    style={{ marginTop: '-0.5rem' }}
                                                    className={classes.iconbtn}
                                                    disabled={role === 'ADMIN_VIEWER'}
                                                    onClick={() => {
                                                        setOpen(true)
                                                        setTags(value ? value : [])
                                                        setUserId(tableMeta.rowData[0])
                                                        setUserName(tableMeta.rowData[3])
                                                    }}>
                                                    <AddCircleOutlineIcon fontSize="sm" />
                                                </IconButton>
                                            </Tooltip></>
                                        :
                                        //  <div style={{width:'7ch'}}></div>
                                        <Chip
                                            style={{ width: '15ch', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipse', background: '#314B8F', color: '#fff' }}
                                            className={classes.chipMargin}
                                            label={"Add tags"}
                                            disabled={role === 'ADMIN_VIEWER'}
                                            variant="outlined"
                                            onClick={() => {
                                                setOpen(true)
                                                setTags((value && value[0] !== null) ? value : [])
                                                setUserId(tableMeta.rowData[0])
                                                setUserName(tableMeta.rowData[3])
                                            }}
                                        />
                                }

                            </div>

                        );
                    });
                    return resValueRole;
                },
            },
        },
        {
            name: "",
            label: "Action",
            options: {
                filter: false,
                sort: false,

                customBodyRender: (value, tableMeta) => {
                    return (
                        role ==='ADMIN_VIEWER' ? null :<span className={classes.editdeletbtn}>
                            <IconButton
                                onClick={() => handleEditTableRow(tableMeta.rowData[0], tableMeta.rowData[3], tableMeta.rowData[14])}
                                className={classes.iconbtn}
                            >
                                <EditIcon />
                            </IconButton>
                            <IconButton style={{ marginLeft: '8px' }}
                                className={classes.iconbtn}
                                onClick={() => {
                                    props.handleRowDelete(tableMeta.rowData[0])

                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </span>
                    );
                },
            },
        },
        {
            name: "request_id",
            label: "KYC Request Id",
            options: {
                filter: false,
                sort: false,
                hide: true,
                display: false,
            },
        },
        {
            name: "rejected_reason",
            label: "Rejected Reason",
            options: {
                filter: false,
                sort: false,
                hide: true,
                display: false,
            },
        },
    ]


    const handleBeneficiaryStatus = ((values, dematdata) => {
        const dematId = dematdata.map((item) => {
            return (
                {
                    status: item.is_beneficiary_mapped_demat,
                    dematId: item.id
                }
            )
        })
        const isStatus = dematId.some(item => item.status === true)
        const data = {
            userID
                : values.id,
            beneficiaryStatus: isStatus,
            demat: dematId
        }
        userService.updateBeneficiaryStatus(data).then((res) => {
            if (res.status === 200 && res.data.message === 'Successfully updated') {
                getData('', 0)
                setBeneficiaryDialog(status)
                setOpenSnackBar(true);
                setSeverity("success");
                setSnackbarTitle("Successfully updated beneficiary status");
            } else if (
                res.status === 401 &&
                res.data.name === "TokenExpiredError"
            ) {
                setOpenSessionExpiredModal(true);
            } else {
                setBeneficiaryDialog(status)
                setSeverity("error");
                setSnackbarTitle("Something went wrong !");
                setOpenSnackBar(true);
            }

        })
    })

    const goToTransactionHistory = (id) => {
        navigate(`/app/client-service-orders/${id}`);
    };

    const handleStatus=((value)=>{
        const data={
            ...value,id:statusModal.id,status:value.status ==='Verified' ? 1:value.status ==='Pending' ? 2 : 3
          }
          userService.updateVerifiedStatus(data).then((res)=>{
            if (res.status === 200) {
              setSeverity("success");
              setSnackbarTitle("Updated successfully");
              setOpenSnackBar(true);
              setStatusModal({
                open:false,
                status:'',
                reason:''
              })
              getData('', 0)
            } else {
              if (res.status === 401 && res.data.name === "TokenExpiredError") {
                setOpenSessionExpiredModal(true);
              } else {
                setSeverity("error");
                setSnackbarTitle("Failed to update");
                setOpenSnackBar(true);
              }
            }
          })
    })


    const handleEditTableRow = (id, name, requestId) => {
        navigate("/app/user-create?mode=edit", {
            replace: true,
            state: { userID: id, userName: name, requestId: requestId },
        });
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackBar(false);
    };

    const submitHandle = (async (values) => {
        setCustomFilter(false)
        setsdata([])
        setcount(0)
        setAdvanceFilter({
            kycStatus: values.kycStatus.id,
            userType: values.userType.id,
            rmName: values.rmName.id
        })
        setsisLoading(true)
        setPage(0)
        const res = await xhrRequest(``, 0, '', values.kycStatus.id, 10, values.userType.id, values.rmName.id)
        setsisLoading(false)
        setsdata(res.data)
        setcount(res.total)
    })

    // const downloadUsers = () => {
    //     const data = date
    //     userService.downloadUsers(data).then((res) => {
    //         onClose()
    //         if (res.status === 200) {
    //             //   getData('', 0)
    //             setOpenSnackBar(true);
    //             setSeverity("success");
    //             setSnackbarTitle("Export CSV initiated successfully ");
    //         } else if (
    //             res.status === 401 &&
    //             res.data.name === "TokenExpiredError"
    //         ) {
    //             setOpenSessionExpiredModal(true);
    //         } else {
    //             setSeverity("error");
    //             setSnackbarTitle("Something went wrong !");
    //             setOpenSnackBar(true);
    //         }
    //     })
    // }

    // const onClose = () => {
    //     // setDownload(false)
    //     // setValue('')
    //     setDate({
    //         fromDate: '',
    //         toDate: ''
    //     })
    // }
    // const handleDelete = (i) => {
    //     setTags((tag) =>
    //         tag.filter((name, index) => index !== i)
    //     );
    // }

    // const handleAddTag = (e) => {
    //     if (e.key === "Enter" && e.target.value.trim() !== "") {
    //         e.preventDefault();
    //         tags.push(e.target.value);
    //         setTags([...tags], e.target.value);
    //         e.target.value = "";
    //     }
    // }

    // const updateUserTag = () => {
    //     const data = {
    //         userid: userId,
    //         tagsData: tags
    //     }
    //     userService.updateUserTag(data).then((res) => {
    //         if (res.status === 200) {
    //             getData('', 0)
    //             setOpenSnackBar(true);
    //             setSeverity("success");
    //             setSnackbarTitle("Successfully updated user tags successfully");
    //         } else if (
    //             res.status === 401 &&
    //             res.data.name === "TokenExpiredError"
    //         ) {
    //             setOpenSessionExpiredModal(true);
    //         } else {
    //             setSeverity("error");
    //             setSnackbarTitle("Something went wrong !");
    //             setOpenSnackBar(true);
    //         }
    //     })
    // }



    useEffect(() => {
        getData('', 0)
    }, [])


    const getData = async (url, page) => {
        setsisLoading(true)
        setsdata([])
        const res = await xhrRequest(url, page);
        setsdata(res.data)
        setcount(res.total)
        setsisLoading(false)
    };
    const onFilter = async (event) => {
        setSelectedFilter(event.target.value)
        setsisLoading(true)
        if (event.target.value === 'All') {
            event.target.value = ''
        } else if (event.target.value === 'Completed') {
            event.target.value = 'Approved'
        } else if (event.target.value === 'In progress') {
            event.target.value = 'In Progress'
        }
        setsdata([])
        const res = await xhrRequest("", page, "", event.target.value)
        setsdata(res.data)
        setcount(res.total)
        setsisLoading(false)
    };

    // const sort = (page, sortOrder) => {
        // this.setState({ isLoading: true });
        // this.xhrRequest('', page, sortOrder).then(res => {
        //   this.setState({
        //     data: res.data,
        //     page: res.page,
        //     sortOrder,
        //     isLoading: false,
        //     count: res.total,
        //   });
        // });
    // };

    // mock async function
    const xhrRequest = async (url, page, searchText = "", filterText = '', rowsPerPage = 10, userType = 0, rmId = "") => {
        // mock page data
        let filterValue = ''
        if (filterText !== '') {
            filterValue = filterText
        } else {
            filterValue = selectedFilter
        }
        let searchingValue = ''
        if (searchText !== '') {
            searchingValue = searchText
        } else {
            searchingValue = searchAbleText
        }
        let fullData = await userService.getUsers(rowsPerPage, page, searchingValue == null ? "" : searchingValue, filterValue, userType, rmId)
        if (fullData?.data?.length > 0) {
            const total = parseInt(fullData.data[0].total_count)
            let data = fullData.data;
            return {
                data,
                total,
                page,
            };
        } else {
            return {
                data: [],
                total: 0,
                page: 0
            };
        }

    };

    // const changePage = async (page, sortOrder, tableState) => {
    //     setsisLoading(true)
    //     setsdata([])
    //     const res = await xhrRequest("", page);

    //     setsdata(res.data)
    //     setcount(res.total)
    //     setsisLoading(false)

    // };

    const [customFilter, setCustomFilter] = useState(false)
    // const search = (page, sortOrder, searchText) => {
    //     setSearchText(searchText)
    //     setsisLoading(true)
    //     setsdata([])
    //     xhrRequest(``, page, searchText).then(res => {
    //         setsdata(res.data)
    //         setsisLoading(false)
    //         setsortOrder(sortOrder)
    //         setPage(page)
    //         setcount(res.total)


    //     });
    // };

    const filterChange = (page, sortOrder, searchText, rowsPerPages = 10) => {
        setsisLoading(true)
        setsdata([])
        setPage(0)
        xhrRequest(``, page, searchText, advanceFilter ? advanceFilter.kycStatus : '', rowsPerPages, advanceFilter.userType, advanceFilter.rmName).then(res => {
            setsdata(res.data)
            setsisLoading(false)
            setsortOrder(sortOrder)
            setPage(page)
            setcount(res.total)
        });
    };

    const handleDownload =async (data) => {
        const userData = data.map((item) => {
          return {
            "User Id": item.id,
            "Empanelment ID": item.user_unique_code,
            "Name": item.name,
            "Beneficiary Status": item.is_beneficiary_added ?"Added" : "Not Added",
            "Type": item.user_type,
            "Email": item.email,
            "Phone": item.contact_no,
            "KYC Status": item.ulk_kyc_status,
            "PAN": item.pan,
            "Tags":item.tags
          };
        });
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Users');
    
        // Add the header row
        worksheet.columns = Object.keys(userData[0]).map((key) => ({
          header: key,
          key: key,
          width: 25
        }));
    
        // Add the data rows
        userData.forEach((user) => {
          worksheet.addRow(user);
        });
        worksheet.getRow(1).font = { bold: true };
        worksheet.getRow(1).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'd5d5d5' }
        };
        worksheet.getRow(1).border = {
          top: { style: 'thin' },
          bottom: { style: 'thin' },
          left: { style: 'thin' },
          right: { style: 'thin' }
        };
    
        // Apply border styles to all cells
        worksheet.eachRow({ includeEmpty: true }, (row) => {
          row.eachCell({ includeEmpty: true }, (cell) => {
            cell.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
          });
        });
    
        // Generate buffer and download the file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `Users.xlsx`;
        link.click();
      };

    const options = {
        filter: true,
        downloadOptions: { filename: 'Users.csv',
        filterOptions:{
            useDisplayedColumnsOnly:true
        }},
        onDownload:(()=>{
            if(advanceFilter || searchAbleText){
                setsisLoading(true)
                xhrRequest(``, page, searchAbleText, advanceFilter ? advanceFilter.kycStatus : '', 'all', advanceFilter.userType, advanceFilter.rmName).then(res => {
                    handleDownload(res.data)
                });
                setsisLoading(false)
            }else{
                handleDownload(data)
            }
            return false
        }),
        download: role === 'ADMIN_VIEWER' ? false : true,
        viewColumns: false,
        print: false,
        selectableRows: "none",
        filterType: 'dropdown',
        responsive: 'vertical',
        serverSide: true,
        count: count,
        textLabels: {
            body: {
                noMatch: isLoading ?
                    'Loading...' : 'No data found'
            },
        },
        onFilterDialogOpen: (() => {
            console.log('hi')
        }),
        customToolbar: (() => {
            return (
                <span style={{ display: 'inline-flex' }}>
                    <Button disableRipple style={{ textTransform: 'none', textDecoration: 'underline', marginRight: '10px' }} color='primary' onClick={() => setCustomFilter(!customFilter)}>  Advanced Search</Button>
                    <Button disableRipple style={{ textTransform: 'none', textDecoration: 'underline' }} color='primary' onClick={() => {
                        setAdvanceFilter(false)
                        getData('', 0)
                    }} disabled={!advanceFilter}> Reset</Button>

                </span>
            )
        }),
        toolbar: {
            search: "Search",
            downloadCsv: "Download CSV",
            print: "Print",
            filterTable: "Advanced Filter",
        },
        confirmFilters: true,

        sortOrder: sortOrder,
        rowsPerPage: perPageRow,
        rowsPerPageOptions: [10, 25, 50, 100],
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    // setrowsPerPage(tableState.rowsPerPage)
                    // changePage(tableState.page, tableState.sortOrder,tableState.searchText,tableState.rowsPerPage);
                    setrowsPerPage(tableState.rowsPerPage)
                    filterChange(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    break;
                case 'sort':
                    // setrowsPerPage(tableState.rowsPerPage)
                    // sort(tableState.page, tableState.sortOrder,tableState.searchText,tableState.rowsPerPage);
                    setrowsPerPage(tableState.rowsPerPage)
                    filterChange(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    break;
                case 'search':
                    // setrowsPerPage(tableState.rowsPerPage)
                    // search(tableState.page, tableState.sortOrder,tableState.searchText,tableState.rowsPerPage);
                    setrowsPerPage(tableState.rowsPerPage)
                    setSearchText(tableState.searchText)
                    clearTimeout(timer)
                    const newTimer = setTimeout(() => {
                        setsdata([])
                        filterChange(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    }, 500);
                    setTimer(newTimer)
                    break;
                case 'filterChange':
                    setrowsPerPage(tableState.rowsPerPage)
                    filterChange(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    break;
                case 'changeRowsPerPage':
                    setrowsPerPage(tableState.rowsPerPage)
                    filterChange(tableState.page, tableState.sortOrder, tableState.searchText, tableState.rowsPerPage);
                    break;
                default:
                    break;
            }
        },
    };

    const theme = GetMuiTheme
    return (
        <Root>
                <NormalCard title={<Typography style={{fontWeight: 500, fontSize: 14 }}>
                                Users
                                {isLoading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
                            </Typography>}>
            <ThemeProvider theme={theme}>
                    <MUIDataTable

                        data={data}
                        columns={columns}
                        options={options}
                    />
            </ThemeProvider>
                </NormalCard>
            {/* <Dialog
                maxWidth="xs"

                aria-labelledby="confirmation-dialog-title"
                open={open}
            >
                <DialogTitle id="confirmation-dialog-title">Edit tags</DialogTitle>
                <DialogContent dividers>
                    <TextField
                        fullWidth
                        helperText="Press Enter to add tags"
                        label="Add tags"
                        onKeyPress={handleAddTag}
                    />
                    <div
                        className={classes.wrap}
                    >
                        {tags && tags.map((item, index) => (
                            <Chip
                                style={{ margin: '4px' }}
                                key={index}
                                tabIndex={-1}
                                label={item}
                                className={classes.chip}
                                onDelete={() => handleDelete(index)}
                            />
                        ))}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setOpen(false)
                    }} color="primary">
                        Cancel
                    </Button>
                    <Button
                        // disabled={tags.length === 0}
                        onClick={() => {
                            updateUserTag()
                            setOpen(false)
                        }} color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog> */}


            <TagModal getData={getData} open={open} setOpen={setOpen} userId={userId} userName={userName} tagName={tagName} setTagName={setTagName} tags={tags} setTags={setTags} setSnackbarTitle={setSnackbarTitle} setOpenSessionExpiredModal={setOpenSessionExpiredModal} setSeverity={setSeverity} setOpenSnackBar={setOpenSnackBar} />
            {/* <DownloadModal  downloadUsers ={downloadUsers } onClose={onClose} value={value} setValue={setValue} download={download} setDownload={setDownload} date={date}  setDate={setDate} initialDate={initialDate}/> */}
            <Dialog maxWidth="sm" open={customFilter} onClose={() => setCustomFilter(false)}>
                <DialogContent>

                    <Button style={{ margin: '16px', padding: '5px', height: '2rem', textTransform: 'none' }} variant="contained" color="primary">Search</Button>
                </DialogContent>

            </Dialog>
            <ModalCentered
                title={"Advance Search"}
                open={customFilter}
                toggle={() => setCustomFilter(false)}
                children={
                    <AdvanceSearchForm
                        formSubmit={submitHandle}
                    />
                }
            />
            <ModalCentered
                title={"Update Beneficiary Status"}
                open={beneficiaryDialog.open}
                toggle={() => handleBeneficiary(false, '', '', '')}
                children={
                    <BeneficiaryForm
                        role={role}
                        state={beneficiaryDialog}
                        formSubmit={handleBeneficiaryStatus}
                    />
                }
            />
             <ModalCentered
                title={"Update verified status"}
                open={statusModal.open}
                toggle={() => setStatusModal({
                  open:false,
                  status:'',
                  reason:''
                })}
                children={
                    <UpdateStatus
                    data={statusModal}
                        formSubmit={handleStatus}
                    />
                }
            />
            <SnackBar
                open={openSnackBar}
                severity={severity}
                close={handleClose}
                snackbarTitle={snackbarTitle}
            />

            <SessionExpiredModal open={openSessionExpiredModal} />
        </Root>
    );
}


export default DatatableView;
