import React, { useEffect, useState, useLayoutEffect,useRef } from "react";
import { styled } from "@mui/material/styles";
import { Button, Checkbox, Grid, FormControlLabel,Typography } from "@mui/material";

import * as Yup from "yup";
import { Formik } from "formik";
import ReactSpinner from "src/components/ReactSpinner/ReactSpinner";
import Page from "src/components/Page";
import NormalCard from "src/components/Cards/NormalCard";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import { securityService } from "src/_services/security";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import Dark from "@amcharts/amcharts5/themes/Dark"
import Responsive from "@amcharts/amcharts5/themes/Responsive"
import * as am5stock from "@amcharts/amcharts5/stock";
import { useScreenshot, createFileName } from "use-react-screenshot";
import GetAppIcon from '@mui/icons-material/GetApp';

const PREFIX = "PriceTrend";

const classes = {
  root: `${PREFIX}-root`,
  gridPadding: `${PREFIX}-gridPadding`,
  reactSpinner: `${PREFIX}-reactSpinner`,
  textfield: `${PREFIX}-textfield`,
  table: `${PREFIX}-table`,
  box: `${PREFIX}-box`,
  text: `${PREFIX}-text`,
  value: `${PREFIX}-value`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    padding: "0px",
    margin: "0px",
  },

  [`& .${classes.gridPadding}`]: {
    padding: "10px",
    alignItems: "center",
    display: "grid",
  },

  [`& .${classes.reactSpinner}`]: {
    position: "absolute",
    height: "100%",
    width: "100%",
    // background: "#f4f6f870",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  [`& .${classes.textfield}`]: {
    "& .MuiInputBase-input": {
      position: "relative",
      width: "100%",
      color: " black !important",
      fontStyle: "normal  !important",
      fontWeight: "500 !important",
      fontSize: "16px  !important",
      lineHeight: "24px  !important",
    },
  },

  [`& .${classes.table}`]: {
    width: 450,
  },
  [`& .${classes.box}`]: {
    display:"flex !important",
    // background:"#19132C !important",
    justifyContent:'end !important',
     padding:"4px 12px !important",
     borderRadius:"10px !important",
     alignItems:"center !important"
  },
  [`& .${classes.text}`]: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "500  !important",
    fontSize: "14px  !important",
    color: "#FFF !important",
    lineHeight: "15px !important",
    margin:"0px 2px !important"
  },
  [`& .${classes.value}`]: {
    fontFamily: "Poppins  !important",
    fontStyle: "normal  !important",
    fontWeight: "600  !important",
    fontSize: "16px  !important",
    color: "#fff !important",
    lineHeight: "15px !important",
    margin:"0px 2px !important"
  }

}));

const monthNames = [
  { month: "Janauary", monthNo: 1 },
  { month: "February", monthNo: 2 },
  { month: "March", monthNo: 3 },
  { month: "April", monthNo: 4 },
  { month: "May", monthNo: 5 },
  { month: "June", monthNo: 6 },
  { month: "July", monthNo: 7 },
  { month: "August", monthNo: 8 },
  { month: "September", monthNo: 9 },
  { month: "October", monthNo: 10 },
  { month: "November", monthNo: 11 },
  { month: "December", monthNo: 12 },
];

const date = moment(new Date()).format("L");
var date1 = new Date();

let years = [];
for (let i = 0; i <= 4; i++) {
  let year = (date1.getFullYear() - i).toString();
  years.push(year);
}
// const years = [
//     "2020", "2021", "2022"
// ];
const dates = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
];
const PriceTrend = () => {
  const location = useLocation();

  const { id } = useParams();
  const [spinner, setSpinner] = useState(false);
  const [edit, setEdit] = useState(false);
  const [placeholderDate, setPlaceholderDate] = useState();
  const [placeholderMonth, setPlaceholderMonth] = useState();
  const [placeholderYear, setPlaceholderYear] = useState();
  const [priceTrendData, setPriceTrendData] = useState([]);
  const [trendData, setTrendData] = useState(false);
  const [editId, setEditId] = useState();
  const [approve, setApprove] = useState(false);
  const [create_datetime, setCreateDatetime] = useState();
  const currentDay = new Date();
  const [value,setValue] = React.useState()

  const currentYear = currentDay.toLocaleString("default", { year: "numeric" });
  const currentMonth = currentDay.toLocaleString("default", {
    month: "numeric",
  });
  const currentDate = currentDay.toLocaleString("default", { day: "numeric" });
  const currentMonthInName = currentDay.toLocaleString("default", {
    month: "long",
  });
  const navigate = useNavigate();
  const initialData ={
    year: edit === true ? priceTrendData.year : currentYear,
    month: edit === true ? priceTrendData.month : currentMonth,
    date: edit === true ? priceTrendData.date : currentDate,
    price: edit === true ? priceTrendData.price : "",
    related_security_id: id ? id : "",
    id: edit === true ? editId : "",
    pricetrend: edit === false ? true : false,
    status: edit === true ? approve : "",
  };
   // eslint-disable-next-line
  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0
  });

  useEffect(() => {
    const values = {
      year: currentYear,
      month: {
        monthNo: currentMonth,
      },
      date: currentDate,
      related_security_id: initialData.related_security_id,
    };
    getPriceTrend(values);
  }, []);

  const getPriceTrend = (values) => {
    setPlaceholderDate(values.date);
    setPlaceholderMonth(values.month.monthNo || values.month);
    setPlaceholderYear(values.year);
    setSpinner(true);

    if (
      values.month.monthNo > currentMonth ||
      values.month.monthNo > currentMonth
    ) {
      if (values.year >= currentYear) {
        setTrendData(true);
      } else {
        setSpinner(true);
        securityService.getPriceTrendData(values).then((res) => {
          setSpinner(false);
          setTrendData(false);
          setPriceTrendData(res.data.data);
        });
      }
    } else if (values.year <= currentYear && values.date <= currentDate) {
      if (
        values.month <= currentMonth ||
        values.month.monthNo <= currentMonth
      ) {
        securityService.getPriceTrendData(values).then((res) => {
          setSpinner(false);
          setTrendData(false);
          setPriceTrendData(res.data.data);
        });
      }
    }
  };
  const handleOnSubmit = (values) => {
    if (edit === true) {
      securityService
        .updatePriceOnPriceTrend(values, editId, approve, create_datetime)
        .then((res) => {
          setEdit(false);
          getPriceTrend(values);
        })
        .catch((error) => console.log(error));
    } else if (edit === false) {
      getPriceTrend(values);
    }
  };
  const ref = useRef();
  const chart = useRef();

  const downloadScreenshot = () => takeScreenShot(chart.current).then(download);
  const download = (image, { name = "img", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  useLayoutEffect(() => {

    // amchart licence code
    am5.addLicense("AM5S371141295")
    let root = am5.Root.new("chartdiv",{
      useSafeResolution: false
    });

    // show current day as start of graph
    const today =  new Date().getDay()
    root.locale.firstDayOfWeek = today;
    root.setThemes([
      am5themes_Animated.new(root),
      Dark.new(root),
      Responsive.new(root)
    ]);
    let stockChart = root.container.children.push(am5stock.StockChart.new(root, { }))
    root.numberFormatter.set("numberFormat", "#,###.00");
    let mainPanel = stockChart.panels.push(am5stock.StockPanel.new(root, {
      wheelY: "zoomX",
      panX: false,
      panY: false,
    }));
    let valueAxis = mainPanel.yAxes.push(am5xy.ValueAxis.new(root, {
      maxPrecision: 0,
      renderer: am5xy.AxisRendererY.new(root, {
        pan: "zoom",
        opposite: false,
        minGridDistance: 25
      }),
      extraMin: 0.1,
      // tooltip: am5.Tooltip.new(root, {}),
      numberFormat: "[bold fontFamily: Roboto fontWeight:600 fontSize:10px #767677]#,###",
      extraTooltipPrecision: 1,
      forceHidden: false,
      
    }));
    root.utc = true;
    root.dateFormatter.set("dateFormat", "dd MMM yy").toUpperCase();
    let dateAxis = mainPanel.xAxes.push(am5xy.DateAxis.new(root, {
      groupData: true,
      groupCount:130,
      baseInterval: {
        timeUnit: "day",
        count: 1,
      },
        renderer: am5xy.AxisRendererX.new(root, {
        minGridDistance: 25
      }),
      startLocation:0,
      endLocation:0,
      // tooltip: am5.Tooltip.new(root, {}),
      forceHidden: true
    }));

    // tooltip text which displayed on graph
    var tooltip = am5.Tooltip.new(root, {
      pointerOrientation: "vertical",
      labelText:"[bold fontFamily: Roboto fontWeight:500]₹{valueY}[/] [#767677 bold]| [/][ fontFamily: Poppins fontWeight:400 ]{valueX.formatDate(MMM dt, yyyy)}[/]"
    })

    let valueSeries = mainPanel.series.push(am5xy.LineSeries.new(root, {
      valueXField: "Date",
      valueYField: "Close",
      valueYGrouped: "average",
      calculateAggregates: true,
      xAxis: dateAxis,
      yAxis: valueAxis,
      stroke: am5.color('#AC93FF'),
      fill: am5.color('#0D0D0D'),
      color: am5.color(0xf3f3f3),
      tooltip: tooltip,
    }));
    valueSeries.strokes.template.set("strokeWidth", 1.5);
    let valueLegend = mainPanel.plotContainer.children.push(am5stock.StockLegend.new(root, {
      stockChart: stockChart,
    }));
    let volumeAxisRenderer = am5xy.AxisRendererY.new(root, {
      inside: true,
      opposite: false
    });
    let volumeValueAxis = mainPanel.yAxes.push(am5xy.ValueAxis.new(root, {
      numberFormat: "#.#a",
      forceHidden: true,
      extraTooltipPrecision: 0.1,
      height: am5.percent(0),
      y: am5.percent(100),
      centerY: am5.percent(100),
      renderer: volumeAxisRenderer,
      opposite: false,
    }));
    let volumeSeries = mainPanel.series.push(am5xy.ColumnSeries.new(root, {
      name: "Volume",
      xAxis:volumeValueAxis,
      yAxis:  dateAxis,
      valueYField: "Volume",
      valueXField: "Date",
      tooltip: am5.Tooltip.new(root, {}),
      clustered: true
    }));
    mainPanel.appear(100, 500);
    volumeSeries.columns.template.adapters.add("fill", function (fill, target) {
      let dataItem = target.dataItem;
      if (dataItem) {
        return stockChart.getVolumeColor(dataItem);
      }
      return fill;
    })
    valueLegend.data.setAll([]);
    mainPanel.set("cursor", am5xy.XYCursor.new(root, {
      yAxis: valueAxis,
      xAxis: dateAxis,
      color: am5.color(0xff0000)
    }));
    let cursor = mainPanel.get("cursor");

    // cursor line styles
    cursor.lineX.setAll({
      stroke: am5.color(0x767677),
      strokeWidth: 1,
      strokeDasharray: []
    });
    cursor.lineY.setAll({
      visible: false
    });
    mainPanel.panelControls.set("forceHidden");
    cursor.lineX.setAll({
      visible: true
    });

    valueSeries.events.on("datavalidated", function() {
      setTimeout(()=>{
        mainPanel.get("cursor").setAll({
          positionX: 0.5,
          positionY: 0.5,
          alwaysShow: true
        });
      }, 1000)
    });
    
    mainPanel.plotContainer.events.on("pointerover", ()=>{
      mainPanel.get("cursor").setAll({
        positionX: undefined,
        positionY: undefined,
      });    
    })
    mainPanel.plotContainer.events.on("pointerout", ()=>{
      mainPanel.get("cursor").setAll({
        alwaysShow: false
      });    
    })
 
// get scrip  price trend data
    function loadData(ticker, series, granularity) {
      const data = {
        related_security_id:id
      }
      securityService.priceHistory(data).then((res) => {
          if (res.status === 200 && res.data.data.length >0) {
            setValue(res.data.minAndMAx[0])
          let data =res.data.data
            let processor = am5.DataProcessor.new(root, {
              dateFields: ["Date"],
              dateFormat: granularity === "minute" ? "MMM dt, yyyy" : "MMM dt, yyyy",
              numericFields: ["Close"]
            });
            processor.processMany(data);
            ref.current = data[0].Date;
            am5.array.each(series, function (item) {
              item.data.setAll(data);
            });
        }
      });
    }
    // Load initial data for the first series
    let currentGranularity = "day";
    loadData("MSFT", [valueSeries, volumeSeries], currentGranularity);
    var periodSelector = am5stock.PeriodSelector.new(root, {
      stockChart: stockChart,
      periods: [
        { timeUnit: "day", count: 8, name: "1W" },
        { timeUnit: "month", count: 1, name: "1M" },
        { timeUnit: "week", count: 27, name: "6M" },
        { timeUnit: "week", count: 53, name: "1Y" },
        { timeUnit: "week", count: 105, name: "2Y" },
      ],
    })
    valueSeries.events.on("datavalidated", function(err,data) {
        periodSelector.selectPeriod({ timeUnit: "week", count: 27, name: "6M" })
        let today = new Date()
        let array = []
        const month  = [0,0,1,7,13]
        month.forEach((i)=>{
            let oneMonth = moment(today).subtract(i, 'months').format("YYYY-MM-DD")
            array.push(Math.floor(new Date(oneMonth).getTime()) )
        })
        let divID = document.getElementsByClassName("am5stock-link")
        array.forEach((i,index)=>{
          if(ref.current > i) {
            divID[index].className += "   am5stock-disabledBtn" 
          } 
        })
    })

    // display list of deafult periods 
     am5stock.StockToolbar.new(root, {
      container: document.getElementById("chartdiv"),
      stockChart: stockChart,
      controls: [
        periodSelector
      ],
    })

    // circle on the point of line
    var tooltipBullet = mainPanel.plotContainer.children.push(am5.Circle.new(root, {
      radius: 5,
      fill: am5.color('#AC93FF'),
      stroke:am5.color('#FFF'),
      x: -1000
    }));
    
    valueSeries.on("tooltipDataItem", function(tooltipDataItem) {
      var x = -1000;
      var y = -1000;
      if (tooltipDataItem) {
        var point = tooltipDataItem.get("point");
        if (point) {
          x = point.x;
          y = point.y;
        }
      }
      tooltipBullet.setAll({
        x: x,
        y: y
      })
    });
    
    tooltip.on("opacity", function(opacity){
      tooltipBullet.set("opacity", opacity);
    });

    return () => {
      root.dispose();
    };
  }, [priceTrendData]);

  return (
    <Root>
      <Page className={classes.root} title="Price Trend">
        <Button
          style={{ marginBottom: "1rem" }}
          variant="contained"
          color="primary"
          onClick={() => {
            navigate("/app/security", {
              replace: true,
              state: location?.state?.tabvalue ? 1 : 3,
            });
          }}
          className={classes.button}
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
        <Formik
          enableReinitialize={true}
          initialValues={initialData}
          validationSchema={Yup.object().shape({
            date: Yup.string().when("pricetrend", {
              is: false,
              then: Yup.string().required("date is required"),
              otherwise: Yup.string(),
            }),

            price: Yup.string().when("pricetrend", {
              is: false,
              then: Yup.string().required("Price is required"),
              otherwise: Yup.string(),
            }),
          })}
          onSubmit={(values) => {
            handleOnSubmit(values);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            touched,
            values,
          }) => (
            <form
              onSubmit={handleSubmit}
              style={{ padding: "0px" }}
              autoComplete="off"
              noValidate
            >
              <NormalCard title="PriceTrend" style={{ padding: "0px" }}>
                <Grid
                  container
                  rowSpacing={1}
                //   columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid
                    item
                    xs={edit ? 2 : 4}
                    sm={edit ? 2 : 4}
                    md={edit ? 2 : 4}
                    lg={edit ? 2 : 4}
                    className={classes.gridPadding}
                  >
                    <Autocomplete
                      id="year"
                      options={years}
                      getOptionLabel={(option) => (option ? option : "")}
                      disabled={edit}
                      value={values.year}
                      onChange={(e, value) => {
                        setFieldValue("year", value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          placeholder={
                            edit === true ? priceTrendData.year : "Year"
                          }
                          className={classes.textfield}
                          name="year"
                          error={Boolean(touched.year && errors.year)}
                          helperText={touched.year && errors.year}
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={edit ? 2 : 4}
                    sm={edit ? 2 : 4}
                    md={edit ? 2 : 4}
                    lg={edit ? 2 : 4}
                    className={classes.gridPadding}
                  >
                    <Autocomplete
                      id="month"
                      options={monthNames}
                      getOptionLabel={(option) =>
                        option.month ? option.month : currentMonthInName
                      }
                      disabled={edit}
                      value={values.month}
                      onChange={(e, value) => {
                        setFieldValue("month", value);
                      }}
                      getOptionDisabled={(option) => {
                        if (values.year === "2020") {
                          return (
                            option === monthNames[0] ||
                            option === monthNames[1] ||
                            option === monthNames[2] ||
                            option === monthNames[3] ||
                            option === monthNames[4] ||
                            option === monthNames[5]
                          );
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          placeholder={
                            edit === true ? priceTrendData.month : "Month"
                          }
                          className={classes.textfield}
                          name="month"
                          error={Boolean(touched.month && errors.month)}
                          helperText={touched.month && errors.month}
                        />
                      )}
                    />
                  </Grid>

                  {edit ? (
                    <>
                      <Grid
                        item
                        xs={1}
                        sm={1}
                        md={1}
                        lg={1}
                        className={classes.gridPadding}
                      >
                        <Autocomplete
                          id="date"
                          options={dates}
                          getOptionLabel={(option) =>
                            option ? option : currentDate
                          }
                          value={values.date}
                          disabled={edit}
                          onChange={(e, value) => {
                            setFieldValue("date", value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              placeholder={
                                edit === true ? placeholderDate : "Date"
                              }
                              className={classes.textfield}
                              name="date"
                              error={Boolean(touched.date && errors.date)}
                              helperText={touched.date && errors.date}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        xs={2}
                        sm={2}
                        md={2}
                        lg={2}
                        className={classes.gridPadding}
                      >
                        <TextField
                          id="price"
                          placeholder={edit === true ? "price" : "price"}
                          autoFocus
                          className={classes.textfield}
                          name="price"
                          autoComplete="off"
                          fullWidth={true}
                          value={values.price}
                          onChange={handleChange}
                          error={Boolean(touched.price && errors.price)}
                          helperText={touched.price && errors.price}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={2}
                        sm={2}
                        md={2}
                        lg={2}
                        className={classes.gridPadding}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={approve}
                              onChange={() => {
                                if (approve === false) {
                                  setApprove(1);
                                } else {
                                  setApprove(0);
                                }
                              }}
                              name="status"
                              id="status"
                              aria-describedby="outlined-weight-helper-text"
                              onBlur={handleBlur}
                              color="primary"
                            />
                          }
                          label="Price Split"
                        />
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}
                  <Grid
                    item
                    xs={edit ? 2 : 4}
                    sm={edit ? 2 : 4}
                    md={edit ? 2 : 4}
                    lg={edit ? 2 : 4}
                    className={classes.gridPadding}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{ margin: "1rem 0" }}
                    >
                      {edit ? "Update Price Trend" : "Get Price Trend"}
                    </Button>
                  </Grid>
                  {edit && (
                    <Grid
                      item
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                      className={classes.gridPadding}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{ margin: "1rem 0" }}
                        onClick={() => setEdit(false)}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </NormalCard>
            </form>
          )}
        </Formik>

        <NormalCard title="Price Trend Table">
          {spinner && (
            <div className={classes.reactSpinner}>
              <ReactSpinner />
            </div>
          )}
          <TableContainer component={Paper} style={{ padding: "1rem"}}>
            <Grid container sx={{display:'flex', justifyContent:'space-between'}}>
              <Grid item xs={12} md={4} sx={{overflow:'scroll',height:'600px' ,scrollbarWidth:'thin'}}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {priceTrendData?.map((row) => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell>
                        {" "}
                        {!trendData
                          ? row.date + "/" + row.month + "/" + row.year
                          : placeholderDate +
                            "/" +
                            placeholderMonth +
                            "/" +
                            placeholderYear}
                      </TableCell>
                      <TableCell>{!trendData ? row.price : "-"}</TableCell>
                      <TableCell>
                        {date ===
                          moment(new Date(row.create_datetime)).format("L") && (
                          <EditIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setEdit(true);
                              setEditId(row.id);
                              setPlaceholderDate(row.date);
                              setCreateDatetime(
                                row.year + "-" + row.month + "-" + row.date
                              );
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
              </Grid>
              <Grid item xs={12} ref={chart} md={8} sx={{background:'black', height:'600px'}}>
              <div>

            {value &&
              <Grid style={{display:"flex", margin:'1rem 1rem 0',justifyContent:'end'}}>
              <div className={classes.box}>
                <Typography className={classes.text}>
                52 Weeks High
                </Typography>
                <Typography className={classes.value}> {" "}{value.max  ? value.max : "N/A"}</Typography>
                <Typography mx={1} style={{color:"#A489FF"}}>{" "}| {" "}</Typography>
                <Typography className={classes.text}>
                 52 Weeks Low
                </Typography>
                <Typography className={classes.value}> {" "}{value.min ? value.min : "N/A"}</Typography>
                 <GetAppIcon style={{ width:'20px', cursor: "pointer", color:'#fff', marginLeft:'1rem' }} onClick={downloadScreenshot} />
              </div>
            </Grid>}

      </div>
      <div id="chartdiv"  style={{ width: "100%", height: "500px", textAlign:"center" }}></div>
              </Grid>
            </Grid>
          </TableContainer>
        </NormalCard>
      </Page>
    </Root>
  );
};

export default PriceTrend;
