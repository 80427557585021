import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import MUIDataTable from "mui-datatables";
import {  Typography, Link, Chip, Button } from "@mui/material";

import { ThemeProvider } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import { connect } from 'react-redux';
import NormalCard from "src/components/Cards/NormalCard";
import IconButton from "@mui/material/IconButton";
import Page from "src/components/Page";
import SnackBar from "src/components/SnackBar/SnackBar";
import ModalCentered from "src/components/Modals/ModalCentered";
import AlertModal from 'src/components/AlertModal/AlertModal'
import { additionalTradeService } from 'src/_services/additionalTrade';
// import AddSecondoryCreateFormAndDetails from "./AddSecondoryCreateFormAndDetails";
import moment from 'moment';
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal"
import GetMuiTheme from "src/components/theme";
import { userService } from "src/_services/users";
import { useNavigate } from "react-router";
import UpdateTicket from "./UpdateTicket";
import { AttachFile } from "@mui/icons-material";
import CreateTicket from "./CreateTicket";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
const PREFIX = 'Index';

const classes = {
    tableEllips: `${PREFIX}-tableEllips`,
    root: `${PREFIX}-root`,
    btnright: `${PREFIX}-btnright`,
    container: `${PREFIX}-container`,
    popDownBtn: `${PREFIX}-popDownBtn`,
    button: `${PREFIX}-button`,
    editdeletbtn: `${PREFIX}-editdeletbtn`,
    btnsdltedt: `${PREFIX}-btnsdltedt`,
    iconbtn: `${PREFIX}-iconbtn`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.tableEllips}`]: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "11rem",
        [theme.breakpoints.down("sm")]: {
            width: "8rem",
        },
        [theme.breakpoints.up("sm")]: {
            width: "11rem",
        },
    },

    [`& .${classes.root}`]: {
        margin: theme.spacing(2),
        "& .MuiTableHead-root": {
            background: "blue",
        },
    },

    [`& .${classes.btnright}`]: {
        textAlign: "end",
    },

    [`& .${classes.container}`]: {
        padding: "24px",
    },

    [`& .${classes.popDownBtn}`]: {
        marginTop: '-11px',
        textTransform: 'capitalize',
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.65rem'
        },
        borderRadius: "0 16px 16px 0",
        padding: "4px 8px",
    },

    [`& .${classes.button}`]: {
        marginBottom: "1%",
        borderRadius: "14px 14px 0 0",
        boxShadow: "none",
        textTransform: "capitalize",
    },

    [`& .${classes.editdeletbtn}`]: {
        color: "#3363cb",
        display: 'flex'
    },

    [`& .${classes.btnsdltedt}`]: {
        marginRight: theme.spacing(1),
    },

    [`& .${classes.iconbtn}`]: {
        padding: theme.spacing(0.8),
    }
}));

const AddSecondoryTrade = (props) => {

    const [secondaryTrade, setSecondaryTrade] = useState([])
    const [modalOpen, setModalOpen] = useState(false);
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [snackbarTitle, setSnackbarTitle] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [severity, setSeverity] = React.useState("");
    const [loading, setLoading] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
    const [editRowData, setEditRowData] = useState(null)
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [mode, setMode] = React.useState(null);
    const [spinner, setSpinner] = useState(false)
    const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)
    const navigate = useNavigate()



    const handleClose = (reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackBarOpen(false);
    };

    var isAdmin = props.authReducer.auth.user.default_role_code;



const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 12,
      padding:8
    },
  }));

    const handleShowClose = () => {
        setModalOpen(false);
    };

    const submitHandle = (values) => {
        const data={
            feedback:values.comments,
            status:parseInt(values.status),
            id:parseInt(values.id),
            question:values.subject,
            description:values.description,
            updated_values:values.updated_values
        }
        userService.updateTicket(data).then((res)=>{
            if(res.data.success){
                getAllTickets();
                            setModalOpen(false);
                            setBtnLoading(false)
                            setSeverity("success")
                            setSnackbarTitle("Updated Successfully")
                            setSnackBarOpen(true);
            }else{
                setModalOpen(false);
                setSeverity("error")
                setSnackbarTitle("Failed to update")
                setSnackBarOpen(true);
            }
        }).catch(error => console.log(error))
    }

    const handleDeleteTableRow = () => {
        setSpinner(true)
        additionalTradeService.deleteSecondaryTradeByID(null)
            .then(res => {
                if (res.status === 200) {
                    getAllTickets();
                    setDeleteOpen(false)
                    setSpinner(false)
                    setSeverity("success")
                    setSnackBarOpen(true)
                    setSnackbarTitle("Secondary Trade deleted")

                }
                else {
                    if (res.status === 401 && res.data.name === "TokenExpiredError") {
                        setDeleteOpen(false);
                        setSpinner(false)
                        setOpenSessionExpiredModal(true)
                    } else {
                        setDeleteOpen(false)
                        setSpinner(false)
                        setSeverity("error")
                        setSnackbarTitle("Something Went Wrong!")
                        setSnackBarOpen(true)

                    }
                }
            }).catch(error => {
                setDeleteOpen(false)
                setSpinner(false)
                setSeverity("error")
                setSnackbarTitle("Invalid Token")
                setSnackBarOpen(true)

            })
    };


    const getAllTickets = () => {
        setLoading(true)
        userService.getAllTickets()
            .then(res => {
                if (res) {
                    if (res.status === 200) {
                        let data =res.data.data.map((item)=>{
                            return{
                                ...item,
                                statusCode:item.status ===1 ? "Open" : item.status ===2  ? "In Progress" :"Closed"
                            }
                        })
                        setSecondaryTrade(data)
                        setLoading(false)
                    }
                    else {
                        if (res.status === 401 && res.data.name === "TokenExpiredError") {
                            setLoading(false)
                            setOpenSessionExpiredModal(true)
                        } else {
                            setSeverity("error")
                            setSnackbarTitle("Something Went Wrong!")
                            setSnackBarOpen(true);
                            setLoading(false)
                        }
                    }
                }
            }).catch(error => {
                console.log(error)
                setLoading(false)
            })
    }

    useEffect(() => {
        getAllTickets();
    }, [])


    const getMuiTheme = GetMuiTheme

    const columns = [
        {
            name: "id",
            label: "Id",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta) => {

                    return (
                        <div className={classes.tableEllips}>
                            {`#00${value}`}
                        </div>
                    )
                }
            },
        },
        {
            name: "related_user_id",
            label: "User id",
            options: {
                filter: false,
                sort: false,
                display: false,
            },
        },


        {
            name: "user_name",
            label: "User Name",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {

                    return (
                        <div className={classes.tableEllips}>
                            <Link
                                onClick={() => {
                                    navigate(`/app/user-details/${tableMeta.rowData[1]}`);
                                }}
                                // href={`app/user-details/${tableMeta.rowData[1]}`}
                                component="button"
                                variant="subtitle1"
                                style={{ textTransform: 'capitalize', textDecoration: 'none' }}
                            >
                                {value}
                            </Link>
                        </div>
                    );
                },
            },
        },
        {
            name: "question",
            label: "Subject",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    return (
                        <div className={classes.tableEllips}>
                            <LightTooltip title={value} placement="bottom-start">
                                <Typography variant="body2" noWrap>
                                    {value}
                                </Typography>
                            </LightTooltip>
                        </div>
                    );
                },
            },
        },
        {
            name: "description",
            label: "Description",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    return (
                        <div className={classes.tableEllips}>
                            <LightTooltip title={value} placement="bottom-start">
                                <Typography variant="body2" noWrap>
                                    {value}
                                </Typography>
                            </LightTooltip>
                        </div>
                    );
                },
            },
        },
        {
            name: "status",
            label: "Status",
            options: {
                hint: "Update Secondary Trade",
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <>
                            <Chip sx={{ marginLeft: '1rem' }} label={value === 1 ? "Open"  : value === 2 ? "In Progress": 'Closed'} size="small" color={value === 1 ? "error" :value === 2 ? "primary" : 'success'} variant="outlined" />
                        </>

                    )
                }
            },
        },
        {
            name: "updated_datetime",
            label: "Last Updated",
            options: {
                hint: "Update Secondary Trade",
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    let valueMoment = moment(value).format("DD-MM-YYYY LTS")
                    return (
                        <Typography
                            variant='body2'
                            noWrap
                        >{valueMoment}</Typography>
                    )
                }
            },
        },
        {
            name: "related_file_id",
            label: "File Url",
            options: {
                filter: false,
                sort: false,
                display: false,
            },
        },
        {
            name: "feedback",
            label: "Feedback",
            options: {
                filter: false,
                sort: false,
                display: false,
            },
        },

        {
            name: "related_file_id",
            label: "Attachment",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    return (
                         value?
                             <AttachFile sx={{marginLeft:'1rem'}}/>
                         : null
                
                    )
                }
            },
        },
        {
            name: "statusCode",
            label: "Status Code",
            options: {
                filter: true,
                sort: false,
                display:false,
            },
        },
        {
            name: "rm_name",
            label: "Updated By",
            options: {
                hint: "RM who updated status",
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    return (
                        <Typography
                            variant='body2'
                            noWrap
                        >{value}</Typography>
                    )
                }
            },
        },

{
    name: "",
        label: "Action",
            options: {
        sort: false,
            filter: false,
                customBodyRender: (value, tableMeta) => {
                    return tableMeta.rowData[5] ===1  || isAdmin === "ADMIN" ?   (
                        <span className={classes.editdeletbtn}>
                            <IconButton
                                className={classes.iconbtn}
                                aria-label="edit"
                                disabled={isAdmin ==='ADMIN_VIEWER' }
                                onClick={() => {
                                    if(isAdmin ==='ADMIN'){
                                        navigate(`/app/ticket/${tableMeta.rowData[0]}`,{ replace: true ,state:tableMeta.rowData})
                                    }else{
                                        handleEditSecondary(tableMeta.rowData)}}
                                    }
                            >
                                <EditIcon />
                            </IconButton>
                        </span>
                    ) : null;
                },
            },
},

{
    name: "modify_user",
        label: "modify_user",
            options: {
        filter: false,
            sort: false,
                display: false,
            },
},
    ];

const handleEditSecondary = async (rowData) => {
    setEditRowData(rowData)
    setModalOpen(true)
    setModalTitle('Update Ticket')
}


const  handleCreateTicket =(()=>{
    setModalOpen(true)
    setMode('create')
    setModalTitle('Create Ticket')  
})

const createTicket = ((values)=>{
    const formData = new FormData();
    setLoading(true)
    formData.append("Doc", values.file);
    formData.append("question", values.subject);
    formData.append("message", values.description);
    userService.createTicket(formData).then((res) => {
      setLoading(false)
      setModalOpen(false);
      setModalTitle()
      if (res.status === 201) {
        setSeverity("success")
        setSnackbarTitle("Ticket created successfully!")
        setSnackBarOpen(true)
        getAllTickets()
      }else{
        setSeverity("success")
        setSnackbarTitle("Something went wrong!")
        setSnackBarOpen(true)
      }
})
})

return (
    <Root>
        <Page title="Unlistedkart | Support Tickets">
            <NormalCard
                title={"Tickets"}
                subtitle={"List of Support Tickets"}
                btn={ 
                    <div style={{ display: "flex" }}>
                      <Button
                        className={classes.addButton}
                        variant="contained"
                        color="primary"
                        size="medium"
                        disabled={isAdmin ==='ADMIN_VIEWER'}
                        onClick={handleCreateTicket}
                      >
                        Create Ticket
                      </Button>
                      </div>
                      }
            >
                <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        data={secondaryTrade}
                        headerSort={false}
                        columns={columns}
                        options={{
                            search: true,
                            download: true,
                            print: false,
                            filter: true,
                            viewColumns: false,
                            rowsPerPage: 10,
                            rowsPerPageOptions: [5, 10, 25],
                            // responsive: 'scrollMaxHeight',
                            selectableRows: "none",
                            sortOrder: {
                                name: 'last_updated',
                                direction: 'desc'
                            },
                            textLabels: {
                                body: {
                                    noMatch: loading ?
                                        'Loading...' : 'Sorry, there are no matching data to display'
                                },
                            }
                        }}
                    />
                </ThemeProvider>
            </NormalCard>
            <ModalCentered
                title={modalTitle}
                open={modalOpen}
                maxWidth={'md'}
                toggle={handleShowClose}
                children={
                    modalTitle ==="Update Ticket" ?
                    <UpdateTicket
                        spinner={btnLoading}
                        formSubmit={submitHandle}
                        type={''}
                        mode={null}
                        data={editRowData}
                    /> : 
                    <CreateTicket 
                    data={''}
                    formSubmit={createTicket}
                    mode={mode}/>
                }
            />
            <SnackBar
                open={snackBarOpen}
                severity={severity}
                close={handleClose}
                snackbarTitle={snackbarTitle}
            />
            <AlertModal
                title="Are you sure you want to delete this?"
                open={deleteOpen}
                setOpen={setDeleteOpen}
                toggle={() => setDeleteOpen(false)}
                onConfirm={() => handleDeleteTableRow()}
                openLoading={spinner}
            >
            </AlertModal>
            <SessionExpiredModal
                open={openSessionExpiredModal}

            />
        </Page>
    </Root>
);
};

const mapStateToProps = state => {
    return ({
        force: state, // force state from reducer
        authReducer: state.authReducer,
    })
};

export default connect(mapStateToProps)(AddSecondoryTrade);

