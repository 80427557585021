import * as React from 'react';
import { styled } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import {
  Typography, AppBar, Box,
  Tabs,
  Tab,
} from '@mui/material';

// import SecuritySortIndexTagged from './SecuritySortIndexTagged';
// import SecuritySortIndexUntagged from './SecuritySortIndexUntagged';
import FaqSortIndex from './FaqSortIndex';
import FaqMain from './FaqMain';
import ServiceCard from 'src/components/Cards/ServiceCard';
import Page from "src/components/Page";
import GetMuiTheme from 'src/components/theme';

const PREFIX = 'Index';

const classes = {
  root: `${PREFIX}-root`,
  addressBlock: `${PREFIX}-addressBlock`,
  gridPadding: `${PREFIX}-gridPadding`,
  card: `${PREFIX}-card`,
  accountBlock: `${PREFIX}-accountBlock`,
  bottomborder: `${PREFIX}-bottomborder`,
  reactSpinner: `${PREFIX}-reactSpinner`,
  accountTitle: `${PREFIX}-accountTitle`,
  addressBlocktitle: `${PREFIX}-addressBlocktitle`,
  removeLink: `${PREFIX}-removeLink`,
  buttonProgress: `${PREFIX}-buttonProgress`,
  wrapper: `${PREFIX}-wrapper`,
  btnroot: `${PREFIX}-btnroot`,
  btnright: `${PREFIX}-btnright`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },

  [`& .${classes.addressBlock}`]: {
    padding: "1rem",
    color: "#314B8F",
  },

  [`& .${classes.gridPadding}`]: {
    paddingTop: " 10px !important",
    paddingBottom: " 0px !important",
  },

  [`& .${classes.card}`]: {
    borderBottom: "none",
    padding: 0,
  },

  [`& .${classes.accountBlock}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem",
    margin: "1rem 0",
  },

  [`& .${classes.bottomborder}`]: {
    backgroundColor: "#fff",
    borderBottom: "1px solid #e8e8e8",
  },

  [`& .${classes.reactSpinner}`]: {
    position: "absolute",
    height: "100%",
    width: "100%",
    background: "#f4f6f870",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  [`& .${classes.accountTitle}`]: {
    fontSize: "1.2rem",
    fontWeight: "500",
    color: "#314B8F",
  },

  [`& .${classes.addressBlocktitle}`]: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(2),
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },

  [`& .${classes.removeLink}`]: {
    cursor: "pointer",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: "8px",
  },

  [`& .${classes.wrapper}`]: {
    margin: theme.spacing(1),
    position: "relative",
  },

  [`& .${classes.btnroot}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },

  [`& .${classes.btnright}`]: {
    textAlign: "end",
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component='span'>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs() {

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const getMuiTheme = GetMuiTheme

  return (
    <Root>
      <Page title="Unlistedkart | Order">
        <ServiceCard className={classes.card}>
          <ThemeProvider theme={getMuiTheme}>
            <div style={{ position: "relative" }}>
              <div className={classes.bottomborder}>
                <AppBar position="static" >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    style={{
                      marginBottom: 24,
                      borderBottom: "1px solid #e8e8e8",
                    }}
                  >
                    <Tab label="Faq" {...a11yProps(0)} />
                    <Tab label="Faq Sort" {...a11yProps(1)} />
                  </Tabs>
                </AppBar>
              </div>
            </div>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <FaqMain />
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <FaqSortIndex />
              </TabPanel>
            </SwipeableViews>
          </ThemeProvider>
        </ServiceCard>
      </Page>
    </Root>
  );
}
