import React from "react";
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";

import ErrorFocus from "../../components/ErrorFocus/ErrorFocus";
import {
  Container,
  TextField,
  Grid,
} from "@mui/material";
import * as Yup from "yup";
import { Formik, FieldArray, getIn } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import loadingSpinner from "src/images/loading-spinner.gif";

const PREFIX = 'ServiceOrderCreateForm';

const classes = {
  container: `${PREFIX}-container`,
  buttonProgress: `${PREFIX}-buttonProgress`,  
  popDownBtn: `${PREFIX}-popDownBtn`,

};

const Root = styled('div')((
  {
    theme
  }
) => ({
 
  [`& .${classes.container}`]: {
    justifyContent: "center",
    // justifyItems: "center",

    marginTop: "10%",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: "4px",
  },
  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "20%",
  }
}));

function SalesOrdeUpdate(props) {


  const [loading, setLoading] = React.useState(false);
 


  return (
    <Root>
    <Container>
      <Formik
        initialValues={{
          orderList: [{
          fieldName:'',
          fieldOldValue:'',
          fieldNewValue:''
        }],
        }}
        // validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object().shape({
         
            orderList: Yup.array(
            Yup.object({
              fieldName: Yup.string().required("Field name required"),
              fieldOldValue: Yup.string().required("Required"),
              fieldNewValue: Yup.string().required("Required"),
            })
          ),
        })}
        onSubmit={(values) => {
          setLoading(true);
          props.formSubmit(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Container className={classes.container}>
              <Grid container>
               
                <FieldArray name="orderList">
                  {({ push, remove }) => (
                    <Grid container>
                      {values.orderList.map((_, index) => {
                        const touchedFieldName = getIn(
                          touched,
                          `orderList.${index}.fieldName`
                        );
                        const errorFieldName = getIn(
                          errors,
                          `orderList.${index}.fieldName`
                        );
                        const touchedOldValue = getIn(
                          touched,
                          `orderList.${index}.fieldOldValue`
                        );
                        const errorOldValue = getIn(
                          errors,
                          `orderList.${index}.fieldOldValue`
                        );
                        const touchedNewValue = getIn(
                          touched,
                          `orderList.${index}.fieldNewValue`
                        );
                        const errorNewValue = getIn(
                          errors,
                          `orderList.${index}.fieldNewValue`
                        );
                        return (
                          <Grid
                            container
                            spacing={1}
                            sx={{my:1}}
                            alignItems="flex-start"
                            key={index}
                          >
                            <Grid item xs={5}>
                            <TextField
                                label="Field Name"
                                id="value"
                                placeholder="Ex:Scrip Name,Qty,price,etc"

                                variant="outlined"
                                name={`orderList.${index}.fieldName`}
                                value={values.orderList[index].fieldName}
                                autoComplete="off"
                                fullWidth
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(touchedFieldName && errorFieldName)}
                                helperText={touchedFieldName && errorFieldName}
                              />
                            </Grid>
                            <Grid item xs={3} className={classes.gridPadding}>
                              <TextField
                                label="Old Value"
                                id="value"
                                variant="outlined"
                                name={`orderList.${index}.fieldOldValue`}
                                value={values.orderList[index].fieldOldValue}
                                autoComplete="off"
                                fullWidth
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(touchedOldValue && errorOldValue)}
                                helperText={touchedOldValue && errorOldValue}
                              />
                              <ErrorFocus />
                            </Grid>
                            <Grid item xs={3} className={classes.gridPadding}>
                              <TextField
                                disabled={props.mode ==="edit"}
                                label="New Value"
                                id="newValue"
                                variant="outlined"
                                name={`orderList.${index}.fieldNewValue`}
                                value={values.orderList[index].fieldNewValue}
                                autoComplete="off"
                                fullWidth
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(touchedNewValue && errorNewValue)}
                                helperText={touchedNewValue && errorNewValue}
                              />
                              <ErrorFocus />
                            </Grid>
                            <Grid item xs={1} className={classes.gridPadding}>
                              <CloseIcon
                                onClick={() => {
                                  remove(index);
                                }}
                                style={{
                                  cursor: "pointer",
                                  margin: "1rem 8px",
                                }}
                              />
                            </Grid>
                          </Grid>
                        );
                      })}
                  
                    <Button onClick={()=>{
                         push({
                            fieldName: "",
                            fieldOldValue: "",
                            fieldNewValue:""
                          });
                    }}>
                        Add More
                    </Button>
                    </Grid>
                  )}
                </FieldArray>
              </Grid>
              <Button
                color="primary"
                className={classes.popDownBtn}
                size="medium"
                disabled={isSubmitting || values.orderList.length===0}
                type="submit"
                variant="contained"
              >
                {loading === false ? ("Request") : (
                  <>
                    <img
                      alt="loader"
                      src={loadingSpinner}
                      className={classes.buttonProgress}
                    />
                    {"Request"}
                  </>
                )}
              </Button>
            </Container>
          </form>
        )}
      </Formik>
    </Container>
    </Root>
  );
}

export default SalesOrdeUpdate;
