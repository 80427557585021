import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorFocus from "../../components/ErrorFocus/ErrorFocus";
import { Container, TextField, Grid } from "@mui/material";
import * as Yup from "yup";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import { Formik } from "formik";
import Autocomplete from "@mui/material/Autocomplete";
import SnackBar from "src/components/SnackBar/SnackBar";
import { getLookUpDataService } from "src/_services/lookUp";
import { ChannelGroupService } from "src/_services/groupSelector";
import loadingSpinner from 'src/images/loading-spinner.gif'

import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal"


const PREFIX = 'ChannelPartnersListModel';

const classes = {
  zeroPadding: `${PREFIX}-zeroPadding`,
  cardBorderRadius: `${PREFIX}-cardBorderRadius`,
  cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
  fileInput: `${PREFIX}-fileInput`,
  imageSizes: `${PREFIX}-imageSizes`,
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  marginInputLabel: `${PREFIX}-marginInputLabel`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  mquery: `${PREFIX}-mquery`,
  resendBtnMargin: `${PREFIX}-resendBtnMargin`,
  inputMargin: `${PREFIX}-inputMargin`,
  paddingInput: `${PREFIX}-paddingInput`,
  buttonProgress: `${PREFIX}-buttonProgress`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.zeroPadding}`]: {
    padding: 0,
  },

  [`& .${classes.cardBorderRadius}`]: {
    borderRadius: "0 10px 10px 10px",
  },

  [`& .${classes.cardHeaderMargin}`]: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    borderRadius: "0 0  10px 0",
  },

  [`& .${classes.fileInput}`]: {
    display: "none",
  },

  [`& .${classes.imageSizes}`]: {
    width: "40%",
    margin: "auto",
    display: "block",
  },

  [`& .${classes.container}`]: {
    justifyContent: "center",
    justifyItems: "center",
    marginTop: "10%",
  },

  [`& .${classes.label}`]: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },

  [`& .${classes.marginInputLabel}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0  12px 12px 0",
    paddingBottom: "16.5px",
    paddingTop: "16.5px",
  },

  [`& .${classes.mquery}`]: {
    [theme.breakpoints.down("xs")]: {
      marginRight: 24,
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: 1,
    },
    [theme.breakpoints.down(450)]: {
      marginRight: 10,
    },
    [theme.breakpoints.down(370)]: {
      marginRight: 20,
    },
  },

  [`& .${classes.resendBtnMargin}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.inputMargin}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.paddingInput}`]: {
    padding: "0px 0px",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: '4px'
  }
}));


function ChannelPartnersListModel(props) {

  const [open, setOpen] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [severity, setSeverity] = React.useState("");
  const [lookUpforCP, setLookUpforCP] = useState([])
  const [groupDetails ,setGroupDetails ] = useState([])
  const id =props.id;
  const [loading , setLoading] = React.useState(false)
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)


  
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const  getgroupDataById = (id) =>{
    ChannelGroupService.getGroupSelectoById(id)
    .then(res => {
      if(res){
        
        if(res.status === 200){
          setGroupDetails(res.data[0].group_members)
        }else{
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenSessionExpiredModal(true)
          }
        }
      }
    })
  }

  const getCPlookUP = () =>{
    getLookUpDataService.getLookUpChannelPartner()
    .then(res => {
      if (res) {
        
        if (res.status === 200) {   
          setLookUpforCP(res.data.map( data => {   
          return { id: data.user_unique_code, label:data.member_name }
        }))
        }else{
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenSessionExpiredModal(true)
          }
        }
      }
    })
  }

  useEffect( () =>{
    getgroupDataById(id)
    getCPlookUP()   
  }, [id])

  const updateChannelPartner = (cpID) =>{
    ChannelGroupService.deleteChannelPartners(id,cpID)
   .then(res =>{
     if(res){
       if(res.status === 200){
        props.reLoadApi()
        getCPlookUP()
        getgroupDataById(id)
         setSeverity("succes")
         setSnackbarTitle("Deleted")
       }else{
        if (res.status === 401 && res.data.name === "TokenExpiredError") {
          setOpenSessionExpiredModal(true)
        }
       }
     }
   }).catch(error => {
   })
  }

  return (
    <StyledContainer style={{ padding: 0 }}>
      <Formik
        initialValues={{
          group_members: [""],
        }}
        validationSchema={Yup.object().shape({
          group_members: Yup.string().required("Required"),
        })}
        onSubmit={(values) => {
          setLoading(true)
          props.formSubmit(values)
        }}
      >
        {({
          errors,
         
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container style={{ padding: 0 }}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <List>
                  {
                    groupDetails.map(value => {
                      return (
                        <ListItem key={value} button>
                          <ListItemText primary={` ${value.member_name}`} />
                          <ListItemSecondaryAction>
                            <IconButton
                              aria-label="delete"
                              style={{ color: "#314b8f", padding: 0 }}
                              onClick={() => {
                                updateChannelPartner(value.user_unique_code)
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })
                  }
                </List>
              </Grid>
              <Grid container justifyContent="center" className={classes.mquery}>
                <Grid item xs={10} sm={10} md={10} lg={10}>
                  <Autocomplete
                    multiple
                    options={lookUpforCP}
                    classes={{
                      option: classes.option,
                    }}
                    autoHighlight
                    getOptionLabel={(option) => option.label}
                    onChange={(e, value) => {
                      setFieldValue("group_members", value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth={true}
                        label="Channel Partners *"
                        variant="outlined"
                        name="group_members"
                        id="group_members"
                        value={values.group_members}
                        error={Boolean(
                          touched.group_members &&
                            errors.group_members
                        )}
                        helperText={
                          touched.group_members &&
                          errors.group_members
                        }
                        inputProps={{
                          ...params.inputProps,
                        }}
                        autoComplete="off"
                      />
                    )}
                  />
                  <ErrorFocus />
                </Grid>

                <Grid item xs={2} sm={2} md={2} lg={2}>
                  <Button
                    color="primary"
                    className={classes.popDownBtn}
                    size="medium"
                    type="submit"
                    variant="contained"
                    fullWidth
                    disabled={isSubmitting}
                  >
                     {loading === false ? "Add" : <><img src={loadingSpinner} alt="logo" className={classes.buttonProgress}/> Add </>}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <SnackBar
        open={open}
        severity={severity}
        close={handleClose}
        snackbarTitle={snackbarTitle}
      />
            <SessionExpiredModal
                open={openSessionExpiredModal}
            />
    </StyledContainer>
  );
}

export default ChannelPartnersListModel;
