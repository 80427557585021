import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  colors
} from '@mui/material';
import { DashboardData } from 'src/_services/dashboard';
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal"
const PREFIX = 'TotalProfit';

const classes = {
  root: `${PREFIX}-root`,
  boxHeader: `${PREFIX}-boxHeader`,
  italic: `${PREFIX}-italic`,
  revenueRate: `${PREFIX}-revenueRate`,
  avatar: `${PREFIX}-avatar`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.root}`]: {
    height: '100%'
  },

  [`& .${classes.boxHeader}`]: {
    fontWeight: 'normal',
  },

  [`& .${classes.italic}`]: {
    fontStyle: "italic",
    fontWeight: '400',
    fontSize: '0.8rem'
  },

  [`& .${classes.revenueRate}`]: {
    fontWeight: "400"
  },

  [`& .${classes.avatar}`]: {
    backgroundColor: colors.indigo[600],
    height: 56,
    width: 56
  }
}));

const TotalProfit = ({ className, ...rest }) => {

  const [revenue, setRevenue] = useState('')
  const [currentRevenue, setCurrentRevenue] = useState(0)
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)

  useEffect(() => {
    DashboardData.getDashboardRevenue().then(res => {
     if(res){
      if (res.status === 200) {
        res.data.forEach(item => {
          setRevenue(item.previous_month_service_order_revenue)
          setCurrentRevenue(item.current_month_service_order_revenue)
        })
      } else {
        if (res.status === 401 && res.data.name === "TokenExpiredError") {
          setOpenSessionExpiredModal(true)
        }
      }
     }
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    (<Root>
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <CardContent>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="h6"
                className={classes.boxHeader}
              >
                REVENUE
            </Typography>

              <Typography
                color="textSecondary"
                gutterBottom
                variant="h4"
                className={classes.revenueRate}
              >
                <i className="fas fa-rupee-sign" style={{ fontWeight: '500', fontSize: '0.99rem' }} ></i>&nbsp;
                {currentRevenue}&nbsp;
              <span className={classes.italic}>Current Month</span>
              </Typography>
              <Typography
                color="textPrimary"
                variant="h4"
                className={classes.revenueRate}
              >
                <i className="fas fa-rupee-sign" style={{ fontWeight: '500', fontSize: '0.99rem' }}></i>&nbsp;
              {revenue !== null ? revenue : 0 }&nbsp;
            <span className={classes.italic}>Last Month</span>
              </Typography>

            </Grid>
            <Grid item>
              <Avatar className={classes.avatar}>
                {/* <AttachMoneyIcon /> */}
                <i className="fas fa-rupee-sign"></i>
              </Avatar>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <SessionExpiredModal
        open={openSessionExpiredModal}
      />
    </Root>)
  );
};

TotalProfit.propTypes = {
  className: PropTypes.string
};

export default TotalProfit;
