
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import React, { useState, useEffect } from "react";
import { securityService } from 'src/_services/security';
import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    Typography,
    DialogActions,
    Table,
    TableHead,
    TableRow,TableCell,TableBody
  } from "@mui/material";
import { useSelector } from "react-redux";

const SortIndex= () =>{
    const [open, setOpen] = React.useState(false);
    const [securityData, setSecurityData] = useState([])
    const [dragComplete,setDragComplete] = useState(false);
    const role =useSelector((auth)=>auth.authReducer.auth.user.default_role_code)
    const handleClickOpen = () => {
        setOpen(true); 
      };
      const handleClose = () => {
        setOpen(false);
      };
    const getSecurity = () => {
        securityService.getSecurity()
            .then(res => {
                if (res) {
                    if (res.status === 200) {
                        for (let i = 0; i < res.data.taggedArray.length; i++) {
                                for (let j = 0; j < res.data.taggedArray[i].related_security_tag?.length; j++) {
                                    res.data.taggedArray[i]["chipName"] = res.data.taggedArray[i].related_security_tag[0].tag_name;
                                }
                        }
                        setSecurityData(res.data.taggedArray)
                        // setLoading(false)
                    } 
                    // else {
                    //     if (res.status === 401 && res.data.taggedArray.name === "TokenExpiredError") {
                    //         setOpenSessionExpiredModal(true)
                    //         setLoading(false)
                    //     }
                    // }

                }
            }).catch(error => console.log(error))
    }
    useEffect(() => {
        getSecurity()
    }, [])

    const handleDragEnd = (e) => {
        if (!e.destination) return;
        let tempData = Array.from(securityData);
        let [source_data] = tempData.splice(e.source.index, 1);
        tempData.splice(e.destination.index, 0, source_data);
        setSecurityData(tempData);
        setDragComplete(true);
    };
    const  saveData =()=>{
        const newArray = []
        for(let i=0;i<securityData.length;i++){
        newArray.push({
            "security_id":securityData[i].id,
            "sort_index":i+1,
            "sort_tag_index":i+1
        })
        }
        securityService.updateSecurityS(newArray).then(res => {
            getSecurity()
            setOpen(false)
        })
            .catch(error => {
                console.log("error");
            })
    }
    return (
        <div className="App mt-4" style={{ fontFamily: "sans-serif", textAlign: "center" }}>
           {role === "ADMIN_VIEWER" ? null : <div style={{ fontFamily: "sans-serif", textAlign: "right",margin:"14px",paddingLeft:"15px" }}>
                <Button style={{backgroundColor:"#3f51b5",color:"white"}} disabled={dragComplete === false}  onClick={handleClickOpen}>
                    Save
                </Button><br></br>
            </div>}
            <DragDropContext onDragEnd={handleDragEnd}>
                <Table className="table borderd">
                    <TableHead style={{ textAlign: "left" }}>
                        <TableRow>
                            <TableCell />
                            <TableCell>Scrip Name</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Advance & Decline</TableCell>
                            {/* <TableCell>Index</TableCell> */}
                            <TableCell>Tag</TableCell>
                            {/* <TableCell>Sort Index</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <Droppable droppableId="droppable-1">
                        {(provider) => (
                            <TableBody
                                className="text-capitalize"
                                ref={provider.innerRef}
                                {...provider.droppableProps}
                                style={{ textAlign: "left" }}
                            >
                                {securityData?.map((item, index) => (
                                    <Draggable
                                        key={item.name}
                                        draggableId={item.name}
                                        index={index}

                                    >
                                        {(provider, snapshot) => (
                                            <TableRow {...provider.draggableProps}
                                                {...provider.dragHandleProps}
                                                ref={provider.innerRef}
                                            >
                                                <TableCell {...provider.dragHandleProps}
                                                >  </TableCell>
                                                <TableCell {...provider.dragHandleProps}>{item.name}</TableCell>
                                                <TableCell {...provider.dragHandleProps}>{item.price}</TableCell>
                                                <TableCell {...provider.dragHandleProps}> {item.percentage_of_change}</TableCell>
                                                {/* <TableCell {...provider.dragHandleProps}>{index + 1}</TableCell> */}
                                                <TableCell {...provider.dragHandleProps}>{item.chipName}</TableCell>
                                                {/* <TableCell {...provider.dragHandleProps}>{item.sort_index}</TableCell> */}
                                            </TableRow>
                                        )}
                                    </Draggable>
                                ))}
                                {provider.placeholder}
                            </TableBody>
                        )}
                    </Droppable>
                </Table>
            </DragDropContext>
            <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
    <Typography>Do you want to Save ?</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}      color="primary">No</Button>
          <Button onClick={saveData} autoFocus   color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
        </div>
    );
}

export default SortIndex
