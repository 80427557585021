import React from 'react';
import { GlobalStyles as MUIGlobalStyles } from '@mui/system';

const globalStyles = {
  '*': {
    boxSizing: 'border-box',
    margin: 0,
    padding: 0,
  },
  html: {
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    height: '100%',
    width: '100%',
  },
  body: {
    backgroundColor: '#f4f6f8',
    height: '100%',
    width: '100%',
  },
  a: {
    textDecoration: 'none',
  },
  '#root': {
    width: '100%',
  },
};

const GlobalStyles = () => {
  return <MUIGlobalStyles styles={globalStyles} />;
};

export default GlobalStyles;
