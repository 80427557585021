import React ,{useEffect}from 'react';
import { styled } from '@mui/material/styles';
import ProcureCard from "src/components/Cards/ProcureCard"

import ProcureChannelPartnerCard from "src/components/Cards/ProcureChannelPartnerCard"
import { DashboardData } from "src/_services/dashboard"
import {
   
    Card,Typography,Button
  } from '@mui/material';

import { useNavigate} from 'react-router-dom';
import { connect } from 'react-redux';
import { ProcurementService } from "src/_services/procurement"
import SnackBar from "src/components/SnackBar/SnackBar";

import ModalCentered from "src/components/Modals/ModalCentered";
import CreateLotForm from "../ChannelPartnersView/CreateLotForm"
import { NavLink } from 'react-router-dom';
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal"

const PREFIX = 'TopFiveProcureShares';

const classes = {
    active: `${PREFIX}-active`,
    inactive: `${PREFIX}-inactive`,
    closed: `${PREFIX}-closed`,
    processing: `${PREFIX}-processing`,
    completed: `${PREFIX}-completed`,
    boxHeader: `${PREFIX}-boxHeader`,
    viewAll: `${PREFIX}-viewAll`
};

const Root = styled('div')(() => ({
    [`& .${classes.active}`]: {
        color: 'white',
        backgroundColor: "#00C914",
        fontSize: '11px',
    },

    [`& .${classes.inactive}`]: {
        color: 'white',
         fontSize: '11px',
        backgroundColor: "#D50000"
    },

    [`& .${classes.closed}`]: {
        color: 'white',
         fontSize: '11px',
        backgroundColor: "#314B8F"
    },

    [`& .${classes.processing}`]: {
        color: 'white',
         fontSize: '11px',
        backgroundColor: "#A48800"
    },

    [`& .${classes.completed}`]: {
        color: 'white',
         fontSize: '11px',
        backgroundColor: "#A40094"
    },

    [`& .${classes.boxHeader}`]: {
        fontWeight:'normal',
        padding: '4px 14px',
        borderBottom:"1px solid #e0e0e0"
    },

    [`& .${classes.viewAll}`]: {
        float: 'right',
        padding: '4px 8px',
        fontSize: '12px',
        margin: '14px',
    }
}));

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) { // eslint-disable-line
    return <NavLink to={props.to} {...props}  />; // eslint-disable-line
});



const TopFiveProcureShares = (props) => {
    const navigate = useNavigate();

    const role =props.authReducer.auth ? props.authReducer.auth.user!==null && props.authReducer.auth.user.default_role_code : null;
    const [procureDetails,setProcureDetails] = React.useState([])
    const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const [snackbarTitle, setSnackbarTitle] = React.useState("");
    const [openModal, setOpenModal] = React.useState(false);
    const [titleModal, setTitleModal] = React.useState("");
    const [securityId,setSecurityId] = React.useState("")
    
   
   
    const [severity, setSeverity] = React.useState("");
    const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)


    


    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackBarOpen(false);
    };
  


    const handleOpen = (id) => {
        setSecurityId(id)
        setTitleModal("Requested Quantity")
        setOpenModal(true)
    }

    const handleModalClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenModal(false);
    };

    const getProcureDetails = () => {

        DashboardData.getTopFiveData()
        .then((res) => {
           
            if(res){
                

                if( res.status === 200 ){
                    if(res.data.length > 0) {
                        setProcureDetails(res.data)
                    }else{
                        setProcureDetails([]) 
                    }
                   
                }else{
                    if (res.status === 401 && res.data.name === "TokenExpiredError") {
                        setOpenSessionExpiredModal(true)
                    }
                }
            }
        })

    }

     useEffect(() => {
        getProcureDetails()
     }, []);




    const handleLotSubmit = (values) => {
        


        ProcurementService.postProcurement(values)
        .then((res) => {
            if(res){
                if (res.status === 401 && res.data.name === "TokenExpiredError") {
                    setOpenSessionExpiredModal(true)
                }

                if(res.status === 201){
                    getProcureDetails()
                    setSeverity("success")
                    setSnackbarTitle("The order is placed")
                    setSnackBarOpen(true)
                    setOpenModal(false)
                }else if(res.status === 400){
                    setSeverity("error")
                    setSnackbarTitle(`Please update  PAN, DEMAT ACCOUNT, BANK ACCOUNT to Procure Shares.`)
                    setTimeout(
                        () => navigate(`/app/profile`, { replace: true }),
                        3000
                      );
                    setSnackBarOpen(true)
                    setOpenModal(false)  
                }
            }
        })
        
    }

    


    
    return (
        <Root >
                    
            <Card>
            <Typography color="primary" className={classes.boxHeader} variant="h6">
                Top Shares
              
            </Typography>


            <> {role === "INVESTER_CLIENT" ? <> {procureDetails.length > 0 ? <ProcureCard  data = {procureDetails} callBack = {handleOpen} /> : <><Typography color="primary" className={classes.boxHeader} variant="h6">There Are No Matching Data    </Typography></> }  </>:
            role === "CHANNEL_PARTNER"? <> {procureDetails.length > 0 ?  <ProcureChannelPartnerCard data = {procureDetails} callBack = {handleOpen}/> : <> <Typography color="primary" className={classes.boxHeader} variant="h6">There Are No Matching Data    </Typography></> }  </> :<></>

          }   </>
             

             {procureDetails.length > 0 ?
             <Button variant="contained"
                    className={classes.viewAll}   
                    component={LinkBtn}
                    to={role === "INVESTER_CLIENT" ? "/app/investor-client-procure-share":
                    role === "CHANNEL_PARTNER"?"/app/channel-partners-views":""} 
                >
                    View ALL
                </Button>
            :<></>}
            
            </Card>



            <ModalCentered
                title={titleModal}

                open={openModal}
                toggle={handleModalClose}
                children={<CreateLotForm formSubmit={handleLotSubmit} security_id = {securityId} />}
            />

            <SnackBar
                open={snackBarOpen}
                severity={severity}
                close={handleClose}
                snackbarTitle={snackbarTitle}
            />
                        <SessionExpiredModal
                open={openSessionExpiredModal}
            />
        </Root>
    );
}




const mapStateToProps = state => {
    return ({
        force: state, // force state from reducer
        authReducer: state.authReducer,
    })
};
export default connect(mapStateToProps, null)(TopFiveProcureShares);

