/*eslint-disable*/
import React from 'react';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
 Typography
} from '@mui/material';

import Logo from 'src/components/Logo';

const PREFIX = 'TopBar';

const classes = {
  root: `${PREFIX}-root`,
  toolbar: `${PREFIX}-toolbar`,
  logoName: `${PREFIX}-logoName`
};

const StyledAppBar = styled(AppBar)({
  [`&.${classes.root}`]: {},
  [`& .${classes.toolbar}`]: {
    height: 64
  },
  [`& .${classes.logoName}`]: {
    display:"flex",
    alignItem:"center",
    color:"#fff"
  }
});

const TopBar = ({ className, ...rest }) => {


  return (
    <StyledAppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
        <div className={classes.logoName}>            
          <Logo /> 
          <Typography variant="h4" style={{margin:"auto",marginLeft:10}}>UnlistedKart</Typography>
          </div>
        </RouterLink>
      </Toolbar>
    </StyledAppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
