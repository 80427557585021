import React from 'react'
import Zoom from '@mui/material/Zoom';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const ToolTip = (props) => {
    
    
      const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(() => ({
        [`& .${tooltipClasses.tooltip}`]: {
          color: "#1c1c1c",
              margin: 0,
              maxWidth:props.width ? props.width :400,
              backgroundColor: "#fff",
              border: "1px solid #dadde9",
        },
      }));
    
  return (
    <HtmlTooltip
          TransitionComponent={Zoom}
            {...props}
            title={props.title}
          />
  )
}

export default ToolTip