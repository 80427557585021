import React,{useEffect, useState} from "react";
import { styled } from '@mui/material/styles';
import { Autocomplete, Button, TextField } from "@mui/material";

const PREFIX = 'TaxModel';

const classes = {
  border: `${PREFIX}-border`,
  centeredText: `${PREFIX}-centeredText`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.border}`]: {
 border: '1px solid #000',
 padding:'10px 30px',
 width:'auto',
 whiteSpace:'nowrap',
 textAlign:'center' // You can adjust the border style as needed
},

  [`& .${classes.centeredText}`]: {
    '& input': {
      textAlign: 'center',
      borderBottom:'none'
    },
  }
}));

const TaxModel = ((props) => {
const [value,setValue ] = useState(props.data)


  useEffect(()=>{
    setValue(props.data)
  },[])

  const handleChange = (index, event) => {   
    const regex = /^[+-]?(\d+(\.\d*)?|\.\d+)$/;
    if (event.target.value === "" || regex.test(event.target.value)) {
     setValue((items) => {
          const updatedItems = [...items];
          updatedItems[index].newTaxValue = event.target.value;
          return updatedItems;
        }
        )
      }
  };

  const handleChangeforSelect = (index, value) => {  
    setValue((items) => {
         const updatedItems = [...items];
         console.log(updatedItems)
         updatedItems[index].is_tcs_tds_collected = value.id;
          if(value.id ===0){
            updatedItems[index].newTaxValue = 0;
          }else{
            updatedItems[index].newTaxValue=props.data[index].newTaxValue1
          }
         return updatedItems;
     }
    )
 };

  const options = 
  [{
    id:0,
    label:'No tax'
  },
  {
    id:1,
    label:'TCS'
  },
  {
    id:2,
    label:'TDS'
  }]

  return (
    <Root>
      <table style={{
        margin:'1rem 0',
        border:'1px solid grey',
        borderCollapse:'collapse'
      }}>
        <tr>
          <th className={classes.border}>Order Id</th>
          <th className={classes.border}>Order Value</th>
          <th className={classes.border}>TCS/TDS</th>
          <th className={classes.border}>Old Tax Value</th>
          <th className={classes.border}>New Tax Value</th>
        </tr>
        {value.map((item,index) => {
          return (
            <tr  key={item.id}>
              <td className={classes.border}>{item.service_id}</td>
              <td className={classes.border}>{item.sum}</td>
              <td className={classes.border}>
              <Autocomplete
                      options={options}
                      classes={{
                        option: classes.option,
                      }}
                      disableClearable
                      autoHighlight
                      id="vender"
                      value={ options[item.is_tcs_tds_collected]}
                      getOptionLabel={(option) => option.label}
                      onChange={(e,value) => {
                        handleChangeforSelect(index,value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          sx={{width:'120px'}}
                          {...params}
                          fullWidth={true}
                          label=""
                          // variant="outlined"
                          name="vender"
                          id="vender"
                         
                          autoComplete="off"
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}
                    />
              </td>
              <td className={classes.border}>{parseFloat(item.is_tcs_tds_collected_value).toFixed(3)}</td>
              <td className={classes.border}>
                <TextField
                placeholder="New Tax Value"
                value={item.newTaxValue}
                disabled={value[index].is_tcs_tds_collected ===0}
                className={classes.centeredText}
                onChange={(e) => handleChange(index, e)}
                >
                    
                </TextField>
              </td>
            </tr>
          );
        })}
      </table>
      {/* <Typography><b>Note : </b>Please update tax type(TDS/TCS) which is mandotory  for future orders in case not selected, which will be updated for respective orders </Typography> */}
      <Button onClick={()=>props.formSubmit(value)} variant="contained" color="primary" style={{float:'right', margin:'1rem'}}>
        Update
      </Button>

    </Root>
  );
});

export default TaxModel;
