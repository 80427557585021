import { Button, Card, Grid } from "@mui/material";
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import { Typography } from "@mui/material";
import { userService } from "src/_services/users";
import { connect } from "react-redux";
import DematAccountData from "./DematAccount";
import BankAccountData from "./BankAccount";
import SnackBar from "src/components/SnackBar/SnackBar";
import Page from "src/components/Page";
import { Formik } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import MenuItem from "@mui/material/MenuItem";

import ErrorFocus from "../../components/ErrorFocus/ErrorFocus";
import Select from "@mui/material/Select";
import { getLookUpDataService } from "src/_services/lookUp";
import loadingSpinner from "src/images/loading-spinner.gif";
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal"

const PREFIX = 'Index';

const classes = {
  profileIcon: `${PREFIX}-profileIcon`,
  editButton: `${PREFIX}-editButton`,
  header: `${PREFIX}-header`,
  profiletitle: `${PREFIX}-profiletitle`,
  borderBottom: `${PREFIX}-borderBottom`,
  card: `${PREFIX}-card`,
  iconpdf: `${PREFIX}-iconpdf`,
  label: `${PREFIX}-label`,
  avatar: `${PREFIX}-avatar`,
  reactSpinner: `${PREFIX}-reactSpinner`,
  buttonProgress: `${PREFIX}-buttonProgress`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.profileIcon}`]: {
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.editButton}`]: {
    [theme.breakpoints.up("sm")]: {
      marginTop: " 12px",
      float: "right",
    },
  },

  [`& .${classes.header}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: " space-between",
  },

  [`& .${classes.profiletitle}`]: {
    paddingLeft: "16px",
  },

  [`& .${classes.borderBottom}`]: {
    borderBottom: "1px dotted gray",
    padding: "4px 0",
    margin: " 0 -16px 8px -16px",
  },

  [`& .${classes.card}`]: {
    padding: "16px",
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.iconpdf}`]: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(-1),
  },

  [`& .${classes.label}`]: {
    color: "gray",
  },

  [`& .${classes.avatar}`]: {
    width: "60px",
    height: "60px",
    fontSize: "30px",
  },

  [`& .${classes.reactSpinner}`]: {
    position: "absolute",
    height: "100%",
    width: "100%",
    background: "#f4f6f870",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: "4px",
  }
}));



const ProfileDetails = (props) => {

  const [userBasicInfo, setUserBasicInfo] = useState([]);
  const [userAddress, setUserAddress] = useState([]);
  const [states, setStates] = useState([]);
  const [country, setCountry] = useState([]);
  const [formMode, setFormMode] = useState(false);
  const userID  = props.authReducer.auth.user && props.authReducer.auth.user.id;
  const [loading, setLoading] = useState(false);
  const [severity, setSeverity] = React.useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState("");

  const [userBasicData, setUserBasicData] = useState({
    selectrole: [],
    login: false,
    loginphone: "",
    userType: "",
    firstName: "",
    lastname: "",
    email: "",
    phone: "",
    pan: "",
    addresslineone: "",
    addresslinetwo: "",
    city: "",
    state: ["KARNATAKA"],
    postcode: "",
    country: ["INDIA"],
    companyName: "",
    companyregistered: "",
    gstin: "",
    companypan: "",
    priceupdate: false,
    internalrepresentative: [],
  });

  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)

  
  useEffect(() => {
    const getUserDetails =(async()=>{
      let initValues = { ...userBasicData };
      await userService.getUserDetails(userID).then((res) => {
        if (res) {
         
          if (res.status === 200) {
            setUserBasicInfo(res.data[0]);
            //   setSpinner(false)
            //   if (res.data[0].code === "COMPANY") {
            //     setContactInfo("COMPANY");
            //   }
            initValues.selectrole = res.data[0].user_role;
            initValues.login = res.data[0].required_login;
            initValues.loginphone = res.data[0].login_mobile_no;
            initValues.userType = res.data[0].code;
            initValues.firstName = res.data[0].first_name;
            initValues.lastname = res.data[0].last_name;
            initValues.email = res.data[0].email;
            initValues.phone = res.data[0].contact_no;
            initValues.pan = res.data[0].pan;
            initValues.companyName = res.data[0].company_name || "";
            initValues.companyregistered = res.data[0].company_registered_no || "";
            initValues.gstin = res.data[0].gstin || "";
            initValues.companypan = res.data[0].pan || "";
            initValues.priceupdate = res.data[0].required_price_update;
            initValues.internalrepresentative = {
              id: res.data[0].reporting_user_code,
              label: `${res.data[0].reporting_user_first_name} ${res.data[0].reporting_user_last_name}`,
            };
          }else{
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true)
            }
          }
        }
      });
  
      //get address and demat
      await userService.getUserAddress(userID).then((res) => {
        if (res) {      
          if (res.status === 200) {
            initValues.addresslineone = res.data[0].address1 || "";
            initValues.addresslinetwo = res.data[0].address2 || "";
            initValues.city = res.data[0].city;
            initValues.state = res.data[0].state_code;
            initValues.country = res.data[0].country_code;
            initValues.postcode = res.data[0].zipcode;
          }else{
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true)
            }
          }
        }
      });
      setUserBasicData(initValues);
    })
    getUserDetails()
  }, [formMode]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  const getDetails = () => {
    if (userID) {
      userService.getUserDetails(userID).then((res) => {
        if (res) {
         
          if (res.status === 200) {
            setUserBasicInfo(res.data[0]);
          }else{
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true)
            }
          }
        }
      });
      userService.getUserAddress(userID).then((res) => {
        if (res) {
         
          if (res.status === 200) {
            setUserAddress(res.data[0]);
          }else{
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true)
            }
          }
        }
      });
    }
  };

  useEffect(() => {
    getDetails();
    getLookUpDataService.getLookUpData("state").then((res) => {
      if (res) {
      
        if (res.status === 200) {
          if (res.data) {
            setStates(res.data[0].state);
          }
        }else{
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenSessionExpiredModal(true)
          }
        }
      }
    });

    getLookUpDataService.getLookUpData("country").then((res) => {
      if (res) {
        
        if (res.status === 200) {
          if (res.data) {
            setCountry(res.data[0].country);
          }
        }else{
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenSessionExpiredModal(true)
          }
        }
      }
    });
  }, [userID]);

  const handleEditBtn = () => {
    setFormMode(true);
  };

  const submitUserBasic = (values) => {
    const userRoles = values.selectrole.map((item) => item.code);
    const userAddress = {
      address1: values.addresslineone,
      address2: values.addresslinetwo,
      city: values.city,
      state: values.state,
      country: values.country,
      zipcode: values.postcode,
    };

    let userData = {
      user_type: values.userType,
      first_name: values.firstName,
      last_name: values.lastname,
      email: values.email,
      contact_no: values.phone,
      pan: values.pan,
      required_login: values.login,
      required_price_update: values.priceupdate,
      gstin: values.gstin,
      user_role: userRoles,
      username: values.loginphone,
      login_mobile_no: values.loginphone || null,
      company_name: values.companyName,
      company_registration_no: values.companyregistered,
      reporting_user: toString(values.internalrepresentative.id),
    };
    userService.updateUser(userID, userData).then((res) => {
      if (res) {  
        if (res.status === 200) {
          setLoading(false)
          setSeverity("success");
          setSnackbarTitle("User Record Updated");
          setOpenSnackBar(true);
          getDetails(); }
          else if (res.status === 400) {
            setLoading(false);
            if (res.data === "PAN_ALREADY_USED") {
              setSeverity("error");
              setSnackbarTitle("PAN already used");
              setOpenSnackBar(true);
            } else if (res.data === "COMPANEY_GSTIN_ALREADY_EXISTS") {
              setSeverity("error");
              setSnackbarTitle("GST already used");
              setOpenSnackBar(true);
            } else {
              if (res.status === 401 && res.data.name === "TokenExpiredError") {
                setOpenSessionExpiredModal(true);
              } else {
                setSeverity("error");
                setSnackbarTitle("OOPS something went wrong!");
                setOpenSnackBar(true);
              }
            }
          }
        // } else {
        //   if (res.status === 401 && res.data.name === "TokenExpiredError") {
        //     setOpenSessionExpiredModal(true)
        //   }else{
        //     setSeverity("error");
        //     setSnackbarTitle("Unable Update (BAD REQUEST)");
        //     setOpenSnackBar(true);
        //   }
        // }
      } else {
        setLoading(false);
      }
    });

    //update Address

    userService.updateUserAddress(userID, userAddress).then((res) => {
      if (res) {
        if (res.status === 200) {
          getDetails();
          setSeverity("success");
          setSnackbarTitle("User Address Updated");
          setOpenSnackBar(true);
          setFormMode(false);
        } else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenSessionExpiredModal(true)
          }else{
            setSeverity("error");
            setSnackbarTitle("Unable Update (BAD REQUEST)");
            setOpenSnackBar(true);
          }
        }
      } 
    });
  };

  return (
    <Root>
    <Page title="Unlistedkart | Profile">
      {formMode === false ? (
        <Card className={classes.card}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={10} md={10} lg={10}>
              <div className={classes.profileIcon}>
                <Avatar
                  className={classes.avatar}
                  alt={userBasicInfo.code === "INDIVIDUAL" ? userBasicInfo.first_name : userBasicInfo.company_name}
                  src="/static/images/avatar/1.jpg"
                />
                  { userBasicInfo.code === "INDIVIDUAL" ? (
                    <div className={classes.profiletitle}>
                     <Typography variant="h5">
                       {userBasicInfo.first_name} {userBasicInfo.last_name}
                     </Typography>
                     <Typography variant="body2">{userBasicInfo.email}</Typography>
                   </div>
                  ) : (
                    <div className={classes.profiletitle}>
                      <Typography variant="h5">
                        {userBasicInfo.company_name} 
                      </Typography>
                    
                    </div>
                  ) }
              </div>
            </Grid>
            <Grid item xs={12} sm={2} md={2} lg={2}>
              <Button
                variant="contained"
                size="small"
                onClick={handleEditBtn}
                className={classes.editButton}
                color="primary"
              >
                Edit
              </Button>
            </Grid>
          </Grid>
          <div className={classes.borderBottom}></div>
          {userBasicInfo.code === "INDIVIDUAL" ? (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <Typography variant="body2" className={classes.label}>
                    First Name
                  </Typography>
                  <Typography variant="h6">{userBasicInfo.first_name}</Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <Typography variant="body2" className={classes.label}>
                    Last Name
                  </Typography>
                  <Typography variant="h6">{userBasicInfo.last_name}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Typography variant="body2" className={classes.label}>
                  Email
                </Typography>
                <Typography variant="h6">{userBasicInfo.email}</Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Typography variant="body2" className={classes.label}>
                  Phone
                </Typography>
                <Typography variant="h6">{userBasicInfo.contact_no}</Typography>
              </Grid>
            </Grid>
          </>
          
          ) : (

            <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Typography variant="body2" className={classes.label}>
              Company Name
              </Typography>
              <Typography variant="h6">{userBasicInfo.company_name}</Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Typography variant="body2" className={classes.label}>
              Registration Number
              </Typography>
              <Typography variant="h6">{userBasicInfo.company_registered_no}</Typography>
            </Grid>
          </Grid>
          ) }
        
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Typography variant="body2" className={classes.label}>
                PAN
              </Typography>
              <Typography variant="h6">{userBasicInfo.pan}</Typography>
            </Grid>
            {userBasicInfo.code !== "INDIVIDUAL" && (

              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Typography variant="body2" className={classes.label}>
                  GSTIN
                </Typography>
                <Typography variant="h6">
                  {userBasicInfo.gstin && userBasicInfo.gstin}
                </Typography>
              </Grid>
            )}

            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Typography variant="body2" className={classes.label}>
                Type
              </Typography>
              <Typography variant="h6">{userBasicInfo.code}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8} md={8} lg={8}>
              <Typography variant="body2" className={classes.label}>
                Address
              </Typography>
              <Typography variant="h6">
                {userAddress.address1 && userAddress.address1 !== null
                  ? userAddress.address1 + ", "
                  : ""}
                {userAddress.address2 && userAddress.address2 !== null
                  ? userAddress.address2 + ", "
                  : ""}
                {userAddress.city && userAddress.city !== null
                  ? userAddress.city + ","
                  : ""}
                {userAddress.zipcode && userAddress.zipcode !== null
                  ? userAddress.zipcode + ", "
                  : ""}
                {userAddress.state && userAddress.state !== null
                  ? userAddress.state + ", "
                  : ""}
                {userAddress.country && userAddress.country !== null
                  ? userAddress.country
                  : ""}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      ) : (
        <Card className={classes.card}>
          <Formik
            enableReinitialize={true}
            initialValues={userBasicData}
            validationSchema={Yup.object().shape({
              gstin: Yup.string().matches("[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}Z[0-9A-Za-z]{1}", "Incorrect format"),
              pan: Yup.string()
                .nullable()
                .required()
                .matches("[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}", "PAN is invalid"),
              postcode: Yup.string()
                .nullable()
                .max(10, "max length of 10 Digit"),
              firstName: Yup.string().when("userType", {
                is: "INDIVIDUAL",
                then: Yup.string().required("Required"),
                otherwise: Yup.string(),
              }),
              companyName: Yup.string().when("userType", {
                is: "COMPANY",
                then: Yup.string().required("Required"),
                otherwise: Yup.string(),
              }),
  
              email: Yup.string().when("userType", {
                is: "INDIVIDUAL",
                then: Yup.string().email("Enter a valid email").required("Email is invalid"),
                otherwise: Yup.string(),
              }),
              phone: Yup.string().when("userType", {
                is: "INDIVIDUAL",
                then: Yup.string().required("Phone is required"),
                otherwise: Yup.string(),
              }),
            })}
            onSubmit={(values) => {
              setLoading(true);
              submitUserBasic(values);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              setFieldValue,
              values,
              dirty,
            }) => (
              <form onSubmit={handleSubmit} style={{ position: "relative" }}>
            
                <div style={{ padding: "1rem" }}>
                  <React.Fragment>
                    <Grid container spacing={5} alignItems="center">
                      {
                        userBasicData.userType !== "COMPANY" ? (
                          <>
                            <Grid item xs={12} sm={6} md={3} lg={3}>
                              <TextField
                                label="First Name*"
                                id="firstName"
                                name="firstName"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={Boolean(touched.firstName && errors.firstName)}
                                fullWidth
                                helperText={touched.firstName && errors.firstName}
                                value={values.firstName}
                              />
                              <ErrorFocus />
                            </Grid>
                            
                            <Grid item xs={12} sm={6} md={3} lg={3}>
                              <TextField
                                fullWidth
                                label="Last Name"
                                id="last-name"
                                name="lastname"
                                type="lastname"
                                value={values.lastname}
                                onChange={handleChange}
                              />
                            </Grid>
                            
                            <Grid item xs={12} sm={6} md={4} lg={4}>
                              <TextField
                                label="Email*"
                                id="email"
                                name="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={Boolean(touched.email && errors.email)}
                                fullWidth
                                helperText={touched.email && errors.email}
                                value={values.email}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3}>
                              <TextField
                                label="Phone*"
                                id="phone"
                                name="phone"
                                fullWidth
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(touched.phone && errors.phone)}
                                helperText={touched.phone && errors.phone}
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                }}
                                InputProps={{
                                  // readOnly: true,
                                  maxLength: 10,
                                  // startAdornment: (
                                  //   <InputAdornment position="start">
                                  //     +91
                                  //   </InputAdornment>
                                  // ),
                                }}
                                value={values.phone}
                              />
                            </Grid>
                          </>
                        ) : (
                          <>

                            <Grid item xs={12} sm={6} md={3} lg={3}>
                              <TextField
                                label="Company Name*"
                                id="companyName"
                                name="companyName"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={Boolean(touched.companyName && errors.companyName)}
                                fullWidth
                                helperText={touched.companyName && errors.companyName}
                                value={values.companyName}
                              />
                              <ErrorFocus />
                            </Grid>

                          </>
                        )
                      }

                      <Grid item xs={12} sm={6} md={3} lg={3}>
                        <TextField
                          label="Pan*"
                          id="pan"
                          name="pan"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(touched.pan && errors.pan)}
                          fullWidth
                          helperText={touched.pan && errors.pan}
                          value={values.pan}
                          inputProps={{ maxLength: 10 }}
                        />
                      </Grid>
                      {values.userType !== "INDIVIDUAL" ? (
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                          <TextField
                            label="GSTIN"
                            id="gstin"
                            name="gstin"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            error={Boolean(touched.gstin && errors.gstin)}
                            fullWidth
                            helperText={touched.gstin && errors.gstin}
                            value={values.gstin}
                          />
                        </Grid>
                      ) : null}

                      <Grid item xs={12} sm={6} md={3} lg={3}>
                        <TextField
                          id="address-line1"
                          label="Address Line 1"
                          name="addresslineone"
                          fullWidth={true}
                          onChange={handleChange}
                          value={values.addresslineone}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={3}>
                        <TextField
                          id="address-line2"
                          label="Address Line 2"
                          name="addresslinetwo"
                          fullWidth={true}
                          onChange={handleChange}
                          value={values.addresslinetwo}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={3} lg={3}>
                        <TextField
                          id="city"
                          label="City"
                          name="city"
                          fullWidth={true}
                          value={values.city}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={3} lg={3}>
                        <FormControl style={{ width: "100%" }}>
                          <InputLabel id="demo-simple-select-helper-label">
                            State
                          </InputLabel>
                          <Select
                            id="state"
                            name="state"
                            value={values.state}
                            onChange={handleChange}
                          >
                            {states.map((data) => (
                              <MenuItem value={data.code}>
                                {data.description}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6} md={3} lg={3}>
                        <TextField
                          id="postal-code"
                          name="postcode"
                          label="Postal Code"
                          fullWidth={true}
                          value={values.postcode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.postcode && errors.postcode)}
                          helperText={touched.postcode && errors.postcode}
                          inputProps={{ maxLength: 10 }}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={3}>
                        <FormControl style={{ width: "100%" }}>
                          <InputLabel id="demo-simple-select-helper-label">
                            Country
                          </InputLabel>
                          <Select
                            id="country"
                            name="country"
                            value={values.country}
                            onChange={handleChange}
                          >
                            {country.map((data) => (
                              <MenuItem value={data.code}>
                                {data.description}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                </div>

                <div className={classes.btnroot}>
                  <div style={{ textAlign: "end" }} className={classes.wrapper}>
                    <Button
                      color="primary"
                      size="medium"
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      {loading === false ? (
                        "Update"
                      ) : (
                        <>
                          <img
                            alt='loader'
                            src={loadingSpinner}
                            className={classes.buttonProgress}
                          />{" "}
                          Update{" "}
                        </>
                      )}
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </Card>
      )}

      <DematAccountData userID={userID} edit={true} />

      <BankAccountData userID={userID} edit={true} />

      <SnackBar
        open={openSnackBar}
        severity={severity}
        close={handleClose}
        snackbarTitle={snackbarTitle}
      />
              <SessionExpiredModal
                open={openSessionExpiredModal}
            />
    </Page>
    </Root>
  );
};

// export default  ProfileDetails

const mapStateToProps = (state) => {
  return {
    force: state, // force state from reducer
    authReducer: state.authReducer,
  };
};

export default connect(mapStateToProps, null)(ProfileDetails);
