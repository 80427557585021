import React, { useEffect, } from "react";
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import {
  Container,
  TextField,
  Grid,
  Typography,
  FormHelperText,
  OutlinedInput
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import Autocomplete from '@mui/material/Autocomplete';
import { getLookUpDataService } from "src/_services/lookUp"
import loadingSpinner from 'src/images/loading-spinner.gif'
import { useState } from "react";
import { Field } from "formik";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const PREFIX = 'MailTemplate';

const classes = {
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  inputMargin: `${PREFIX}-inputMargin`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.container}`]: {
    justifyContent: "center",
    // justifyItems: "center",

    marginTop: "10%",
  },

  [`& .${classes.label}`]: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.inputMargin}`]: {
    marginBottom: theme.spacing(2),
  }
}));


function MailTemplate({template,formSubmit}) {


  const [mailTemplates, setMailTemplates] = React.useState([{
    id:"",
    lable:"Loading..."
  }])
  const [loading, setLoading] = React.useState(false)
  
  const [templates,setTemplates] = useState([])
    const initialData = template || {
        mail_type: "",
        subject:'',
        email_template:'',
        file:''
    }
  useEffect(() => {
    getLookUpDataService.getMailTemplates()
      .then((res) => {
        if (res) {
          if (res.status === 200){
            setTemplates(res.data.data)
              let newData =  res.data.data.map((item)=>{
                return{
                    id:item.id,
                    lable:item.subject
                }
              })
            setMailTemplates(newData)
          }
        }
      })
  }, [])


  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialValues={initialData}
        validationSchema={Yup.object().shape({
          mail_type: Yup.string().required("Required"),
          subject: Yup.string().required("Required"),
          email_template: Yup.string().required("Required"),

        })}
        onSubmit={(values) => {
          setLoading(true)
          formSubmit(values)
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,

          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Autocomplete
                    id="document_type"
                    options={mailTemplates}
                    value={values.mail_type}
                    getOptionLabel={(option) => option.lable}
                    onChange={(e, value) => {
                      let currentTemplate = templates.find((item)=>{
                        return item.id === value?.id
                      })
                      setFieldValue('mail_type',value)
                      setFieldValue('subject',currentTemplate.subject)
                      setFieldValue('email_template',currentTemplate.email_template)

                    }}
                    disabled
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.mail_type && errors.mail_type)}
                        helperText={touched.mail_type && errors.mail_type}
                        name="mail_type"
                        label="Document Type *"
                        fullWidth
                        className={classes.inputMargin}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>  
                    <TextField
                     error={Boolean(touched.subject && errors.subject)}
                     helperText={touched.subject && errors.subject}
                     name="subject"
                     label="Mail Subject*"
                     fullWidth
                     InputLabelProps={{
                        shrink: true,
                        }}
                     onChange={handleChange}
                     value={values.subject}
                     className={classes.inputMargin}
                     variant="outlined"
                     />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography>Email Content</Typography>
                      <Field
                        name="email_template"
                        render={({ field, form,handleChange }) => {
                          return (   
                            <CKEditor
                                editor={ClassicEditor}
                                data={field.value}
                                config={{
                                  toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'numberedList', 'bulletedList', 'insertTable',
                                    '|', 'undo', 'redo']
                                }}
                                onChange={(e, editor) => {
                                  form.setFieldValue("email_template", editor.getData());
                                }}
                              />
                          )
                        }}
                      />
                      <FormHelperText style={{ color: errors.email_template && "#F44336" }}>
                        {touched.email_template && errors.email_template}
                      </FormHelperText>
                    </Grid>
                    <Typography variant="h5" style={{paddingTop:'1rem'}}>Disclaimer (Which sent to customer by default) :</Typography>
                    <Typography style={{paddingTop:'4px'}} ><i>NSE share transfers are subject to approval from NSE itself upon analyzing the fit and proper for each client.UnlistedKart does not have control over the timelines for NSE trades.</i></Typography>

                    <Grid item xs={12} sm={12} md={12} lg={12}>

                    <Typography style={{paddingTop:'1rem'}}>Upload File(If Required)</Typography>
                <OutlinedInput
                  accept="image/*"
                  // className={classes.input}
                  id="file"
                  name="file"
                  fullWidth={true}
                  onChange={(e) => {
                    setFieldValue("file", e.currentTarget.files[0]);
                  }}
                  type="file"
                />
                </Grid>
              </Grid>
             
              <Button
                color="primary"
                disabled={isSubmitting}
                className={classes.popDownBtn}
                size="medium"
                type="submit"
                variant="contained"
              >
                {loading === false ? "Send" : <><img alt="loader" src={loadingSpinner} className={classes.buttonProgress} /> Send </>}
              </Button>
            </Container>
          </form>
        )}
      </Formik>
    
    </StyledContainer>
  );
}

export default MailTemplate;
