import React from 'react';
import { styled } from '@mui/material/styles';

import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  Typography,
  
  } from '@mui/material';



const PREFIX = 'ServiceOrderStatusModal';

const classes = {
  root: `${PREFIX}-root`,
  root2: `${PREFIX}-root2`,
  closeButton: `${PREFIX}-closeButton`,
  zeroPadding: `${PREFIX}-zeroPadding`,
  cardBorderRadius: `${PREFIX}-cardBorderRadius`,
  cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
  fileInput: `${PREFIX}-fileInput`,
  imageSizes: `${PREFIX}-imageSizes`,
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  marginInputLabel: `${PREFIX}-marginInputLabel`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  resendBtnMargin: `${PREFIX}-resendBtnMargin`,
  inputMargin: `${PREFIX}-inputMargin`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.zeroPadding}`]: {
    padding:0
  },

  [`& .${classes.cardBorderRadius}`]: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },

  [`& .${classes.cardHeaderMargin}`]: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    justifyContent:"center",
    borderRadius: "0 0  10px 0",
  },

  [`& .${classes.fileInput}`]: {
    display: "none"
  },

  [`& .${classes.imageSizes}`]: {
    width: "40%",
    margin: "auto",
    display: "block",
  },

  [`& .${classes.container}`]: {
    justifyContent: "center",
    // justifyItems: "center",

    marginTop: "10%",
  },

  [`& .${classes.label}`]: {
    marginBottom:theme.spacing(1),
    color:theme.palette.primary.main
  },

  [`& .${classes.marginInputLabel}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.resendBtnMargin}`]: {
    marginTop: theme.spacing(3)
  },

  [`& .${classes.inputMargin}`]: {
    marginBottom:theme.spacing(2)
  },

  [`& .${classes.closeButton}`]:{
    position: 'absolute',
      right: theme.spacing(0),
      top: theme.spacing(0),
      color: theme.palette.primary.main,
  }
}));

const CustomDialogTitle = ((props) => {
  const { children,  onClose, ...other } = props;
  return (
    <MuiDialogTitle  className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" sx={{
          position: 'absolute',
          right:0,
          top:0,
          // color: theme.palette.primary.main,
        }} className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = MuiDialogContent;



function ServiceOrderStatusModal(props) {



 
  const handleClose = () => {
    props.toggle()
  };

  return (
    <Root>    
      <Dialog fullWidth={true}  onClose={handleClose} aria-labelledby="customized-dialog-title" open={props.open}  className= {classes.cardBorderRadius}>
        <CustomDialogTitle id="customized-dialog-title" onClose={handleClose} className= {classes.zeroPadding}>
          <Typography variant="h6" className={classes.cardHeaderMargin}>
            {props.title}
          </Typography>
        </CustomDialogTitle>
            <DialogContent
              classes={{
                root: classes.root
              }}>
                {props.children}          
            </DialogContent>        
      </Dialog>
    </Root>
  );
}


export default  ServiceOrderStatusModal;