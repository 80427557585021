import React, { useEffect } from "react";
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import ErrorFocus from "../../components/ErrorFocus/ErrorFocus";
import {
  Container,
  TextField,
  Grid,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Chip,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import moment from "moment";
import loadingSpinner from "src/images/loading-spinner.gif";
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal";
import { getLookUpDataService } from "src/_services/lookUp";
import Autocomplete from "@mui/material/Autocomplete";

const PREFIX = 'OrderExportForm';

const classes = {
  zeroPadding: `${PREFIX}-zeroPadding`,
  cardBorderRadius: `${PREFIX}-cardBorderRadius`,
  cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
  fileInput: `${PREFIX}-fileInput`,
  imageSizes: `${PREFIX}-imageSizes`,
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  marginInputLabel: `${PREFIX}-marginInputLabel`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  resendBtnMargin: `${PREFIX}-resendBtnMargin`,
  inputMargin: `${PREFIX}-inputMargin`,
  paddingInput: `${PREFIX}-paddingInput`,
  buttonProgress: `${PREFIX}-buttonProgress`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.zeroPadding}`]: {
    padding: 0,
  },

  [`& .${classes.cardBorderRadius}`]: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },

  [`& .${classes.cardHeaderMargin}`]: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    borderRadius: "0 0  10px 0",
  },

  [`& .${classes.fileInput}`]: {
    display: "none",
  },

  [`& .${classes.imageSizes}`]: {
    width: "40%",
    margin: "auto",
    display: "block",
  },

  [`& .${classes.container}`]: {
    justifyContent: "center",
    // justifyItems: "center",
    marginTop: "10%",
  },

  [`& .${classes.label}`]: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },

  [`& .${classes.marginInputLabel}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.resendBtnMargin}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.inputMargin}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.paddingInput}`]: {
    padding: "0px 0px",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: "4px",
  }
}));

const OrderExportForm = (props) => {

  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(
    false
  );
  const [loading, setLoading] = React.useState(false);
  const [client, setClient] = React.useState([]);
  const [tags, setTags] = React.useState([]);
  const [externalRM, setExternalRM] = React.useState([]);
  const [leadOwners, setLeadOwners] = React.useState([]);

  const initValues = {
    fromDate: moment(new Date()).format("YYYY-MM-DD"),
    toDate: moment(new Date()).format("YYYY-MM-DD"),
    userType: "all",
    orderType: "all",
    client: [],
    leadOwner: [],
    channelPartner:[],
    tagName:''
    // ownedBy: { id: props.authReducer.auth.user.user_unique_code, label: props.authReducer.auth.user.display_name },
  };

  useEffect(() => {
    getLookUpDataService
      .getLookUpUser("CHANNEL_PARTNER,INVESTER_CLIENT")
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            let item = res.data.map((items) => {
              return {
                id: items.id,
                code: items.user_code,
                lable: `${
                  items.user_name
                } ( ${items.user_code.toUpperCase()} )`,
              };
            });

            setClient(item);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
            }
          }
        }
      })
      .catch((error) => {
        console.log("error");
      });
       getLookUpDataService
      .getLookUpUser("CHANNEL_PARTNER")
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            let item = res.data.map((items) => {
              return {
                id: items.id,
                code: items.user_code,
                lable: `${
                  items.user_name
                } ( ${items.user_pan ?items.user_pan?.toUpperCase() :items.user_code.toUpperCase()} )`,
              };
            });

            setExternalRM(item);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
            }
          }
        }
      })
    getLookUpDataService
      .getLookUpUser("SALES,OPERATION")
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            let item = res.data.map((items) => {
              return {
                id: items.id,
                code: items.user_code,
                lable: `${items.user_name}`,
              };
            });
            setLeadOwners(item);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  const handleAddTag = (e, setFieldValue) => {
    if (e.key === "Enter" && e.target.value.trim() !== "") {
      e.preventDefault();
      if (e.target.value.includes(" ")) {
        let orderArray = e.target.value
          .split(" ")
          .map((item) => item.trim().replace(",", ""));
        setTags([...tags, ...orderArray]);
      } else {
        setTags([...tags, e.target.value]);
      }
      e.target.value = "";
    }
    setFieldValue("tagName", "");
  };

  const handleDelete = (i) => {
    setTags((tag) => tag.filter((name, index) => index !== i));
  };

  const selectOption = (value, options, setFieldValue, name, label,touched,errors) => {
    return (
      <Grid item xs={12}>
        <Autocomplete
          multiple={props.type === "MIS"}
          id="client"
          // name="investorOrVendor"
          value={value}
          options={options}
          getOptionLabel={(option) => option.lable || ""}
          onChange={async (e, value) => {
            if (value !== null) {
              setFieldValue(name, value);
            }
          }}
          renderInput={(params) => <TextField
           
             {...params} label={label} />}
        />
        <ErrorFocus />
      </Grid>
    );
  };

  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialValues={initValues}
        validationSchema={Yup.object().shape({
          fromDate: Yup.string().required("Required"),
          toDate: Yup.string().required("Required"),
        })}
        onSubmit={(values) => {
          setLoading(true);
          let orderId = [];
          if (values.tagName?.includes(" ")) {
            let orderArray = values.tagName
              .split(" ")
              .map((item) => item?.trim().replace(",", ""));
            orderId = [...tags, ...orderArray];
          } else {
            orderId = [...tags, values.tagName];
          }
          const orders = orderId
            .map((order) => order.replace(/\D/g, ""))
            .map((order) => Number(order));
          const filteredArr = orders.filter((item) => item !== "" && item !== 0);
          props.formSubmit({...values,order_id:filteredArr});
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <div></div>
            <Container className={classes.container}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControl
                    component="fieldset"
                    error={Boolean(touched.userType && errors.userType)}
                  >
                    <FormLabel component="legend">User type</FormLabel>
                    <RadioGroup
                      row
                      aria-label="gender"
                      name="userType"
                      value={values.userType}
                      id="userType"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue("userType", e.target.value);
                        props.type === "MIS" && e.target.value === "online"
                          ? setFieldValue("orderType", "sell")
                          : setFieldValue("orderType", "all");
                      }}
                    >
                      {/* <FormControlLabel value="1" control={<Radio color="primary" />} label="Micro" /> */}
                      <FormControlLabel
                        value="all"
                        control={<Radio color="primary" />}
                        label="All"
                      />
                      <FormControlLabel
                        value="online"
                        control={<Radio color="primary" />}
                        label="Online"
                      />
                      <FormControlLabel
                        value="offline"
                        control={<Radio color="primary" />}
                        label="Offline"
                      />
                    </RadioGroup>
                  </FormControl>
                  <ErrorFocus />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControl
                    component="fieldset"
                    error={Boolean(touched.orderType && errors.orderType)}
                  >
                    <FormLabel component="legend">Order type</FormLabel>
                    <RadioGroup
                      row
                      aria-label="gender"
                      name="orderType"
                      value={values.orderType}
                      id="orderType"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      {/* <FormControlLabel value="1" control={<Radio color="primary" />} label="Micro" /> */}
                      {props.type === "MIS" &&
                      values.userType === "online" ? null : (
                        <FormControlLabel
                          value="all"
                          control={<Radio color="primary" />}
                          label="All"
                        />
                      )}
                      {props.type === "MIS" &&
                      values.userType === "online" ? null : (
                        <FormControlLabel
                          value="buy"
                          control={<Radio color="primary" />}
                          label="Buy"
                        />
                      )}
                      <FormControlLabel
                        value="sell"
                        control={<Radio color="primary" />}
                        label="Sell"
                      />
                    </RadioGroup>
                  </FormControl>
                  <ErrorFocus />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="fromDate"
                    label="From Date"
                    type="date"
                    name="fromDate"
                    error={Boolean(touched.fromDate && errors.fromDate)}
                    helperText={touched.fromDate && errors.fromDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      inputProps: {
                        max: `${moment(new Date()).format("YYYY-MM-DD")}`,
                      },
                    }}
                    value={values.fromDate}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <ErrorFocus />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="toDate"
                    label="End Date"
                    type="date"
                    name="toDate"
                    value={values.toDate}
                    InputProps={{
                      inputProps: {
                        min: values.fromDate,
                        max: `${moment(new Date()).format("YYYY-MM-DD")}`,
                      },
                    }}
                    error={Boolean(touched.toDate && errors.toDate)}
                    helperText={touched.toDate && errors.toDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    // InputProps={{ inputProps: { min: `${moment(values.fromDate).format("YYYY-MM-DD")}` } }}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <ErrorFocus />
                </Grid>
               
                    {selectOption(
                      values.client,
                      client,
                      setFieldValue,
                      "client",
                      values.orderType === "sell"
                        ? "Investor"
                        : values.orderType === "buy"
                        ? "Vendor"
                        : "Investor/Vendor"
                    )}
                  {props.type === "MIS" ? 
                    selectOption(
                      values.leadOwner,
                      leadOwners,
                      setFieldValue,
                      "leadOwner",
                      "Lead Owner"
                    ):
                    <>
               { selectOption(
                  values.channelPartner,
                  externalRM,
                  setFieldValue,
                  "channelPartner",
                  "Channel Partner"
                )}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography textAlign={'center'}>Or</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  helperText=""
                  label={`Search by order id`}
                  onKeyPress={(e) => handleAddTag(e, setFieldValue)}
                  name="tagName"
                  // disabled={orders}
                  onChange={handleChange}
                />
                <div className={classes.wrap}>
                  {tags &&
                    tags.map((item, index) => (
                      <Chip
                        // disabled={orders}
                        style={{ margin: "4px" }}
                        key={index}
                        tabIndex={-1}
                        label={item}
                        onDelete={() => handleDelete(index)}
                      />
                    ))}
                </div>
              </Grid>
                    
                    </>
                }
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    className={classes.popDownBtn}
                    size="medium"
                    type="submit"
                    variant="contained"
                  >
                    {loading === false ? (
                      <> {"Download"}</>
                    ) : (
                      <>
                        <img
                          alt="loader"
                          src={loadingSpinner}
                          className={classes.buttonProgress}
                        />{" "}
                        Download{" "}
                      </>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </form>
        )}
      </Formik>
      <SessionExpiredModal open={openSessionExpiredModal} />
    </StyledContainer>
  );
};

export default OrderExportForm;
