import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import MUIDataTable from "mui-datatables";
import ExcelJS from 'exceljs';
import {
  Button,
  Typography,
  Box,
  AppBar,
  Tabs,
  Tab,
  IconButton,
  Grid,
  Tooltip,
  TableRow,
  TableCell,
  Autocomplete,
  TextField,
  FormControl,Slide,
  RadioGroup,
  FormControlLabel,
  Radio,
  Zoom
} from "@mui/material";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import ServiceCard from "src/components/Cards/ServiceCard";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import { serviceOrderService } from "src/_services/serviceOrder";
import Page from "src/components/Page";
import Chip from "@mui/material/Chip";
import { NavLink, useNavigate } from "react-router-dom";
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal";
import { useLocation } from "react-router-dom";
import ModalCentered from "src/components/Modals/ModalCentered";
import OrderExportForm from "./OrderExportForm";
import SnackBar from "src/components/SnackBar/SnackBar";
import { connect } from "react-redux";
import {  AddCircleOutline, DeleteOutline, InfoOutlined } from "@mui/icons-material";
// import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AlertModal from "src/components/AlertModal/AlertModal";
import StarRateIcon from "@mui/icons-material/StarRate";
import CopytoClipboard from "src/components/ButtonComponents/CopytoClipboard";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip1 from "../../components/Tooltip/tooltip";
import GenerateInvoice from "./GenerateInvoice";
import AdvanceSearchFormForOrders from "./AdvanceSearchFormForOrders";
import GetMuiTheme from "src/components/theme";
import DocumentsReport from "./DocumentsReport";
import BulkUpdateCommissionReport from "./BulkUpdateCommissionReport";
import ServiceOrderCreate from 'src/containers/ServiceOrders/ServiceOrderCreate';
import { getLookUpDataService } from "src/_services/lookUp";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import saveAs from "save-as";
import GenerateDealterm from "./DealtermForMultipleOrders";
import CountersignedDealterm from "./CountersignedDealterm";
import UploadCounterSignedDealTerm from "./UploadCounterSignedDealterm";
import BulkReferralUpdate from "./BulkReferralUpdate";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from "@mui/icons-material/Cancel";
import OrderStatusReport from "./OrderStatusReport";

const PREFIX = "ServiceOreder";

const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  btnright: `${PREFIX}-btnright`,
  container: `${PREFIX}-container`,
  button: `${PREFIX}-button`,
  editdeletbtn: `${PREFIX}-editdeletbtn`,
  btnsdltedt: `${PREFIX}-btnsdltedt`,
  detailsbtn: `${PREFIX}-detailsbtn`,
  inactive: `${PREFIX}-inactive`,
  closed: `${PREFIX}-closed`,
  completed: `${PREFIX}-completed`,
  processing: `${PREFIX}-processing`,
  preProcessing: `${PREFIX}-preProcessing`,
  cancelled: `${PREFIX}-cancelled`,
  hold: `${PREFIX}-hold`,
  pending: `${PREFIX}-pending`,
  active: `${PREFIX}-active`,
  stageOneProcessing: `${PREFIX}-stageOneProcessing`,
  stageOneProcessed: `${PREFIX}-stageOneProcessed`,
  stageTwoProcessed: `${PREFIX}-stageTwoProcessed`,
  stageTwoProcessing: `${PREFIX}-stageTwoProcessing`,
  completed2: `${PREFIX}-completed2`,
  shareTransferProcessing: `${PREFIX}-shareTransferProcessing`,
  bottomborder: `${PREFIX}-bottomborder`,
  subtitle: `${PREFIX}-subtitle`,
  formgroup: `${PREFIX}-formgroup`,
  popDownBtn: `${PREFIX}-popDownBtn`
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    margin: theme.spacing(2),
    "& .MuiTableHead-root": {
      background: "blue",
    },
  },

  [`& .${classes.card}`]: {
    borderBottom: "none",
    padding: 0,
  },

  [`& .${classes.btnright}`]: {
    textAlign: "end",
  },

  [`& .${classes.container}`]: {
    padding: "0px",
  },

  [`& .${classes.button}`]: {
    marginLeft: "8px !important",
    marginBottom: "1%",
    borderRadius: "14px 14px 0 0",
    boxShadow: "none",
    textTransform: "capitalize",
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },
  [`& .${classes.editdeletbtn}`]: {
    color: "#3363cb",
  },

  [`& .${classes.btnsdltedt}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.detailsbtn}`]: {
    boxShadow: "none",
    textTransform: "capitalize",
    borderRadius: "0px 20px 20px 0px",
  },

  // active: {
  //   color: "black",
  //   backgroundColor: "#ffdf00",
  // },
  [`& .${classes.inactive}`]: {
    color: "white",
    backgroundColor: "#D50000",
    fontWeight: "bold",
  },

  [`& .${classes.closed}`]: {
    color: "white",
    backgroundColor: "#314b8f",
    fontWeight: "bold",
  },

  [`& .${classes.completed}`]: {
    color: "white",
    backgroundColor: "#285430",
    fontWeight: "bold",
  },

  [`& .${classes.processing}`]: {
    color: "white",
    backgroundColor: "#CD7A00",
    fontWeight: "bold",
  },

  [`& .${classes.preProcessing}`]: {
    color: "white",
    backgroundColor: "#FFA559",
    fontWeight: "bold",
  },

  [`& .${classes.cancelled}`]: {
    color: "white",
    backgroundColor: "#226FCD",
    fontWeight: "bold",
  },

  [`& .${classes.hold}`]: {
    color: "white",
    backgroundColor: "#FA9884",
    fontWeight: "bold",
  },

  [`& .${classes.pending}`]: {
    color: "white",
    backgroundColor: "grey",
    fontWeight: "bold",
  },

  [`& .${classes.active}`]: {
    color: "white",
    backgroundColor: "#a526a5",
    fontWeight: "bold",
  },

  [`& .${classes.stageOneProcessing}`]: {
    color: "white",
    backgroundColor: "#87C4FF",
    fontWeight: "bold",
  },

  [`& .${classes.stageOneProcessed}`]: {
    color: "white",
    backgroundColor: "#39A7FF",
    fontWeight: "bold",
  },

  [`& .${classes.stageTwoProcessed}`]: {
    color: "white",
    backgroundColor: "#427D9D",
    fontWeight: "bold",
  },

  [`& .${classes.stageTwoProcessing}`]: {
    color: "white",
    backgroundColor: "#9BBEC8",
    fontWeight: "bold",
  },

  [`& .${classes.completed2}`]: {
    color: "white",
    backgroundColor: "#285430",
    fontWeight: "bold",
  },

  [`& .${classes.shareTransferProcessing}`]: {
    color: "white",
    backgroundColor: "#9d8b70",
    fontWeight: "bold",
  },

  [`& .${classes.bottomborder}`]: {
    backgroundColor: "#fff",
    borderBottom: "1px solid #e8e8e8",
  },

  [`& .${classes.subtitle}`]: {
    paddingLeft: "16px",
  },

  [`& .${classes.formgroup}`]: {
    display: "flow",
    cursor: "copy",
    minWidth: "150px",
    alignItems: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) {
  // eslint-disable-line
  return <NavLink to={props.to} {...props} />; // eslint-disable-line
});
const MyStyledButton = styled(Button)(({ theme }) => ({
  marginLeft: "8px ",
  marginBottom: "1%",
  borderRadius: "14px 14px 0 0",
  boxShadow: "none",
  textTransform: "capitalize",
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ServiceOrderItems = (props) => {
  const theme = useTheme();
  let location = useLocation();
  const navigate = useNavigate();
  const [sellOrderData, setSellOrderData] = React.useState([]);
  const [buyOrderData, setBuyOrderData] = React.useState([]);
  const [buyCount, setBuyCount] = React.useState(0);
  const [sellCount, setSellCount] = React.useState(0);
  const [openBulkUpdateDialog, setBulkUpdateDialog] = React.useState(false);

  const [deleteConfirm, setDeleteConfirm] = React.useState(false);
  const [orderType,setOrderType] = useState(location.state?.orderType?location.state?.orderType : 'all')
  const [activeTab, setActiveTab] = React.useState(
    location?.state?.tabValue ? 1 : 0
  );

  const [loading, setLoading] = React.useState(false);
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(
    false
  );
  const [page, setPage] = React.useState(
    location?.state?.page ? location.state.page : 0
  );

  const [hover, setHover] = useState(false);
  const [rowindex, setrowIndex] = React.useState("");
  const [openModal, setOpenModal] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [severity, setSeverity] = React.useState("");
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [timer, setTimer] = React.useState(false);
  const [copy, setCopy] = React.useState(false);
  const [dealTermData, setDealTermData] = React.useState(false);
  const [advanceFilter, setAdvanceFilter] = React.useState(location.state?.advanceSearchForm ? location.state?.advanceSearchForm : false);
  const [deleteId, setDeleteId] = React.useState(false);
  const [searchTextValue, setSearchText] = React.useState("");
  const [exportType, setExportType] = React.useState("");
  const userID = props?.authReducer?.auth?.user && props.authReducer.auth.user.id;
  const [advanceSearchForm, setAdvanceSearchForm] = useState(location.state?.advanceSearchForm ? location.state?.advanceSearchForm : false)
  const userRole =
    props?.authReducer?.auth?.user &&
    props.authReducer.auth.user.default_role_code;

  const onHover = () => {
    setHover(true);
  };

  const submitHandle = (values) => {
    setCustomFilter(false);
    setAdvanceSearchForm(values)
    setPage(0);
    setOrderType('all')
    getServiceOrder(0, "", values);
    setAdvanceFilter(values);
  };

  const getMuiTheme = GetMuiTheme;
  const CopyText = (value) => {
    return (
        <Tooltip title={copy ? "Copied" : "Click to Copy"}>
          <Typography 
            onClick={() => {
              navigator.clipboard.writeText(value);
              setCopy(true);
            }}
            onMouseLeave={() => setCopy(false)}
            sx={classes.formgroup}
          component={'span'}>{value}</Typography>
        </Tooltip>
    );
  };

  const Copy = (value) => {
    return (
      <div
        onClick={() => {
          navigator.clipboard.writeText(value);
          setCopy(true);
        }}
        onMouseLeave={() => setCopy(false)}
        className={classes.formgroup}
      >
        <Tooltip title={copy ? "Copied" : "Click to Copy"}>
          <Typography component={'span'}>{value}</Typography>
        </Tooltip>
      </div>
    );
  };

  const uniqueArray =((data)=>{
    return data.filter((item, index, self) => 
      index === self.findIndex((t) => t.related_service_order_id === item.related_service_order_id))
  })
  
  const commissionBulkUpdate = (async (values) => {
    const today= new Date()
    const date = moment(today).format("YYYY-MM-DD")
    const invoiceUploadArray = uniqueArray(values.orderList.filter(item => item.uploadInvoice).map(item => ({
      document_type:"OTHERS",
      document_name:`Commission_Invoice_${item.service_id}`,
      document_reference_date:date,
      related_service_order_id:item.order_id
    })));
    const orderList =[...new Set(values.orderList.map(item => item.order_id))]
    const commissonPaidProofArray =  uniqueArray(values.orderList.filter(item => item.uploadCommissionProof).map(item => ({
      document_type:"OTHERS",
      document_name:`Commission_Proof_${item.service_id}`,
      document_reference_date:date,
      related_service_order_id:item.order_id
    })))
    const othersCopyArray =  uniqueArray(values.orderList.filter(item => item.uploadOthers).map(item => ({
      document_type:"OTHERS",
      document_name:`${values.otherDocumentName}_${item.service_id}`,
      document_reference_date:date,
      related_service_order_id:item.order_id
    })))
    const formData = new FormData();
    formData.append('invoice_copy', values.invoiceCopy)
    formData.append('commission_proof',values.commissionProof)
    formData.append('other_copy',values.othersCopy)
    formData.append('commission_data', JSON.stringify(values.orderList))
    formData.append('commssion_proof_array',JSON.stringify(commissonPaidProofArray))
    formData.append('other_document_array',JSON.stringify(othersCopyArray))
    formData.append('invoice_array',JSON.stringify(invoiceUploadArray))
    formData.append('remarks',JSON.stringify(values.remarks))
    formData.append('remarks_array',JSON.stringify(orderList))
    formData.append('type', "multiple")
    const res = await serviceOrderService.commissionUpdate(formData)
    if (res.status === 200) {
      setSpinner(false);
      closeModalHandler();
      setOpen(true);
      setSnackbarTitle(res.data);
      setSeverity("success");
    } else if (res.status === 401) {
      setSpinner(false);
      closeModalHandler();
      setOpen(true);
      setTitleModal()
      setSnackbarTitle(res.data.message);
      setSeverity("error");
    } else {
      setSpinner(false);
      closeModalHandler();
      setTitleModal()
      setOpen(true);
      setSnackbarTitle("Something went wrong");
      setSeverity("error");
    }
  })

  const referralBulkUpdate = (async (values) => {
    const today= new Date()
    const date = moment(today).format("YYYY-MM-DD") 
    const othersCopyArray =  uniqueArray(values.orderList.filter(item => item.uploadOthers).map(item => ({
      document_type:"OTHERS",
      document_name:`${values.otherDocumentName}_${item.service_id}`,
      document_reference_date:date,
      related_service_order_id:item.order_id
    })))
    const formData = new FormData();
    formData.append('other_copy',values.othersCopy)
    formData.append('other_document_array',JSON.stringify(othersCopyArray))
    formData.append('commission_data', JSON.stringify(values.orderList))
    const res = await serviceOrderService.bulkReferralUpdate(formData)
    if (res.status === 200) {
      setSpinner(false);
      closeModalHandler();
      setOpen(true);
      setSnackbarTitle(res.data);
      setSeverity("success");
    } else if (res.status === 401) {
      setSpinner(false);
      closeModalHandler();
      setOpen(true);
      setTitleModal()
      setSnackbarTitle(res.data.message);
      setSeverity("error");
    } else {
      setSpinner(false);
      closeModalHandler();
      setTitleModal()
      setOpen(true);
      setSnackbarTitle("Something went wrong");
      setSeverity("error");
    }
  })

  const csdlBulkUpdate =(async (values)=>{
    const formData = new FormData();
    formData.append('file', values.doc)
    formData.append('order_id',JSON.stringify(values.order_id)) 
    formData.append("document_type", 'COUNTER_SIGNED_DEAL_TERM');
    formData.append("document_name", 'Counter Signed Deal Term');
    formData.append("document_reference_date", moment(new Date()).format("YYYY-MM-DD"));

    const res = await serviceOrderService.csdlUpdate(formData)
    if (res.status === 200) {
      setSpinner(false);
      closeModalHandler();
      setOpen(true);
      setSnackbarTitle(res.data);
      setSeverity("success");
    } else if (res.status === 401) {
      setSpinner(false);
      closeModalHandler();
      setOpen(true);
      setTitleModal()
      setSnackbarTitle(res.data.message);
      setSeverity("error");
    } else {
      setSpinner(false);
      closeModalHandler();
      setTitleModal()
      setOpen(true);
      setSnackbarTitle("Something went wrong");
      setSeverity("error");
    }

  })

  const renderTooltip = (value, type,user) => {
    const investorDetails =
      type === "Buy"
        ? buyOrderData[value.rowIndex]
        : sellOrderData[value.rowIndex];
    const customerInfo = [
      {
        name: type === "Buy" ? "Vendor" : "Investor",
        key:
          type === "Buy" ? investorDetails?.vendor : investorDetails?.investor,
      },
      {
        name: "Pan",
        key:
          type === "Buy"
            ? investorDetails?.vendor_pan === "undefined"
              ? ""
              : investorDetails?.vendor_pan
            : investorDetails?.investor_pan,
      },
      {
        name: "Contact",
        key:
          type === "Buy"
            ? investorDetails?.vendor_contact === "undefined"
              ? ""
              : investorDetails?.vendor_contact
            : investorDetails?.investor_contact,
      },
      { name: "Bank Name", key: investorDetails?.client_bank_name },
      {
        name: "Bank Account Number",
        key: investorDetails?.client_bank_account_no,
      },
      { name: "Bank IFSC", key: investorDetails?.client_bank_ifsc_code },
      { name: "DP Id", key: investorDetails?.client_dp_id },
      { name: "Client Id", key: investorDetails?.client_client_id },
    ];

    let cpInfo=[
      {
        name:   "Name",
        key: investorDetails?.channel_partner,
      },
      {
        name: "Pan",
        key: investorDetails?.channel_partner_pan ? investorDetails?.channel_partner_pan.toUpperCase() : null
      },
      { 
        name: "Contact",
        key: investorDetails?.channel_partner_contact
      },
      { 
        name: "Bank Name",
        key: investorDetails?.cp_bank_name
      },
      {
        name: "Account Number",
        key: investorDetails?.cp_bank_account_number,
      },
      { 
        name: "Bank IFSC",
        key: investorDetails?.cp_ifsc_code 
      },
      { 
        name: "Account Type",
        key: investorDetails?.cp_bank_type 
      }
    ]

   let userInfo =  user ==='cp' ? cpInfo: customerInfo
    return (
      <Root>
        <Grid container>
          {userInfo.map((item) => {
            return (
              <Grid container key={item.key}>
                <Grid item xs={6}>
                  <Typography style={{ marginTop: "8px" }} >
                    {item.name}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  {Copy(item.key)}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Root>
    );
  };

  const renderChannelPartnerTooltip = (value, type) => {
    const investorDetails =
      type === "Buy"
        ? buyOrderData[value.rowIndex]
        : sellOrderData[value.rowIndex];
    const data = investorDetails.commission_split_data.filter((item) => item.related_user_id !== investorDetails.related_channel_partner_id)
    return (
      <Root>
        <Grid container>
          {data.map((item, index) => {
            return (
              <Grid container key={item.id}>
                <Grid item xs={6}>
                  <Typography style={{ marginTop: "8px" }}>
                    Channel Partner {index + 1}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  {Copy(item.name)}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Root>
    );
  };
  const sellColumnData = [
    {
      name: "_id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "service_id",
      label: "Order Id",
      options: {
        hint: "Unique Order Number",
        filter: true,
        sort: true,
        setCellProps: (value, a) => {
          return {
            style: {
              whiteSpace: "nowrap",
              position: "sticky",
              left: "0",
              background: sellOrderData[a].is_purchased_online ? "#e7eaff" : "#fff",
              zIndex: 100
            }
          }
        },
        setCellHeaderProps: () => {
          return {
            style: {
              whiteSpace: "nowrap",
              position: "sticky",
              left: 0,
              zIndex: 101
            }
          }
        },
        customBodyRender: (value, tableMeta) => {
          return (
            <div
              style={{ width: "10rem" }}
              className={classes.formgroup}
              onMouseEnter={() => {
                onHover();
                setrowIndex(tableMeta.rowData[0]);
              }}
              onMouseLeave={() => setrowIndex()}
            >
              <Typography
                variant="body2"
                component={LinkBtn}
                noWrap={false}
                // className={classes.formgroup}
                to={`/app/service-order-details/${tableMeta.rowData[0]}`}
                state={{
                  page: page,
                  orderType:orderType,
                  data: tableMeta.currentTableData,
                  index: tableMeta.rowIndex,
                  advanceSearchForm:advanceSearchForm
                }}
                color="primary"
              >
                {tableMeta.rowData[8]
                  ? tableMeta.rowData[14] !== null
                    ? tableMeta.rowData[14]
                    : value
                  : value}
              </Typography>
              {rowindex === tableMeta.rowData[0] && (
                <Tooltip
                  style={{ cursor: "copy" }}
                  title={hover ? "Copied" : "Click to Copy"}
                >
                  <CopytoClipboard
                    userBasicInfo={
                      tableMeta.rowData[8]
                        ? tableMeta.rowData[14] !== null
                          ? tableMeta.rowData[14]
                          : value
                        : value
                    }
                    state={hover}
                  />
                </Tooltip>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "create_datetime",
      label: "Created Date",
      options: {
        filter: false,
        sort: true,
        hint: "Date of Transaction Initiation",
        customBodyRender: (value) => {
          let valueMoment = moment(value).format("DD-MM-YYYY LT");
          return (
            <div className={classes.tableEllips}>
              <Typography variant="body2" noWrap component={'span'}>
                {valueMoment}
              </Typography>
            </div>
          );
        },
      },
    },

    {
      name: "is_tcs_crossed",
      label: "Type",
      options: {
        hint: "Type of ",
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "transaction_type_code",
      label: "Transaction Type",
      options: {
        hint: "Information on Type of Relation",
        filter: false,
        sort: false,
      },
    },
    {
      name: "investor",
      label: "Investor",
      options: {
        hint: "Party Investing with Unlistedkart",
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const tds_tcs = Number(tableMeta.rowData[3] > 5000000) ? true : false;
          return (
            <div
              className={classes.formgroup}
            >
                <Typography 
                 variant="body2"
                 noWrap={false}
                 sx={{alignItems:'center'}}
                 color="primary"
                 onDoubleClick={()=>{
                  window.open(`/app/user-details/${sellOrderData[tableMeta.rowIndex].inv}`,'_blank')
                 }}
                >
                  {CopyText(value)}{" "}
                  <Tooltip title="Beneficiary status">
                  {tableMeta.rowData[17]  ?  <CheckCircleOutlineIcon
                  style={{ color: "green",fontSize:'10px'}}
                /> :    <CancelIcon color="error" style={{fontSize:'10px'}} />}
                </Tooltip>
                  {tds_tcs && (
                    <StarRateIcon
                      style={{
                        color: "#319f8b",
                        width: "15px",
                        height: "15px",
                        borderRadius: "8px",
                      }}
                    />
                  )}
                </Typography>
            </div>
          );
        },
      },
    },
    {
      name: "channel_partner",
      label: "Channel Partner",
      options: {
        hint: "Party through Whom Transaction is Occuring",
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <div
          
              className={classes.formgroup}
            >
              <Typography 
                 variant="body2"
                 //  component={LinkBtn}
                  noWrap={false}
                  sx={{alignItems:'center'}}
                  color="primary"
                  onDoubleClick={()=>{
                   window.open(`/app/user-details/${sellOrderData[tableMeta.rowIndex].related_channel_partner_id}`,'_blank')
                  }}
                >
                  {CopyText(value)}{" "}
                  {sellOrderData[tableMeta.rowIndex].transaction_type_code ==='INTERMEDIATE' && <Tooltip1 title={renderTooltip(tableMeta, "Sell","cp")}>
                   <InfoOutlined sx={{fontSize:'14px'}} />
                   </Tooltip1>}
                  <Typography component={'span'}>
                  {tableMeta.rowData[7] && tableMeta.rowData[7].length > 1 && 
                  <Tooltip1 title={renderChannelPartnerTooltip(tableMeta, "Sell")}>
                    <AddCircleOutline sx={{ fontSize: '14px', color: 'grey' }} />
                  </Tooltip1>}
                    </Typography>
                </Typography>   
                
            </div>
          )
        },
      },
    },
    {
      name: "commission_split_data",
      label: "Channel Partner",
      options: {
        hint: "Party through Whom Transaction is Occuring",
        filter: false,
        sort: false,
        display: false
      },
    },
    {
      name: "is_purchased_online",
      label: "Order Type",
      options: {
        filter: true,
        sort: true,
        display: false,
        hint: "Date of Transaction Initiation",
        customBodyRender: (value) => {
          return (
            <div className={classes.tableEllips}>
              {value ? (
                <Chip
                  label="Online"
                  style={{ backgroundColor: "#8ADAB2", color: "#fff" }}
                />
              ) : (
                <Chip label="Offline" color="primary" />
              )}
            </div>
          );
        },
      },
    },
    {
      name: "order_status",
      label: "Status",
      options: {
        hint: "Transaction Service Progress",
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography component={'span'}>
              <Chip
                size="small"
                label={value === "Preprocessing" ? "Pre-processing" : value}
                className={clsx({
                  [classes.active]: value === "Active",
                  [classes.completed]: value === "Completed",
                  [classes.inactive]:
                    value === "Inactive" || value === "INACTIVE",
                  [classes.processing]: value === "Processing",
                  [classes.preProcessing]: value === "Preprocessing",
                  [classes.cancelled]: value === "Cancelled",
                  [classes.hold]: value === "Onhold",
                  [classes.closed]: value === "Closed",
                  [classes.pending]: value === "Deal Term Pending",
                  [classes.shareTransferProcessing]:
                    value === "Share Transfer Pending",
                  [classes.stageOneProcessing]:
                    value === "Stage one processing",
                  [classes.stageOneProcessed]: value === "Stage one processed",
                  [classes.stageTwoProcessing]:
                    value === "Stage two processing",
                  [classes.stageTwoProcessed]: value === "Stage two processed",
                })}
              />
            </Typography>
          );
        },
      },
    },
    // {
    //   name: "is_investor_beneficiary_added",
    //   label: "Investor Beneficiary",
    //   options: {
    //         filter: false,
    //         sort: false,
    //         customBodyRender: (value,tableMeta) => {
    //           return (
    //              <div style={{display:'flex', alignItems:'center', cursor:'pointer'}} >
    //               <Typography variant="span" style={{padding:'4px'}}>{value ?"Yes" : "No"}</Typography>
    //              {value? <CheckCircleOutlineIcon style={{color:'green'}}/> : <CancelIcon color={'error'}/>}
    //              </div>
    //           )
    //       }
    //       },
    // },
    // {
    //     name: "order_status",
    //     label: "Status",
    //     options: {
    //       hint: "Transaction Service Progress",
    //       filter: true,
    //       sort: true,
    //       customBodyRender: (value) => {
    //         return (
    //           <Typography>
    //             <Chip
    //               size="small"
    //               label={value}
    //               className={clsx({
    //                 [classes.active]: value === "Deal Term Pending" ,
    //                 [classes.inactive]: value === "INACTIVE",
    //                 [classes.processing]: value === "Deal Term Uploaded" ,
    //                 [classes.processing]: value === "Share Transfer Pending" ,
    //                 [classes.processing]: value === "Share Transfer Completed" ,
    //                 [classes.completed]: value === "COMPLETED",
    //                 [classes.closed]: value === "CLOSED",
    //               })}
    //             />
    //           </Typography>
    //         );
    //       },
    //     },
    // },
    {
      name: "lead_owner",
      label: "Internal RM",
      options: {
        hint: "Internal RM Servicing Order Currently",
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <div
              className={classes.formgroup}
            >
              <Typography 
                  variant="body2"
                   noWrap={false}
                   sx={{alignItems:'center'}}
                   color="primary"
                   onDoubleClick={()=>{
                    window.open(`/app/user-details/${sellOrderData[tableMeta.rowIndex].related_lead_owner_id}`,'_blank')
                   }}
                >
               {CopyText(value)}{" "}
                </Typography>   
                  {rowindex === `${sellOrderData[tableMeta.rowIndex]._id}${sellOrderData[tableMeta.rowIndex].related_lead_owner_id}` && (
                <Tooltip
                  style={{ cursor: "copy" }}
                  title={hover ? "Copied" : "Click to Copy"}
                >
                  <CopytoClipboard
                    userBasicInfo={value}
                    state={hover}
                  />
                </Tooltip>
              )}
            </div>
          )
        },
      },
    },
    {
      name: "assign",
      label: "Servicing Owner",
      options: {
        hint: "Internal RM Servicing Order Currently",
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) =>
          tableMeta.rowData[8] ? null : Copy(value, tableMeta),
      },
    },
    {
      name: "user_signed_status",
      label: "Signed status",
      options: {
        display: false,
      },
    },
    // {
    //   name: "owner_dp",
    //   label: "Seller DP Id",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRender: (value, tableMeta) => Copy(value, tableMeta),
    //   },
    // },
    // {
    //   name: "owner_client_id",
    //   label: "Seller Client Id",
    //   options: {
    //         filter: false,
    //         sort: false,
    //         customBodyRender: (value, tableMeta) => Copy(value, tableMeta),
    //       },
    // },
    // {
    //   name: "owner_bank_name",
    //   label: "Seller Bank Name",
    //   options: {
    //         filter: false,
    //         sort: false,
    //         customBodyRender: (value, tableMeta) => Copy(value, tableMeta),
    //       },
    // },
    // {
    //   name: "client_dp_id",
    //   label: "Client DP Id",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRender: (value, tableMeta) => Copy(value, tableMeta),
    //   },
    // },
    // {
    //   name: "client_client_id",
    //   label: "Client Client Id",
    //   options: {
    //         filter: false,
    //         sort: false,
    //         customBodyRender: (value, tableMeta) => Copy(value, tableMeta),
    //       },
    // },
    {
      name: "",
      label: "Investor Info",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Tooltip1 title={renderTooltip(tableMeta, "Sell",'user')}>
              <InfoOutlined />
            </Tooltip1>
          );
        },
      },
    },
    {
      name: "buy_order_id",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "is_purchased_online",
      label: "Online Info",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "",
      label: "Action",
      options: {
        // hint: "delete",
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            (userRole === "ADMIN" || userRole === "OPERATION") && (<div style={{ display: "flex" }}>
              {/* {!tableMeta.rowData[8] && <IconButton
                onClick={() => setEditOrder({
                  id: tableMeta.rowData[0],
                  open: true
                })}
              >
                <Edit />
              </IconButton>} */}
              <IconButton
                onClick={() => {
                  setDeleteConfirm(true);
                  setDeleteId({
                    orderId: tableMeta.rowData[1],
                    id: tableMeta.rowData[0],
                  });
                }}
                style={{ padding: 0 ,marginLeft:'2rem'}}
                disabled={
                  tableMeta.rowData[12] !== "null" &&
                  tableMeta.rowData[9].toLowerCase() !== "inactive"
                }
              >

                <DeleteOutline />

              </IconButton>
            </div>))

        },
      },
    },
    {
      name: "client_beneficiary_status",
      label: "Beneficiary Status",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    }
  ];

  const [customFilter, setCustomFilter] = useState(false);

  const invoicePreview = (values) => {
    navigate("/app/invoice-form", { replace: true, state: values });
  };

  const buyColumnData = [
    {
      name: "_id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "service_id",
      label: "Order Id",
      options: {
        hint: "Unique Order Number",
        filter: true,
        sort: true,
        setCellProps: () => {
          return {
            style: {
              whiteSpace: "nowrap",
              position: "sticky",
              left: "0",
              background: "#fff",
              zIndex: 100
            }
          }
        },
        setCellHeaderProps: () => {
          return {
            style: {
              whiteSpace: "nowrap",
              position: "sticky",
              left: 0,
              zIndex: 101
            }
          }
        },
        customBodyRender: (value, tableMeta) => {
          return (
            <div
              style={{ width: "10rem" }}
              className={classes.formgroup}
              onMouseEnter={() => {
                onHover();
                setrowIndex(tableMeta.rowData[0]);
              }}
              onMouseLeave={() => setrowIndex()}
            >
              <Typography
                variant="body2"
                component={LinkBtn}
                noWrap={false}
                // className={classes.formgroup}
                to={`/app/service-order-details/${tableMeta.rowData[0]}`}
                state={{
                  page: page,
                  orderType:orderType,
                  data: tableMeta.currentTableData,
                  index: tableMeta.rowIndex,
                  advanceSearchForm:advanceSearchForm
                }}
                color="primary"
              >
                {value}
              </Typography>
              {rowindex === tableMeta.rowData[0] && (
                <Tooltip
                  style={{ cursor: "copy" }}
                  title={hover ? "Copied" : "Click to Copy"}
                >
                  <CopytoClipboard userBasicInfo={value} state={hover} />
                </Tooltip>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "create_datetime",
      label: "Created Date",
      options: {
        hint: "Date of Transaction Initiation",
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          let valueMoment = moment(value).format("DD-MM-YYYY LT");
          return (
            <div className={classes.tableEllips}>
              <Typography variant="body2" noWrap component={'span'}>
                {valueMoment}
              </Typography>
            </div>
          );
        },
      },
    },

    {
      name: "is_tcs_crossed",
      label: "Type",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "transaction_type_code",
      label: "Transaction Type",
      options: {
        hint: "Information on Type of Relation",
        filter: false,
        sort: false,
      },
    },
    {
      name: "vendor",
      label: "Vendor",
      options: {
        hint: "Party from Whom Inventory has been Sourced",
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const tds_tcs = Number(tableMeta.rowData[3] > 5000000) ? true : false;
          return (
            <div
              className={classes.formgroup}
            >
                <Typography 
                  variant="body2"
                   noWrap={false}
                   sx={{alignItems:'center'}}
                   color="primary"
                   onDoubleClick={()=>{
                    window.open(`/app/user-details/${buyOrderData[tableMeta.rowIndex].ven}`,'_blank')
                   }}
                >
                  {CopyText(value)}{" "}
                <Tooltip title="Beneficiary status">
                {tableMeta.rowData[14]  ?  <CheckCircleOutlineIcon
                  style={{ color: "green",fontSize:'10px'}}
                /> :    <CancelIcon color="error" style={{fontSize:'10px'}} />}
                </Tooltip>
                  {tds_tcs && (
                    <StarRateIcon
                      style={{
                        color: "#319f8b",
                        width: "15px",
                        height: "15px",
                        borderRadius: "8px",
                      }}
                    />
                  )}
                </Typography>
            </div>
          );
        },
      }
    },
    {
      name: "channel_partner",
      label: "Channel Partner",
      options: {
        hint: "Party through Whom Transaction is Occuring",
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <div
              className={classes.formgroup}
            >
              <Typography
                  variant="body2"
                   noWrap={false}
                   sx={{alignItems:'center'}}
                   color="primary"
                   onDoubleClick={()=>{
                    window.open(`/app/user-details/${buyOrderData[tableMeta.rowIndex].related_channel_partner_id}`,'_blank')
                   }}
                >
                  {CopyText(value)}{" "}
                  {buyOrderData[tableMeta.rowIndex].transaction_type_code ==='INTERMEDIATE' && <Tooltip1 title={renderTooltip(tableMeta, "Buy","cp")}>
                   <InfoOutlined sx={{fontSize:'14px'}} />
                   </Tooltip1>}
                  <Typography component={'span'}>
                  {tableMeta.rowData[7] && tableMeta.rowData[7].length > 1 && 
                  <Tooltip1 title={renderChannelPartnerTooltip(tableMeta, "Buy")}>
                    <AddCircleOutline sx={{ fontSize: '14px', color: 'grey' }} />
                  </Tooltip1>}
                  </Typography>
                </Typography> 
            </div>
          )
        },
      },
    },
    {
      name: "commission_split_data",
      label: "Channel Partner",
      options: {
        hint: "Party through Whom Transaction is Occuring",
        filter: false,
        sort: false,
        display: false
      },
    },

    {
      name: "order_status",
      label: "Status",
      options: {
        hint: "Transaction Service Progress",
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography component={'span'}>
              <Chip
                size="small"
                label={value === "Preprocessing" ? "Pre-processing" : value}
                className={clsx({
                  [classes.active]: value === "Active",
                  [classes.completed]: value === "Completed",
                  [classes.inactive]: value === "Inactive",
                  [classes.processing]: value === "Processing",
                  [classes.preProcessing]: value === "Preprocessing",
                  [classes.cancelled]: value === "Cancelled",
                  [classes.hold]: value === "Onhold",
                  [classes.closed]: value === "Closed",
                  [classes.stageOneProcessing]:
                    value === "Stage one processing",
                  [classes.stageOneProcessed]: value === "Stage one processed",
                  [classes.stageTwoProcessing]:
                    value === "Stage two processing",
                  [classes.stageTwoProcessed]: value === "Stage two processed",
                })}
              />
            </Typography>
          );
        },
      },
    },
    // {
    //   name: "is_vendor_beneficiary_added",
    //   label: "Vendor Beneficiary",
    //   options: {
    //         filter: false,
    //         sort: false,
    //         customBodyRender: (value) => {
    //           return (
    //              <div style={{display:'flex', alignItems:'center', cursor:'pointer'}} >
    //               <Typography variant="span" style={{padding:'4px'}}>{value ?"Yes" : "No"}</Typography>
    //              {value? <CheckCircleOutlineIcon style={{color:'green'}}/> : <CancelIcon color={'error'}/>}
    //              </div>
    //           )
    //       }
    //       },
    // },
    {
      name: "lead_owner",
      label: "Internal RM",
      options: {
        hint: "Internal RM Owning the Order",
        filter: true,
        sort: true,
         customBodyRender: (value, tableMeta) => {
          return (
            <div
              className={classes.formgroup}
            >
              <Typography 
                  variant="body2"
                   noWrap={false}
                   sx={{alignItems:'center'}}
                   color="primary"
                   onDoubleClick={()=>{
                    window.open(`/app/user-details/${buyOrderData[tableMeta.rowIndex].related_lead_owner_id}`,'_blank')
                   }}
                >
                  {CopyText(value)}{" "}
                </Typography>   
            </div>
          )
        },
      },
    },
    {
      name: "assign",
      label: "Servicing Owner",
      options: {
        hint: "Internal RM Servicing Order Currently",
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => Copy(value, tableMeta),
      },
    },
    {
      name: "user_signed_status",
      label: "Signed status",
      options: {
        display: false,
      },
    },
    // {
    //   name: "owner_dp",
    //   label: "Buyer DP Id",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRender: (value, tableMeta) => Copy(value, tableMeta),
    //   },
    // },
    // {
    //   name: "owner_client_id",
    //   label: "Buyer Client Id",
    //   options: {
    //         filter: false,
    //         sort: false,
    //         customBodyRender: (value, tableMeta) => Copy(value, tableMeta),
    //       },
    // },
    // {
    //   name: "owner_bank_name",
    //   label: "Buyer Bank Name",
    //   options: {
    //         filter: false,
    //         sort: false,
    //         customBodyRender: (value, tableMeta) => Copy(value, tableMeta),
    //       },
    // },
    // {
    //   name: "client_dp_id",
    //   label: "Client DP Id",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRender: (value, tableMeta) => Copy(value, tableMeta),
    //   },
    // },
    // {
    //   name: "client_client_id",
    //   label: "Client Client Id",
    //   options: {
    //         filter: false,
    //         sort: false,
    //         customBodyRender: (value, tableMeta) => Copy(value, tableMeta),
    //       },
    // },
    // {
    //   name: "client_bank_name",
    //   label: "Client Bank Name",
    //   options: {
    //         filter: false,
    //         sort: false,
    //         customBodyRender: (value, tableMeta) => Copy(value, tableMeta),
    //       },
    // },
    {
      name: "",
      label: "Vendor Info",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Tooltip1 title={renderTooltip(tableMeta, "Buy",'user')}>
              <InfoOutlined />
            </Tooltip1>
          );
        },
      },
    },
    {
      name: "",
      label: "Action",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            (userRole === "ADMIN" || userRole === "OPERATION") && (<div>
              {/* <IconButton
                onClick={() => setEditOrder({ id: tableMeta.rowData[0], open: true })}
              >
                <Edit />
              </IconButton> */}
              <IconButton
              ml={5}
                onClick={() => {
                  setDeleteConfirm(true);
                  setDeleteId({
                    orderId: tableMeta.rowData[1],
                    id: tableMeta.rowData[0],
                  });
                }}
                style={{ padding: 0,marginLeft:'2rem' }}
                disabled={
                  tableMeta.rowData[11] !== "null" &&
                  tableMeta.rowData[8] !== "Inactive"
                }
              >

                <DeleteOutline />

              </IconButton>
            </div>))
        },
      },
    },  
    {
      name: "client_beneficiary_status",
      label: "Beneficiary Status",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },         
  ];

  // function arrangeOrdersbyValues(array, value) {
  //   const sortedArray = [];
  //   const openLabels = [];

  //   for (const item of array) {
  //     if (item.order_status.includes(value)) {
  //       openLabels.push(item);
  //     } else {
  //       sortedArray.push(item);
  //     }
  //   }

  //   // Sort the open labels by their label name
  //   openLabels.sort((a, b) => a.order_status.localeCompare(b.order_status));

  //   // Add the open labels to the sorted array
  //   openLabels.forEach((item) => {
  //     sortedArray.push(item);
  //   });

  //   return sortedArray;
  // }

  

  const getOrderStatusReport=(async(values)=>{
    const res = await serviceOrderService.getStatusReport(values)
    if (res.status === 200) {
      setSpinner(false);
      setOpenModal(false);
      setOpen(true);
      setTitleModal()
      if(res.data.length>0){
        const groupedData = {};

        res.data.forEach(item => {
            const { id, document_name } = item;
            // If the id is not already in the object, create an entry
            if (!groupedData[id]) {
                groupedData[id] = { ...item, document_names: [] };
            }
            // Add the document_name to the document_names array
            groupedData[id].document_names.push(document_name);
        });
        const result = Object.values(groupedData).map(item => {
            const { document_name, ...rest } = item; // Remove individual document_name
            return rest;
        });
        let filteredData = result.map((item)=>{
          const data = item.addtional_channel_partner ? item.addtional_channel_partner.filter(
            partner => partner !== item.channel_partner
          ) :[]
          var textRemarks = item.remarks.replace(/<[^>]+>/g, ' ');
          return{
            ...item,
            addtional_channel_partner:data.length>0 ?data.join(', ') :'',
            document_name:item.document_names.length > 0 ? item.document_names.join(', ') : '',
            remarks:textRemarks
          }
        })
        handleDownload(filteredData,'status')
        setSnackbarTitle('Downloaded successfully');
      }else{
        setSnackbarTitle('No data found');
      }
    } else if (res.status === 401) {
      setSpinner(false);
      setOpenModal(false);
      setOpen(true);
      setTitleModal()
      setSnackbarTitle(res.data.message);
      setSeverity("error");
    } else {
      setSpinner(false);
      setOpenModal(false);
      setTitleModal()
      setOpen(true);
      setSnackbarTitle("Something went wrong");
      setSeverity("error");
    }
  })


  const deleteOrder = () => {
    serviceOrderService.deleteServiceOrder(deleteId?.id).then((res) => {
      setDeleteConfirm(false);
      setDeleteId();
      if (res.data.success) {
        setSnackbarTitle("Service order deleted successfully");
        setSeverity("success");
        setOpen(true);
        getServiceOrder();
      } else {
        setSnackbarTitle("Something went wrong");
        setSeverity("error");
        setOpen(true);
      }
    });
  };

  const getServiceOrder = (pNo, search, value,orderType,limit) => {
    setLoading(true);
    if(!limit){
      setSellOrderData([]);
      setBuyOrderData([]);
    }
    // setBuyCount(0)
    // setSellCount(0)
    let pageNo = 0;
    let searchText = "";
    if (pNo !== undefined) {
      pageNo = pNo;
    }
    if (search !== undefined) {
      searchText = search;
    }
    if (page !== pNo - 1) {
      setBuyCount(0);
      setSellCount(0);
    }
    const data = {
      searchText: searchText ? searchText : '',
      pNo: pageNo,
      order_type: value ? (value?.order_type?.id ? value?.order_type?.id :0) : 0,
      order_status: value ? (value?.order_status?.id ? value?.order_status?.id : 0) : 0,
      transaction_type: value ? (value?.transaction_type?.id ? value?.transaction_type?.id: 0)  : 0,
      assign: value ? (value?.serving_owner?.id ? value?.serving_owner?.id : "")  : "",
      internal_rm_id: value ? (value?.internal_rm?.id ? value?.internal_rm?.id : "") : "",
      channel_partner: value ? (value?.channel_partner?.id ? value?.channel_partner?.id : "") : "",
      client_id: value ? (value?.client?.id ? value?.client?.id: "") : "",
      scrip_id: value ? (value?.scrips?.id ? value?.scrips?.id : "") : "",
      from_date: value ? (value?.from_date ? value?.from_date: "") : "",
      to_date: value ? (value?.to_date ? value?.to_date : "") : "",
      is_nse:orderType==='nse' ? true : (value ? (value?.is_nse === 'no' ? false : value?.is_nse === 'yes' ? true : false)   : false),
      is_facilitated: orderType==='facilitator' ? true: (value ? (value?.is_facilitated === 'no' ? false : value?.is_facilitated === 'yes' ?  true : false)   : false),
      limit:limit ? limit :10
    };
     return serviceOrderService
      .getOnlineServiceOrder(data)
      .then((res) => {
        if (res) {
          if (res.status === 200 && res.data.length > 0) {
            const sellData = [];
            const buyData = [];
            setLoading(false);
            setBuyCount(
              res.data[0].order_type === "Buy"
                ? parseInt(res.data[0]?.total_buy_count)
                : 0
            );
            setSellCount(
              res.data[res.data.length - 1].order_type === "Sell"
                ? parseInt(res.data[res.data.length - 1]?.total_sell_count)
                : 0
            );
            res.data.forEach((item) => {
              var newData = item;
              if (newData.order_type === "Sell") {
                if (newData.is_purchased_online) {
                  if (
                    newData.confirmed_verified === "false" &&
                    newData.dealterm_uploaded === "false" &&
                    newData.user_signed_status === null &&
                    newData.order_status_code !== "INACTIVE" &&
                    newData.order_status_code !== "COMPLETED" &&
                    newData.order_status_code !== "CLOSED"
                  ) {
                    newData["order_status"] = "Deal Term Pending";
                  } else if (
                    newData.confirmed_verified === "false" &&
                    newData.dealterm_uploaded === "true" &&
                    newData.user_signed_status === "pending" &&
                    newData.order_status_code !== "INACTIVE" &&
                    newData.order_status_code !== "COMPLETED" &&
                    newData.order_status_code !== "CLOSED"
                  ) {
                    newData["order_status"] = "Share Transfer Pending";
                  } else if (
                    newData.confirmed_verified === "false" &&
                    newData.dealterm_uploaded === "false" &&
                    newData.user_signed_status === "complete" &&
                    newData.order_status_code !== "INACTIVE" &&
                    newData.order_status_code !== "COMPLETED" &&
                    newData.order_status_code !== "CLOSED"
                  ) {
                    newData["order_status"] = "Share Transfer Pending";
                  } else if (
                    newData.confirmed_verified === "false" &&
                    newData.dealterm_uploaded === "true" &&
                    newData.user_signed_status === "complete" &&
                    newData.order_status_code !== "INACTIVE" &&
                    newData.order_status_code !== "COMPLETED" &&
                    newData.order_status_code !== "CLOSED"
                  ) {
                    newData["order_status"] = "Share Transfer Pending";
                  } else if (
                    newData.confirmed_verified === "true" &&
                    newData.dealterm_uploaded === "true" &&
                    newData.user_signed_status === "complete" &&
                    newData.order_status_code !== "INACTIVE" &&
                    newData.order_status_code !== "COMPLETED" &&
                    newData.order_status_code !== "CLOSED"
                  ) {
                    newData["order_status"] = "Share Transfer Completed";
                  } else if (
                    newData.confirmed_verified === "true" &&
                    newData.dealterm_uploaded === "true" &&
                    newData.user_signed_status === "complete" &&
                    (newData.order_status_code === "COMPLETED" ||
                      newData.order_status_code === "CLOSED")
                  ) {
                    newData["order_status"] = "Completed";
                  } else if (newData.order_status_code === "INACTIVE") {
                    newData["order_status"] = "INACTIVE";
                  } else {
                    // console.log("8",newData.user_signed_status);
                    // if(newData.user_signed_status=='complete'){
                    //   newData['order_status'] = "Share Transfer Pending"
                    //  }else if(newData.user_signed_status=='pending'){
                    //   newData['order_status'] = 'Deal Term Pending'
                    //  }else{
                    //   newData['order_status'] = 'COMPLETED'
                    //  }
                    if (
                      (newData.user_signed_status === "complete" &&
                        newData.order_status_code === "COMPLETED") ||
                      newData.order_status_code === "CLOSED"
                    ) {
                      newData["order_status"] = "Completed";
                      newData["order_status_code"] = "Completed";
                    } else if (
                      newData.user_signed_status === "complete" &&
                      newData.order_status_code !== "COMPLETED"
                    ) {
                      newData["order_status"] = "Share Transfer Pending";
                      newData["order_status_code"] = "Share Transfer Pending";
                    } else if (
                      newData.user_signed_status === "pending" &&
                      newData.order_status_code !== "COMPLETED"
                    ) {
                      newData["order_status"] = "Deal Term Pending";
                      newData["order_status_code"] = "Deal Term Pending";
                    } else if (
                      newData.user_signed_status == null &&
                      newData.order_status_code !== "COMPLETED"
                    ) {
                      newData["order_status"] = "Deal Term Pending";
                      newData["order_status_code"] = "Deal Term Pending";
                    } else {
                      newData["order_status"] = "Completed";
                      newData["order_status_code"] = "Completed";
                    }
                  }
                }
                sellData.push(newData);
              } else if (newData.order_type === "Buy") {
                buyData.push(newData);
              }
            });
            // const sellOrdersByList = arrangeOrdersbyValues(
            //   sellData,
            //   searchItem
            // );
            // const buyOrdersByList = arrangeOrdersbyValues(buyData, searchItem);
         
            if(limit ==='sell'){
              return sellData
            }else  if(limit ==='buy'){
              return buyData
            }else{
              setSellOrderData(sellData);
              setBuyOrderData(buyData);
            }
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setLoading(false);
              setOpenSessionExpiredModal(true);
            } else {
              setLoading(false);
            }
          }
        } else if(res !== undefined) {
          setLoading(false);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if(location.state?.advanceSearchForm){
      getServiceOrder(page, "", location.state?.advanceSearchForm);
    }else if(location.state?.orderType ==='nse'){
      getServiceOrder(page,searchTextValue,{is_nse:'yes'})
    }else if(location.state?.orderType ==='facilitator'){
      getServiceOrder(page,searchTextValue,{is_facilitated:"yes"})
    }else{
      getServiceOrder(page,'')
    }
    
  }, []);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
    setPage(0);
  };

  const handleChangeIndex = (index) => {
    setActiveTab(index);
  };

  const openModalHandler = (type) => {
    setOpen(false);
    setExportType(
      type === "MIS" ? "MIS" : type === "Invoice" ? "Invoice" : "Order"
    );
    setTitleModal(
      type === "MIS"
        ? "Export MIS Report"
        : type === "order"
          ? "Export order"
          : type === "Invoice"
            ? "Generate Invoice"
            : type === "dealterm" ?
            "Generate Dealterm" 
            : type === "countersign" ?
            "Counter Signed Dealterm report" :
            type === "statusreport" ?
            "Order Status report" :type
    );
    setSnackbarTitle("CSV report sent to email");
    setSeverity("success");
    setOpenModal(true);
  };

  const closeModalHandler = () => {
    setOpenModal(false);
    setTitleModal("")
    setBulkUpdateDialog(false)
    setDealTermData(false)
    setExportType('')
  };

  const exportOrders = async (values) => {
    let clients =exportType ==="MIS" ? values.client.map((item) => item.id) : values.client.id;
    let leadOwners = values.leadOwner.map((item) => item.id);
    let channelPartners = exportType ==="MIS" ?values.channelPartner.map((item) => item.id) : values.channelPartner.id;;

    const data = {
      fromDate: values.fromDate,
      toDate: values.toDate,
      is_purchased_online:
        values.userType === "online"
          ? true
          : values.userType === "offline"
            ? false
            : "",
      getAllOrders: values.userType === "all" ? true : false,
      service_order_type:
        values.orderType === "buy"
          ? "BUY"
          : values.orderType === "sell"
            ? "SELL"
            : "",
      getAllOrderType: values.orderType === "all" ? true : false,
      auth_user_id: userID,
      user_role: userRole,
      client_id: clients,
      lead_owner_id: leadOwners,
      channel_partner:channelPartners,
      orders:values.order_id
    };
    const res =
      exportType === "MIS"
        ? await serviceOrderService.generateMIS(data)
        : await serviceOrderService.exportOrders(data);
    if (res.status === 200) {
      setSpinner(false);
      setTitleModal(false)
      setOpenModal(false);
      setOpen(true);
    } else if (res.status === 401) {
      setSpinner(false);
      setTitleModal()
      setOpenModal(false);
      setOpen(true);
      setSnackbarTitle(res.data.message);
      setSeverity("error");
    } else {
      setTitleModal()
      setSpinner(false);
      setOpenModal(false);
      setOpen(true);
      setSnackbarTitle("Something went wrong");
      setSeverity("error");
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  let reports = [
    {
      id: 1,
      name: "Export MIS Reports",
    },
    {
      id: 2,
      name: "Generate Invoice",
    },
    {
      id: 3,
      name: "Export Order Reports",
    },
    {
      id: 4,
      name: "Export Document Reports",
    },
    {
      id: 5,
      name: "Generate Dealterm",
    },
    {
      id: 6,
      name: "Counter Signed Dealterm Report",
    },
    {
      id: 7,
      name: "Order Status Report",
    },
  ];
  let uploads = [
    {
      id: 1,
      name: "Commission Data",
    },
    {
      id: 2,
      name: "Counter Signed Dealterm",
    },
    {
      id: 3,
      name: "Referral Data",
    },
  ];
  reports = userRole === "SALES"? reports.filter((item)=> item.id === 3 || item.id === 5 ||  item.id === 4 ||  item.id === 7)  :reports
  uploads = userRole === "SALES"? uploads.filter((item)=> item.id === 2) :uploads
  // const editTypes = [
  //   {
  //     id: 0,
  //     name: "Order details",
  //   },
  //   {
  //     id: 1,
  //     name: "Order Basic details",
  //   },
  //   {
  //     id: 2,
  //     name: "Scrip and Price details",
  //   },
  //   {
  //     id: 3,
  //     name: "Payment details",
  //   },
  //   {
  //     id: 4,
  //     name: "Others",
  //   },
  // ];

  const handleDownloadDocuments = async (values) => {
    const res = await serviceOrderService.getDocumentId(values);
    if (res.status === 200) {
      setSpinner(false);
      setOpen(true);
      setSnackbarTitle("Downloaded successfully");
      setOpenModal(false);
      setTitleModal()
      if (res.data.length > 0) {
        let data=res.data
        try {
          if(values.docType ==='1'){

            const promises = data.map((item) =>
              getLookUpDataService.getObjectUrl(item.related_file_id)
            );
    
            const results = await Promise.all(promises);
            // Fetch URLs for each document
            results.forEach((res, index) => {
              if (res.data.success) {
                data[index].url = res.data.data;
                data[index].mimetype = res.data.mimetype;
              }
            });
    
            const getFileExtension = (mimetype) => mimetype.split("/")[1];
    
            const zip = new JSZip();
    
            // Group files by service_id
            const groupedFiles = data.reduce((acc, file) => {
              const { service_id ,client_name } = file;
              const key = `${service_id}_${client_name}`; // Composite key for unique grouping by service_id and client_name

              if (!acc[key]) {
                acc[key] = [];
              }
              acc[key].push(file);
              return acc;
            }, {});
    
            await Promise.all(
              Object.keys(groupedFiles).map(async (service_id) => {
                const folder = zip.folder(service_id); // Create a folder with service_id
    
                await Promise.all(
                  groupedFiles[service_id].map(async (file) => {
                    try {
                      const fileBinary = await JSZipUtils.getBinaryContent(file.url);
                      const fileExtension = getFileExtension(file.mimetype);
                      const fileName = `${file.document_name}.${fileExtension}`;
    
                      // Add the file to the service_id folder
                      folder.file(fileName, fileBinary, { binary: true });
                    } catch (error) {
                      console.error(`Failed to download file: ${file.document_name}`, error);
                    }
                  })
                );
              })
            );
    
            // Generate and download the zip file
            zip.generateAsync({ type: "blob" }).then((content) => {
              saveAs(content, "documents.zip");
            });
          }else{
            const promises = data.map((item) =>
              serviceOrderService.getServiceOrderdDealTermDocuments(item.id)
            );
            const results = await Promise.all(promises);
            results.forEach((res, index) => {
              if (res.status ===200) {
                const file = new Blob([res.data], { type: "application/pdf" });
                const fileURL = URL.createObjectURL(file);
                data[index].url = fileURL;
                data[index].mimetype = 'application/pdf';
              }
            });
        
            const zip = new JSZip();
    
            // Group files by service_id
            const groupedFiles = data.reduce((acc, file) => {
              const { service_id ,client_name } = file;
              const key = `${service_id}_${client_name}`; // Composite key for unique grouping by service_id and client_name

              if (!acc[key]) {
                acc[key] = [];
              }
              acc[key].push(file);
              return acc;
            }, {});
    
            await Promise.all(
              Object.keys(groupedFiles).map(async (service_id) => {
                const folder = zip.folder(service_id); // Create a folder with service_id
    
                await Promise.all(
                  groupedFiles[service_id].map(async (file,index) => {
                    try {
                      const fileBinary = await JSZipUtils.getBinaryContent(file.url);
                      const fileName = `Dealterm_${service_id}.pdf`; // Unique file name in case of multiple files in the same folder
                      // Add the file to the service_id folder
                      folder.file(fileName, fileBinary, { binary: true });
                    } catch (error) {
                      console.error(`Failed to download file: Dealterm`, error);
                    }
                  })
                );
              })
            );
    
            // Generate and download the zip file
            zip.generateAsync({ type: "blob" }).then((content) => {
              saveAs(content, "Dealterm.zip");
            });
          }
          
          setSnackbarTitle("Downloaded successfully");
        } catch (error) {
          console.error("Error fetching URLs:", error);
          setSnackbarTitle("Error downloading files");
        }
      } else {
        setSnackbarTitle("No data found");
      }
    } else if (res.status === 401) {
      setSpinner(false);
      setOpenModal(false);
      setOpen(true);
      setSnackbarTitle(res.data.message);
      setSeverity("error");
    } else {
      setSpinner(false);
      setOpenModal(false);
      setOpen(true);
      setSnackbarTitle("Something went wrong");
      setSeverity("error");
    }
  };

  const generateDealtermDocument =(async(values)=>{
    setSpinner(true)
    setDealTermData(false)
    const res = await serviceOrderService.generateBulkDealTerm(values)
    if (res.status === 200) {
      setSpinner(false);
      // setOpenModal(false);
      setOpen(true);
      // setTitleModal()
      const file = new Blob([res.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      setDealTermData(fileURL)
      // downloadFile(fileURL)
       setSeverity("success");
        setSnackbarTitle('Generated successfully');
      
    } else if (res.status === 401) {
      setSpinner(false);
      setOpenModal(false);
      setOpen(true);
      setTitleModal()
      setSnackbarTitle(res.data.message);
      setSeverity("error");
    } else if (res.status === 400) {
      setSpinner(false);
      setOpen(true);
      setSnackbarTitle("No data found");
      setSeverity("error");
    }else {
      setSpinner(false);
      setOpenModal(false);
      setTitleModal()
      setOpen(true);
      setSnackbarTitle("Something went wrong");
      setSeverity("error");
    }
  })

  const getCSDTReport =(async(values)=>{
    setSpinner(true)
    setDealTermData(false)
    const res = await serviceOrderService.getCounterSignDealtermReport(values)
    if (res.status === 200) {
      setSpinner(false);
      setOpenModal(false);
      setOpen(true);
      setTitleModal()
      if(res.data.length>0){
        let filteredData = res.data.map((item)=>{
          const data = item.addtional_channel_partner ? item.addtional_channel_partner.filter(
            partner => partner !== item.channel_partner
          ) :[]
          return{
            ...item,
            addtional_channel_partner:data.length>0 ?data.join(', ') :''
           }
        })
      handleDownload(filteredData,'csdt')
      setSnackbarTitle('Downloaded successfully');
       setSeverity("success");
        setSnackbarTitle('Downloaded successfully');
      }else{
          setSnackbarTitle('No data found');
        }
    } else if (res.status === 401) {
      setSpinner(false);
      setOpenModal(false);
      setOpen(true);
      setTitleModal()
      setSnackbarTitle(res.data.message);
      setSeverity("error");
    } else if (res.status === 400) {
      setSpinner(false);
      setOpen(true);
      setSnackbarTitle("No data found");
      setSeverity("error");
    }else {
      setSpinner(false);
      setOpenModal(false);
      setTitleModal()
      setOpen(true);
      setSnackbarTitle("Something went wrong");
      setSeverity("error");
    }
  })
  
  const handleDocumentReports = (async (values) => {
    const res = await serviceOrderService.getDocumentData(values)
    if (res.status === 200) {
      setSpinner(false);
      setOpenModal(false);
      setOpen(true);
      setTitleModal()
      if(res.data.length>0){
        let filteredData = res.data.map((item)=>{
          const data = item.addtional_channel_partner ? item.addtional_channel_partner.filter(
            partner => partner !== item.channel_partner
          ) :[]
          return{
            ...item,
            addtional_channel_partner:data.length>0 ?data.join(', ') :''
          }
        })
        handleDownload(filteredData,'data')
        setSnackbarTitle('Downloaded successfully');
      }else{
        setSnackbarTitle('No data found');
      }
    } else if (res.status === 401) {
      setSpinner(false);
      setOpenModal(false);
      setOpen(true);
      setTitleModal()
      setSnackbarTitle(res.data.message);
      setSeverity("error");
    } else {
      setSpinner(false);
      setOpenModal(false);
      setTitleModal()
      setOpen(true);
      setSnackbarTitle("Something went wrong");
      setSeverity("error");
    }
  })

  const handleReport = (e, value) => {
    if (value) {
      if (value.id === 1) {
        openModalHandler("MIS");
      } else if (value.id === 2) {
        openModalHandler("Invoice");
      } else if (value.id === 3) {
        openModalHandler("order");
      } else if (value.id === 4) {
        openModalHandler("Documents");
      }else if (value.id === 5) {
        openModalHandler("dealterm");
      }else if (value.id === 6) {
      openModalHandler("countersign");
    }else if (value.id === 7) {
      openModalHandler("statusreport");
    }
    } else {
      openModalHandler("");
    }
  };
  const openModalForUpload = (type) => {
    setOpen(false);
    setExportType(type);
    setTitleModal(
      type === "Commission"
        ? "Upload Bulk Commission Data"
        :  "Upload Bulk Counter signed dealterm Data"
         
    );
  };
  const handleUpload = (e, value) => {
    if (value) {
      if (value.id === 1) {
        openModalForUpload("Commission");
      } else if (value.id === 2) {
        openModalForUpload("CSDL");
      }  else if (value.id === 3) {
        openModalForUpload("Referral");
      } 
    } else {
      openModalForUpload("");
    }
  };


  const headersMapping = {
    _id: "ID",
    create_datetime: "Creation DateTime",
    inv: "Investor Id",
    ven: "Vendor Id",
    service_id: "Service ID",
    buy_order_id: "Buy Order ID",
    is_purchased_online: "Purchased Online",
    transaction_type_code: "Transaction Type Code",
    order_type: "Order Type",
    related_channel_partner_id: "Related Channel Partner ID",
    channel_partner: "Channel Partner",
    investor: "Inevstor",
    investor_pan: "Investor Pan",
    vendor: "Vendor",
    vendor_pan: "Vendor Pan",
    lead_owner: "Lead Owner",
    assign: "Assign",
    order_status: "Order Status",
    client_dp_id: "Client DP ID",
    client_client_id: "Client Client ID",
    client_bank_account_no: "Client Bank Account No",
    client_bank_ifsc_code: "Client Bank IFSC Code",
    client_bank_name: "Client Bank Name",
  };

  const dataMapping ={
    "id": "Id",
    "service_id": "Service_ Id",
    "create_datetime": "Created Date",
    "order_type": "Type",
    "order_status": "Order Status",
    "client_name": "Client Name",
    "internal_rm": "Internal RM",
    "channel_partner": "Channel Partner",
    "addtional_channel_partner":"Addtional Channel Partner"
}

const statusMapping ={
  "id": "Id",
  "service_id": "Service_ Id",
  "create_datetime": "Created Date",
  "order_type": "Type",
  "order_status": "Order Status",
  "client_name": "Client Name",
  "internal_rm": "Internal RM",
  "channel_partner": "Channel Partner",
  "addtional_channel_partner":"Addtional Channel Partner",
  "remarks":"Remarks",
  "document_name":'Documents'
}


const csdtMapping ={
  "service_id": "Service_ Id",
  "order_date":"Order Date",
  "order_type":"Order Type",
  "client_name": "Client Name",
  "channel_partner": "Channel Partner",
  "addtional_channel_partner":"Addtional Channel Partner",
  "order_status": "Order Status",
  "uploaded_by": "Uploaded By",
  "csdt":"CSDT",
  'spa':"SPA",
  'soa':"SOA"
}
  const handleDownload = async (data,report) => {
    
   const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Investors');

    // Add headers to the worksheet
    if(report ==='data'){
      worksheet.columns = Object.keys(dataMapping).map(key => ({
        header: dataMapping[key],
        key: key,
        width: 25
      }));
    }else if(report ==='csdt'){
      worksheet.columns = Object.keys(csdtMapping).map(key => ({
        header: csdtMapping[key],
        key: key,
        width: 25
      }));
    }else if(report ==='status'){
      worksheet.columns = Object.keys(statusMapping).map(key => ({
        header: statusMapping[key],
        key: key,
        width: 25
      }));
    }else{
      worksheet.columns = Object.keys(headersMapping).map(key => ({
        header: headersMapping[key],
        key: key,
        width: 25
      }));
    }
   

    // Add data rows
    data?.forEach(item => {
      worksheet.addRow(item);
    });

    // Style the headers
    worksheet.getRow(1).font = { bold: true };
    worksheet.getRow(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'd5d5d5' }
    };
    worksheet.getRow(1).border = {
      top: { style: 'thin' },
      bottom: { style: 'thin' },
      left: { style: 'thin' },
      right: { style: 'thin' }
    };
    worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
      row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      });
    });

    // Generate buffer and save file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${report ? 'Data_Reports'  :'Orders'}.xlsx`;
    link.click();
  };

  return (
    <Root>
      <Page title="Unlistedkart | Order">
        <Zoom in={true} >
        <div className={classes.container}>
        {(userRole ==='ADMIN_VIEWER' || userRole ==='CHANNEL_PARTNER' || userRole==='INVESTER_CLIENT') ? null :    
        <div
            style={{
              textAlign: "end",
            }}
          >
            <MyStyledButton
              // className={classes.button}
              variant="contained"
              color="primary"
              size="medium"
              component={LinkBtn}
              to="/app/service-order-create"
            >
              Create Order
            </MyStyledButton>
            <MyStyledButton
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => {
                setOpenModal(true);
              }}
            >
              Reports
            </MyStyledButton>
            {(userRole === "ADMIN" || userRole === "OPERATION") && (
              <>
                <MyStyledButton
                  // className={classes.button}
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={() => {
                    setBulkUpdateDialog(true)
                  }}
                >
                  Bulk Update 
                </MyStyledButton>
              
                <MyStyledButton
                  // className={classes.button}
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={() => {
                    navigate("/app/deleted-orders");
                  }}
                >
                  <DeleteIcon style={{ marginRight: "4px" }} fontSize="small" />{" "}
                  Trash
                </MyStyledButton>
                </>
            )}
          </div>}

          <ServiceCard className={classes.card}>
            <ServiceCard className={classes.card}>
              <ThemeProvider theme={getMuiTheme}>
                <div style={{ position: "relative" }}>
                  <div className={classes.bottomborder}>
                    <AppBar position="static">
                      <Tabs
                        value={activeTab}
                        onChange={handleChange}
                        style={{
                          marginBottom: 24,
                          borderBottom: "1px solid #e8e8e8",
                        }}
                      >
                        <Tab label="Sell Orders" {...a11yProps(0)} />
                        <Tab label="Buy Orders" {...a11yProps(1)} />
                      </Tabs>
                      <FormControl component="orderType">
                      <RadioGroup sx={{marginLeft:'1rem'}}  row aria-label="order_type" name="order_type"
                            value={orderType}
                            id="order_type"
                            // onBlur={handleBlur}
                            onChange={(e)=>{
                              const value=e.target.value
                              setOrderType(value)
                              setPage(0)
                              if(value ==='nse'){
                                getServiceOrder(0,searchTextValue,{is_nse:'yes'}, value)
                              }else if(value ==='facilitator'){
                                getServiceOrder(0,searchTextValue,{is_facilitated:"yes"},value)
                              }else{
                                setAdvanceFilter(false)
                                setAdvanceSearchForm(false)
                                getServiceOrder(0,searchTextValue)
                              }
                            }}
                          >
                            <FormControlLabel value={"all"} control={<Radio color="primary" />} label="All" />
                            <FormControlLabel value={"nse"} control={<Radio color="primary" />} label="NSE" />
                            <FormControlLabel value={"facilitator"} control={<Radio color="primary" />} label="Facilitated" />
                          </RadioGroup>
                          </FormControl>
                    </AppBar>
                  </div>
                </div>
                <SwipeableViews
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={activeTab}
                  onChangeIndex={handleChangeIndex}
                >
                  <TabPanel value={activeTab} index={0}>
                    <ThemeProvider theme={getMuiTheme}>
                      <MUIDataTable
                        data={sellOrderData}
                        headerSort={false}
                        columns={sellColumnData}
                        options={{
                          serverSide: true,
                          rowsPerPage: 10,
                          expandableRows:(userRole === "ADMIN" || userRole === "OPERATION") ? true : false,
                          expandableRowsOnClick: false,
                          download: userRole === 'ADMIN_VIEWER' ? false : true,
                          onDownload:(()=>{
                            if(advanceFilter || searchTextValue){
                                getServiceOrder(0, searchTextValue, advanceSearchForm,'','sell').then((res)=>{
                                  handleDownload(res)
                                })
                            }else{
                                handleDownload(sellOrderData)
                            }
                            return false
                          }),
                          isRowExpandable: (dataIndex, expandedRows) => {
                           const online  = sellOrderData.map((item,index)=> {
                              return item.is_purchased_online ===true && index 
                            })
                            if (online.includes(dataIndex)) {
                              return false;
                            }
                            if (expandedRows.data.length > 0 && expandedRows.data.filter(d => d.dataIndex === dataIndex).length === 0)
                            return false;
                            return true;
                          },
                          expandableRowsHeader:false,
                          renderExpandableRow: ((row) => {
                            const colSpan = row.length + 1;
                            return(
                            !row[15] && 
                                  <Slide direction="up"  in={true} >
                                   <TableRow >
                                    <TableCell  colSpan={colSpan}>
                                     <div style={{height:'600px',width:'98%', overflowY:'scroll', scrollbarWidth:'thin'}}>
                                      <ServiceOrderCreate editType={{id:0}} id={row[0]}/>
                                     </div>
                                    </TableCell>
                                   </TableRow>
                                  </Slide>
                           )
                          }),
                          customToolbar: () => {
                            return (
                              <span style={{ display: "inline-flex" }}>
                                <Button
                                  disableRipple
                                  style={{
                                    textTransform: "none",
                                    textDecoration: "underline",
                                    marginRight: "10px",
                                  }}
                                  color="primary"
                                  onClick={() => setCustomFilter(!customFilter)}
                                >
                                  {" "}
                                  Advanced Search
                                </Button>
                                <Button
                                  disableRipple
                                  style={{
                                    textTransform: "none",
                                    textDecoration: "underline",
                                  }}
                                  color="primary"
                                  onClick={() => {
                                    setAdvanceSearchForm(false)
                                    setAdvanceFilter(false);
                                    getServiceOrder(0, "");
                                    setPage(0)
                                  }}
                                  disabled={!advanceFilter}
                                >
                                  {" "}
                                  Reset
                                </Button>
                              </span>
                            );
                          },
                          setRowProps: (row) => {
                            const category = row[14];
                            return {
                              style: {
                                backgroundColor: category
                                  ? "#e7eaff"
                                  : "inherit",
                                borderBottom: "1px solid grey",
                              },
                            };
                          },
                          rowsPerPageOptions: [10],
                          // responsive: 'scrollMaxHeight',
                          selectableRows: "none",
                          sortOrder: {
                            name: "create_datetime",
                            direction: "desc",
                          },
                          viewColumns: false,
                          print: false,
                          filter: false,
                          textLabels: {
                            body: {
                              noMatch: loading
                                ? "Loading..."
                                : "Sorry, there are no matching data to display",
                            },
                          },
                          onChangePage(pageNumber) {
                            clearTimeout(timer);
                            const newTimer = setTimeout(() => {
                              getServiceOrder(
                                pageNumber,
                                searchTextValue,
                                advanceFilter ? advanceFilter :orderType==='nse' ? {is_nse:"yes"}: orderType==='facilitator' ? {is_facilitated:'yes'} : null
                              );
                            }, 500);
                            setTimer(newTimer);
                            setPage(pageNumber);
                          },
                          onSearchChange(searchText) {
                            setSearchText(searchText);
                            clearTimeout(timer);
                            setPage(0)
                            const newTimer = setTimeout(() => {
                              getServiceOrder(0, searchText, advanceFilter  ? advanceFilter :orderType==='nse' ? {is_nse:"yes"}: orderType==='facilitator' ? {is_facilitated:'yes'} : null);
                            }, 500);
                            setTimer(newTimer);
                          },
                          onFilterChange(filterValue, filterList) {
                            const data = filterList.filter(
                              (item) => item.length > 0
                            );
                            setPage(0)
                            getServiceOrder(0, data[0], advanceFilter  ? advanceFilter :orderType==='nse' ? {is_nse:"yes"}: orderType==='facilitator' ? {is_facilitated:'yes'} : null);
                            setSearchText(data[0]);
                          },
                          onFilterChipClose: () => {
                            getServiceOrder();
                            setPage(0)
                          },
                          page: page,
                          count: sellCount,
                        }}
                      />
                    </ThemeProvider>
                  </TabPanel>
                  <TabPanel value={activeTab} index={1}>
                    <Typography
                      component={'span'}
                      variant="body2"
                      className={classes.subtitle}
                    ></Typography>
                    <ThemeProvider theme={getMuiTheme}>
                      <MUIDataTable
                        data={buyOrderData}
                        headerSort={false}
                        columns={buyColumnData}
                        options={{
                          serverSide: true,
                          rowsPerPage: 10,
                          rowsPerPageOptions: [10],
                          // responsive: 'scrollMaxHeight',
                          viewColumns: false,
                          print: false,
                          customToolbar: () => {
                            return (
                              <span style={{ display: "inline-flex" }}>
                                <Button
                                  disableRipple
                                  style={{
                                    textTransform: "none",
                                    textDecoration: "underline",
                                    marginRight: "10px",
                                  }}
                                  color="primary"
                                  onClick={() => setCustomFilter(!customFilter)}
                                >
                                  {" "}
                                  Advanced Search
                                </Button>
                                <Button
                                  disableRipple
                                  style={{
                                    textTransform: "none",
                                    textDecoration: "underline",
                                  }}
                                  color="primary"
                                  onClick={() => {
                                    setAdvanceFilter(false);
                                    getServiceOrder(0, "");
                                    setPage(0)
                                  }}
                                  disabled={!advanceFilter}
                                >
                                  {" "}
                                  Reset
                                </Button>
                              </span>
                            );
                          },
                          // setRowProps: (row) => {
                          //   const category = row[14];
                          //   return {
                          //     style: {
                          //       backgroundColor: category
                          //         ? "#e7eaff"
                          //         : "inherit",
                          //       borderBottom: "1px solid grey",
                          //     },
                          //   };
                          // },
                          download: userRole === 'ADMIN_VIEWER' ? false : true,
                          onDownload:(()=>{
                            if(advanceFilter || searchTextValue){
                                getServiceOrder(0, searchTextValue, advanceSearchForm,orderType,'buy').then((res)=>{
                                  handleDownload(res)
                                })
                            }else{
                                handleDownload(buyOrderData)
                            }
                            return false
                          }),
                          expandableRows:(userRole === "ADMIN" || userRole === "OPERATION") ? true : false,
                          expandableRowsOnClick: false,
                          isRowExpandable: (dataIndex, expandedRows) => {                 
                            if (expandedRows.data.length > 0 && expandedRows.data.filter(d => d.dataIndex === dataIndex).length === 0)
                              return false;
                            return true;
                          },
                          expandableRowsHeader:false,
                          renderExpandableRow: ((row) => {
                            const colSpan = row.length + 1;
                            return(
                              <Slide  direction='up' in={true}>
                              <TableRow >
                                <TableCell  colSpan={colSpan}>
                                  <div style={{height:'600px',width:'98%', overflowY:'scroll'}}>
                                  <ServiceOrderCreate editType={{id:0}} id={row[0]}/>
                                  </div>
                                </TableCell>
                              </TableRow>
                              </Slide>
                           )
                          }),
                          filter: false,
                          selectableRows: "none",
                          sortOrder: {
                            name: "create_datetime",
                            direction: "desc",
                          },
                          textLabels: {
                            body: {
                              noMatch: loading
                                ? "Loading..."
                                : "Sorry, there are no matching data to display",
                            },
                          },
                          onChangePage(pageNumber) {
                            setPage(pageNumber);
                            clearTimeout(timer);
                            const newTimer = setTimeout(() => {
                              getServiceOrder(
                                pageNumber,
                                searchTextValue,
                                advanceFilter  ? advanceFilter :orderType==='nse' ? {is_nse:"yes"}: orderType==='facilitator' ? {is_facilitated:'yes'} : null
                              );
                            }, 500);
                            setTimer(newTimer);
                          },
                          onSearchChange(searchText) {
                            setSearchText(searchText);
                            clearTimeout(timer);
                            const newTimer = setTimeout(() => {
                              getServiceOrder(0, searchText, advanceFilter  ? advanceFilter :orderType==='nse' ? {is_nse:"yes"}: orderType==='facilitator' ? {is_facilitated:'yes'} : null);
                            }, 500);
                            setPage(0)
                            setTimer(newTimer);
                          },
                          onFilterChange(filterValue, filterList) {
                            const data = filterList.filter(
                              (item) => item.length > 0
                            );
                            setSearchText(data[0]);
                            setPage(0)
                            getServiceOrder(0, data[0], advanceFilter  ? advanceFilter :orderType==='nse' ? {is_nse:"yes"}: orderType==='facilitator' ? {is_facilitated:'yes'} : null);
                          },
                          onFilterChipClose: () => {
                            getServiceOrder();
                            setPage(0)
                          },
                          page: page,
                          count: buyCount,
                        }}
                      />
                    </ThemeProvider>
                  </TabPanel>
                </SwipeableViews>
              </ThemeProvider>
            </ServiceCard>
          </ServiceCard>
          <ModalCentered
            title={"Reports"}
            open={openModal}
            toggle={closeModalHandler}
            maxWidth={'md'}
            children={
              <>
                <Autocomplete
                  id="grouped-demo"
                  options={reports}
                  size="small"
                  onChange={handleReport}
                  getOptionLabel={(option) => option.name}
                  sx={{ width: 300, margin: '2rem auto' }}
                  renderInput={(params) => (
                    <TextField sx={{ mb: 2 }}{...params} label="Reports" />
                  )}
                />
                {
                  titleModal === "Export MIS Report" ||
                    titleModal === "Export order" ? (
                    <OrderExportForm
                      type={exportType}
                      spinner={spinner}
                      formSubmit={exportOrders}
                    />
                  ) : titleModal === "Generate Invoice" ? ( // <MISData data={[{ name: "venue" }, { age: 28 }]} />
                    <GenerateInvoice
                      type={exportType}
                      spinner={spinner}
                      formSubmit={invoicePreview}
                    />
                  ) : titleModal === "Documents" ?
                    <DocumentsReport
                      spinner={spinner}
                      formSubmit={handleDocumentReports}
                      handleDownload={handleDownloadDocuments}
                    /> : titleModal === "Generate Dealterm" ?
                    <GenerateDealterm
                    spinner={spinner}
                    type={exportType}
                    data={dealTermData}
                    formSubmit={generateDealtermDocument}
                    />  : titleModal === "Counter Signed Dealterm report" ?
                    <CountersignedDealterm
                    spinner={spinner}
                    type={exportType}
                    formSubmit={getCSDTReport}
                    />  :  titleModal === "Order Status report" ?
                    <OrderStatusReport
                    spinner={spinner}
                    type={exportType}
                    formSubmit={getOrderStatusReport}
                    />  :  null
                }
              </>

            }
          />
          <ModalCentered
            title={"Bulk Update Documents"}
            open={openBulkUpdateDialog}
            toggle={closeModalHandler}
            fullScreen={exportType ==="Commission" ?true :false}
            maxWidth={exportType !=="Commission" ? "lg":''}
            children={
              <>
               <Autocomplete
                  id="grouped-demo"
                  options={uploads}
                  size="small"
                  onChange={handleUpload}
                  getOptionLabel={(option) => option.name}
                  sx={{ width: 300, margin: '2rem auto' }}
                  renderInput={(params) => (
                    <TextField sx={{ mb: 2 }}{...params} label="Select type" />
                  )}
                />
             { exportType ==="CSDL" ?
             <UploadCounterSignedDealTerm
             type={exportType}
             spinner={spinner}
             formSubmit={csdlBulkUpdate}
             />
             : exportType ==="Commission" ?<BulkUpdateCommissionReport
                type={exportType}
                spinner={spinner}
                formSubmit={commissionBulkUpdate}
              /> : exportType === "Referral" ?<BulkReferralUpdate
              type={exportType}
              spinner={spinner}
              formSubmit={referralBulkUpdate}
            /> : null}
              </>
            }
          />
          <AlertModal
            title={`Are you sure you want to delete ${deleteId?.orderId}?`}
            open={deleteConfirm}
            toggle={() => {
              setDeleteConfirm(false);
              setDeleteId();
            }}
            onConfirm={deleteOrder}
          ></AlertModal>
          <SnackBar
            open={open}
            severity={severity}
            close={handleClose}
            snackbarTitle={snackbarTitle}
          />
        </div>
        </Zoom>
        <ModalCentered
          title={"Advance Search"}
          open={customFilter}
          toggle={() => setCustomFilter(false)}
          children={<AdvanceSearchFormForOrders advanceSearchForm={advanceSearchForm} formSubmit={submitHandle} />}
        />
        <SessionExpiredModal open={openSessionExpiredModal} />
      </Page>
    </Root>

  );
};

const mapStateToProps = (state) => {
  return {
    force: state, // force state from reducer
    authReducer: state.authReducer,
  };
};

export default connect(mapStateToProps, null)(ServiceOrderItems);
