import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import {
  Button,
  Typography,
  Grid,
} from "@mui/material";
import ReactSpinner from "src/components/ReactSpinner/ReactSpinner";
import Page from "src/components/Page";
import NormalCard from "src/components/Cards/NormalCard";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { AllFaq } from "src/_services/faq's";
import AlertModal from "src/components/AlertModal/AlertModal";
import { useSelector } from "react-redux";
// import parse from 'html-react-parser';
const PREFIX = 'FAQsPage';

const classes = {
  root: `${PREFIX}-root`,
  addressBlock: `${PREFIX}-addressBlock`,
  gridPadding: `${PREFIX}-gridPadding`,
  accountBlock: `${PREFIX}-accountBlock`,
  reactSpinner: `${PREFIX}-reactSpinner`,
  accountTitle: `${PREFIX}-accountTitle`,
  addressBlocktitle: `${PREFIX}-addressBlocktitle`,
  removeLink: `${PREFIX}-removeLink`,
  buttonProgress: `${PREFIX}-buttonProgress`,
  title: `${PREFIX}-title`,
  description: `${PREFIX}-description`,
  wrapper: `${PREFIX}-wrapper`,
  btnroot: `${PREFIX}-btnroot`,
  btnright: `${PREFIX}-btnright`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },

  [`& .${classes.addressBlock}`]: {
    padding: "1rem",
    color: "#314B8F",
  },

  [`& .${classes.gridPadding}`]: {
    paddingTop: " 10px !important",
    paddingBottom: " 0px !important",
  },

  [`& .${classes.accountBlock}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem",
    margin: "1rem 0",
  },

  [`& .${classes.reactSpinner}`]: {
    position: "absolute",
    height: "100%",
    width: "100%",
    background: "#f4f6f870",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  [`& .${classes.accountTitle}`]: {
    fontSize: "1.2rem",
    fontWeight: "500",
    color: "#314B8F",
  },

  [`& .${classes.addressBlocktitle}`]: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(2),
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },

  [`& .${classes.removeLink}`]: {
    cursor: "pointer",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: "8px",
  },

  [`& .${classes.title}`]: {
    fontSize:"18px",
    fontWeight:500
  },

  [`& .${classes.description}`]: {
    fontSize:"16px"
  },

  [`& .${classes.wrapper}`]: {
    margin: theme.spacing(1),
    position: "relative",
  },

  [`& .${classes.btnroot}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },

  [`& .${classes.btnright}`]: {
    textAlign: "end",
  }
}));

const FAQsPage = () => {
  const role = useSelector((user)=>user.authReducer.auth.user.default_role_code)
  const [spinner, setSpinner] = useState(false);
  const [allFaqData, setAllFaqData] = useState([]);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);
  const navigate = useNavigate();

  const getAllFaq = () => {
    AllFaq.getAllFaq()
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            setAllFaqData(res.data.data);
          }
        }
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    getAllFaq();
  }, []);
  const deleteFAQ = (id) => {
    setSpinner(true);
    AllFaq.deleteFaq(id)
      .then((res) => {
        setSpinner(false);
        getAllFaq();
        setDeleteOpen(false);
      })
      .catch((error) => console.log(error));
  };

    
  const createMarkup = (htmlString) => {
    return { __html: htmlString };
};

  return (
    <Root>
    <Page className={classes.root} title="Unlistedkart | FAQ's">
      {/* <Container maxWidth={false} className={classes.container}> */}
      <div className={classes.btnright}>
       {role ==='ADMIN_VIEWER' ? null : <Button
          className={classes.button}
          variant="contained"
          color="primary"
          size="medium"
          onClick={() =>
            navigate(`/app/FAQs/:create`, {
              replace: true,
              state: {
                type: "create",
              },
            })
          }
        >
          Create FAQ's
        </Button>}
      </div>
        <NormalCard title="All FAQ's">
      <Grid>
        {allFaqData &&
          allFaqData.map((item, id) => (
            <div key={id}>
              {spinner && (
                <div className={classes.reactSpinner}>
                  <ReactSpinner />
                </div>
              )}
              <div style={{ padding: "2rem" , border:"1px solid black", margin:'2rem' }}>
                <Grid
                  container
                  spacing={5}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={classes.gridPadding}
                  >
                    <Typography className={classes.title}>Question:</Typography>
                    <Typography className={classes.description}>{item.question}</Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={classes.gridPadding}
                  >
                    <Typography className={classes.title}>Answer:</Typography>
                    <div className={classes.description} dangerouslySetInnerHTML={createMarkup(item.answer)} />
                    {/* <Typography className={classes.description}>{parse(item.answer)}</Typography> */}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={classes.gridPadding}
                  >
                    <Typography className={classes.title}>Category:</Typography>
                    <Typography className={classes.description}>{item.name}</Typography>
                  </Grid>
                  {role ==='ADMIN_VIEWER' ? null :<Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={classes.gridPadding}
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <Button
                      startIcon={<EditIcon />}
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{ margin: "0.5rem 0" }}
                      onClick={() =>
                        navigate(`/app/FAQs/:edit`, {
                          replace: true,
                          state: {
                            question: item.question,
                            answer: item.answer,
                            category: {
                              id: item.related_faq_category_id,
                              name: item.name,
                            },
                            itemid: item.id,
                            type: "update",
                          },
                        })
                      }
                    >
                      Edit FAQ
                    </Button>
                    <Button
                      startIcon={<DeleteIcon />}
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{ margin: "0.5rem 0" }}
                      onClick={() => {
                        setDeleteOpen(true);
                        setDeleteId(item.id);
                      }}
                    >
                      Delete FAQ
                    </Button>
                  </Grid>}
                </Grid>
              </div>
            </div>
          ))}
      </Grid>
          </NormalCard>

      <AlertModal
        title="Are you sure you want to delete this?"
        open={deleteOpen}
        setOpen={setDeleteOpen}
        toggle={() => setDeleteOpen(false)}
        onConfirm={() => deleteFAQ(deleteId)}
        openLoading={spinner}
      ></AlertModal>
    </Page>
    </Root>
  );
};

export default FAQsPage;
