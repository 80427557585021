

import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import {
    Checkbox,
    FormControl,
    FormHelperText,
    Container, TextField
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import ErrorFocus from "../../../components/ErrorFocus/ErrorFocus";
import { getLookUpDataService } from 'src/_services/lookUp'
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


const PREFIX = 'ConfigurePartnerScrips';

const classes = {
    zeroPadding: `${PREFIX}-zeroPadding`,
    cardBorderRadius: `${PREFIX}-cardBorderRadius`,
    cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
    fileInput: `${PREFIX}-fileInput`,
    imageSizes: `${PREFIX}-imageSizes`,
    container: `${PREFIX}-container`,
    label: `${PREFIX}-label`,
    marginInputLabel: `${PREFIX}-marginInputLabel`,
    popDownBtn: `${PREFIX}-popDownBtn`,
    buttonProgress: `${PREFIX}-buttonProgress`,
    resendBtnMargin: `${PREFIX}-resendBtnMargin`,
    inputMargin: `${PREFIX}-inputMargin`,
    paddingInput: `${PREFIX}-paddingInput`
};

const StyledContainer = styled(Container)((
    {
        theme
    }
) => ({
    [`& .${classes.zeroPadding}`]: {
        padding: 0,
    },

    [`& .${classes.cardBorderRadius}`]: {
        borderRadius: "0 10px 10px 10px",
        width: "100%",
    },

    [`& .${classes.cardHeaderMargin}`]: {
        margin: 0,
        backgroundColor: theme.palette.primary.main,
        padding: 12,
        color: "#fff",
        border: "1px solid #324c90",
        width: "max-content",
        // justifyContent:"center",
        borderRadius: "0 0  10px 0",
    },

    [`& .${classes.fileInput}`]: {
        display: "none",
    },

    [`& .${classes.imageSizes}`]: {
        width: "40%",
        margin: "auto",
        display: "block",
    },

    [`& .${classes.container}`]: {
        justifyContent: "center",
        // justifyItems: "center",

        marginTop: "10%",
    },

    [`& .${classes.label}`]: {
        marginBottom: theme.spacing(1),
        color: theme.palette.primary.main,
    },

    [`& .${classes.marginInputLabel}`]: {
        marginBottom: theme.spacing(3),
    },

    [`& .${classes.popDownBtn}`]: {
        borderRadius: "0 0 12px 12px",
        padding: "8px",
        float: "right",
        marginTop: theme.spacing(3),
        marginBottom: "10%",
    },

    [`& .${classes.buttonProgress}`]: {
        marginRight: '4px'
    },

    [`& .${classes.resendBtnMargin}`]: {
        marginTop: theme.spacing(3),
    },

    [`& .${classes.inputMargin}`]: {
        marginBottom: theme.spacing(2),
    },

    [`& .${classes.paddingInput}`]: {
        padding: "0px 0px",
    }
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


function ConfigurePartnerScrips(props) {



    const [allScrips, setAllScrips] = useState([])
    const [data, setData] = useState([])
    useEffect(() => {
        getLookUpDataService.getLookSecurity()
            .then(res => {
                if (res) {
                    if (res.status === 200) {
                        let item = res.data.sort((a, b) => a.name.localeCompare(b.name)).map(data => {
                            return { id: data.id, label: data.name }
                        })
                        const filteredArray = item.filter(item => props.data.map(Number).includes(item.id));
                        setAllScrips(item)
                        setData(filteredArray)

                    } else {
                        if (res.status === 401 && res.data.name === "TokenExpiredError") {
                            // setOpenSessionExpiredModal(true)
                        }
                    }
                }
            }).catch((error) => {
                console.log(error)
            })
    }, [])

   
 

    return (
        <StyledContainer>
            <Formik
                initialValues={{
                    scrips: data
                }}
                enableReinitialize={true}
                validationSchema={Yup.object().shape({
                    scrips: Yup.array().required("Required"),
                })}
                onSubmit={(values) => {
                    let newArray = values.scrips.map(item => item.id);
                    props.formSubmit(newArray)
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                    setFieldValue
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Container className={classes.container}>
                            <FormControl variant="outlined" fullWidth={true}>
                                <Autocomplete
                                    multiple
                                    value={values.scrips}
                                    aria-describedby="outlined-weight-helper-text"
                                    onBlur={handleBlur}
                                    onChange={(e, value) => {
                                        if (value !== null) {
                                            setFieldValue("scrips", value);
                                        }
                                    }}
                                    name="scrips"
                                    id="checkboxes-tags-demo"
                                    options={allScrips}
                                    inputProps={{
                                        shrink: true,
                                    }}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.label}
                                    renderOption={(props,option, { selected }) => (
                                        <li {...props}>

                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.label}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params}
                                            variant="outlined" label="Scrips" />
                                    )}
                                />
                                <FormHelperText style={{ color: "#F44336", position: "absolute", top: "53px" }}>
                                    {errors.scrips &&
                                        touched.scrips &&
                                        errors.scrips}
                                </FormHelperText>
                            </FormControl>
                            <ErrorFocus />
                            <Button onClick={() => {
                                const allValues = allScrips.map((option) => option);
                                setFieldValue('scrips', allValues)
                            }}>Select All</Button>
                            <Button
                                color="primary"
                                // disabled={isSubmitting}
                                className={classes.popDownBtn}
                                size="medium"
                                type="submit"
                                variant="contained"
                            >
                                Save
                            </Button>
                        </Container>
                    </form>
                )}
            </Formik>
        </StyledContainer>
    );
}

export default ConfigurePartnerScrips;
