import React from 'react';
import { styled } from '@mui/material/styles';
import { Paper, Typography, Button, Grid } from '@mui/material';

import { NavLink } from 'react-router-dom';

const PREFIX = 'ProcureChannelPartnerCard';

const classes = {
    root: `${PREFIX}-root`,
    editbtn: `${PREFIX}-editbtn`,
    innerDivPadding: `${PREFIX}-innerDivPadding`,
    innerQuantityPadding: `${PREFIX}-innerQuantityPadding`,
    innerButtonDivPadding: `${PREFIX}-innerButtonDivPadding`,
    button: `${PREFIX}-button`,
    button1: `${PREFIX}-button1`,
    procured: `${PREFIX}-procured`,
    placed: `${PREFIX}-placed`,
    rejected: `${PREFIX}-rejected`,
    Paper: `${PREFIX}-Paper`,
    caption: `${PREFIX}-caption`,
    h6: `${PREFIX}-h6`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.root}`]: {
        '& .MuiCardContent-root': {
            padding: '0px !important'
        },
        position: 'relative',
        backgroundColor: "#ffffff",
        paddingBottom: theme.spacing(2),
        marginBottom: theme.spacing(3),

    },

    [`& .${classes.editbtn}`]: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
        alignItems: 'center',
        borderBottom: '1px solid #324C90',
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
        }
    },

    [`& .${classes.innerDivPadding}`]: {
      
        paddingBottom: 16,
        paddingLeft: 32,
       
        [theme.breakpoints.between(404, 'sm')]: {
            textAlign: "center",
            paddingTop: 10,
            paddingBottom: 10,
        },
        [theme.breakpoints.down(390)]: {
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 10,
            paddingBottom: 10,
        }

    },

    [`& .${classes.innerQuantityPadding}`]: {
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 8,
        paddingRight: 8,
        [theme.breakpoints.between(404, 'sm')]: {
            textAlign: "center",
            paddingTop: 10,
            paddingBottom: 10, 
        },
        [theme.breakpoints.down(390)]: {
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 10,
            paddingBottom: 10,
        }

    },

    [`& .${classes.innerButtonDivPadding}`]: {
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 32,
        paddingRight: 32,
        textAlign: "center",
        
        [theme.breakpoints.between(404, 'sm')]: {
            textAlign: "center",
            paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 10,
        paddingBottom: 10,
        },
        [theme.breakpoints.down('390')]: {
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 10,
            paddingBottom: 10,
        }
    },

    [`& .${classes.button}`]: {
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 32,
        paddingRight: 32,
        backgroundColor: "#324C90",
        color: "white",
        boxShadow: 'none',
        border: '1px solid #324C90',
        fontWeight: 600,
        borderRadius: "12px 12px 12px 12px",
        outline: "none",
        cursor:'pointer',
          
       




    },

    [`& .${classes.button1}`]: {
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 32,
        paddingRight: 32,
        backgroundColor: "#324C90",
        color: "white",
        boxShadow: 'none',
        border: '1px solid #324C90',
        fontWeight: 600,
        borderRadius: "12px 12px 12px 12px",
        outline: "none",
        cursor:'pointer',
            width: "100%",
            [theme.breakpoints.down('sm')]: {
                maxWidth: "500px",
            }
       




    },

    [`& .${classes.procured}`]: {
        color:"white",
        backgroundColor:"#304B8E"
    },

    [`& .${classes.placed}`]: {
        color:"white",
        backgroundColor:"#ACACAC"
    },

    [`& .${classes.rejected}`]: {
        color:"white",
        backgroundColor:"#7A7A7A"
    },

    [`& .${classes.Paper}`]: {
        margin: 16,
        backgroundImage: "linear-gradient(to bottom, #E8EDFB, #D8DDF0)"
    },

    [`& .${classes.caption}`]: {
        color : "#919191"
    },

    [`& .${classes.h6}`]: {
        [theme.breakpoints.down(469)]: {
            fontSize: "14px"
        },
        [theme.breakpoints.down(375)]: {
            fontSize: "13px"
        }
    }
}));

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) { // eslint-disable-line
    return <NavLink to={props.to} {...props}  />; // eslint-disable-line
});

const ProcureChannelPartnerCard = (props) => {

  

    return (
        (<Root>
            <Grid container>

                 {props.data && props.data.map((item) => {
                     return (
                         <Grid item xs={12} sm={12} md={6} lg={6}>
                         <Paper className={classes.Paper} elevation={10} >
                         {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                                     <div style ={{textAlign:"center",padding:16}}>
                                         <img src={logo} alt="" width="75px"/>
                                     </div>
                                 </Grid> */}
                             <Grid container style={{paddingTop:16}}>
                                 <Grid item xs={6} sm={6} md={12} lg={12}>
                                     <div className={classes.innerDivPadding}>
                                         <Typography variant="caption" className={classes.caption}>Scrip</Typography>
                                         <Typography variant="subtitle2" className={classes.h6} color={'primary'}>{item.security_name}</Typography>
                                     </div>
                                 </Grid>
                                 <Grid item xs={6} sm={6} md={12} lg={12}>
                                     <div className={classes.innerDivPadding}>
                                         <Typography variant="caption" className={classes.caption}>Company</Typography>
                                         <Typography variant="subtitle2" className={classes.h6} color={'primary'}>{item.company_name}</Typography>
                                     </div>
                                 </Grid>
                                 <Grid item xs={6} sm={6} md={12} lg={12}>
                                     <div className={classes.innerDivPadding}>
                                         <Typography variant="caption" className={classes.caption}>ISIN</Typography>
                                         <Typography variant="subtitle2" className={classes.h6} color={'primary'}>{item.isin}</Typography>
                                     </div>
                                 </Grid>
                                 <Grid item xs={6} sm={6} md={12} lg={12}>
                                     <div className={classes.innerDivPadding}>
                                         <Typography variant="caption" className={classes.caption}>Sector</Typography>
                                         <Typography variant="subtitle2" className={classes.h6} color={'primary'}>{item.sector}</Typography>
                                     </div>
                                 </Grid>
                                 <Grid item xs={6} sm={6} md={6} lg={6}>
                                     <div className={classes.innerDivPadding}>
                                         <Typography variant="caption" className={classes.caption}>Price</Typography>
                                         <Typography variant="subtitle2" className={classes.h6} color={'primary'}>{item.price === "0" ? "" :item.price}</Typography>
                                     </div>
                                 </Grid>
                                 {/* <Grid item xs={6} sm={6} md={6} lg={6}>
                                     <div className={classes.innerDivPadding}>
                                         <Typography variant="caption" className={classes.caption}>Quantity</Typography>
                                         <Typography variant="subtitle2" className={classes.h6} color={'primary'}>{item.quantity === undefined || null || 0 ? "" :item.quantity}</Typography>
                                     </div>
                                 </Grid> */}
                                 
                                 <Grid item xs={12} sm={12} md={12} lg={12} >
                                     <div className={classes.innerButtonDivPadding}>
                                     <Button 
                                         size="small"
                                          variant="contained" 
                                          className={classes.button}
                                          onClick ={() => 
                                             props.callBack(item.security_id) 
                                          }
                                          >
                                             {item.is_procurement_placed === true ? "PLACED" : "PROCURE"}
                                         </Button> 
                                     </div>
                                 </Grid>
                             </Grid>
                         </Paper>
                         </Grid>
                     )
                 })}


     </Grid>
        </Root>)
    );
};

export default ProcureChannelPartnerCard;
