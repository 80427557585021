import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import {
  Button,
  Container,
  Typography,
  Card,
  Grid,
  Box,
  IconButton,
} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import { useParams } from "react-router-dom";
import NormalCard from "src/components/Cards/NormalCard";
import { securityService } from "src/_services/security";
import { connect } from "react-redux";
import AlertModal from "src/components/AlertModal/AlertModal";
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal";
import ModalCentered from "src/components/Modals/ModalCentered";
import NewsCreateForm from "./NewsCreateForm";
import SnackBar from "src/components/SnackBar/SnackBar";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DefaultLogo from "src/images/news.jpg";
import like from "src/images/ActiveLike.svg";
import bulb from "src/images/ActiveBulb.svg";
import heart from "src/images/ActiveHeart.svg";
import moment from "moment/moment";


const PREFIX = 'SecurityNews';

const classes = {
  reaction: `${PREFIX}-reaction`,
  hdrlblbtn: `${PREFIX}-hdrlblbtn`,
  editbutton: `${PREFIX}-editbutton`,
  induvidual: `${PREFIX}-induvidual`,
  labelname: `${PREFIX}-labelname`,
  card: `${PREFIX}-card`,
  image: `${PREFIX}-image`,
  details: `${PREFIX}-details`,
  formgrgroup: `${PREFIX}-formgrgroup`,
  removeLink: `${PREFIX}-removeLink`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.reaction}`]: {
    margin: "0 8px !important",
    display: "flex !important",

    alignItems: "center !important"
  },

  [`& .${classes.hdrlblbtn}`]: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(0),
    alignItems: "center",
    marginBottom: "1%",
  },

  [`& .${classes.editbutton}`]: {
    borderRadius: "14px 14px 0 0",
    boxShadow: "none",
    textTransform: "capitalize",
    marginLeft: "1rem",
  },

  [`& .${classes.induvidual}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.labelname}`]: {
    margin: "1rem 1rem 0 1rem",
    fontWeight: "500",
  },

  [`& .${classes.card}`]: {
    padding: "1rem",
    margin: "1rem",
    border: "1px solid grey",
    borderRadius: "10px",
  },

  [`& .${classes.image}`]: {
    alignItems: "center",
    objectFit:'contain'
  },

  [`& .${classes.details}`]: {
    margin: "1rem",
  },

  [`& .${classes.formgrgroup}`]: {
    display: "flex",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.removeLink}`]: {
    cursor: "pointer",
  }
}));

const SecurityNews = (props) => {
  const userID = props.authReducer.auth.user && props.authReducer.auth.user.id;
  let id = useParams();
  let useParamsId = id.id;
  let role = props.role
  const [newsList, setNewsList] = useState([]);
  const [readId, setReadId] = useState();
  const [isReadMore, setIsReadMore] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);
  const [severity, setSeverity] = React.useState("");
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(
    false
  );
  const [openModal, setOpenModal] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [rowID, setRowID] = useState();
  const [formMode, setFormMode] = useState(null);

  const toggleReadMore = (id) => {
    if (id === readId) {
      setIsReadMore(!isReadMore);
    }
    else {
      setIsReadMore(false)
    }
    setReadId(id);
  };

  
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const submitHandleSecurityNews = (values) => {
    setSpinner(true);
    // const news_total_count = parseInt(values.heart) + parseInt(values.like) + parseInt(values.bulb)
    const file = values.fileType === "file" ? true : false;
    const formData = new FormData();
    formData.append("short_description", values.shortDescription);
    formData.append("long_description", values.longDescription);
    formData.append("fileUpload", file);
    formData.append("news_source", values.newsSource);
    formData.append("news_image_url", values.url);
    formData.append("news_image_url_file", values.file);
    formData.append("related_security_id", useParamsId);
    formData.append("related_admin_id", userID);
    formData.append("news_picked_date", values.selectedDate);
    formData.append("news_total_count", values.adminCount);
    if (formMode === "create") {
      securityService
        .postNews(formData)
        .then((res) => {
          if (res.status === 200) {
            getNews();
            setOpenModal(false);
            setSpinner(false);
            setSeverity("success");
            setSnackbarTitle("News created successfully");
            setOpen(true);
          } else if (
            res.status === 401 &&
            res.data.name === "TokenExpiredError"
          ) {
            setOpenModal(false);
            setOpenSessionExpiredModal(true);
          } else {
            setOpenModal(false);
            setSpinner(false);
            setSeverity("error");
            setSnackbarTitle("Something went wrong");
            setOpen(true);
          }
        })
        .catch((error) => {
          setSeverity("Error");
          setSnackbarTitle("Something went wrong");
          setOpen(true);
          setOpenModal(false);
          setSpinner(false);
        });
    } else {
      formData.append("id", rowID);
      securityService
        .updateNews(formData)
        .then((res) => {
          if (res.status === 200) {
            getNews();
            setOpenModal(false);
            setSpinner(false);
            setSeverity("success");
            setSnackbarTitle("Security news updated");
            setOpen(true);
          } else if (
            res.status === 401 &&
            res.data.name === "TokenExpiredError"
          ) {
            setOpenModal(false);
            setOpenSessionExpiredModal(true);
          } else {
            setOpenModal(false);
            setSpinner(false);
            setSeverity("error");
            setSnackbarTitle("Something went wrong");
            setOpen(true);
          }
        })
        .catch((error) => {
          setSeverity("Error");
          setSnackbarTitle("Something went wrong");
          setOpen(true);
          setOpenModal(false);
          setSpinner(false);
        });
    }
  };

  const handleDelete = () => {
    setSpinner(true);
   
    securityService
      .deleteNews(deleteId)
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            setSpinner(false);
            getNews();
            setDeleteOpen(false);
            setSeverity("success");
            setSnackbarTitle("Scrips news deleted");
            setOpen(true);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
              setDeleteOpen(false);
              setSpinner(false);
            } else {
              setSpinner(false);
              setDeleteOpen(false);
              setSeverity("error");
              setSnackbarTitle("Failed to delete");
              setOpen(true);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setDeleteOpen(false);
        setSpinner(false);
        setSeverity("error");
        setSnackbarTitle("Something Went Wrong !");
        setOpen(true);
      });
  };

  const getNews = () => {
    const data = {
      userId: userID
    }
    setLoading(true);
    securityService
      .getNews(data, useParamsId)
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            setNewsList(res.data);
            setLoading(false);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
              setLoading(false);
            }
          }
        }
      })
      .catch((error) => console.log(error));
  };

  const closeModalHandler = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    getNews();
  }, []);


  const openModalHandler = (type) => {
    setFormMode(type);
    if (type === "create") {
      setTitleModal("Add news");
      setSnackbarTitle("News added successfully");
    } else {
      setTitleModal("Edit news");
      setSnackbarTitle("News updated successfully");
    }
    setSeverity("success");
    setOpenModal(true);
  };

  const handleEditNews = (item) => {
    setFormMode("edit");
    setEditData({
      shortDescription: item.short_description,
      longDescription: item.long_description,
      fileType: "",
      url: item.news_image_url,
      file: "",
      newsSource: item.news_source,
      selectedDate: `${moment(item.news_picked_date).format("YYYY-MM-DD")}`,
      like: item.like_count,
      bulb: item.bulb_count,
      heart: item.heart_count,
      adminCount:item.news_total_count,
    });
    setRowID(item.id);
    openModalHandler("edit");
  };

  return (
    <Root>

        <NormalCard title="Scrip News"
          btn={
            role ==='ADMIN_VIEWER' ? null : <div style={{ display: "flex" }}>
              <Button className={classes.addButton}
                variant="contained"
                color="primary"
                size='medium'
                onClick={() => {
                  openModalHandler("create");
                }}>
                Add News
              </Button>
            </div>
          }>
          {newsList.length >= 1 ? (
            newsList.map((item) => {
              const text = item.long_description;
              return (
                <Container key={item.id}>
                  <Card className={classes.card}>
                    <div style={{ textAlign: "center" }}>
                      <img
                        src={
                          item.news_image_url
                            ? item.news_image_url
                            : DefaultLogo
                        }
                        width={500}
                        height={300}
                        alt={item.short_description}
                        className={classes.image}
                      />
                    </div>
                    <div

                    >
                      <Grid container
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}>
                        <Grid item xs={10} sm={10} md={10} lg={10}>
                          <Typography className={classes.labelname}>
                            {item.short_description}
                          </Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2}
                          style={{

                            justifyContent: "end",
                          }}>
                          <Typography className={classes.labelname}>
                            <span style={{ fontWeight: "300", }}>{moment(item.news_picked_date).fromNow()}</span>

                          </Typography>
                          {/* <Typography >
                            <span style={{ fontWeight: "300", margin: "0px 1rem" }}>{moment(item.news_picked_date).format("YYYY-MM-DD")}</span>

                          </Typography> */}

                        </Grid>
                      </Grid>
                    </div>
                    <Typography className={classes.details}>
                      {readId === item.id && !isReadMore
                        ? text
                        : text.slice(0, 280)}
                      <span
                        onClick={() => toggleReadMore(item.id)}
                        style={{ color: "rgb(192,192,192)", cursor: "pointer" }}
                      >
                        {readId === item.id && !isReadMore
                          ? " show less"
                          : text.length > 280
                            ? "...read more"
                            : ""}
                      </span>
                    </Typography>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Grid
                          container
                          className={classes.hdrlblbtn}
                          style={{ justifyContent: "start", margin: "1rem", marginTop: "2rem", textAlign: "center", color: "#1c1c1c" }}
                        >
                          <Grid item xs={1} sm={1} md={1} lg={1} className={classes.reaction}>
                            <img src={like} alt={like} width={24} />
                            <span style={{ marginLeft: "5px" }}>{item.like_count}</span>
                          </Grid>
                          <Grid item xs={1} sm={1} md={1} lg={1} className={classes.reaction}>
                            <img src={bulb} alt={bulb} width={24} />
                            <span style={{ marginLeft: "5px" }}>{item.bulb_count}</span>
                          </Grid>
                          <Grid item xs={1} sm={1} md={1} lg={1} className={classes.reaction}>
                            <img src={heart} alt={heart} width={24} />
                            <span style={{ marginLeft: "5px" }}>{item.heart_count}</span>
                          </Grid>
                          <Grid item xs={3} sm={3} md={3} lg={3} className={classes.reaction}>
                            {/* <img src={heart} alt={heart} width={24} /> */}
                            <span style={{ marginLeft: "5px" }}> Total Count : {item.news_count}</span>
                          </Grid>
                          {/* <Grid item xs={1} sm={1} md={1} lg={1} style={{marginLeft:"8px"}}>
                          <img src={like} alt={like} />
                            <span style={{marginLeft:"5px"}}>300</span>
                          </Grid> */}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        {role ==='ADMIN_VIEWER' ? null :<Grid
                          container
                          className={classes.hdrlblbtn}
                          style={{ justifyContent: "end" }}
                        >
                          {item.news_source && (
                            <Box style={{ margin: "1rem" }}>
                              <Button
                                className={classes.button}
                                startIcon={<LinkIcon />}
                              >
                                <a href={item.news_source} target="_blank" rel="noreferrer">
                                  Source Link
                                </a>
                              </Button>
                            </Box>
                          )}
                          <IconButton
                            variant="outlined"
                            color="primary"
                            onClick={() => handleEditNews(item)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              setDeleteOpen(true);
                              setDeleteId(item.id);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>}
                      </Grid>
                    </Grid>
                  </Card>
                </Container>
              );
            })
          ) : (
            <div style={{ textAlign: "center" , marginTop:'1rem'}}>
             {loading ? "Loading..." : "Sorry, there are no matching data to display"}
            </div>
          )}
        </NormalCard>
      <SnackBar
        open={open}
        severity={severity}
        close={handleClose}
        snackbarTitle={snackbarTitle}
      />

      <AlertModal
        title="Are you sure you want to delete this?"
        open={deleteOpen}
        setOpen={setDeleteOpen}
        toggle={() => setDeleteOpen(false)}
        onConfirm={() => handleDelete()}
        openLoading={spinner}
      ></AlertModal>
      <ModalCentered
        title={titleModal}
        open={openModal}
        toggle={closeModalHandler}
        children={
          <NewsCreateForm
            spinner={spinner}
            mode={formMode}
            data={editData}
            formSubmit={submitHandleSecurityNews}
          />
        }
      />
      <SessionExpiredModal open={openSessionExpiredModal} />
    </Root>
  );
};

const mapStateToProps = (state) => {
  return {
    force: state, // force state from reducer
    authReducer: state.authReducer,
  };
};

export default connect(mapStateToProps, null)(SecurityNews);
