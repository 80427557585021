import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import MUIDataTable from "mui-datatables";
import {  Typography , Button} from '@mui/material'
import { ThemeProvider } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import NormalCard from 'src/components/Cards/NormalCard'
import IconButton from '@mui/material/IconButton';
import Page from 'src/components/Page';
import { securityService } from 'src/_services/security';
import SnackBar from "src/components/SnackBar/SnackBar";
import AlertModal from 'src/components/AlertModal/AlertModal'
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal"
import ModalCentered from "src/components/Modals/ModalCentered";
import TagCreateForm from "./TagCreateForm"
import GetMuiTheme from 'src/components/theme';

const PREFIX = 'SecurityTags';

const classes = {
    editdeletbtn: `${PREFIX}-editdeletbtn`,
    iconbtn: `${PREFIX}-iconbtn`,
    tableEllips: `${PREFIX}-tableEllips`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.editdeletbtn}`]: {
        color: '#3363cb',
        display: 'flex'
    },

    [`& .${classes.iconbtn}`]: {
        padding: theme.spacing(0.8)
    },

    [`& .${classes.tableEllips}`]: {
        display: "flex",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: '11rem',
        [theme.breakpoints.down('sm')]: {
            width: '8rem',
        },
        [theme.breakpoints.up('sm')]: {
            width: '11rem',
        },
    }
}));


const SecurityTags = (props) => {

    const [securityTagData, setSecurityTagData] = useState([])
    const [open, setOpen] = React.useState(false);
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [deleteId, setDeleteId] = React.useState(null);
    const [severity, setSeverity] = React.useState("");
    const [snackbarTitle, setSnackbarTitle] = useState("");
    const [loading, setLoading] = useState(false);
    const [spinner, setSpinner] = useState(false)
    const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)
    const [openModal, setOpenModal] = useState(false);
    const [titleModal, setTitleModal] = useState(false);
    const [editData, setEditData] = useState(null);
    const [rowID, setRowID] = useState();
    const [formMode, setFormMode] = useState(null);

    const getSecurityTag = () => {
        setLoading(true)
        securityService.getSecurityTags()
            .then(res => {
                if (res) {
                    if (res.status === 200) {
                        setSecurityTagData(res.data.data)
                        setLoading(false)
                    } else {
                        if (res.status === 401 && res.data.name === "TokenExpiredError") {
                            setOpenSessionExpiredModal(true)
                            setLoading(false)
                        }
                    }

                }
            }).catch(error => console.log(error))
    }

    const closeModalHandler = () => {
        setOpenModal(false);
    };

    useEffect(() => {
        getSecurityTag()
    }, [])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    }


    const openModalHandler = (type) => {
        setFormMode(type);
        if(type === "create"){
        setTitleModal("Add scrip tag");
        setSnackbarTitle("Tag added successfully");
        }else{
            setTitleModal("Edit scrip tag");
            setSnackbarTitle("Tag updated successfully");   
        }
        setSeverity("success");
        setOpenModal(true);
    }

    const handleEditTag = (rowData) => {
        setFormMode("edit");
        setEditData({
          tagName: rowData[2],
          status: rowData[3] === 1 ? true :false,
          fileType:"url",
          url: rowData[3],
          file:""
        });
        setRowID(rowData[0]);
        openModalHandler("edit");
      };

    const submitHandleSecurityTags = (values) => {
        setSpinner(true)
        const file = values.fileType === "file" ? true : false
        const status = values.status === true ? 1 : 2
        const formData = new FormData();
        formData.append('tag_name', values.tagName)
        formData.append('fileUpload', file)
        formData.append('tag_icon_url', values.url)
        formData.append('tag_icon_url_file', values.file)
        formData.append('status', status)
        // formData.append('status', values.status)
        if (formMode === "create") {
        securityService.postSecurityTags(formData).then((res) => {
            if (res.status === 200) {
                getSecurityTag();
                setOpenModal(false);
                setSpinner(false)
                setSeverity("success")
                setSnackbarTitle("scrip tag created")
                setOpen(true);
            }else if (res.status === 401 && res.data.name === "TokenExpiredError") {
                    setOpenModal(false);
                   setOpenSessionExpiredModal(true)
                   
                }else{
                    setOpenModal(false);
                    setSpinner(false)
                    setSeverity("error")
                    setSnackbarTitle("Something went wrong")
                    setOpen(true);
                }
            
        }).catch(error =>{
            setSeverity("Error")
            setSnackbarTitle("Something went wrong")
            setOpen(true);
            setOpenModal(false);
            setSpinner(false)
        })
    }else{
        formData.append('id', rowID)
        securityService.updateSecuirtyTags(formData).then((res) => {
            if (res.status === 200) {
                getSecurityTag();
                setOpenModal(false);
                setSpinner(false)
                setSeverity("success")
                setSnackbarTitle("scrip tag updated")
                setOpen(true);
            }else if (res.status === 401 && res.data.name === "TokenExpiredError") {
                    setOpenModal(false);
                    setOpenSessionExpiredModal(true)
                }else{
                    setOpenModal(false);
                    setSpinner(false)
                    setSeverity("error")
                    setSnackbarTitle("Something went wrong")
                    setOpen(true);
                }
            
        }).catch(error =>{
            setSeverity("Error")
            setSnackbarTitle("Something went wrong")
            setOpen(true);
            setOpenModal(false);
            setSpinner(false)
        })
    }
    };

    const getMuiTheme =GetMuiTheme


    const columns = [
        {
            name: "id",
            label: "Id",
            options: {
                filter: false,
                sort: false,
                display: false
            }
        },
        {
            name: "tag_name",
            label: "Tag name",
            options:{
                sort:false
            }
        },
        // {
                    //     name: "tag_icon_url",
        //     label: "Icon",
        //     options: {
        //     customBodyRender: (value) => {
        //         return (
        //         <Avatar variant="rounded" src={value} >
        //         </Avatar>
        //         )
        //     }}
        // },
        {
            name: "status",
            label: "Status",
            options: {
            sort:false,
            customBodyRender: (value) => {
                return (
                    <div className={classes.tableEllips} >
                        <Typography
                            variant='body2'
                            noWrap
                        >
                          { value === 1 ? "Active" : "Inactive"}
                        </Typography>
                    </div>
                )
            }}
        },
        {
            name: "",
            label: "Action",
            options: {
                sort: false,
                filter: false,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <span className={classes.editdeletbtn} >
                            <IconButton 
                              onClick={() => handleEditTag(tableMeta.rowData)}
                              className={classes.iconbtn} aria-label="edit" >
                                <EditIcon />
                            </IconButton>
                            <IconButton onClick={() => { setDeleteOpen(true); setDeleteId(tableMeta.rowData[0]) }} className={classes.iconbtn} aria-label="delete" >
                                <DeleteIcon />
                            </IconButton>

                        </span>
                    );
                }
            }
        }
    ];
    const handleDeleteTableRow = () => {
        setSpinner(true)
         const data = {
          id: deleteId
        }
        securityService.deleteSecurityTag(data)
            .then(res => {
                if (res) {

                    if (res.status === 200) {
                        setSpinner(false)
                        getSecurityTag()
                        setDeleteOpen(false)
                        setSeverity("success")
                        setSnackbarTitle("scrip tag deleted")
                        setOpen(true);
                    }
                    else {
                        if (res.status === 401 && res.data.name === "TokenExpiredError") {
                            setOpenSessionExpiredModal(true)
                            setDeleteOpen(false)
                            setSpinner(false)
                        } else {
                            setSpinner(false)
                            setDeleteOpen(false)
                            setSeverity("error")
                            setSnackbarTitle("Failed to delete")
                            setOpen(true);
                        }
                    }
                }
            }).catch(error => {
                console.log(error)
                setDeleteOpen(false)
                setSpinner(false)
                setSeverity("error")
                setSnackbarTitle("Something Went Wrong !")
                setOpen(true);
            })
    }

    return (
        <Root>

            <Page title="Unlistedkart | Security List">

                <NormalCard title={"Scrip tags"} subtitle={"List of scrip tags"} 
                 btn={
                    <div style={{ display: "flex" }}>
                      <Button
                        color="primary"
                        onClick={()=>{
                          openModalHandler("create")
                        }}
                        size="medium"
                        type="submit"
                        variant="outlined"
                        className={classes.addButton}
                        name="addDemat"
                        value="addDemat"
                      >
                        {/* <GetAppIcon /> */}
                        Add Tags
                      </Button>
                    </div>
                  }>
                    <ThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable

                            data={securityTagData}
                            headerSort={false}
                            columns={columns}
                            options={{
                                download: true,
                                print: false,
                                filter: false,
                                 viewColumns: false,  
                                rowsPerPage: 10,
                                rowsPerPageOptions: [5, 10, 25],
                                selectableRows: "none",
                                textLabels: {
                                    body: {
                                        noMatch: loading ?
                                            'Loading...' : 'Sorry, there is no matching data to display'
                                    },
                                },
                            }}
                        />
                    </ThemeProvider>
                </NormalCard>

            </Page>
            <SnackBar
                open={open}
                severity={severity}
                close={handleClose}
                snackbarTitle={snackbarTitle}
            />

            <AlertModal
                title="Are you sure you want to delete this?"
                open={deleteOpen}
                setOpen={setDeleteOpen}
                toggle={() => setDeleteOpen(false)}
                onConfirm={() => handleDeleteTableRow()}
                openLoading={spinner}

            >
            </AlertModal>
            <ModalCentered
                title={titleModal}
                open={openModal}
                toggle={closeModalHandler}
                children={
                    <TagCreateForm
                     spinner={spinner}
                     mode={formMode}
                     data={editData}
                     formSubmit={submitHandleSecurityTags}
                     />
                }
            />
            <SessionExpiredModal
                open={openSessionExpiredModal}
            />
        </Root>
    );

}
export default SecurityTags



