import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import {
  Typography,
  IconButton,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Button,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import Page from "src/components/Page";
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal";
import NormalCard from "src/components/Cards/NormalCard";
import {  useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import ModalCentered from "src/components/Modals/ModalCentered";
// import ReactJson from "react-json-view";
import GetMuiTheme from "src/components/theme";
import { DashboardData } from "src/_services/dashboard";
import LaunchIcon from "@mui/icons-material/Launch";
import AdvanceSearchforLogs from "./AdvanceSearchorLogs";
import { JsonView, allExpanded, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

const DashboardLogs = () => {
  const navigate = useNavigate();
  const [partnerLogs, setPartnerLogs] = useState([]);
  const [customFilter, setCustomFilter] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = useState(false);
  const [advanceSearchForm,setAdvanceSearchForm] = useState(false)

  const getMuiTheme = GetMuiTheme;

  const getLogs = (fromDate, toDate, actionId, typeId, userId,relatedId='') => {
    setLoading(true);
    try {
      setCustomFilter(false)
      DashboardData.getDashboardLogs(fromDate, toDate, actionId, typeId, userId,relatedId)
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              setPartnerLogs(res.data);
              setLoading(false);
            } else {
              if (res.status === 401 && res.data.name === "TokenExpiredError") {
                setLoading(false);
                setOpenSessionExpiredModal(true);
              }
            }
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    } catch (error) {}
  };

  const columns = [
    {
      name: "created_time",
      label: "Created date",
      options: {
        filter: false,
        sort: false,
        hide: true,
        customBodyRender: (value, rowData) => {
          const date = moment(value).format("DD-MM-YYYY LT");
          return (
            <Typography variant="body2" noWrap>
              {date}
            </Typography>
          );
        },
      },
    },
    {
      name: "type_name",
      label: "Description",
      options: {
        filter: false,
        sort: false,
        hide: false,
        display: true,
        customBodyRender: (value, rowData) => {
          const description = partnerLogs[rowData.rowIndex];
          return (
            <div>
              <Typography variant="body2" noWrap>
                {`${description.creater_name} ${
                  description.action_type === "update"
                    ? "updated"
                    : description.action_type === "create"
                    ? "created"
                    : "deleted"
                }
                        ${description.type_name}
                        ${
                          description.sub_type_name
                            ? `${description.sub_type_name} for`
                            : ""
                        }  
                       ${
                         description.type_name === "order"
                           ? description.service_order_id
                           : description.type_name === "scrip"
                           ? description.scrip_name
                           : description.user_name
                       } 
                     `}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: "action_type",
      label: "View changes",
      options: {
        filter: false,
        sort: false,
        hide: false,
        display: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              {(value === "update" || value === "delete") && partnerLogs[tableMeta.rowIndex].updated_values !== (null || "") && (
                <IconButton
                  disableTouchRipple
                  sx={{ padding: 0, ml: "8px", textAlign: "center" }}
                  onClick={() => {
                    setOpen(tableMeta.rowIndex + 1);
                  }}
                >
                  <VisibilityIcon />
                </IconButton>
              )}
            </>
          );
        },
      },
    },
    {
      name: "creater_name",
      label: "Creater Name",
      options: {
        filter: false,
        sort: false,
        display: {
          search: true, 
          viewColumns: true,
      }
      }
    },
    {
      name: "service_order_id",
      label: "Order Id",
      options: {
        filter: false,
        sort: false,
        display: {
          search: true, 
          viewColumns: true,
      }
      }
    },
    {
      name: "user_name",
      label: "User name",
      options: {
        filter: false,
        sort: false,
        display: {
          search: true, 
          viewColumns: true,
      }
      }
    },
    
    {
      name: "scrip_name",
      label: "Scrip Name",
      options: {
        filter: false,
        sort: false,
        display: {
          search: true, 
          viewColumns: true,
      }
    }
    },
    {
      name: "sub_type_name",
      label: "Sub type name",
      options: {
        filter: false,
        sort: false,
        display: {
          search: true, 
          viewColumns: true,
      }
      }
    },
    {
      name: "link",
      label: "Link",
      options: {
        filter: false,
        sort: false,
        hide: false,
        display: true,
        customBodyRender: (value, tableData) => {
          return (
            <IconButton
              disableTouchRipple
              sx={{ padding: 0, textAlign: "center" }}
              onClick={() => redirectLink(tableData)}
            >
              <LaunchIcon  />
            </IconButton>
          );
        },
      },
    },
  ];

  useEffect(() => {
    getLogs("", "", "", "", "");
  }, []);

  

  const getAdvancesearchLogs=((values)=>{
    setAdvanceSearchForm(values)
    getLogs(values.from_date, values.to_date, values.action_type.id, values.log_type.id, values.channel_partner.id);
  })

  const redirectLink = (value) => {
    if (value.rowData[1] === "order") {
      navigate(
        `/app/service-order-details/${partnerLogs[value.rowIndex].order_id}`
      );
    } else if (value.rowData[1] === "scrip") {
      navigate(`/app/security-details/${partnerLogs[value.rowIndex].scrip_id}`);
    } else if (value.rowData[1] === "user") {
      navigate(`/app/user-details/${partnerLogs[value.rowIndex].user_id}`);
    }
  };

  return (
    <Page title="Unlistedkart | Dashboard Log List">
      <NormalCard title={`Dashboard log list`}>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            data={partnerLogs}
            columns={columns}
            options={{
              download: true,
              print: false,
              rowsPerPage: 10,
              filter:false,
              viewColumns:false,
              jumpToPage:true,
              rowsPerPageOptions: [10, 25, 40],
              customToolbar: () => {
                return (
                  <span style={{ display: "inline-flex" }}>
                    <Button
                      disableRipple
                      style={{
                        textTransform: "none",
                        textDecoration: "underline",
                        marginRight: "10px",
                      }}
                      color="primary"
                      onClick={() => setCustomFilter(!customFilter)}
                    >
                      {" "}
                      Advanced Search
                    </Button>
                    <Button
                      disableRipple
                      style={{
                        textTransform: "none",
                        textDecoration: "underline",
                      }}
                      color="primary"
                      onClick={() => {
                        setAdvanceSearchForm(false)
                        getLogs("", "", "", "", "");
                      }}
                      disabled={!advanceSearchForm}
                    >
                      {" "}
                      Reset
                    </Button>
                  </span>
                );
              },
              // responsive: 'scrollMaxHeight',
              selectableRows: "none",
              sortOrder: {
                name: "last_updated",
                direction: "desc",
              },
              textLabels: {
                body: {
                  noMatch: loading
                    ? "Loading..."
                    : "Sorry, there are no matching data to display",
                },
              },
            }}
          />
        </ThemeProvider>
        <ModalCentered
          title={"Logs Details"}
          open={open ? true: false}
          maxWidth="xl"
          toggle={() => setOpen(false)}
          children={
            <>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Field Name</TableCell>
                    <TableCell>{open && `${partnerLogs[open - 1].action_type ==='update' ?  "Old" :''}   Value`}</TableCell>
                {open && partnerLogs[open - 1].action_type ==='update' ? <TableCell>New Value</TableCell> : ''}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {open &&
                  partnerLogs[open - 1].updated_values !==(null || "") && partnerLogs[open - 1].updated_values.startsWith('[{') && JSON.parse(partnerLogs[open - 1].updated_values).map(
                      (item, index) => {
                        const oldType = typeof item?.oldValue;
                        const newType = typeof item?.newValue;

                        return (
                          <TableRow key={index}>
                            <TableCell sx={{ textTransform: "capitalize" }}>
                              {item?.field.replaceAll("_"," ").replaceAll("."," ")}
                            </TableCell>
                            <TableCell>
                              {oldType === "object" && item?.oldValue ? (
                                // <ReactJson
                                //   displayDataTypes={false}
                                //   enableClipboard={(e) =>
                                //     navigator.clipboard.writeText(
                                //       JSON.stringify(e.src)
                                //     )
                                //   }
                                //   src={item?.oldValue}
                                // />
                                <JsonView data={item?.oldValue} shouldExpandNode={allExpanded} style={defaultStyles} />
                                // null
                              ) : (
                                item?.oldValue === true ? "Yes" : item?.oldValue === false ? "No" :item?.oldValue || ""
                              )}
                            </TableCell>
                            { partnerLogs[open - 1].action_type ==='update' ?  <TableCell>
                              {newType === "object" ? (
                                <JsonView data={item?.newValue} shouldExpandNode={allExpanded} style={defaultStyles} />

                                // null
                              ) : (
                                item?.newValue === true ? "Yes" : item?.newValue === false ? "No" :item?.newValue
                              )}
                            </TableCell>: null}
                          </TableRow>
                        );
                      }
                    )}
                </TableBody>
              </Table>
              {/* <Typography>
                                <b> Response:</b>
                                {isJSON(partnerLogs[open - 1]?.apiresponse) && open?
                                    <ReactJson
                                        displayDataTypes={false}
                                        enableClipboard={(e)=> navigator.clipboard.writeText(JSON.stringify(e.src))}
                                        src={JSON.parse(partnerLogs[open - 1]?.apiresponse)} /> : <span>
                                        {partnerLogs[open - 1]?.apiresponse}
                                    </span>}
                            </Typography> */}
            </>
          }
        />
         <ModalCentered
          title={"Advance Search"}
          open={customFilter}
          toggle={() => setCustomFilter(false)}
          children={<AdvanceSearchforLogs advanceSearchForm={advanceSearchForm} formSubmit={getAdvancesearchLogs} />}
        />
        <SessionExpiredModal open={openSessionExpiredModal} />
      </NormalCard>
    </Page>
  );
};

export default DashboardLogs;
