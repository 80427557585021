import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Card, CardContent } from '@mui/material';
const PREFIX = 'ServiceCard';

const classes = {
    root: `${PREFIX}-root`
};

const StyledCard = styled(Card)((
    {
        theme
    }
) => ({
    [`&.${classes.root}`]: {    
        '& .MuiCardContent-root':{
            padding:'0px !important'   
        },          
        backgroundColor: "#ffffff",
        boxShadow:'none',
        paddingBottom: theme.spacing(2),        
        marginBottom:theme.spacing(3)
    }
}));

const ServiceCard = (props) => {


    return (
        <StyledCard className={classes.root}>
            <CardContent>
                <Grid container>
                    <Grid item xs={12} >
                        {props.children}
                    </Grid>
                </Grid>
            </CardContent>
        </StyledCard>
    );
};

export default ServiceCard;
