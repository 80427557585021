import React from "react";
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import {
  Container,
  TextField,
  Grid,
  TableContainer,TableHead,TableRow,
  TableCell,TableBody,FormControlLabel,Checkbox,Typography
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import loadingSpinner from 'src/images/loading-spinner.gif'
const PREFIX = 'MailTemplate';

const classes = {
  popDownBtn: `${PREFIX}-popDownBtn`,
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

}));


function CommissionStatusForIndividual({item,formSubmit}) {

  const [loading, setLoading] = React.useState(false)
  
    const initialData = {
        id:item?.id,
        commission_paid_date:item?.commission_paid_date,
        commission_paid_status:item?.commission_paid_status,
        name:item?.name
    }
 


  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialValues={initialData}
        validationSchema={Yup.object().shape({
          commission_paid_date: Yup.date().when("commission_paid_status", {
            is: true,
            then: Yup.date().required("Date is required"),
            otherwise: Yup.date(),
          }),
        })}
        onSubmit={(values) => {
          setLoading(true)
          formSubmit(values)
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,

          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container>
           <Grid item xs={12} p={0} sx={{textAlign:'center', width:'100%'}}>
                        <TableContainer fullWidth sx={{display:'flex', justifyContent:'center'}} >
                            <div>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Commission Paid status
                                    </TableCell>
                                    <TableCell>
                                    Commission Paid date
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                             <TableRow>
                            
                               <TableCell>
                                <FormControlLabel
                                   control={
                                     <Checkbox
                                     checked={values.commission_paid_status}
                                     name={`commission_paid_status`}
                                     id="commissionStatus"
                                     aria-describedby="outlined-weight-helper-text"
                                     onBlur={handleBlur}
                                     onChange={(e,value)=>{                                 
                                       setFieldValue(`commission_paid_status`, value)
                                       if(!value){
                                         setFieldValue(`commission_paid_date`,'')
                                       }
                                     }}
                                     color="primary"
                                   />
                                   }
                                   label={<Typography>Paid</Typography>}
                                 />
                               </TableCell>
                               <TableCell>
                               { values.commission_paid_status && <TextField
                                InputLabelProps={{
                                 shrink: true,
                                 }}
                                //  InputProps={{ disableUnderline: true, }}
                                 style={{marginTop:"8px"}}
                                label="Commission Paid Date*"
                                id="investorcolor"
                                variant="outlined"
                                type="date"
                                size="small"
                                disabled={!values.commission_paid_status}
                                name={`commission_paid_date`}
                                value={values.commission_paid_date}
                                autoComplete="off"
                                fullWidth
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={ touched.commission_paid_date &&errors.commission_paid_date  }
                                helperText={touched.commission_paid_date &&errors.commission_paid_date}
                                />}
                               </TableCell>
                             
                                 </TableRow>
                         
                            </TableBody>
                            </div>
                        </TableContainer>
                    </Grid>
                    </Grid>
                    <Button
                color="primary"
                disabled={isSubmitting}
                className={classes.popDownBtn}
                size="medium"
                type="submit"
                variant="contained"
              >
                {loading === false ? (
                  "Update"
                ) : (
                  <>
                    <img
                      src={loadingSpinner}
                      alt="Logo"
                      className={classes.buttonProgress}
                    />{" "}
                    Update{" "}
                  </>
                )}
              </Button>
          </form>
        )}
      </Formik>
    
    </StyledContainer>
  );
}

export default CommissionStatusForIndividual;
