import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import NormalCard from 'src/components/Cards/NormalCard'
import { Typography, IconButton, Button,Tooltip ,Grid,CircularProgress} from '@mui/material'
import Chip from '@mui/material/Chip';
import { userService } from 'src/_services/users';
import DeleteIcon from '@mui/icons-material/Delete';
import MUIDataTable from "mui-datatables";
import ImageIcon from '@mui/icons-material/Image';
import ModalCentered from "src/components/Modals/ModalCentered";
import BankCreateForm from "src/containers/Users/UserAccountDocument/BankCreateForm";
import AlertModal from 'src/components/AlertModal/AlertModal'
import SnackBar from "src/components/SnackBar/SnackBar";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal"
import EditIcon from '@mui/icons-material/Edit';
import ImageRotate from './Rotate';
import { useNavigate,useLocation } from "react-router-dom";
import { getLookUpDataService } from "src/_services/lookUp";
import GetMuiTheme from 'src/components/theme';
import compareJSON from 'src/components/comparision';
const PREFIX = 'BankAccountData';

const classes = {
    addButton: `${PREFIX}-addButton`,
    iconpdf: `${PREFIX}-iconpdf`,
    tooltip: `${PREFIX}-tooltip`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.addButton}`]: {
        fontSize: '13px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '11px',
            padding: '4px'
        }
    },

    [`& .${classes.iconpdf}`]: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(-1)
    },

    [`& .${classes.tooltip}`]: {
        cursor:'copy'
    }
}));

const theme = GetMuiTheme

const BankAccountData = (props) => {


    const [bankAccountData, setBankAccountData] = useState([])
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [titleModal, setTitleModal] = useState("");
    const [severity, setSeverity] = React.useState("");
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [snackbarTitle, setSnackbarTitle] = useState("");
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [accountID, setAccountID] = useState()
    const [spinner, setSpinner] = useState(false);
    const [loadSpinner, setLoadSpinner] = useState(false)
    const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)
    const [data,setData]=  useState(false)
    const [url , setUrl] = React.useState(null);
    const [show,setShow] = useState(false)
    const [hover, setHover] = useState(false);
    const location = useLocation();
    const [isPdf, setIsPdf] = useState(false)


    const navigate = useNavigate()

    const onHover = (value) => {
        setHover(true);
        navigator.clipboard.writeText(value);
    };

    const onLeave = () => {
        setHover(false);
    };
        
const handleShowClose=()=>{
    // handleShowClose
    // setRotation(0)
    navigate(`/app/user-details/${props.userID}`)
    setShow(false)
    setUrl(false)
}


    const copy =((value)=>{
        return (
            <div onClick={()=>onHover(value)} onMouseLeave={onLeave}   style={{ display: 'flex' }} >
             <Tooltip placement="right"  className={classes.tooltip} title={hover ? "Copied" :"Click to Copy"}>
                  <Typography
                      variant='body2'
                      style={{ textTransform: 'capitalize' }}
                     noWrap
                       >
                      {value}
                  </Typography>
                  </Tooltip>
            </div>
          );
    })
    useEffect(() => {
        const isFile = location.search.split('bankId=')
        if (isFile.length > 1 && bankAccountData) {
            const data= bankAccountData.filter((item)=>  item.fileid === Number(isFile[1]))
             setShow(true);
             handleFileUrl(isFile[1],data[0]);
             setTitleModal("Bank Proof");
           }
       
      }, [bankAccountData])

      const handleFileUrl = (id,value) => {
        getLookUpDataService.getObjectUrl(id).then((res) => {
          if (res.data.success) {
            setIsPdf(res.data.mimetype  ==="application/pdf" ?true : false)
            setUrl({
              name: value?.beneficiary_name,
              bankName: value?.bank_name,
              accountNumber: value?.account_no,
              ifscCode: value?.ifsc_code,
              url: res.data.data,
            });
          }
        });
      };
    
    

    const BankAccountDetailsHeading = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display: false
            }
        },
        {
            name: "beneficiary_name",
            label: "Beneficiary Name",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => copy(value, tableMeta),
            }
        },
        {
            name: "bank_name",
            label: "Bank",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => copy(value, tableMeta),
            }
        },
        {
            name: "account_no",
            label: "AC No",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => copy(value, tableMeta),
            }
        },
        {
            name: "ifsc_code",
            label: "IFSC Code",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => copy(value, tableMeta),
            }
        },
        {
            name: "account_type",
            label: "AC Type",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => copy(value, tableMeta),
            }
        },
        {
            name: "fileid",
            label: "POD",
            options: {
                hint: "Prof of Document",
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        value !== null && <Typography
                        style={{ maxWidth: 300,cursor:'pointer' }}
                        // component={isPdf ? "a" : null}
                            noWrap={false}
                            variant='body2'
                            // href= {isPdf ? `${value && value[0].json_agg ? value[0].json_agg[0] : value}` : null}
                            component={"a"}
                            href={ `/app/user-details/${props.userID}?bankId=${value}`}
                            target="_blank"
                            color="primary"

                        >
                            {(tableMeta.rowData[7] === "image/gif" || tableMeta.rowData[7] === "image/png" || tableMeta.rowData[7] === "image/jpeg" || tableMeta.rowData[7] === "image/jpg") && (<ImageIcon className={classes.iconpdf} size='small' />)}
                            {tableMeta.rowData[7] === "application/pdf" && (<PictureAsPdfIcon className={classes.iconpdf} size='small' />)}
                            {/* {tableMeta.rowData[7] === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && (<DescriptionIcon className={classes.iconpdf} size='small' />)} */}
                            {'View'}
                        </Typography>
                    )
                }
            }
        },
        {
            name: "mime_type",
            label: "mime_type",
            options: {
                filter: false,
                sort: false,
                display: false,

            }
        },
        {
            name: "is_primary",
            label: "Default",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    return value === true ? (
                        <Chip size="small" label={"Primary"} color="primary" />)
                        : props.edit ? (
                            <div style={{ display: "flex", alignItems: "center" }}
                                onClick={async () => {
                                    await setLoading(true)
                                    makePrimaryDemat(tableMeta.rowData[0])
                                }}>
                                <Chip
                                    size="small"
                                    label="Make Primary"
                                    style={{ marginRight: 10,cursor:'pointer' }}

                                />
                            </div>) : null
                }
            }
        },
        {
            name: "is_primary",
            label: "Action",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    return props.edit ? (
                        <Root>
                            {/* {type === "button" && (<span className={classes.editdeletbtn} style={{ display: 'flex' }}> */}
                            <IconButton
                                edge="end"
                                // disabled={value}
                                aria-label="delete"
                                onClick={() => handleEdit(tableMeta.rowIndex)}
                            >
                                <EditIcon />
                            </IconButton>
                            <IconButton
                                edge="end"
                                disabled={value}
                                aria-label="delete"
                                onClick={() => handleConfirm(tableMeta.rowData[0])}
                            >
                                <DeleteIcon />
                            </IconButton>
                            {/* </span>)} */}
                        </Root>
                    ) : null;
                }
            }
        }
    ]

    const deleteDocument = () => {
        setLoadSpinner(true)
        setDeleteConfirm(false)
        setLoadSpinner(false)
        userService.deleteBankAccount(props.userID, accountID)
            .then(res => {
                if (res) {


                    if (res.status === 200) {
                        getBankAccounts();
                        setSeverity("success");
                        setSnackbarTitle("Bank Account Deleted Successfully");
                        setOpenSnackBar(true)
                    } else {
                        if (res.status === 401 && res.data.name === "TokenExpiredError") {
                            setOpenSessionExpiredModal(true)
                        }
                    }
                }
            })
    }

    const handleConfirm = (accID) => {
        setDeleteConfirm(true);
        setAccountID(accID)
    }

        
    const handleEdit=((id)=>{
        setData({
            beneficiryName:bankAccountData[id].beneficiary_name,
            bankName: bankAccountData[id].bank_name,
            accountNumber: bankAccountData[id].account_no,
            accountType: bankAccountData[id].code,
            ifscCode: bankAccountData[id].ifsc_code,
            id: bankAccountData[id].id,
            podCopy:'' ,
            primaryAccount: bankAccountData[id].is_primary,
        })
            setTitleModal("Edit Bank Account");
            setSeverity("success");
            setSnackbarTitle("Bank account updated successfully");
            setOpenModal(true);
            
    })

    const makePrimaryDemat = (id) => {
        userService.makePrimaryBank(id, props.userID,)
            .then(res => {
                if (res) {


                    if (res.status === 200) {
                        getBankAccounts()
                        setSeverity("success");
                        setSnackbarTitle("Updated");
                        setOpenSnackBar(true);
                    } else {
                        if (res.status === 401 && res.data.name === "TokenExpiredError") {
                            setOpenSessionExpiredModal(true)
                        }
                    }
                }
            })
    }

    useEffect(() => {
        if (props.userID !== null) {
            getBankAccounts();
        }

    }, [props.userID])

    const getBankAccounts = () => {
        userService.getUserBankAccounts(props.userID)
            .then(res => {
                if (res) {


                    if (res.status === 200) {
                        setBankAccountData(res.data)
                    } else {
                        if (res.status === 401 && res.data.name === "TokenExpiredError") {
                            setOpenSessionExpiredModal(true)
                        }
                    }
                }
            })
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    }


    const openModalHandler = (type, add) => {
        setData()
        setTitleModal("Add Bank Account");
        setSeverity("success");
        setSnackbarTitle("Bank account added successfully");
        setOpenModal(true);
    }

    const closeModalHandler = () => {
        setOpenModal(false);
    };

    const submitHandleBankAccount = (values) => {
        setSpinner(true)
        // handleOnSubmit(values)
        // setFMIdProofDoc(target.files[0])
        const formData = new FormData();
        formData.append('beneficiary_name', values.beneficiryName)
        formData.append('bank_name', values.bankName)
        formData.append('account_no', values.accountNumber)
        formData.append('ifsc_code', values.ifscCode)
        formData.append('bank_account_type', values.accountType)
        formData.append('is_primary', values.primaryAccount)
        formData.append('POD_COPY', values.podCopy) //file

        userService.createBankAccount(props.userID, formData).then((res) => {
            if (res.status === 201) {
                getBankAccounts();
                setOpenModal(false);
                setOpenSnackBar(true);
                setSpinner(false)
            } else {
                if (res.status === 401 && res.data.name === "TokenExpiredError") {
                    setOpenModal(false);
                    setOpenSessionExpiredModal(true)
                }
            }
        })
    };


    const editBankAccount = (values) => {
        const  result = compareJSON(values,data)
        setSpinner(true)
        const formData = new FormData();
        formData.append('beneficiary_name', values.beneficiryName)
        formData.append('bank_name', values.bankName)
        formData.append('account_no', values.accountNumber)
        formData.append('ifsc_code', values.ifscCode)
        formData.append('bank_account_type', values.accountType)
        formData.append('is_primary', values.primaryAccount)
        formData.append('POD_COPY', values.podCopy) //file
        formData.append('id', values.id) //file
        formData.append('updatedValues', JSON.stringify(result)) 



        userService.editBankAccount(props.userID, formData).then((res) => {
            if (res.status === 200) {
                getBankAccounts();
                setOpenModal(false); 
                setOpenSnackBar(true);
                setSpinner(false)
            } else {
                if (res.status === 401 && res.data.name === "TokenExpiredError") {
                    setOpenModal(false);
                    setOpenSessionExpiredModal(true)
                }
            }
        })
    };

    return (
        <React.Fragment>
            <Root>
            <NormalCard title="Bank Account Details"
                className={classes.cardroot}
                btn={props.edit ?
                    <Button
                        color="primary"
                        onClick={() => openModalHandler("demat", "create")}
                        size="medium"
                        type="submit"
                        variant="outlined"
                        className={classes.addButton}
                        name="addDemat"
                        value="addDemat"
                        disabled={props.userID !== null ? false : true}
                    >
                        Add Account
                    </Button> : null
                }

            >
                <ThemeProvider theme={theme}>

                    <MUIDataTable
                        data={bankAccountData}
                        columns={BankAccountDetailsHeading}
                        options={{
                            download: true,
                            viewColumns: false,
                            filter: false,
                            pagination: false,
                            search: false,
                            print: false,
                            rowsPerPage: 5,
                            rowsPerPageOptions: [5, 10, 25],
                            selectableRows: "none",
                            // display: props.display,
                            textLabels: {
                                body: {
                                    noMatch: loading ?
                                        'Loading...' : 'Sorry, there are no matching data to display'
                                },
                            },
                        }}
                    />

                </ThemeProvider>
            </NormalCard>

            <ModalCentered
                title={titleModal}
                open={openModal}
                toggle={closeModalHandler}
                children={
                    <BankCreateForm data={data} spinner={spinner} isPrimary={bankAccountData.length ===0 ?true :false} formSubmit={data ?editBankAccount : submitHandleBankAccount} />
                }
            />

            <AlertModal
                title="Are you sure you want to delete this account?"
                open={deleteConfirm}
                toggle={() => setDeleteConfirm(false)}
                onConfirm={deleteDocument}
                openLoading={loadSpinner}
            >
            </AlertModal>

            <SnackBar
                open={openSnackBar}
                severity={severity}
                close={handleClose}
                snackbarTitle={snackbarTitle}
            />
            <ModalCentered
        title={titleModal}
        fullScreen={true}
        open={show}
        toggle={handleShowClose}
        children={
            <div style={{textAlign:'center', position:'relative'}}>
           {url?.url ?  <Grid container style={{display:'flex'}}>
                <Grid item xs={12}  style={{justifyContent:'center', display:'flex'}}>
                   <div style={{textAlign:'start'}}>
                   <Typography><b>Name:</b> {url.name}</Typography>
                    <Typography><b>Bank Name:</b> {url.bankName}</Typography>
                    <Typography><b>Bank Account Number:</b> {url.accountNumber}</Typography>
                    <Typography><b>Bank IFSC Code:</b> {url.ifscCode}</Typography>
                   </div>
                </Grid>
                <Grid item xs={12} md={12}>
         <div style={{ 
                alignItems:'center', display:'flex', justifyContent:'center'}}>
                     { isPdf? <iframe title="iframe" style={{width:'100%', height:'calc(100vh - 100px)'}} src={url.url}/>:
        <ImageRotate
      alt="cool image"
      type="users"
      image={url &&url.url}/>}
         </div>
               </Grid>
            </Grid> : (
                <Typography> <CircularProgress size={20} /> Loading...</Typography>
              )}
          
            </div>}
      />
            <SessionExpiredModal
                open={openSessionExpiredModal}
            />
            </Root>
        </React.Fragment>
    )

}

export default BankAccountData