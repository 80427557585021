import React from 'react';

class DisableRightClick extends React.Component {
  componentDidMount() {
    // Add event listener to disable right-click
    document.addEventListener('contextmenu', this.disableContextMenu);
    document.addEventListener('keydown', this.handleF12Key);

  }

  componentWillUnmount() {
    // Remove event listener when the component is unmounted
    document.removeEventListener('keydown', this.handleF12Key);
    document.removeEventListener('contextmenu', this.disableContextMenu);
  }

   handleF12Key = (e) => {
    if (e.keyCode === 123) {
      e.preventDefault(); // Prevent F12 from opening DevTools
    }
  };

  disableContextMenu = (e) => {
    e.preventDefault(); // Prevent the default right-click behavior
  };

  render() {
    return this.props.children; // Render the children components
  }
}

export default DisableRightClick;