import React, { useEffect } from "react";
import { styled } from '@mui/material/styles';
import MUIDataTable from "mui-datatables";
import {
  Button,
  Typography,
  Box,
  AppBar,
  Tabs,
  Tab,
} from "@mui/material";

import {
  ThemeProvider,
  useTheme, 
} from "@mui/material/styles";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import ServiceCard from "src/components/Cards/ServiceCard";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import { serviceOrderService } from "src/_services/serviceOrder";
import Page from "src/components/Page";
import Chip from "@mui/material/Chip";
import { NavLink } from "react-router-dom";
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal";
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from 'react-router-dom';
import GetMuiTheme from "src/components/theme";

const PREFIX = 'ServiceOrderItemsByUserID';

const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  btnright: `${PREFIX}-btnright`,
  container: `${PREFIX}-container`,
  button: `${PREFIX}-button`,
  editdeletbtn: `${PREFIX}-editdeletbtn`,
  btnsdltedt: `${PREFIX}-btnsdltedt`,
  detailsbtn: `${PREFIX}-detailsbtn`,
  active: `${PREFIX}-active`,
  inactive: `${PREFIX}-inactive`,
  closed: `${PREFIX}-closed`,
  completed: `${PREFIX}-completed`,
  processing: `${PREFIX}-processing`,
  preProcessing: `${PREFIX}-preProcessing`,
  cancelled: `${PREFIX}-cancelled`,
  hold: `${PREFIX}-hold`,
  pending: `${PREFIX}-pending`,
  stageOneProcessing: `${PREFIX}-stageOneProcessing`,
  stageOneProcessed: `${PREFIX}-stageOneProcessed`,
  stageTwoProcessed: `${PREFIX}-stageTwoProcessed`,
  stageTwoProcessing: `${PREFIX}-stageTwoProcessing`,
  bottomborder: `${PREFIX}-bottomborder`,
  subtitle: `${PREFIX}-subtitle`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    margin: theme.spacing(2),
    "& .MuiTableHead-root": {
      background: "blue",
    },
  },

  [`& .${classes.card}`]: {
    borderBottom: "none",
    padding: 0,
  },

  [`& .${classes.btnright}`]: {
    textAlign: "end",
  },

  [`& .${classes.container}`]: {
    padding: "0px",
  },
  [`& .${classes.inactive}`]: {
    color: "white",
    backgroundColor: "#D50000",
    fontWeight: "bold",
  },

  [`& .${classes.closed}`]: {
    color: "white",
    backgroundColor: "#314b8f",
    fontWeight: "bold",
  },

  [`& .${classes.completed}`]: {
    color: "white",
    backgroundColor: "#285430",
    fontWeight: "bold",
  },

  [`& .${classes.processing}`]: {
    color: "white",
    backgroundColor: "#CD7A00",
    fontWeight: "bold",
  },

  [`& .${classes.preProcessing}`]: {
    color: "white",
    backgroundColor: "#FFA559",
    fontWeight: "bold",
  },

  [`& .${classes.cancelled}`]: {
    color: "white",
    backgroundColor: "#226FCD",
    fontWeight: "bold",
  },

  [`& .${classes.hold}`]: {
    color: "white",
    backgroundColor: "#FA9884",
    fontWeight: "bold",
  },

  [`& .${classes.pending}`]: {
    color: "white",
    backgroundColor: "grey",
    fontWeight: "bold",
  },

  [`& .${classes.active}`]: {
    color: "white",
    backgroundColor: "#a526a5",
    fontWeight: "bold",
  },

  [`& .${classes.stageOneProcessing}`]: {
    color: "white",
    backgroundColor: "#87C4FF",
    fontWeight: "bold",
  },

  [`& .${classes.stageOneProcessed}`]: {
    color: "white",
    backgroundColor: "#39A7FF",
    fontWeight: "bold",
  },

  [`& .${classes.stageTwoProcessed}`]: {
    color: "white",
    backgroundColor: "#427D9D",
    fontWeight: "bold",
  },

  [`& .${classes.stageTwoProcessing}`]: {
    color: "white",
    backgroundColor: "#9BBEC8",
    fontWeight: "bold",
  },

  [`& .${classes.button}`]: {
    marginBottom: "1%",
    borderRadius: "14px 14px 0 0",
    boxShadow: "none",
    textTransform: "capitalize",
  },

  [`& .${classes.editdeletbtn}`]: {
    color: "#3363cb",
  },

  [`& .${classes.btnsdltedt}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.detailsbtn}`]: {
    boxShadow: "none",
    textTransform: "capitalize",
    borderRadius: "0px 20px 20px 0px",
  },

  [`& .${classes.bottomborder}`]: {
    backgroundColor: "#fff",
    borderBottom: "1px solid #e8e8e8",
  },

  [`& .${classes.subtitle}`]: {
    paddingLeft: "16px",
  }
}));

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) {
  // eslint-disable-line
  return <NavLink to={props.to} {...props}  />; // eslint-disable-line
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ServiceOrderItemsByUserID = () => {

  const theme = useTheme();
  let location = useLocation();
  const [sellOrderData, setSellOrderData] = React.useState([]);
  const [buyOrderData, setBuyOrderData] = React.useState([]);
  const [activeTab, setActiveTab] = React.useState(
    location?.state?.tabValue ? location.state.tabValue : 0
  );
  const [btnSwitch, setbtnSwitch] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(
    false
  );
  const [page, setPage] = React.useState(
    location?.state?.page ? location.state.page : 0
  );
   let id = useParams();
  // const [row, setRow] = React.useState([])
  const navigate = useNavigate();

  const navigateToDetails = (value, tableData, index) => {
    navigate(`/app/service-order-details/${value}`, {
      replace: true,
      state: { page: page, data: tableData, index: index },
    });
  };

  const getMuiTheme =GetMuiTheme

  const sellColumnData = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "service_id",
      label: "Order Id",
      options: {
        hint: "Unique Order Number",
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <Typography
            style={{ cursor: "pointer" }}
              variant="body2"
              noWrap={false}
              onClick={() => {
                navigateToDetails(
                  tableMeta.rowData[0],
                  tableMeta.currentTableData,
                  tableMeta.rowIndex
                );
                // setRow(tableMeta.tableData);
                // console.log(tableMeta.currentTableData.length, 'hgjhgjh');
              }}
              color="primary"
            >
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "create_datetime",
      label: "Created Date",
      options: {
        filter: false,
        sort: true,
        hint: "Date of Transaction Initiation",
        customBodyRender: (value) => {
          let valueMoment = moment(value).format("DD-MM-YYYY LTS");
          return (
            <div className={classes.tableEllips}>
              <Typography variant="body2" noWrap>
                {valueMoment}
              </Typography>
            </div>
          );
        },
      },
    },

    // {
    //     name: "order_type_code",
    //     label: "Type",
    //     options: {
    //         hint: 'Type of ',
    //         filter: false,
    //         sort: false,
    //     }
    // },
    {
      name: "transaction_type_code",
      label: "Transaction Type",
      options: {
        hint: "Information on Type of Relation",
        filter: false,
        sort: false,
      },
    },
    {
      name: "investor",
      label: "Investor",
      options: {
        hint: "Party Investing with Unlistedkart",
        filter: false,
        sort: false,
      },
    },
    {
      name: "channel_partner",
      label: "Client",
      options: {
        hint: "Party through Whom Transaction is Occuring",
        filter: false,
        sort: false,
      },
    },
    {
      name: "order_status",
      label: "Status",
      options: {
        hint: "Transaction Service Progress",
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography>
              <Chip
                size="small"
                label={value}
                className={clsx({
                  [classes.active]: value === "Active",
                  [classes.completed]: value === "Completed",
                  [classes.inactive]: value === "Inactive",
                  [classes.processing]: value === "Processing",
                  [classes.preProcessing]: value === "Preprocessing",
                  [classes.cancelled]: value === "Cancelled",
                  [classes.hold]: value === "Onhold",
                  [classes.closed]: value === "Closed",
                  [classes.stageOneProcessing]:
                    value === "Stage one processing",
                  [classes.stageOneProcessed]: value === "Stage one processed",
                  [classes.stageTwoProcessing]:
                    value === "Stage two processing",
                  [classes.stageTwoProcessed]: value === "Stage two processed",
                })}
              />
            </Typography>
          );
        },
      },
    },
    {
      name: "lead_owner",
      label: "Internal RM",
      options: {
        hint: "Internal RM Owning the Order",
        filter: false,
        sort: true,
      },
    },
    {
      name: "assign",
      label: "Servicing Owner",
      options: {
        hint: "Internal RM Servicing Order Currently",
        filter: false,
        sort: true,
      },
    },
  ];

  const buyColumnData = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "service_id",
      label: "Order Id",
      options: {
        hint: "Unique Order Number",
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <Typography
            style={{ cursor: "pointer" }}
              variant="body2"
              noWrap={false}
              onClick={() => {
                navigateToDetails(tableMeta.rowData[0],
                    tableMeta.currentTableData,
                    tableMeta.rowIndex);
              }}
              color="primary"
            >
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "create_datetime",
      label: "Created Date",
      options: {
        hint: "Date of Transaction Initiation",
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          let valueMoment = moment(value).format("DD-MM-YYYY LTS");
          return (
            <div className={classes.tableEllips}>
              <Typography variant="body2" noWrap>
                {valueMoment}
              </Typography>
            </div>
          );
        },
      },
    },

    {
      name: "order_type_code",
      label: "Type",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "transaction_type_code",
      label: "Txn Type",
      options: {
        hint: "Information on Type of Relation",
        filter: false,
        sort: false,
      },
    },
    {
      name: "vendor",
      label: "Vendor",
      options: {
        hint: "Party from Whom Inventory has been Sourced",
        filter: false,
        sort: false,
      },
    },
    {
      name: "channel_partner",
      label: "Client",
      options: {
        hint: "Party through Whom Transaction is Occuring",
        filter: false,
        sort: false,
      },
    },
    {
      name: "order_status",
      label: "Status",
      options: {
        hint: "Transaction Service Progress",
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Typography>
              <Chip
                size="small"
                label={value}
                className={clsx({
                  [classes.active]: value === "Active",
                  [classes.completed]: value === "Completed",
                  [classes.inactive]: value === "Inactive",
                  [classes.processing]: value === "Processing",
                  [classes.preProcessing]: value === "Preprocessing",
                  [classes.cancelled]: value === "Cancelled",
                  [classes.hold]: value === "Onhold",
                  [classes.closed]: value === "Closed",
                  [classes.stageOneProcessing]:
                    value === "Stage one processing",
                  [classes.stageOneProcessed]: value === "Stage one processed",
                  [classes.stageTwoProcessing]:
                    value === "Stage two processing",
                  [classes.stageTwoProcessed]: value === "Stage two processed",
                })}
              />
            </Typography>
          );
        },
      },
    },
    {
      name: "lead_owner",
      label: "Internal RM",
      options: {
        hint: "Internal RM Owning the Order",
        filter: false,
        sort: true,
      },
    },
    {
      name: "assign",
      label: "Servicing Owner",
      options: {
        hint: "Internal RM Servicing Order Currently",
        filter: false,
        sort: true,
      },
    },
  ];

  const getServiceOrder = (userID) => {
    setLoading(true);
    serviceOrderService
      .getServiceOrderByUserID(userID)
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            const sellData = [];
            const buyData = [];

            res.data.forEach((item) => {
              if (item.order_type === "Sell") {
                sellData.push(item);
              } else if (item.order_type === "Buy") {
                buyData.push(item);
              }
            });

            setSellOrderData(sellData);
            setBuyOrderData(buyData);
            setLoading(false);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setLoading(false);
              setOpenSessionExpiredModal(true);
            } else {
              setLoading(false);
            }
          }
        } else {
          setLoading(false);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
   
    
    getServiceOrder(id.id);
  }, []);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
    setPage(0);
    setbtnSwitch(!btnSwitch);
  };

  const handleChangeIndex = (index) => {
    setActiveTab(index);
  };

  return (
    <Root>
      <Page title="Unlistedkart | Order">
        <div className={classes.container}>
          <div className={classes.btnright}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              size="medium"
              component={LinkBtn}
              to="/app/service-order-create"
            >
              Create Order
            </Button>
          </div>

          <ServiceCard className={classes.card}>
            <ThemeProvider theme={getMuiTheme}>
              <div style={{ position: "relative" }}>
                <div className={classes.bottomborder}>
                  <AppBar position="static">
                    <Tabs
                      value={activeTab}
                      onChange={handleChange}
                      style={{
                        marginBottom: 24,
                        borderBottom: "1px solid #e8e8e8",
                      }}
                    >
                      <Tab label="Sell Orders" {...a11yProps(0)} />
                      <Tab label="Buy Orders" {...a11yProps(1)} />
                    </Tabs>
                  </AppBar>
                </div>
              </div>
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={activeTab}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={activeTab} index={0}>
                  <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                      data={sellOrderData}
                      headerSort={false}
                      columns={sellColumnData}
                      options={{
                        rowsPerPage: 10,
                        rowsPerPageOptions: [5, 10, 25],
                        // responsive: 'scrollMaxHeight',
                        viewColumns: false,
                        download: true,
                        print: false,    
                        selectableRows: "none",
                        sortOrder: {
                          name: "create_datetime",
                          direction: "desc",
                        },
                        textLabels: {
                          body: {
                            noMatch: loading
                              ? "Loading..."
                              : "Sorry, there are no matching data to display",
                          },
                        },
                        onChangePage(pageNumber) {
                          setPage(pageNumber);
                        },
                        page: page,
                      }}
                    />
                  </ThemeProvider>
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                  <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                      data={buyOrderData}
                      headerSort={false}
                      columns={buyColumnData}
                      options={{
                        rowsPerPage: 10,
                        rowsPerPageOptions: [5, 10, 25],
                        viewColumns: false,
                        download: true,
                        print: false,    
                        // responsive: 'scrollMaxHeight',
                        selectableRows: "none",
                        sortOrder: {
                          name: "create_datetime",
                          direction: "desc",
                        },
                        textLabels: {
                          body: {
                            noMatch: loading
                              ? "Loading..."
                              : "Sorry, there are no matching data to display",
                          },
                        },
                        onChangePage(pageNumber) {
                          setPage(pageNumber);
                        },
                        page: page,
                      }}
                    />
                  </ThemeProvider>
                </TabPanel>
              </SwipeableViews>
            </ThemeProvider>
          </ServiceCard>
        </div>
        <SessionExpiredModal open={openSessionExpiredModal} />
      </Page>
    </Root>
  );
};

export default ServiceOrderItemsByUserID;
