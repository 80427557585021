import React from "react";
import { styled } from '@mui/material/styles';
import loadingSpinner from "src/images/loading-spinner.gif";
import {
    FormControl,
    RadioGroup,
    FormLabel,
    Checkbox,
    Grid,
    Container,
    Radio,
    Button,
    FormControlLabel, FormGroup, Typography,
    FormHelperText,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
const PREFIX = 'GenerateDealTerm';

const classes = {
    container: `${PREFIX}-container`,
    popDownBtn: `${PREFIX}-popDownBtn`,
    buttonProgress: `${PREFIX}-buttonProgress`
};

const StyledContainer = styled(Container)((
    {
        theme
    }
) => ({
    [`& .${classes.container}`]: {
        justifyContent: "center",
        // justifyItems: "center",

        marginTop: "10%",
    },

    [`& .${classes.popDownBtn}`]: {
        borderRadius: "0 0 12px 12px",
        padding: "8px",
        float: "right",
        marginTop: theme.spacing(3),
        marginBottom: "10%",
    },

    [`& .${classes.buttonProgress}`]: {
        marginRight: "4px",
    }
}));

function GenerateDealTerm(props) {

    const [loading, setLoading] = React.useState(false);

    return (
        <StyledContainer>
            <Formik
                initialValues={{
                    type: "",
                    order_type: props.order_type,
                    discloseStatus: true,
                    //   order_id: props.order_id,
                }}
                validationSchema={Yup.object().shape({
                    type: Yup.string().when("discloseStatus", {
                        is: true,
                        then: Yup.string().required("Required"),
                        otherwise: Yup.string(),
                      }),
                })}
                onSubmit={(values) => {
                    setLoading(true);
                    props.formSubmit(values);
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    setFieldValue
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Container className={classes.container}>
                            <Grid container>
                            <Grid item xs={12}>
                                    <FormControl component="fieldset" variant="standard">
                                        <FormGroup row>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={values.discloseStatus}
                                                        name="discloseStatus"
                                                        id="commissionStatus"
                                                        aria-describedby="outlined-weight-helper-text"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        color="primary"
                                                    />
                                                }
                                                label={<Typography> Hide client details in the dealterm</Typography>}
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                              {values.discloseStatus && <Grid item xs={12}>
                                    <FormControl component="fieldset" error={Boolean(touched.type && errors.type)}>
                                        <FormLabel component="legend">Generate Deal Term For *</FormLabel>
                                        <RadioGroup row aria-label="payment_mode" name="type"
                                            value={values.type}
                                            id="payment_mode"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        >
                                            <FormControlLabel value={"1"} control={<Radio color="primary" />} label="Buyer" />
                                            <FormControlLabel value={"2"} control={<Radio color="primary" />} label="Seller" />
                                        </RadioGroup>
                                        <FormHelperText>{touched.type && errors.type}</FormHelperText>
                                    </FormControl>
                                </Grid>}
                             
                            </Grid>
                            <Button
                                color="primary"
                                disabled={isSubmitting}
                                className={classes.popDownBtn}
                                size="medium"
                                type="submit"
                                variant="contained"
                            >
                                {loading === false ? (
                                    "Download"
                                ) : (
                                    <>
                                        <img
                                            src={loadingSpinner}
                                            alt="Logo"
                                            className={classes.buttonProgress}
                                        />{" "}
                                        Download{" "}
                                    </>
                                )}
                            </Button>
                        </Container>
                    </form>
                )}
            </Formik>
        </StyledContainer>
    );
}

export default GenerateDealTerm;
