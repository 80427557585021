import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import MUIDataTable from "mui-datatables";
import { Typography, Button } from '@mui/material';

import {  ThemeProvider } from '@mui/material/styles';
import Page from 'src/components/Page';
import NormalCard from 'src/components/Cards/NormalCard'
import { userService } from 'src/_services/users'
import {useNavigate, NavLink} from 'react-router-dom';
import { connect } from 'react-redux';
import Chip from '@mui/material/Chip';
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal"
import GetMuiTheme from 'src/components/theme';
const PREFIX = 'Index';

const classes = {
    tableEllips: `${PREFIX}-tableEllips`,
    root: `${PREFIX}-root`,
    btnright: `${PREFIX}-btnright`,
    container: `${PREFIX}-container`,
    button: `${PREFIX}-button`,
    editdeletbtn: `${PREFIX}-editdeletbtn`,
    btnsdltedt: `${PREFIX}-btnsdltedt`,
    chipMargin: `${PREFIX}-chipMargin`,
    iconbtn: `${PREFIX}-iconbtn`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.tableEllips}`]: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: '7rem',
        [theme.breakpoints.down('sm')]: {
            width: '8rem',
        },
        [theme.breakpoints.up('sm')]: {
            width: '7rem',
        },
    },

    [`& .${classes.root}`]: {
        margin: theme.spacing(2),
        '& .MuiTableHead-root': {
            background: 'blue'
        }
    },

    [`& .${classes.btnright}`]: {
        textAlign: 'end'
    },

    [`& .${classes.container}`]: {
        minHeight: '100%',
        padding: theme.spacing(3)

    },

    [`& .${classes.button}`]: {
        marginBottom: '1%',
        borderRadius: '14px 14px 0 0',
        boxShadow: 'none',
        textTransform: 'capitalize'
    },

    [`& .${classes.editdeletbtn}`]: {
        color: '#3363cb',
        display: 'flex'
    },

    [`& .${classes.btnsdltedt}`]: {
        marginRight: theme.spacing(1)
    },

    [`& .${classes.chipMargin}`]: {
        marginRight: "5px",
        marginBottom: "3px",
        fontSize: "12px !important",
        padding: 0,
        height: "20px",
        paddingLeft: "0px !important",
        paddingRight: "0px !important"
    },

    [`& .${classes.iconbtn}`]: {
        padding: theme.spacing(0.8)
    }
}));

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) { // eslint-disable-line
    return <NavLink to={props.to} {...props}  />; // eslint-disable-line
});

const ChannelPartners = (props) => {
  

    const navigate = useNavigate();
    const [userData, setUserData] = useState([])
    // const [loading, setLoading] = useState(false)
    const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)


    

    const getMuiTheme = GetMuiTheme

    useEffect(() => {
        if(props.authReducer.auth){
            if(props.authReducer.auth.user){
                userService.getExternalRmsForLoggedinUser().then(res => {
                    if(res){
                        if (res.status === 200) {
                            setUserData(res.data)
                        }else{
                            if (res.status === 401 && res.data.name === "TokenExpiredError") {
                                setOpenSessionExpiredModal(true)
                            }
                        }
                    }
            })
            }
        }
        
    }, [])

    const goToCreateUserPage =() =>{
        navigate("/app/user-create?mode=create", { replace: true })
    }

    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                hide: true,
                display: false
            }
        },
        {
            name: "is_accredit",
            label: "Accredit Type",
            options: {
                filter: false,
                sort: false,
                hide: true,
                display: false
            }
        },

        {
            name: "user_unique_code",
            label: "Empanelment ID",
            options: {
                hint :"Unlistedkart Unique ID for Users",
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    return (
                        <Typography>
                            {value?.toUpperCase()}
                        </Typography>)
                }
            }

        },

        {
            name: "name",
            label: "Name",
            options: {
                hint :"Full Name of Users",
                filter: false,
                customBodyRender: (value, tableMeta) => {
                    const name = value && value.length > 15 ? value.substr(0, 15) + '...' : value
                    return (
                        <div className={classes.tableEllips} >
                            <Typography
                                variant='body2'
                                style={{ maxWidth: 300,textTransform: 'capitalize'  }}
                                component={LinkBtn}
                                noWrap
                                to={`/app/user-details/${tableMeta.rowData[0]}`}
                                color="primary"
                             >
                                {name}
                            </Typography>
                        </div>
                    )
                }
            }
        },
        {
            name: "user_type",
            label: "Type",
            options: {
                hint :"Individual or Institution ",
                filter: true,
                sort: true,
            }
        },
        {
            name: "user_role",
            label: "Roles",
            options: {
                hint :"User Roles",
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {            
                    const resValueRole=value.map((value,i)=>{                                        
                          return (<Chip className={classes.chipMargin} label={value==="Client" && tableMeta.rowData[1] ===true ? "Accredit Investor" : value} variant="outlined" />)                    
                      })  
                    return resValueRole               
                  } 
            }
        },

        {
            name: "email",
            label: "Email",
            options: {
                hint :"User Email ID",
                filter: false,
                sort: false,
            }
        },
        {
            name: "contact_no",
            label: "Phone",
            options: {
                hint :"Contact of User",
                filter: false,
                sort: false,
            }
        },

        {
            name: "pan",
            label: "PAN",
            options: {
                hint :"Permanent Account Number",
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    return (
                        <Typography>
                            {value?.toUpperCase()}
                        </Typography>)
                }
            }
        },
        
        {
            name: "last_updated",
            label: "Last Updated",
            options: {
                filter: false,
                sort: false,
                hide: true,
                display: false
            }
        },
    ];


    return (
        <Root>
        <Page title="Unlistedkart | Users List">
            <div className={classes.btnright}>
                <Button className={classes.button} 
                    variant="contained" 
                    color="primary" 
                    size='medium'
                    onClick={goToCreateUserPage}>
                    Create External RM's
                </Button>
            </div>
            <NormalCard title={"External RM's"} subtitle={"List of Channel Partners"} >
                <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        data={userData}
                        columns={columns}
                        options={{
                            download: true,
                            print: false,
                            viewColumns: false,
                            rowsPerPage: 10,
                            rowsPerPageOptions: [5, 10, 25],
                            // responsive: 'scrollMaxHeight',
                            selectableRows: "none",
                            sortOrder: {
                                name: 'last_updated',
                                direction: 'desc'
                            }
                        }}
                    />
                </ThemeProvider>
            </NormalCard>
            <SessionExpiredModal
                open={openSessionExpiredModal}
            />
        </Page>
        </Root>
    );
}

const mapStateToProps = state => {    
    return ({
        force: state, // force state from reducer
        authReducer: state.authReducer,
    })
  };

export default connect(mapStateToProps)(ChannelPartners);
