import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles';
import React, { useState, useEffect } from 'react'
import IconButton from '@mui/material/IconButton';
import { Grid, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction } from '@mui/material';
import Page from 'src/components/Page';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { ThemeProvider } from '@mui/material/styles';
import MUIDataTable from "mui-datatables";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Divider from '@mui/material/Divider';
import {  NavLink } from 'react-router-dom';
import NormalCard from 'src/components/Cards/NormalCard'
// import axios from "axios"
// import ImageIcon from '@mui/icons-material/ImageIcon';
import ImageIcon from '@mui/icons-material/Image';
import DescriptionIcon from '@mui/icons-material/Description';


import CircularLoading from 'src/components/Loading/Loading';
import { useParams } from "react-router-dom";
import { serviceOrderService } from "src/_services/serviceOrder"
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal"
import GetMuiTheme from 'src/components/theme';








const PREFIX = 'ChannelPartnerServiceOrderDetails';

const classes = {
    root: `${PREFIX}-root`,
    hdrlblbtn: `${PREFIX}-hdrlblbtn`,
    induvidual: `${PREFIX}-induvidual`,
    labelname: `${PREFIX}-labelname`,
    details: `${PREFIX}-details`,
    formgrgroup: `${PREFIX}-formgrgroup`,
    btndocuments: `${PREFIX}-btndocuments`,
    btnprimary: `${PREFIX}-btnprimary`,
    iconpdf: `${PREFIX}-iconpdf`,
    editbutton: `${PREFIX}-editbutton`,
    pricelbl: `${PREFIX}-pricelbl`,
    remark: `${PREFIX}-remark`,
    popDownBtn: `${PREFIX}-popDownBtn`,
    verified: `${PREFIX}-verified`,
    verified_item: `${PREFIX}-verified_item`,
    check: `${PREFIX}-check`,
    unCheck: `${PREFIX}-unCheck`,
    shortdetails: `${PREFIX}-shortdetails`,
    backButton: `${PREFIX}-backButton`,
    instructions: `${PREFIX}-instructions`,
    pricebar: `${PREFIX}-pricebar`,
    pricelabel: `${PREFIX}-pricelabel`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.root}`]: {
        backgroundColor: theme.palette.background.dark,
        paddingBottom: theme.spacing(2),
        // paddingTop: theme.spacing(2),
        width: '100%',
        padding: '0px',
        paddingTop: '15px',
    },

    [`& .${classes.hdrlblbtn}`]: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: theme.spacing(0),
        alignItems: 'center',
        marginBottom: '1%'
    },

    [`& .${classes.induvidual}`]: {
        marginTop: theme.spacing(2)
    },

    [`& .${classes.labelname}`]: {
        [theme.breakpoints.up(576)]: {
            width: "22%",
        },
        width: "50%",
        textAlign: 'end',
        fontWeight: '500'
    },

    [`& .${classes.details}`]: {
        width: '68%',
        marginLeft: theme.spacing(2)
    },

    [`& .${classes.formgrgroup}`]: {
        display: 'flex',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

    [`& .${classes.btndocuments}`]: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '16px'
    },

    [`& .${classes.btnprimary}`]: {
        borderRadius: '12px 12px 12px 0px',
        marginRight: theme.spacing(2)
    },

    [`& .${classes.iconpdf}`]: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(-1)
    },

    [`& .${classes.editbutton}`]: {
        borderRadius: '14px 14px 0 0',
        boxShadow: 'none',
        textTransform: 'capitalize',
        fontSize: '0.9rem',
        lineHeight: '1.3'
    },

    [`& .${classes.pricelbl}`]: {
        textAlign: ' end',
        padding: '8px 18px 0px 9px',
        fontSize: '1rem',
        color: 'rgb(49, 75, 143)',
        fontWeight: ' 500'
    },

    [`& .${classes.remark}`]: {
        padding: theme.spacing(2),
        textAlign: 'justify'
    },

    [`& .${classes.popDownBtn}`]: {
        borderRadius: "0 16px 16px 0",
        padding: "10px",
    },

    [`& .${classes.verified}`]: {
        display: 'flex',
        flexWrap: 'wrap',

    },

    [`& .${classes.verified_item}`]: {
        margin: '16px 16px -4px 16px'
    },

    [`& .${classes.check}`]: {
        fontSize: '20px',
        marginBottom: theme.spacing(-0.5),
        color:"#4caf50"
    },

    [`& .${classes.unCheck}`]: {
        fontSize: '20px',
        marginBottom: theme.spacing(-0.5),
        color:"#f44336"
    },

    [`& .${classes.shortdetails}`]: {
        paddingLeft: theme.spacing(3.1),
        color: '#8F8F8F',
        fontSize: '12px'
    },

    [`& .${classes.backButton}`]: {
        marginRight: theme.spacing(1),
    },

    [`& .${classes.instructions}`]: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

    [`& .${classes.pricebar}`]: {
        display: 'flex',
        paddingTop: theme.spacing(1),
        fontWeight: '500',
        color: 'rgb(49, 75, 143)',
        marginBottom: theme.spacing(-1)
    },

    [`& .${classes.pricelabel}`]: {
        [theme.breakpoints.down(960)]: {
            width: '50%',
            textAlign: 'left'
        },
        width: '80%',
        textAlign: 'end',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    }
}));








const LinkBtn = React.forwardRef(function LinkBtn(props, ref) { // eslint-disable-line
    return <NavLink to={props.to} {...props}  />; // eslint-disable-line
});

const ChannelPartnerServiceOrderDetails = () => {

    let id = useParams();
    let useParamsId = id.id
   
    let [serviceOrderData, setServiceOrderData] = useState({})
    let [securityData, setSecurityData] = React.useState([]);
    const [documentData, setDocumentData] = React.useState([]);
    
   
    const[dealTermFileData,setDealTermFileData] = useState("")

    const stepName = ["INACTIVE", "ACTIVE", "PROCESSING", "COMPLETED", "CLOSED"]
    let [activeStep, setActiveStep] = React.useState(0);

    const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)


  

    let totalConsolidatedPrice = 0



   
   




    const getSecurityData = (id) => {
        serviceOrderService.getSecurityByID(id)
            .then((res) => {
                if (res) {
                    if (res.status === 200) {
                        setSecurityData(res.data)
                    }else{
                        if (res.status === 401 && res.data.name === "TokenExpiredError") {
                            setOpenSessionExpiredModal(true)
                        }
                    }
                }
            }).catch((error) => {
                console.log(error);
            })





    }

    const getOrderBasicInfo = () => {
        serviceOrderService.getServiceOrderByID(useParamsId)
            .then(res => {
                if (res) {
                    if (res.status === 200) {
                        setServiceOrderData(res.data)
                        let stepNameIndex = stepName.indexOf(res.data.order_status_code) + 1
                        setActiveStep(stepNameIndex) 
                    }else{
                        if (res.status === 401 && res.data.name === "TokenExpiredError") {
                            setOpenSessionExpiredModal(true)
                        }
                    }
                }
            }).catch((error) => {
                console.log(error);
            })
    }

    
    
    

    const getServiseOrderDocuments = () => {
        serviceOrderService.getServiceOrderDocuments(useParamsId)
            .then((res) => {
                if (res) {
                   
                    if (res.status === 200) {
                        setDocumentData(res.data)
                    }else{
                        if (res.status === 401 && res.data.name === "TokenExpiredError") {
                            setOpenSessionExpiredModal(true)
                        }
    
                    }
                }
            }).catch((error) => {
                console.log(error);
            })
    }

    const getDealTermDocument = () => {
        serviceOrderService.getServiceOrderdDealTermDocuments(useParamsId)
        .then((res) => {
            if(res){
                if(res.status === 200){
                   
                 
           
       
                  
                    const file = new Blob(
                        [res.data], 
                        {type: 'application/pdf'});
                  //Build a URL from the file
                      const fileURL = URL.createObjectURL(file);
                  //Open the URL on new Window
                  
                   
                   
                    setDealTermFileData(fileURL)


                    // var file = window.URL.createObjectURL(res.data);
                   
                    // var file = new File([res.data], "DealTerm.pdf",{type:"application/pdf", lastModified:new Date().getTime()})
      

                    
                }else{
                    if (res.status === 401 && res.data.name === "TokenExpiredError") {
                        setOpenSessionExpiredModal(true)
                    }
                }
            }
        })
    }


    useEffect(() => {
        getSecurityData(useParamsId)
        getOrderBasicInfo()
       
        getServiseOrderDocuments();
        getDealTermDocument();
    }
        , [])

    // console.log("verifiedData",verifiedDetailsData)

    totalConsolidatedPrice = securityData.reduce((sum, item) => {
        return sum + Number(item.consolidated_price)
    }, 0)

    let totalSoldPrice = new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
    }).format(totalConsolidatedPrice);






   




   
  
//     const handleDownload = (url) => {
//         // const url = window.URL.createObjectURL(new Blob([response.data]));
//         const link = document.createElement('a');
//         link.href = url;
//         // link.setAttribute('download', 'file.pdf');
//         link.download("file.pdf")
//         document.body.appendChild(link);
//         link.click();
       

//         axios.get(url,
//             {
//                 method: 'GET',
//                 headers: {
//                     ContentType: 'application/pdf',
//                 },
//             })
//             .then((response) => response.blob())
//   .then((blob) => {
//     // Create blob link to download
//     const url = window.URL.createObjectURL(
//       new Blob([blob]),
//     );
//     const link = document.createElement('a');
//     link.href = url;
//     link.setAttribute(
//       'download',
//       `FileName.pdf`,
//     );

//     // Append to html link element page
//     document.body.appendChild(link);

//     // Start download
//     link.click();

//     // Clean up and remove the link
//     link.parentNode.removeChild(link);
//   })
//             .catch((error) => {
//                 console.log("error".error);
//                 alert('oh no!')});
//     }

    
    const getMuiTheme = GetMuiTheme

    const SecurityColumn = [
        {
            name: "service_order_id",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display: false
            }
        },
        {
            name: "slno",
            label: "Sl No",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography
                            variant='body2'
                            component={LinkBtn}
                            noWrap={false}
                            to={`/app/service-order-details/${tableMeta.rowData[0]}`}
                            color="primary"
                        >
                            {tableMeta.rowIndex + 1}
                        </Typography>
                    )
                }
            }
        },
        {
            name: "security_name",
            label: "Scrip Name",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "quantity",
            label: "Quantity",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "price",
            label: "Price",
            options: {
                filter: false,
                sort: false,
            }
        },

        {
            name: "commision",
            label: "Commission",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "consolidated_price",
            label: "Consideration Amount",
            options: {
                filter: false,
                sort: false,
            }
        },
    ]



    // const deleteDocument = () => {
    //     setDeleteConfirm(false);
    //     serviceOrderService.deleteServiceOrderDocuments(documentID)
    //         .then((res) => {
    //             if (res) {
    //                 if (res.status === 200) {
    //                     setSeverity("success")
    //                     setSnackbarTitle("File has been  Deleted Successfully")
    //                     setSnackBarOpen(true);
    //                     getServiseOrderDocuments();
    //                 } else {
    //                     setSeverity("error")
    //                     setSnackbarTitle("Failed to Delete File")
    //                     setSnackBarOpen(true);
    //                 }
    //             }
    //         }).catch((error) => {
    //             setSeverity("error")
    //             setSnackbarTitle("Failed to Delete File")
    //             setSnackBarOpen(true);
    //         })
    // }


    // const handlefileDelete = (docID) => {
    //     setDeleteConfirm(true);
    //     setDocumentID(docID)
    // }

    // const handleOpenDealTerm = () =>{
    //     window.open(dealTermFileData, "_blank");
    // }


    // const handleEditBtn=()=>
    // {
    //     navigate("/app/service-order-create?mode=edit", { replace: true, state: { userID: useParamsId } });     
    // }

    function getSteps() {
        return [
            { label: 'Inactive', message: '' },
            { label: 'Active', message: 'Make it Active' },
            { label: 'Processing', message: 'Make it Proccessing' },
            { label: 'Completed', message: 'Make it Completed' },
            { label: 'Closed', message: 'Make it Closed' },
        ];
    }
    // const [module, setModule] = React.useState(true);



    // console.log("stepNameIndex",stepNameIndex);


    const steps = getSteps();
    // setActiveStep(stepNameIndex)





    // const handleStep = (step) => () => {
    //     if (step >= activeStep && step < (activeStep + 2)) {
    //         if (activeStep < step) {
    //             handleVerificationModal()
    //         }
    //     }
    // };

    // let totalSoldPrice = new Intl.NumberFormat("en-IN", {
    //     style: "currency",
    //     currency: "INR",
    //   }).format(totalConsolidatedPrice);

    return (
        <Root>
        <Page title="Unlistedkart | Order Details" >


<ThemeProvider theme={getMuiTheme}>
                <div className={classes.root}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {
                            steps.map((data, index) =>
                               
                                    <Step key={data.label} data-tip data-for="registerTip">
                                        <StepLabel className={classes.stepname} 
                                            activeStep={activeStep[index]}>
                                            {data.label}
                                        </StepLabel>
                                    </Step>
                                
                            )
                        }
                    </Stepper>
                </div>
            </ThemeProvider>
            

            <NormalCard title="Basic info">
                <div className={classes.induvidual}>
                    <div className={classes.formgrgroup} >
                        <label className={classes.labelname}>Type</label>
                        <p className={classes.details}>{serviceOrderData.order_type_code}</p>
                    </div>
                    <div className={classes.formgrgroup} >
                        <label className={classes.labelname}>Transaction Type</label>
                        <p className={classes.details}>{serviceOrderData.transaction_type_code}</p>
                    </div>
                    {(serviceOrderData.order_type_code === "BUY" && serviceOrderData.channel_partner !== null) ?
                        <div className={classes.formgrgroup} >
                           
                            <label className={classes.labelname}>Channel Partner</label>
                            <p className={classes.details}>{serviceOrderData.channel_partner}</p>
                            
                        </div>
                        : <></>
                    }
                    <div className={classes.formgrgroup} >
                        <label className={classes.labelname}>{serviceOrderData.order_type_code === "SELL" ? "Investor" : "Vendor"}</label>
                        <p className={classes.details}>{serviceOrderData.order_type_code === "SELL" ? serviceOrderData.investor : serviceOrderData.vendor}</p>
                    </div>
                    <div className={classes.formgrgroup} >
                        <label className={classes.labelname}>Status</label>
                        <p className={classes.details}>{serviceOrderData.order_status_code}  </p>
                    </div>
                    <div className={classes.formgrgroup} >
                        <label className={classes.labelname}>Lead Owner</label>
                        <p className={classes.details}>{serviceOrderData.lead_owner}</p>
                    </div>
                    <div className={classes.formgrgroup} >
                        <label className={classes.labelname}>Assign</label>
                        <p className={classes.details}>{serviceOrderData.assign} </p>
                    </div>
                    <div className={classes.formgrgroup} >
                        <label className={classes.labelname}>Sevice Id</label>
                        <p className={classes.details}>
                            {serviceOrderData.service_id}
                        </p>
                    </div>

                </div>
            </NormalCard>

            <NormalCard title="Scrip Details">
                <div>
                    <ThemeProvider theme={getMuiTheme}>
                        <MUIDataTable
                            data={securityData}
                            columns={SecurityColumn}
                            options={{
                                search: false,
                                print: false,
                                pagination: false,
                                download: true,
                                viewColumns: false,
                                filter: false,
                                rowsPerPage: 5,
                                rowsPerPageOptions: [5, 10, 25],
                                // responsive: 'scrollMaxHeight',
                                selectableRows: "none",
                                textLabels: {
                                    body: {
                                        noMatch:   <CircularLoading /> 
                                    },
                                },
                            }}
                        />
                        <div className={classes.pricebar}>
                            <div className={classes.pricelabel}>
                                <Typography
                                    variant="body1"
                                >Total Sold Price
                                    </Typography>
                            </div>
                            <div className={classes.price}>
                                <Typography
                                    variant="body1"
                                > {totalSoldPrice}
                                </Typography>
                            </div>
                        </div>
                    </ThemeProvider >
                </div>
            </NormalCard>

           

            <NormalCard title="Documents" >
                <div className={classes.uploadAlign}>
                    <Grid md={6} sm={12}>
                        <List dense={true}>

                         <ListItem>
                             <ListItemIcon><PictureAsPdfIcon /></ListItemIcon>
                             <ListItemText
                              button
                              primary={
                                  <Typography
                                      style={{ maxWidth: 300 }}
                                      component={"a"}
                                      noWrap={false}
                                      href={`${dealTermFileData}`}
                                      color="primary"
                                      target="_blank"
                                     
                                      
                                  >
                                  Deal Term
                                     
                                  </Typography>}
                                
                             ></ListItemText>
                             <ListItemSecondaryAction>
                             <IconButton
                                                edge="end"
                                                aria-label="download"
                                                title="Download"
                                                
                                            >
                                                 <Typography
                                      style={{ maxWidth: 300 }}
                                      component={"a"}
                                      noWrap={false}
                                      href={`${dealTermFileData}`}
                                      
                                      download={`${serviceOrderData.service_id}.pdf`}
                                     
                                      
                                  >
                               <CloudDownloadIcon />
                                     
                                  </Typography>
                                               
                                            </IconButton>

                             </ListItemSecondaryAction>
                         </ListItem>
                         <Divider />


                            
                            {
                                documentData && documentData.map(item =>
                                (<React.Fragment>
                                    <ListItem>
                                        <ListItemIcon>
                                            {(item.deal_term_file_type === "image/png" || item.deal_term_file_type === "image/jpeg") && (<ImageIcon />)}
                                            {item.deal_term_file_type === "application/pdf" && (<PictureAsPdfIcon />)}
                                            {item.deal_term_file_type ===  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && (<DescriptionIcon />)}
                                        </ListItemIcon>

                                        <ListItemText button
                                            primary={
                                                <Typography
                                                    style={{ maxWidth: 300 }}
                                                    component={"a"}
                                                    noWrap={false}
                                                    href={`${item.deal_term_file_url}`}
                                                    color="primary"
                                                    
                                                    target="_blank"
                                                >

                                                    <p> {item.deal_term_document_type_description === "Others" ? item.document_name :item.deal_term_document_type_description}</p>
                                                </Typography>}

                                        // secondary={secondary ? 'Secondary text' : null}
                                        />
                                        {/* <ListItemText><Typography>{item.deal_term_document_type_description === "Others" ? item.document_name : <></>}</Typography></ListItemText> */}
                                        <ListItemSecondaryAction>

                                           
                                            <IconButton
                                                edge="end"
                                                aria-label="download"
                                                title="Download"
                                                // onClick={() => handleDownload(item.deal_term_file_url)}
                                            >
                                                
                                                <Typography
                                      style={{ maxWidth: 300 }}
                                      component={"a"}
                                      noWrap={false}
                                      href={`${item.deal_term_file_url}`}
                                      
                                      download="Deal Term.pdf"
                                     
                                      
                                  >
                               <CloudDownloadIcon />
                                     
                                  </Typography>
                                            
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                                )
                                )
                            }
                        </List>
                    </Grid>


                </div>
            </NormalCard>
            <SessionExpiredModal
                open={openSessionExpiredModal}
            />

        </Page>
        </Root>
    );
}

export default ChannelPartnerServiceOrderDetails