import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from '@mui/material/styles';
import { Typography,  Grow } from '@mui/material';

import { securityService } from 'src/_services/security';
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal"
import moment from 'moment'
import GetMuiTheme from 'src/components/theme';

const PREFIX = 'Scriplogs';

const classes = {
    tooltip: `${PREFIX}-tooltip`,
    tableEllips: `${PREFIX}-tableEllips`,
    card: `${PREFIX}-card`,
    bottomborder: `${PREFIX}-bottomborder`,
    root: `${PREFIX}-root`,
    addButton: `${PREFIX}-addButton`,
    container: `${PREFIX}-container`,
    button: `${PREFIX}-button`,
    editdeletbtn: `${PREFIX}-editdeletbtn`,
    btnsdltedt: `${PREFIX}-btnsdltedt`,
    iconbtn: `${PREFIX}-iconbtn`,
    companyLogo: `${PREFIX}-companyLogo`,
    removeLink: `${PREFIX}-removeLink`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.tableEllips}`]: {
        display: "flex",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: '11rem',
        [theme.breakpoints.down('sm')]: {
            width: '8rem',
        },
        [theme.breakpoints.up('sm')]: {
            width: '11rem',
        },
    },

    [`& .${classes.card}`]: {
        borderBottom: "none",
        padding: 0,
    },

    [`& .${classes.bottomborder}`]: {
        backgroundColor: "#fff",
        borderBottom: "1px solid #e8e8e8",
    },

    [`& .${classes.root}`]: {
        margin: theme.spacing(2),
        '& .MuiTableHead-root': {
            background: 'blue'
        }
    },

    [`& .${classes.addButton}`]: {
        textAlign: 'end',
        marginLeft: "10px"
    },

    [`& .${classes.container}`]: {
        // minHeight: '100%',
        padding: '24px'
    },

    [`& .${classes.button}`]: {
        marginBottom: '1%',
        borderRadius: '14px 14px 0 0',
        boxShadow: 'none',
        textTransform: 'capitalize'
    },

    [`& .${classes.editdeletbtn}`]: {
        color: '#3363cb',
        display: 'flex'
    },

    [`& .${classes.btnsdltedt}`]: {
        marginRight: theme.spacing(1)
    },

    [`& .${classes.iconbtn}`]: {
        padding: theme.spacing(0.8)
    },

    [`& .${classes.companyLogo}`]: {
        "&:hover": {
            transform: 'scale(3.5)',
            filter: 'alpha(opacity=30)'
        },
    },

    [`& .${classes.removeLink}`]: {
        cursor: 'pointer'
    }
}));



const getMuiTheme = GetMuiTheme

const Scriplogs = (props) => {
    const [securityData, setSecurityData] = useState([])
    const [loading, setLoading] = useState(false);
    const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)


    const getSecurity = () => {
        setLoading(true)
        securityService.getSecurityLogs(props.id)
            .then(res => {
                if (res) {
                    if (res.status === 200) {
                        setSecurityData(res.data.data)
                        setLoading(false)
                    } else {
                        if (res.status === 401 && res.data.name === "TokenExpiredError") {
                            setOpenSessionExpiredModal(true)
                            setLoading(false)
                        }
                    }

                }
            }).catch(error => console.log(error))
    }

    const columns = [
        {
            name: "id",
            label: "Id",
            options: {
                filter: false,
                sort: false,
                display: false
            }
        },
        {
            name: "name",
            label: "Modified by",
            options: {
                hint: 'Industry Sectors',
                filter: true,
                sort: true,
            }
        },
        {
            name: "modified_value",
            label: "Modified Field",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <div className={classes.tableEllips} >
                            <Typography
                                variant='body2'
                                noWrap
                            >
                                {value}
                            </Typography>
                        </div>
                    )
                }
            }
        },
        {
            name: "last_updated",
            label: "Last Updated",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value,) => {
                    let valueMoment = moment(value).format("DD-MM-YYYY LTS")
                    return (
                        <div className={classes.tableEllips} >
                            <Typography
                                variant='body2'
                                noWrap
                            >
                                {valueMoment}
                            </Typography>
                        </div>
                    )
                }
            }
        },
        {
            name: "old_value",
            label: "Old Value"
        },
        {
            name: "new_value",
            label: "New  value"
        }

    ];

    useEffect(() => {
        getSecurity()
    }, [])

    return (
        (
            <Grow in={true}>
                <Root>
                    <ThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable

                            data={securityData}
                            headerSort={false}
                            columns={columns}
                            options={{
                                rowsPerPage: 10,
                                rowsPerPageOptions: [5, 10, 25],
                                // responsive: 'scrollMaxHeight',
                                selectableRows: "none",
                                // sortOrder: {
                                //     name: 'last_updated',
                                //     direction: 'desc'
                                // },
                                viewColumns: false,
                                download: true,
                                print: false,
                                textLabels: {
                                    body: {
                                        noMatch: loading ?
                                            'Loading...' : 'Sorry, there is no matching data to display'
                                    },
                                },
                            }}
                        />
                    </ThemeProvider>
                    <SessionExpiredModal
                        open={openSessionExpiredModal}
                    />
                </Root>
            </Grow>)
    );
}

export default Scriplogs