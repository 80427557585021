import React from 'react'
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';

const PREFIX = 'ButtonLoading';

const classes = {
    spinner: `${PREFIX}-spinner`
};

const Root = styled('div')(() => ({
    [`& .${classes.spinner}`]: {
      border: "2px solid #314B8F",
      backgroundColor:"#314B8F",
      "&:hover":{
          backgroundColor:"#314B8F",
      },
    }
}));

export default function ButtonLoading() {
    const [loading, setLoading] = React.useState(false);

    const handleLoading=()=>
    {
        setLoading(!loading)
    }

    return (
        <Root>
            {
                loading ? (                   
       <div>           
<IconButton 
className={classes.spinner}
size="medium"
 >
<CircularProgress style={{color:"#fff"}} size={15} />
</IconButton>
</div> 
                    
              ):(
                <Button
                size="medium"
                variant="contained"
                color="primary"
                className={classes.button}               
                onClick={handleLoading}
              >
              Create
              </Button> 
                  )
            }



<Button
        variant="contained"
        color="secondary"
        className={classes.button}
        startIcon={ loading && (<CircularProgress style={{color:"#fff"}} size={15} />)}
      >
        Create
      </Button>
        </Root>
    );
}
