import axios from 'axios'

import { helpers } from '../_helpers/index'



//default header and api endpoint config
export const loginService = {
    mobileAuthentication,
    verifyMobileOtp,    
    logout,
    createPassword,
    getLoggedInUserInfo,
    setDefaultRole,
    switchRole,
    getFirebaseSecrets,
};

function mobileAuthentication(username) {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/mobile/otpauthentication`, JSON.stringify({ username: username}), requestOptions)
        .then(res => res)
        .catch(error => error.response)
}

function getFirebaseSecrets() {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/mobile/getFirebaseSecrets`, requestOptions)
        .then(res => res)
        .catch(error => error.response)
}
function verifyMobileOtp(username, otp) {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/mobile/otpvalidation`, JSON.stringify({ username, otp}), requestOptions)
        .then(res => {
            return res
        })
        .catch(error => {
            return error.response
        })
}

function createPassword(username, newpassword, confirmpassword) {
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/createnewpassword `,
        JSON.stringify({ username: username, newpassword: newpassword, confirmpassword: confirmpassword }), requestOptions)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}

function logout() {
    helpers.removeCookies("token")
}

function getLoggedInUserInfo(){
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/users/login_detail`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function setDefaultRole(role) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.put(`/users/default_role`,
        JSON.stringify({ role: role}), requestOptions)
        .then(res => {
            return res
        })
        .catch(error => {
            return error.response
        })
}

function switchRole(role) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/switch_user_role`,
        JSON.stringify({ user_role: role}), requestOptions)
        .then(res => {
            return res
        })
        .catch(error => {
            return error.response
        })
}