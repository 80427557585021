import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
// import { Doughnut } from 'react-chartjs-2';
// import "chart.js/auto";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Typography,
} from '@mui/material';

import { DashboardData } from 'src/_services/dashboard';
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal"

const PREFIX = 'TrafficByDevice';

const classes = {
  root: `${PREFIX}-root`,
  status: `${PREFIX}-status`,
  boxHeader: `${PREFIX}-boxHeader`,
  active: `${PREFIX}-active`,
  inactive: `${PREFIX}-inactive`,
  closed: `${PREFIX}-closed`,
  processing: `${PREFIX}-processing`,
  completed: `${PREFIX}-completed`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.root}`]: {
    height: '100%'
  },

  [`& .${classes.status}`]: {
    display:'flex',
    justifyContent:'space-between',
    borderBottom: '1px solid #80808069',
    marginBottom:' 4px'
  },

  [`& .${classes.boxHeader}`]: {
    fontWeight:'normal',
    marginLeft: '14px',
    padding: '4px 0px',
  },

  [`& .${classes.active}`]: {
    color: '#00C914'
  },

  [`& .${classes.inactive}`]: {
      color: '#d50000'
  },

  [`& .${classes.closed}`]: {
      color: '#314B8F'
  },

  [`& .${classes.processing}`]: {
      color: '#A48800'
  },

  [`& .${classes.completed}`]: {
      color: '#A40094'
  }
}));

const TrafficByDevice = ({ className, ...rest }) => {

  // const theme = useTheme();
  const [items] = useState([])

  // const [countData,setCountData] = useState([])
  const [status , setStatus] = useState([]);
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)

  let statusData = [];

  useEffect(()=>{
   DashboardData.getServiceOrderStatus()
    .then(res=>{
      if(res){
        if(res.status === 200){
       const  backgroundColor = ["#00C914", "#314B8F", "#A40094","#d50000","#A48800","#FD8D14","#FFE17B","#CECE5A","#030637","#9DBC98","#E4DEBE","#80BCBD"]
          const sortData = res.data.sort((a, b) => (a.code > b.code) ? 1 : -1)
          const newarray = sortData.map((obj,index) => ({ ...obj, color:backgroundColor[index] }));
          setStatus(newarray)        
          sortData.forEach(data =>{
            items.push(data.code)
            statusData.push(parseInt(data.count))
          })        
          // setCountData(statusData)
        }else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenSessionExpiredModal(true)
          }
        }
      }
    
    })
  },[]) // eslint-disable-line react-hooks/exhaustive-deps

  // const data = {
  //   datasets: [
  //     {
  //       data: countData,
  //       backgroundColor: ["#00C914", "#314B8F", "#A40094","#d50000","#A48800","#FD8D14","#FFE17B","#CECE5A","#030637","#9DBC98","#E4DEBE","#80BCBD"],
  //       borderWidth: 1,
  //       borderColor: colors.common.white,
  //       hoverBorderColor: colors.common.white
  //     }
  //   ],
  //   labels: items
  // };

  // const options = {
  //   animation: { duration: 2000},
  //   plugins: {
  //     legend: {
  //       position: 'top',
  //       display: false,
  //     },
  //   },
  //   cutoutPercentage: 75,
  //   layout: { padding: 0 },
  //   maintainAspectRatio: false,
  //   responsive: true,
  //   tooltips: {
  //     backgroundColor: theme.palette.background.default,
  //     bodyFontColor: theme.palette.text.secondary, 
  //     borderColor: theme.palette.divider,
  //     borderWidth: 1,
  //     enabled: true,
  //     footerFontColor: theme.palette.text.secondary,
  //     // intersect: false,
  //     mode: 'index',
  //     titleFontColor: theme.palette.text.primary
  //   }
  // };

  return (
    (<Root>
      <Card className={clsx(classes.root, className)} {...rest}>
        {/* <CardHeader title="STATUS" /> */}
        <Typography  className={classes.boxHeader} variant="h6">
          Order Statistics 
        </Typography>
        <Divider />
        <CardContent>
          {/* <Box
            height={250}
            position="relative"
          >
            <Doughnut
              data={data}
              options={options}
            />
          </Box> */}
          <Box mt={2} >
        
            {
              status.map(item => {
                return(
                  <div className={classes.status} key={item.code}>  
                    <Typography  >
                    {/* <i className="fa fa-square" aria-hidden="true"></i> &nbsp; */}
                      {/* <StopIcon style={{marginBottom: '-4px', color:item.color}} /> */}
                     {item.description}
                    </Typography>
                    <Typography variant="h5">
                    {item.count}
                    </Typography>
                </div>
                ) 
              })
            }
          </Box>
        </CardContent>
      </Card>
      <SessionExpiredModal
          open={openSessionExpiredModal}
        />
    </Root>)
  );
};

TrafficByDevice.propTypes = {
  className: PropTypes.string
};
export default TrafficByDevice;
