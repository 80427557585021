import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import ErrorFocus from "../../components/ErrorFocus/ErrorFocus";
import {
  Container,
  TextField,
  Grid,
  Chip,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  FormLabel,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import moment from "moment";
import loadingSpinner from "src/images/loading-spinner.gif";
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal";
import { getLookUpDataService } from "src/_services/lookUp";
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
const PREFIX = "OrderExportForm";

const classes = {
  container: `${PREFIX}-container`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  buttonProgress: `${PREFIX}-buttonProgress`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.container}`]: {
    justifyContent: "center",
    // justifyItems: "center",
    marginTop: "10%",
  },
  [`& .${classes.popDownBtn}`]: {
    // borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
    textTransform: "none",
  },
  [`& .${classes.buttonProgress}`]: {
    marginRight: "4px",
  },
}));

const OrderExportForm = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [internalRM, setInternalRM] = React.useState([]);
  const [externalRM, setExternalRM] = React.useState([]);
  const [client, setClient] = React.useState([]);
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(
    false
  );
  const role = useSelector(state => state?.authReducer?.auth?.user?.default_role_code);
  const [tags, setTags] = React.useState([]);
  const [documentLookUpData, setDocumentLookUpData] = React.useState([]);

  useEffect(() => {
    getLookUpDataService
      .getLookUpUser("SALES,OPERATION")
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            let item = res.data.map((items) => {
              return {
                id: items.id,
                code: items.user_code,
                lable: `${
                  items.user_name
                } ( ${items.user_pan ?items.user_pan?.toUpperCase() :items.user_code.toUpperCase()} )`,
              };
            });

            setInternalRM(item);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
            }
          }
        }
      })
      .catch((error) => {
        console.log("error");
      });
      getLookUpDataService
      .getLookUpUser("CHANNEL_PARTNER")
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            let item = res.data.map((items) => {
              return {
                id: items.id,
                code: items.user_code,
                lable: `${
                  items.user_name
                } ( ${items.user_pan ?items.user_pan?.toUpperCase() :items.user_code.toUpperCase()} )`,
              };
            });

            setExternalRM(item);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
            }
          }
        }
      })
      .catch((error) => {
        console.log("error");
      });
      getLookUpDataService
      .getLookUpUser("CHANNEL_PARTNER,INVESTER_CLIENT")
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            let item = res.data.map((items) => {
              return {
                id: items.id,
                code: items.user_code,
                lable: `${
                  items.user_name
                } ( ${items.user_pan ? items.user_pan?.toUpperCase() :items.user_code.toUpperCase()} )`,
              };
            });
            setClient(item);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error)

        console.log("error");
      });
  }, []);

  useEffect(() => {
    getLookUpDataService.getServiceOrderDocumentLookUp().then((res) => {
      if (res) {
        if (res.status === 200) {
          const item = res.data[0].service_order_doc_type.map((data) => {
            return {
              id: data.id,
              code: data.code,
              lable: data.description,
            };
          });
          let newItem = item.filter((items) => {
            return items.id < 6 || items.code ==='SHARE_PURCHASE_AGGREEMENT' || items.code ==='STATEMENT_OF_ADHERENCE';
          });
          setDocumentLookUpData(newItem);
        } else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenSessionExpiredModal(true);
          }
        }
      }
    });
  }, []);

  const handleAddTag = (e, setFieldValue) => {
    if (e.key === "Enter" && e.target.value.trim() !== "") {
      e.preventDefault();
      if (e.target.value.includes(" ")) {
        let orderArray = e.target.value
          .split(" ")
          .map((item) => item.trim().replace(",", ""));
        setTags([...tags, ...orderArray]);
      } else {
        setTags([...tags, e.target.value]);
      }
      e.target.value = "";
    }
    setFieldValue("tagName", "");
  };
  const startDate = new Date();
  const initValues = {
    fromDate: moment(
      new Date(startDate.getUTCFullYear(), startDate.getUTCMonth(), 1)
    ).format("YYYY-MM-DD"),
    toDate: moment(new Date()).format("YYYY-MM-DD"),
    internalRM: [],
    externalRM:"",
    client: "",
    type: "1",
    docs: [],
    docType:'1',
    tagName: "",
    submitType: "",
  };

  const handleDelete = (i) => {
    setTags((tag) => tag.filter((name, index) => index !== i));
  };

  const selectOption = (
    value,
    options,
    setFieldValue,
    name,
    label,
    touched,
    errors
  ) => {
    return (
      <Grid item xs={12}>
        <Autocomplete
          id={name}
          multiple={name ==='internalRM'}
          // name="investorOrVendor"
          value={value}
          options={options}
          getOptionLabel={(option) => option.lable || ""}
          onChange={async (e, value) => {
            if (value !== null) {
              setFieldValue(name, value);
            }else{
              setFieldValue(name, "");
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              helperText={touched && errors}
              error={Boolean(touched && errors)}
            />
          )}
        />
        <ErrorFocus />
      </Grid>
    );
  };

  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialValues={initValues}
        validationSchema={Yup.object().shape({
          fromDate: Yup.string().required("Required"),
          toDate: Yup.string().required("Required"),
          internalRM: Yup.string().when("tagName", {
            is: (tagName) => {
              return (
               ((tagName === "" || tagName === undefined) && tags.length ===0 ) && (role ==='ADMIN' || role ==='OPERATION')
              )
            },  
            then: Yup.string().required("Required"),
            otherwise: Yup.string(),
          }),
          docs: Yup.array().when("docType", {
            is: (tagName) => tagName ==='1',  
            then: Yup.array().min(1, "At least one document should be selected"),
            otherwise: Yup.array(),
          })  
        })}
        onSubmit={(values) => {
          setLoading(true);
          let orderId = [];
          if (values.tagName?.includes(" ")) {
            let orderArray = values.tagName
              .split(" ")
              .map((item) => item?.trim().replace(",", ""));
            orderId = [...tags, ...orderArray];
          } else {
            orderId = [...tags, values.tagName];
          }
          const data = {
            order_id: values.tagName?.trim() !== "" ? orderId : tags,
            internalRM_id: values.internalRM.map((item)=> item.id),
            externalRM_id: values.externalRM.id,
            client_id: values.client.id,
            from_date: values.fromDate,
            to_date: values.toDate,
            docs: values.docs,
            type: values.type,
            docType:values.docType
          };
          if (values.submitType === "report") {
            props.formSubmit(data);
          } else if (values.submitType === "document") {
            props.handleDownload(data);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="fromDate"
                    label="From Date"
                    type="date"
                    name="fromDate"
                    error={Boolean(touched.fromDate && errors.fromDate)}
                    helperText={touched.fromDate && errors.fromDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      inputProps: {
                        max: `${moment(new Date()).format("YYYY-MM-DD")}`,
                      },
                    }}
                    value={values.fromDate}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <ErrorFocus />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="toDate"
                    label="End Date"
                    type="date"
                    name="toDate"
                    value={values.toDate}
                    InputProps={{
                      inputProps: {
                        min: values.fromDate,
                        max: `${moment(new Date()).format("YYYY-MM-DD")}`,
                      },
                    }}
                    error={Boolean(touched.toDate && errors.toDate)}
                    helperText={touched.toDate && errors.toDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <ErrorFocus />
                </Grid>
                {role !=='SALES' && <Grid item xs={12} sm={12} md={12} lg={12}>
                  {selectOption(
                    values.internalRM,
                    internalRM,
                    setFieldValue,
                    "internalRM",
                    "Internal RM Name *",
                    touched.internalRM,
                    errors.internalRM
                  )}
                </Grid>}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {selectOption(
                    values.externalRM,
                    externalRM,
                    setFieldValue,
                    "externalRM",
                    "External RM Name",
                    touched.externalRM,
                    errors.externalRM
                  )}
                
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                {selectOption(
                    values.client,
                    client,
                    setFieldValue,
                    "client",
                    `Client Name`,
                    touched.client,
                    errors.client
                  )}
                  </Grid>
                <Grid item xs={12} sm={12} textAlign={"center"}>
                  <Typography>Or</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    helperText=""
                    label={`Search by order id`}
                    onKeyPress={(e) => handleAddTag(e, setFieldValue)}
                    name="tagName"
                    // disabled={orders}
                    onChange={handleChange}
                  />
                  <div className={classes.wrap}>
                    {tags &&
                      tags.map((item, index) => (
                        <Chip
                          // disabled={orders}
                          style={{ margin: "4px" }}
                          key={index}
                          tabIndex={-1}
                          label={item}
                          onDelete={() => handleDelete(index)}
                        />
                      ))}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormLabel component="legend" color="primary">
                    Document Type *
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-label=""
                    name=""
                    defaultValue={values.type}
                    onChange={(e) => {
                      setFieldValue("docType", e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value={"1"}
                      control={<Radio />}
                      label="Others"
                    />
                    <FormControlLabel
                      value={"2"}
                      control={<Radio />}
                      label="Dealterm document"
                    />
                  </RadioGroup>
                </Grid>
                {values.docType ==='1' ? <>
                  <Grid item xs={12} sm={12} md={6}>
                  {documentLookUpData.length > 0 &&
                    documentLookUpData.map((item) => {
                      const isChecked = values.docs.includes(item.id); // Check if the item's id is in the docs array

                      return (
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isChecked}
                                name={values.docs}
                                id="status"
                                aria-describedby="outlined-weight-helper-text"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  let array = values.docs.filter(
                                    (item1) => item1 !== item.id
                                  );
                                  if (isChecked) {
                                    setFieldValue("docs", array);
                                  } else {
                                    setFieldValue("docs", [
                                      ...values.docs,
                                      item.id,
                                    ]);
                                  }
                                }}
                                color="primary"
                              />
                            }
                            label={item.lable}
                          />
                        </div>
                      );
                    })}
                  {touched.docs && errors.docs && (
                    <span style={{ color: "#F76F50", fontSize: "0.75rem" }}>
                      {errors.docs}
                    </span>
                  )}
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <FormLabel component="legend" color="primary">
                    Includes Documents *
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-label=""
                    name=""
                    defaultValue={values.type}
                    onChange={(e) => {
                      setFieldValue("type", e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value={"1"}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={"2"}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </Grid>
                  </>:null
                }
              <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ display: "flex", gap: 2, justifyContent: "end" }}
                >
                    {values.docType ==='1' &&
                  <Button
                    color="primary"
                    disabled={((values.client ==="" && values.externalRM ==="") &&  (values.tagName ==="" && tags.length ===0)) &&role ==="SALES" }
                    className={classes.popDownBtn}
                    size="medium"
                    type="submit"
                    name="submitType"
                    value="report"
                    variant="contained"
                    onClick={() => setFieldValue("submitType", "report")}
                  >
                    {loading === false ? (
                      <> {"Download Report"}</>
                    ) : (
                      <>
                        <img
                          alt="loader"
                          src={loadingSpinner}
                          className={classes.buttonProgress}
                        />{" "}
                        Download Report{" "}
                      </>
                    )}
                  </Button>}
                  {values.type === "2" ? (
                    ""
                  ) : (
                    <Button
                      color="primary"
                      disabled={((values.client ==="" && values.externalRM ==="") && (values.tagName ==="" && tags.length ===0)) &&role ==="SALES" }
                      className={classes.popDownBtn}
                      size="medium"
                      type="submit"
                      name="submitType"
                      value="document"
                      onClick={() => setFieldValue("submitType", "document")}
                      variant="contained"
                    >
                      {loading === false ? (
                        <> {"Download Documents"}</>
                      ) : (
                        <>
                          <img
                            alt="loader"
                            src={loadingSpinner}
                            className={classes.buttonProgress}
                          />{" "}
                          Download Documents{" "}
                        </>
                      )}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Container>
          </form>
        )}
      </Formik>
      <SessionExpiredModal open={openSessionExpiredModal} />
    </StyledContainer>
  );
};

export default OrderExportForm;
