import React from 'react'
import PulseLoader from "react-spinners/PulseLoader";

const ReactSpinner = () =>{

    const loading = true;
    return(
        <React.Fragment>
             <PulseLoader color="#3f51b5"  loading={loading}   size={10} />
        </React.Fragment>
    )
}

export default ReactSpinner