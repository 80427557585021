import React from 'react';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

const PREFIX = 'Loading';

const classes = {
  root: `${PREFIX}-root`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    height: "100%",
    width: "100%",
    backgroundColor: "#314b8f00",      
    display: "flex",
    marginRight: "8px",
    justifyContent: "center",
    alignItems: "center",
    margin:'auto',
    zIndex: 1201,
    // [theme.breakpoints.down(960)]:{
    //     marginLeft: "-50%",
    // },
  }
}));

export default function CircularLoading() {


  return (
    <Root className={classes.root}>
        <CircularProgress 
            color="primary"
            size={18}
            thickness={4}      
        />
    </Root>
  );
}
