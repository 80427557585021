import React from "react";
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import loadingSpinner from 'src/images/loading-spinner.gif'
import {
  FormControl,
  FormHelperText,
  Select,

  Container,
  InputLabel,
  MenuItem,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,

} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";



const PREFIX = 'UpdateStatusForm';

const classes = {
  zeroPadding: `${PREFIX}-zeroPadding`,
  cardBorderRadius: `${PREFIX}-cardBorderRadius`,
  cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
  fileInput: `${PREFIX}-fileInput`,
  imageSizes: `${PREFIX}-imageSizes`,
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  marginInputLabel: `${PREFIX}-marginInputLabel`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  resendBtnMargin: `${PREFIX}-resendBtnMargin`,
  inputMargin: `${PREFIX}-inputMargin`,
  paddingInput: `${PREFIX}-paddingInput`,
  buttonProgress: `${PREFIX}-buttonProgress`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.zeroPadding}`]: {
    padding: 0,
  },

  [`& .${classes.cardBorderRadius}`]: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },

  [`& .${classes.cardHeaderMargin}`]: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    // justifyContent:"center",
    borderRadius: "0 0  10px 0",
  },

  [`& .${classes.fileInput}`]: {
    display: "none",
  },

  [`& .${classes.imageSizes}`]: {
    width: "40%",
    margin: "auto",
    display: "block",
  },

  [`& .${classes.container}`]: {
    justifyContent: "center",
    // justifyItems: "center",

    marginTop: "10%",
  },

  [`& .${classes.label}`]: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },

  [`& .${classes.marginInputLabel}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.resendBtnMargin}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.inputMargin}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.paddingInput}`]: {
    padding: "0px 0px",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: '4px'
  }
}));


function UpdateStatusForm({status,service_order_id,partner,isNSE,orderType,formSubmit,isSigned,dealTermEmailData,signedDocumentExist}) {
const initialStatus = status
  const [loading, setLoading] = React.useState(false)

  return (
    <StyledContainer>
      <Formik
        initialValues={{
          status: status || "",
          followup:false,
        }}
        validationSchema={Yup.object().shape({
          status: Yup.string().required("Required"),
        })}
        onSubmit={(values) => {
          setLoading(true) 
          values.service_order_id = service_order_id
          values.email=values.followup && dealTermEmailData.mail_id
          values.updatedValues =JSON.stringify([
            {field:'status',
            oldValue:initialStatus,
            newValue:values.status}
          ])
          formSubmit(values)

        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>
              <FormControl variant="outlined" fullWidth={true}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Status*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="role"
                  className={classes.inputMargin}
                  label="Status"
                  // select
                  // SelectProps={{ native: true }}
                  variant="outlined"
                  // error={Boolean(touched.status && errors.status)}
                  // helperText={touched.status && errors.status}
                  name="status"
                  value={values.status}
                  aria-describedby="outlined-weight-helper-text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  autoComplete="off"
                >
                  <MenuItem value="INACTIVE">INACTIVE</MenuItem>
                  <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                  {isSigned &&<MenuItem value="SHARETRANSFERPENDING">SHARE TRANSFER PENDING</MenuItem>}
                  {partner && <MenuItem value="ONHOLD">ONHOLD</MenuItem>}
                  {partner &&<MenuItem value="PREPROCESSING">PREPROCESSING</MenuItem>}
                  {!orderType &&<MenuItem value="PROCESSING">PROCESSING</MenuItem>}
                  {isNSE &&<MenuItem value="STAGE_ONE_PROCESSING">STAGE ONE PROCESSING</MenuItem>}
                  {isNSE &&<MenuItem value="STAGE_ONE_PROCESSED">STAGE ONE PROCESSED</MenuItem>}
                  {isNSE &&<MenuItem value="STAGE_TWO_PROCESSING">STAGE TWO PROCESSING</MenuItem>}
                  {isNSE &&<MenuItem value="STAGE_TWO_PROCESSED">STAGE TWO PROCESSED</MenuItem>}
                  {!orderType &&<MenuItem value="COMPLETED">COMPLETED</MenuItem>}
                  {!orderType && <MenuItem value="CLOSED">CLOSED</MenuItem>}
                 {partner && <MenuItem value="CANCELLED">CANCELLED</MenuItem>}
                </Select>
                <FormHelperText style={{ color: "#F44336", position: "absolute", top: 53 }}>
                  {errors.status && touched.status && errors.status}
                </FormHelperText>
              </FormControl>
             {((values.status ==='INACTIVE' ) &&dealTermEmailData && !signedDocumentExist) &&  <FormControl   variant="outlined" fullWidth={true}>
                          <FormLabel component="legend">Send order cancellation followup email for dealterm</FormLabel>
                          <FormGroup row>
                            <FormControlLabel
                              control={
                                <Checkbox
                                checked={values.followup}
                                name="followup"
                                id="followup"
                                aria-describedby="outlined-weight-helper-text"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                color="primary"
                              />
                              }
                              label={<Typography>{dealTermEmailData.mail_id}</Typography>}
                            />
                            </FormGroup>
                </FormControl>}

              <Button
                color="primary"
                disabled={isSubmitting}
                className={classes.popDownBtn}
                size="medium"
                type="submit"
                variant="contained"
              >
                {loading === false ? "Update" : <><img src={loadingSpinner} alt="Logo" className={classes.buttonProgress} /> Update </>}
              </Button>
            </Container>
          </form>
        )}
      </Formik>

    </StyledContainer>
  );
}

export default UpdateStatusForm;
