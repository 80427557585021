import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Grid,
} from '@mui/material';
import Page from 'src/components/Page';
import Budget from './Budget';
import TotalCustomers from './TotalCustomers';
import TotalProfit from './TotalProfit';
import TrafficByDevice from './TrafficByDevice';
import Holiday from './Holiday';

const PREFIX = 'Dashboard';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },

  [`& .${classes.container}`]: {
    padding:0,
    paddingTop:theme.spacing(1)
  }
}));

const Dashboard = () => {

 

  return (
    <Root>
    <Page
      className={classes.root}
      title="Unlistedkart | User Dashboard"
    >
    
        <Grid container spacing={3}>
          <Grid item lg={4} md={4} sm={6} xl={4} xs={12}>
            <Budget />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xl={4} xs={12}>
            <TotalCustomers/>
          </Grid>
           {/* <Grid item lg={3} sm={6} xl={3} xs={12}>
            <TasksProgress />
          </Grid> */}
          <Grid item lg={4} md={4} sm={6} xl={4} xs={12}>
            <TotalProfit />
          </Grid>
          <Grid item lg={8} md={8} xl={8} xs={12}>
            {/* <Sales /> */}
            <Holiday/>
          </Grid>
          <Grid item lg={4} md={4} xl={4} xs={12}>
          <TrafficByDevice/> 
          </Grid>

          {/* 
          <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <LatestProducts />
          </Grid> */}
          <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}
          >
            {/* <LatestOrders /> */}
          </Grid>
        </Grid>
    </Page>
    </Root>
  );
};

export default Dashboard;
