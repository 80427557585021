import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import MUIDataTable from "mui-datatables";
import {  Typography, Chip,Tooltip } from "@mui/material";

import {  ThemeProvider } from "@mui/material/styles";
import Page from "src/components/Page";
import { NavLink } from "react-router-dom";
import { serviceOrderService } from "src/_services/serviceOrder";
import NormalCard from "src/components/Cards/NormalCard";
import clsx from "clsx";
import moment from "moment";
import CopytoClipboard from "src/components/ButtonComponents/CopytoClipboard";
import GetMuiTheme from "src/components/theme";

const PREFIX = 'PartnerOrders';

const classes = {
    active: `${PREFIX}-active`,
    tooltip: `${PREFIX}-tooltip`,
    inactive: `${PREFIX}-inactive`,
    download: `${PREFIX}-download`,
    tableEllips: `${PREFIX}-tableEllips`,
    unCheck: `${PREFIX}-unCheck`,
    closed: `${PREFIX}-closed`,
    completed: `${PREFIX}-completed`,
    processing: `${PREFIX}-processing`,
    preProcessing: `${PREFIX}-preProcessing`,
    cancelled: `${PREFIX}-cancelled`,
    hold: `${PREFIX}-hold`,
    stageOneProcessing: `${PREFIX}-stageOneProcessing`,
    stageOneProcessed: `${PREFIX}-stageOneProcessed`,
    stageTwoProcessed: `${PREFIX}-stageTwoProcessed`,
    stageTwoProcessing: `${PREFIX}-stageTwoProcessing`,
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.active}`]: {
        color: "white",
        backgroundColor: "#a526a5",
        fontWeight: "bold"
    },

    [`& .${classes.tooltip}`]: {
        cursor:'copy'
    },

    [`& .${classes.inactive}`]: {
        color: "white",
        backgroundColor: "#D50000",
        fontWeight: "bold"
    },

    [`& .${classes.download}`]: {
        fontSize: "20px",
        marginBottom: theme.spacing(-0.5),
        // color: "#4caf50",
    },

    [`& .${classes.tableEllips}`]: {
        display:'flex',
        width: "9rem"
      },

    [`& .${classes.unCheck}`]: {
        fontSize: "20px",
        marginBottom: theme.spacing(-0.5),
        color: "#f44336",
    },
    [`& .${classes.stageOneProcessing}`]: {
        color: "white",
        backgroundColor: "#87C4FF",
        fontWeight: "bold",
      },
    
      [`& .${classes.stageOneProcessed}`]: {
        color: "white",
        backgroundColor: "#39A7FF",
        fontWeight: "bold",
      },
    
      [`& .${classes.stageTwoProcessed}`]: {
        color: "white",
        backgroundColor: "#427D9D",
        fontWeight: "bold",
      },
    
      [`& .${classes.stageTwoProcessing}`]: {
        color: "white",
        backgroundColor: "#9BBEC8",
        fontWeight: "bold",
      },
    [`& .${classes.closed}`]: {
        color: "white",
        backgroundColor: "#314b8f",
        fontWeight: "bold"
    },

    [`& .${classes.completed}`]: {
        color: "white",
        backgroundColor: "green",
        fontWeight: "bold"
    },

    [`& .${classes.processing}`]: {
        color: "white",
        backgroundColor: "#CD7A00",
        fontWeight: "bold"
    },

    [`& .${classes.preProcessing}`]: {
        color: "white",
        backgroundColor: "#FFA559",
        fontWeight: "bold"
    },

    [`& .${classes.cancelled}`]: {
        color: "white",
        backgroundColor: "#226FCD",
        fontWeight: "bold"
    },

    [`& .${classes.hold}`]: {
        color: "white",
        backgroundColor: "#FA9884",
        fontWeight: "bold"
    }
}));

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) {
    // eslint-disable-line
    return <NavLink to={props.to} {...props} />; // eslint-disable-line
});

const PartnerOrders = () => {
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [hover, setHover] = useState(false);
    const [rowindex, setRowindex] = useState(false);

    const onHover = (value) => {
        setHover(true);
        navigator.clipboard.writeText(value);
    };

    const onLeave = () => {
        setHover(false);
    };

    const getMuiTheme = GetMuiTheme

    useEffect(() => {
        getUsers();
    }, []);

    const copyOption = ((value) => {
        return (
            <div
                style={{ display: 'flex' }}
                
            >
                 <Tooltip
                    className={classes.tooltip}
                    title={hover ? "Copied" : "Click to Copy"}
                >
                <Typography
                    variant="body2"
                    noWrap
                    style={{ maxWidth: 300, padding:'10px 0' }}
                    onClick={() => {
                        onHover(value)
                    }} onMouseLeave={onLeave}
                >
                    {value}
                </Typography>
                </Tooltip>
            </div>
        );
    })



    const columns = [
        {
            name: "_id",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                hide: true,
                display: false,
            },
        },
        {
            name: "service_id",
            label: "Order ID",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta) => {

                    return (
                        <div
                        onMouseEnter={() => {
                            setHover(true);
                            setRowindex(tableMeta.rowData[0])
                          }}
                          onMouseLeave={()=>{setRowindex(false)}}
                            className={classes.tableEllips} >
                            <Typography
                                style={{ cursor: "copy" }}
                                variant="body2"
                                component={LinkBtn}
                                noWrap={false}
                                
                                  to={`/app/service-order-details/${tableMeta.rowData[0]}`}
                                  state={{ page: 0, data: tableMeta.currentTableData, index: tableMeta.rowIndex, partner: true }}
                                color="primary">
                               {value}
                            </Typography>
                            {rowindex ===tableMeta.rowData[0] &&(
                            <Tooltip style={{cursor:'copy'}} title={hover ? "Copied" :"Click to Copy"}>
                                <CopytoClipboard userBasicInfo={value} state={hover}/> 
                            </Tooltip>
                            )}
                             </div>

                    );
                },
            },
        },
        {
            name: "order_type",
            label: "Order Type",

        },
        {
            name: "investor",
            label: "Investor",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => copyOption(value, tableMeta),
            },
        },
        {
            name: "description",
            label: "Role",
            options: {
                filter: true,
                sort: false,
            },
        },
        {

            name: "admin_name",
            label: "Comapany Name",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta) => copyOption(value, tableMeta),
            },
        },
        {

            name: "related_partner_code",
            label: "Partner code",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta) => copyOption(value, tableMeta),
            },
        },
        {
            name: "paid_through",
            label: "Payment Type",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    const customValue = value === 'unlistedkart' ? 'Unlistedkart Razorpay' : value === 'imps' ? "Bank IMPS" : value
                    return (
                        copyOption(customValue)
                        )
                }
            }
        },
        {

            name: "is_client_uploaded_payment_proof",
            label: "Payment Staus",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    return (
                        <Typography>{value ? "Uploaded" : "Pending"}</Typography>
                    )
                }
            },
        },
        {
            name: "transaction_type",
            label: "Transaction Type",
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: "create_datetime",
            label: "Created Date",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value,) => {
                    let valueMoment = moment(value).format("DD-MM-YYYY LTS")
                    return (
                        <div className={classes.tableEllips} >
                            <Typography
                                variant='body2'
                                noWrap
                            >
                                {valueMoment}
                            </Typography>
                        </div>
                    )
                }
            }
        },
        {
            name: "order_status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <Typography>
                            <Chip
                                size="small"
                                label={value === "Preprocessing" ? "Pre-processing" : value}
                                className={clsx({
                                    [classes.active]: value === "Active",
                                    [classes.completed]: value === "Completed",
                                    [classes.inactive]: value === "Inactive",
                                    [classes.processing]: value === "Processing",
                                    [classes.closed]: value === "Closed",
                                    [classes.hold]: value === "Onhold",
                                    [classes.cancelled]: value === "Cancelled",
                                    [classes.preProcessing]: value === "Preprocessing",
                                    [classes.stageOneProcessing]: value === "Stage one processing",
                                    [classes.stageOneProcessed]: value === "Stage one processed",
                                    [classes.stageTwoProcessing]: value === "Stage two processing",
                                    [classes.stageTwoProcessed]: value === "Stage two processed",

                                })}
                            />
                        </Typography>
                    );
                },
            },
        },
    ];

    const getUsers = () => {
        setLoading(true);
        serviceOrderService
            .getAllPartnerOrder()
            .then((res) => {
                setUserData(res.data)
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };





    return (
        <Root>
        <Page title="Unlistedkart | Partners Orders">
            <NormalCard
                title={`Partner Orders List`}
            >
                <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        data={userData}
                        columns={columns}
                        options={{
                            download: true,
                            print: false,
                            rowsPerPage: 10,
                            rowsPerPageOptions: [10, 25, 40],
                            // responsive: 'scrollMaxHeight',
                            selectableRows: "none",
                            sortOrder: {
                                name: "last_updated",
                                direction: "desc",
                            },
                            textLabels: {
                                body: {
                                    noMatch: loading
                                        ? "Loading..."
                                        : "Sorry, there are no matching data to display",
                                },
                            },
                        }}
                    />
                </ThemeProvider>
            </NormalCard>
        </Page>
        </Root>
    );
};

export default PartnerOrders;
