import React, { useState,useEffect } from "react";
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import ErrorFocus from "../../../components/ErrorFocus/ErrorFocus";
import {
  Container,
  TextField,
  Grid,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import moment from 'moment'
import loadingSpinner from 'src/images/loading-spinner.gif'

const PREFIX = 'HolidayForm';

const classes = {
  container: `${PREFIX}-container`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  inputMargin: `${PREFIX}-inputMargin`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.container}`]: {
    justifyContent: "center",
    // justifyItems: "center",

    marginTop: "10%",
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },
  [`& .${classes.inputMargin}`]: {
    marginBottom: theme.spacing(2),
  },

}));

function HolidayForm(props) {

  const [formInitValues ,setFormInitValues] = useState({
    holiday_name:"",
    holiday_date: "",
    message: "",
    id:""
  })
  
  const [loading , setLoading] = React.useState(false)
  useEffect(() => {
    if(props.mode==="edit"){
      let formEditValues = {...formInitValues}
      formEditValues.id=props.data[0]
      formEditValues.holiday_name=props.data[2]
      formEditValues.holiday_date=moment(props.data[1]).format('YYYY-MM-DD')
      formEditValues.message=props.data[4]
      setFormInitValues(formEditValues)
    }
  },[]) // eslint-disable-line react-hooks/exhaustive-deps




  return (
    <StyledContainer style={props.type !== "Add" ? { padding: 0 } : {}}>
        <Formik
          enableReinitialize={true}
          initialValues={formInitValues}
          validationSchema={Yup.object().shape({
            holiday_name: Yup.string().required("Required"),
            holiday_date: Yup.date().required("Required"),
            message: Yup.string().required("Required"),
          })}
          onSubmit={(values) => {
            setLoading(true)
            props.formSubmit(values) 
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            isSubmitting,
            setFieldValue,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Container className={classes.container}>
                <Grid container>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={7} lg={7}>
                      <TextField
                        className={classes.inputMargin}
                        variant="outlined"
                        label="Holiday Title*"
                        id="holiday_name"
                        name="holiday_name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        fullWidth
                        value={values.holiday_name}
                        error={Boolean(touched.holiday_name && errors.holiday_name)}
                        helperText={touched.holiday_name && errors.holiday_name}
                        aria-describedby="outlined-weight-helper-text"
                        autoComplete="off"
                        // onInput={(e) => { e.target.value = e.target.value.replace(/^\d*\.?\d*$/g, "") }}
                        // onKeyPress={(e)=>{e.target.value = e.target.value.replace(/^\d+\.\d{0,2}$/,"")}}
                      />

                      <ErrorFocus />
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={5}>
                      <TextField
                        className={classes.inputMargin}
                        variant="outlined"
                        label="Holiday Date*"
                        id="holiday_date"
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                          }}
                        name="holiday_date"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        fullWidth
                        value={values.holiday_date}
                        error={Boolean(
                          touched.holiday_date && errors.holiday_date
                        )}
                        helperText={
                          touched.holiday_date && errors.holiday_date
                        }
                        aria-describedby="outlined-weight-helper-text"
                        autoComplete="off"
                        // onInput={(e) => message{ e.target.value = e.target.value.replace(/^\d*\.?\d*$/g, "") }}
                        // onKeyPress={(e)=>{e.target.value = e.target.value.replace(/^\d+\.\d{0,2}$/,"")}}
                      />
                      <ErrorFocus />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        id="message"
                        label="Message"
                        error={Boolean(touched.message && errors.message)}
                        helperText={touched.message && errors.message}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="message"
                        value={values.message}
                        multiline
                        variant="outlined"
                        minRows={2}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  className={classes.popDownBtn}
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  {loading === false ?<> {props.mode==="edit" ? "UPDATE" : "ADD"}</>: <><img src={loadingSpinner} alt="logo" className={classes.buttonProgress}/> {props.mode==="edit" ? "UPDATE" : "ADD"} </>}
                  
                </Button>
                </Grid>
                </Grid>
              </Container>
            </form>
          )}
        </Formik>
     
    </StyledContainer>
  );
}

export default HolidayForm;
