import React, {useEffect, useState} from "react";
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import {
  OutlinedInput,
  FormControl,
  FormHelperText,
  Select,
  Container,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import ErrorFocus from "../../../components/ErrorFocus/ErrorFocus";
import { getLookUpDataService } from 'src/_services/lookUp'
import loadingSpinner from 'src/images/loading-spinner.gif'


const PREFIX = 'DocumentCreateForm';

const classes = {
  zeroPadding: `${PREFIX}-zeroPadding`,
  cardBorderRadius: `${PREFIX}-cardBorderRadius`,
  cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
  fileInput: `${PREFIX}-fileInput`,
  imageSizes: `${PREFIX}-imageSizes`,
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  marginInputLabel: `${PREFIX}-marginInputLabel`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  buttonProgress: `${PREFIX}-buttonProgress`,
  resendBtnMargin: `${PREFIX}-resendBtnMargin`,
  inputMargin: `${PREFIX}-inputMargin`,
  paddingInput: `${PREFIX}-paddingInput`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.zeroPadding}`]: {
    padding: 0,
  },

  [`& .${classes.cardBorderRadius}`]: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },

  [`& .${classes.cardHeaderMargin}`]: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    // justifyContent:"center",
    borderRadius: "0 0  10px 0",
  },

  [`& .${classes.fileInput}`]: {
    display: "none",
  },

  [`& .${classes.imageSizes}`]: {
    width: "40%",
    margin: "auto",
    display: "block",
  },

  [`& .${classes.container}`]: {
    justifyContent: "center",
    // justifyItems: "center",

    marginTop: "10%",
  },

  [`& .${classes.label}`]: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },

  [`& .${classes.marginInputLabel}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight:'4px'
  },

  [`& .${classes.resendBtnMargin}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.inputMargin}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.paddingInput}`]: {
    padding: "0px 0px",
  }
}));


function DocumentCreateForm(props) {


  const [documentTypes,setDocumentTypes] = useState()
  const [loading, setLoading] = React.useState(false);
  
  useEffect(()=>{
    getLookUpDataService.getLookUpData("document_type")
      .then(res => {
        if(res){
         setDocumentTypes(res.data[0].document_type)
        } 
       
      })
  },[])

  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
    'application/pdf',
    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];

  return (
    <StyledContainer>
      <Formik
        initialValues={{
          documentType: "",
          others: "",
          files: "",
          notes:""
        }}
        validationSchema={Yup.object().shape({
          documentType: Yup.string().required("Required"),

          others: Yup.string().when("documentType", {
            is: "OTHERS", //just an e.g. you can return a function
            then: Yup.string().required("Required"),
            otherwise: Yup.string(),
          }),

          files: Yup
          .mixed()
          .required("A file is required")
          .test(
            "fileFormat",
            "Unsupported Format",
            value => value && SUPPORTED_FORMATS.includes(value.type)
          )
          .test(
            "fileSize",
            "Maximum file size is 10MB",
            value => value && value.size < 10242880
          ) 


        })}
        onSubmit={(values) => {
          setLoading(true)
         props.formSubmit(values)
           
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>
              <FormControl variant="outlined" fullWidth={true}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Document Type *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="documentType"
                  className={classes.inputMargin}
                  label="Document Type"
                  select
                  SelectProps={{ native: true }}
                  variant="outlined"
                  error={Boolean(touched.documentType && errors.documentType)}
                  helperText={touched.documentType && errors.documentType}
                  name="documentType"
                  value={values.documentType}
                  aria-describedby="outlined-weight-helper-text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  autoComplete="off"
                >
                  {documentTypes && documentTypes.map(item=>(
                      <MenuItem key={item.id} value={item.code}>
                        {item.description}
                      </MenuItem>
                  ))}                 
                  
                </Select>
                <FormHelperText style={{ color: "#F44336", position: "absolute", top: "53px" }}>
                  {errors.documentType &&
                    touched.documentType &&
                    errors.documentType}
                </FormHelperText>
              </FormControl>
              <ErrorFocus />
              {values.documentType === "OTHERS" ? (
                <React.Fragment>
                  <TextField
                    className={classes.inputMargin}
                    style={{ marginTop: 24 }}
                    variant="outlined"
                    label="Document Name *"
                    id="others"
                    name="others"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth
                    value={values.others}
                    error={Boolean(touched.others && errors.others)}
                    helperText={touched.others && errors.others}
                    autoComplete="off"
                    aria-describedby="outlined-weight-helper-text"
                  />
                    <TextField
                    className={classes.inputMargin}
                    style={{ marginTop: 24 }}
                    variant="outlined"
                    label="Notes"
                    id="others"
                    name="notes"
                    multiline
                    minRows={2}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth
                    value={values.notes}
                    error={Boolean(touched.notes && errors.notes)}
                    helperText={touched.notes && errors.notes}
                    autoComplete="off"
                    aria-describedby="outlined-weight-helper-text"
                  />
                  <ErrorFocus />
                </React.Fragment>
              ) : (
                  <div></div>
                )}

              <div>
                <Typography className={classes.label}>Upload File *</Typography>
                <OutlinedInput
                  accept="image/*"
                  // className={classes.input}
                  id="icon-button-file"
                  name="files"
                  fullWidth={true}
                  onChange={(e) => {
                    setFieldValue("files", e.currentTarget.files[0]);
                  }}
                  type="file"
                  error={touched.files && Boolean(errors.files)}
                  helperText={touched.files ? errors.files : ""}
                />

                <FormHelperText style={{ color: "#F44336", paddingLeft: 16, }}>
                  {touched.files ? errors.files : ""}
                </FormHelperText>
              </div>
              <ErrorFocus />

              <Button
                color="primary"
                disabled={isSubmitting}
                className={classes.popDownBtn}
                size="medium"
                type="submit"
                variant="contained"
              >
                {loading === false ? "Add" : <><img alt='loader' src={loadingSpinner} className={classes.buttonProgress}/> Add </>}
              </Button>
            </Container>
          </form>
        )}
      </Formik>
    </StyledContainer>
  );
}

export default DocumentCreateForm;
