import React from 'react'
import { styled } from '@mui/material/styles';
import {
  Box,
  Grid,
  Typography,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from "react-router-dom";
const PREFIX = 'GraphTable';

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  count: `${PREFIX}-count`,
  bottom: `${PREFIX}-bottom`,
  content: `${PREFIX}-content`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    margin: '1rem',
    border: '1px solid black',
    borderRadius: '1rem',
    // minHeight:'5rem'
  },

  [`& .${classes.title}`]: {
    color: "#424242",
    fontSize: "1rem",
    fontWeight: "bold",
  },

  [`& .${classes.count}`]: {
    color: "#424242",
    fontSize: "1.75rem",
    fontWeight: "bolder",
    lineHeight: '2rem'
  },

  [`& .${classes.bottom}`]: {
    display: 'flex',
    justifyContent: "end",
    alignItems: "center"
  },

  [`& .${classes.content}`]: {
    cursor:"pointer",
    minWidth:'8rem',
    margin: '1rem 1.5rem', textAlign: "center"
  }
}));

const GraphTable = (props) => {

  const navigate = useNavigate();
  const { data } = props

  return (
    <Root>
    <Box className={classes.root}>
      <Box style={{ display: 'flex' }} >
        <Grid container >
          {data && data.map((item, index) => {
            return (
              <Grid item className={classes.content} key={index} onClick={()=>{
                props?.setId(index)
              }} >
               {props?.id ===index ? <Box mt={-2.1} mb={3} style={{minHeight:'10px', borderRadius:'0 0px 10px 10px', background:'#3970FF'}}/> : <Box mt={-2} mb={2.9} style={{minHeight:'10px', borderRadius:'0 0px 10px 10px', background:'transparent'}}/>}
                <Typography className={classes.title} style={ {color: props?.id ===index ? "#3970FF" : ""}}>
                  {item.title}
                </Typography>
                <Typography className={classes.count} style={ {color: props?.id ===index ? "#000000" : ""}}>
                  {item.count}
                </Typography>
              </Grid>
            )
          })}
        </Grid>
      </Box>
      {props.children}
      <Box style={{ borderBottom: "1px solid grey", margin: '0px 1rem 1rem' }}></Box>
      <Grid className={classes.bottom}>
        {/* <Select  mainValue={props.mainValue} value={props.selectValue} setValue={props.setSelectValue}/> */}
        <Grid className={classes.bottom}
         style={{ margin: '0 1rem 1rem', cursor:"pointer" }}
         onClick={()=>  navigate("/app/user", { replace: true })}
        >
          <Typography style={{ color: "#40506A", fontWeight: "bold"}}>
            View Table
          </Typography>
          <ArrowForwardIcon style={{ color: "#40506A", marginLeft: '4px' }} />
        </Grid>
      </Grid>
    </Box>
    </Root>
  );
}

export default GraphTable