import axios from 'axios'
import { helpers } from 'src/_helpers'


// axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.token.slice(4, store.state.token.length);

//default header and api endpoint config
export const userDocuments = {
    getDocuments,
    addDocument,
    deleteDocument
}

function getDocuments(userID) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/users/document/${userID}`, requestOptions)
        .then(res => res)
        .catch(error => error.response)
}

function addDocument(userID, formData){
    helpers.setHeader();
    return axios.post(`/users/document/${userID}`,formData)
        .then(res => {            
            return res
        })
        .catch(error => {           
            return error.response
        })
}

function deleteDocument(userID,docID){
    helpers.setHeader();
    return axios.delete(`/users/document/${userID}/${docID}`)
        .then(res => {            
            return res
        })
        .catch(error => {           
            return error.response
        })
}