import axios from 'axios'
import { helpers } from 'src/_helpers'


// axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.token.slice(4, store.state.token.length);

//default header and api endpoint config
export const inventoryPriceService = {
    getInventoryPrice,
    getIRMInventoryPrice,
    getIRMInventoryPriceByID,
    postSamedata,
    postInternalTeam,
    putInventory,
    getInventoryPriceById,
    bulkPriceUpdate
};


function getInventoryPrice() {
    
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/inventory_price`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function getIRMInventoryPrice() {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/inventory_price`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}


function getIRMInventoryPriceByID(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/inventory_price/${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function postSamedata(inventoryTypeSameData) {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/updateinventorywith_samedata`,
        JSON.stringify(inventoryTypeSameData), requestOptions)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}


function postInternalTeam(internalTeamInventoryType) {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/inventory/send_inventory_report/?type=${internalTeamInventoryType.security_id.length>0?'SELECTED':'ALL'}`,
        JSON.stringify(internalTeamInventoryType), requestOptions)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}

function putInventory(inventoryData) {

    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.put(`/inventory_price`,
        JSON.stringify(inventoryData), requestOptions)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}



function getInventoryPriceById(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/inventory_price/${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}

function bulkPriceUpdate(formData) {
    helpers.setHeader();
    return axios.post(`/bulkScripPriceUpdate`,
    formData)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}



