import axios from "axios";
import { helpers } from "src/_helpers";

// axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.token.slice(4, store.state.token.length);

//default header and api endpoint config
export const AllFaq = {
  getAllFaq,
  getAllFaqCategories,
  insertFaq,
  deleteFaq,
  updateFaq,
  updateFaqSort
};

function getAllFaq() {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .get(`/getAllFaq`, requestOptions)
    .then((data) => data)
    .catch((error) => error.response);
}
function getAllFaqCategories() {
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .get(`/getAllFaqCategories`, requestOptions)
    .then((data) => data)
    .catch((error) => error.response);
}

function insertFaq(values) {
  const inputObj = {
    related_faq_category_id: values.category.id,
    question: values.question,
    answer: values.answer,
  };

  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .post(
      `/insertFaq`,
      JSON.stringify({ inputObj: inputObj }),
      requestOptions
    )
    .then((data) => data)
    .catch((error) => {
      console.log(error);
    });
}

function deleteFaq(id) {
  const val = {
    id: id,
  };
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .put(`/deleteFaq`, val, requestOptions)

    .then((data) => data)
    .catch((error) => {
      console.log(error.response);
    });
}

function updateFaq(values, itemid) {
  const inputObj = {
    id: itemid,
    related_faq_category_id: values.category.id,
    question: values.question,
    answer: values.answer,
  };
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .put(
      `/updateFaq`,
      JSON.stringify({ updateObj: inputObj }),
      requestOptions
    )
    .then((data) => data)
    .catch((error) => {
      console.log(error);
    });
}

function updateFaqSort(faqData) {
  const faq = {
    Faq:faqData
}
  helpers.setHeader();
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .put(
      `/faq_sort_update`,
      JSON.stringify(faq),
      requestOptions
    )
    .then((data) => data)
    .catch((error) => {
      console.log(error);
    });
}