import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import ErrorFocus from "../../components/ErrorFocus/ErrorFocus";
import {
  Container,
  TextField,
  Grid,
  TableCell,
  TableRow,
  TableHead,
  Checkbox,
  Typography,
  FormControlLabel,
  TableBody,
  TableContainer,
  Chip,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  FormControl,
  Tooltip,
  Box,OutlinedInput,
} from "@mui/material";
import * as Yup from "yup";
import { Formik, FieldArray, getIn } from "formik";
import moment from "moment";
import loadingSpinner from "src/images/loading-spinner.gif";
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal";
import { getLookUpDataService } from "src/_services/lookUp";
import Autocomplete from "@mui/material/Autocomplete";
import { serviceOrderService } from "src/_services/serviceOrder";
import SnackBar from "src/components/SnackBar/SnackBar";
import Copy from "src/components/Copy/CopyText";
import compareJSON from "src/components/comparision";
import { NavLink } from "react-router-dom";
import CopytoClipboard from "src/components/ButtonComponents/CopytoClipboard";
import ModalCentered from "src/components/Modals/ModalCentered";
import ImageRotate from "../Profile/Rotate";
import ExcelJS from 'exceljs';

const PREFIX = "BulkUpdateReferralReport";

const classes = {
  zeroPadding: `${PREFIX}-zeroPadding`,
  cardBorderRadius: `${PREFIX}-cardBorderRadius`,
  cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
  fileInput: `${PREFIX}-fileInput`,
  imageSizes: `${PREFIX}-imageSizes`,
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  marginInputLabel: `${PREFIX}-marginInputLabel`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  resendBtnMargin: `${PREFIX}-resendBtnMargin`,
  inputMargin: `${PREFIX}-inputMargin`,
  paddingInput: `${PREFIX}-paddingInput`,
  buttonProgress: `${PREFIX}-buttonProgress`,
};

const StyledContainer = styled("div")(({ theme }) => ({
  [`& .${classes.zeroPadding}`]: {
    padding: 0,
  },

  [`& .${classes.cardBorderRadius}`]: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },

  [`& .${classes.cardHeaderMargin}`]: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    borderRadius: "0 0  10px 0",
  },

  [`& .${classes.fileInput}`]: {
    display: "none",
  },

  [`& .${classes.imageSizes}`]: {
    width: "40%",
    margin: "auto",
    display: "block",
  },
  [`& .${classes.container}`]: {
    justifyContent: "center",
    // justifyItems: "center",
    marginTop: "2rem",
    maxWidth: "1600px",
  },

  [`& .${classes.label}`]: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },

  [`& .${classes.marginInputLabel}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    textTransform: "none",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.resendBtnMargin}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.inputMargin}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.paddingInput}`]: {
    padding: "0px 0px",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: "4px",
  },
}));

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) {
  // eslint-disable-line
  return <NavLink to={props.to} {...props} />; // eslint-disable-line
});

const BulkUpdateReferralReport = (props) => {
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(
    false
  );
  const [loading, setLoading] = React.useState(false);
  const [client, setClient] = React.useState([]);
  const [orders, setOrders] = React.useState(false);
  const [orderList, setOrderList] = React.useState([]);
  const [tags, setTags] = React.useState([]);
  const [severity, setSeverity] = React.useState("");
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [open, setOpen] = React.useState(false);
  const [preview, setPreview] = React.useState(false);

  const [initValues, setInitValues] = React.useState({
    fromDate: moment(new Date()).format("YYYY-MM-DD"),
    toDate: moment(new Date()).format("YYYY-MM-DD"),
    client: "",
    orders: [],
    invoiceType:"",
    orderList: orderList,
    checkedAll: false,
    paidDateforAll: "",
    remarks: "",
    status: "1",
    tagName:"",
    uploadOthersForAll:"",
    otherDocumentName:"Referral Document"
  });


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getLookUpDataService
      .getLookUpUser("CHANNEL_PARTNER")
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            let item = res.data.map((items) => {
              return {
                id: items.id,
                code: items.user_code,
                lable: `${
                  items.user_name
                } ( ${items.user_code.toUpperCase()} )`,
              };
            });

            setClient(item);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
            }
          }
        }
      })
      .catch((error) => {
        console.log("error");
      });
  }, []);

  const type = [
    {
      value: 3,
      lable: "All",
    },
    {
      value: 1,
      lable: "Buy",
    },
    {
      value: 2,
      lable: "Sell",
    },
  ];



  const handleDownload =async (value,name) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('OrderDetails');
    // Define header row
    worksheet.columns =[
        { header: 'Order Id', key: 'service_id', width: 20 },
        { header: 'Order Type', key: 'order_type', width: 15 },
        { header: 'Order Status', key: 'order_status', width: 15 },
        { header: 'Referral Amount', key: 'referral_amount', width: 20 },
        { header: 'Commission Paid Status', key: 'paid_status', width: 25 },
        { header: 'Commission Paid Date', key: 'paid_date', width: 25 },
    ];

    // Add data rows
   value.forEach(item => {
        worksheet.addRow({
            service_id: item.service_id,
            order_type: item.order_type,
            order_status: item.order_status,
            referral_amount: Number(item.referral_amount),
            paid_status: item.paid_status ? "Yes" : "No",
            paid_date: item.paid_date,
        });
    });

    // Style the header row
    const headerRow = worksheet.getRow(1);
    headerRow.eachCell((cell) => {
        cell.font = { bold: true, color: { argb: '#000' } };
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFF00' },
        };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
        cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        };
    });
    worksheet.eachRow((row, rowNumber) => {
      row.eachCell((cell) => {
          cell.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
          };
      });
  });

    // Merge cells for "Order Id" column based on identical values
    let startRow = 2;
    for (let i = 2; i <= value.length + 1; i++) {
        if (worksheet.getRow(i).getCell(1).value !== worksheet.getRow(startRow).getCell(1).value) {
            if (i - 1 > startRow) {
                worksheet.mergeCells(`A${startRow}:A${i - 1}`);
            }
            startRow = i;
        }
    }
    if (startRow < value.length + 1) {
        worksheet.mergeCells(`A${startRow}:A${value.length + 1}`);
    }

    // Generate Excel file and download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/octet-stream' });;
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `Referral_reports_${name}.xlsx`;
    link.click();
  };

  const selectOption = (
    value,
    multiple,
    options,
    setFieldValue,
    name,
    label,
    touched,
    errors
  ) => {
    return (
      <Grid item xs={12}>
        <Autocomplete
          multiple={multiple}
          id="client"
          // name="investorOrVendor"
          value={value}
          options={options}
          onChange={async (e, value) => {
            if (value !== null) {
              setFieldValue(name, value);
            } else {
              setFieldValue(name, "");
            }
          }}
          disabled={orders}
          getOptionLabel={(option) => option.lable || ""}
          renderInput={(params) => (
            <TextField
              error={Boolean(touched && errors)}
              helperText={touched && errors}
              {...params}
              label={label}
            />
          )}
        />
        <ErrorFocus />
      </Grid>
    );
  };


  const getOrders = (values) => {
    let orderId = [];
    if (values.tagName?.includes(" ")) {
      let orderArray = values.tagName
        .split(" ")
        .map((item) => item?.trim().replace(",", ""));
      orderId = [...tags, ...orderArray];
    } else {
      orderId = [...tags, values.tagName];
    }
    const data = {
      order_id: values.tagName?.trim() !== "" ? orderId : tags,
      order_type: values.invoiceType.value,
      client_id: values.client.id,
      from_date: values.fromDate,
      to_date: values.toDate,
      status: values.status,
    };
    serviceOrderService.getReferralData(data).then((res) => {
      if (res.status === 200) {
        if (res.data.length > 0) {
          setOrders(true);
        console.log(res.data)
        const totalCommission = res.data.reduce(
          (sum, item) => Number(sum) + Number(item.referral_amount),
          0
        );
        let filteredData =res.data.map((item)=>{
          return{
            ...item,
            paid_date:item.paid_date ? moment(item.paid_date).format( "YYYY-MM-DD") :"",
            uploadOthers: false
          }
        })
        setInitValues({
          ...initValues,
          orderList:filteredData,
          client:values.client,
          invoiceType:values.invoiceType,
          totalCommission: parseFloat(totalCommission).toFixed(2)
        })
        setOrderList(res.data)
        } else {
          setSnackbarTitle("No orders found");
          setSeverity("success");
          setOpen(true);
        }
      } else {
        setSnackbarTitle("Something went wrong");
        setSeverity("error");
        setOpen(true);
      }
    });
  };
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "application/pdf",
    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];
  const handleAddTag = (e, setFieldValue) => {
    if (e.key === "Enter" && e.target.value.trim() !== "") {
      e.preventDefault();
      if (e.target.value.includes(" ")) {
        let orderArray = e.target.value
          .split(" ")
          .map((item) => item.trim().replace(",", ""));
        setTags([...tags, ...orderArray]);
      } else {
        setTags([...tags, e.target.value]);
      }
      e.target.value = "";
    }
    setFieldValue("tagName", "");
  };

  const handleDelete = (i) => {
    setTags((tag) => tag.filter((name, index) => index !== i));
  };

 



  const [hover, setHover] = useState(false);
  const [rowindex, setrowIndex] = React.useState("");
  const onHover = () => {
    setHover(true);
  };

  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialValues={initValues}
        validationSchema={Yup.object().shape({
          fromDate: Yup.string().required("Required"),
          toDate: Yup.string().required("Required"),
          client: Yup.string().required("Required"),
          invoiceType: Yup.string().required("Required"),
          orderList: Yup.array(
            Yup.object({
              paid_date: Yup.string().when(
                "paid_status",
                {
                  is: true,
                  then: Yup.string().required("Commission date required"),
                  otherwise: Yup.string().nullable(),
                }
              ),
            })
          ),
          othersCopy: Yup.mixed().when("orderList", {
            is: (type) => type.some((i) => i.uploadOthers === true), //just an e.g. you can return a function
            then: Yup.mixed()
              .required("A file is required")
              .test(
                "fileFormat",
                "Unsupported Format",
                (value) => value && SUPPORTED_FORMATS.includes(value.type)
              )
              .test(
                "fileSize",
                "Maximum file size is 10MB",
                (value) => value && value.size < 10242880
              ),
            otherwise: Yup.mixed(),
          }),
          otherDocumentName: Yup.string().when("orderList", {
            is: (type) => type.some((i) => i.uploadOthers === true), //just an e.g. you can return a function
            then: Yup.string().required("Document name required"),
            otherwise: Yup.string(),
          }),
        })}
        onSubmit={(values) => {
          setLoading(true);
          const newValue = values.orderList.map((item) => {
            const match = initValues.orderList.find(
              (item2) => item2.id === item.id
            );
            let result = compareJSON(item, match);
            return {
              ...item,
              updated_values: JSON.stringify(result),
            };
          });
          values = { ...values, orderList: newValue };
          props.formSubmit(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>
              <Grid container spacing={2}>
                {orders ? (
                  <>
                  
                    <Grid
                      item
                      xs={12}
                      p={0}
                      display="flex"
                      justifyContent={"space-between"}
                    >
                      <Typography sx={{ display: "flex" }}>
                        Channel Partner :{" "}
                        <Copy font="true" value={values.client.lable} />
                      </Typography>
                      <Button
                        onClick={() => handleDownload(initValues.orderList,values.client.lable)}
                        sx={{ textTransform: "none" }}
                      >
                        Download Report
                      </Button>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      sx={{ alignItems: "center", paddingLeft: "1rem" }}
                    >
                      <Grid item xs={4} p={0}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.checkedAll}
                              //  name={`orderList`}
                              //  id="commissionStatus"
                              //  aria-describedby="outlined-weight-helper-text"
                              onBlur={handleBlur}
                              onChange={(e, value) => {
                                setFieldValue("checkedAll", value);
                                if (!value) {
                                  let updateAll = values.orderList.map(
                                    (value) => {
                                      const match = initValues.orderList.find(
                                        (item2) => item2.id === value.id
                                      );
                                      return {
                                        ...value,
                                        paid_date:
                                          value.paid_date,
                                        paid_status:
                                          match.paid_status,
                                      };
                                    }
                                  );
                                  setFieldValue(`orderList`, updateAll);
                                  setFieldValue("checkedAll", false);
                                } else {
                                  let updateAll = values.orderList.map(
                                    (value) => {
                                      return {
                                        ...value,
                                        paid_status: true,
                                      };
                                    }
                                  );
                                  setFieldValue(`orderList`, updateAll);
                                }
                              }}
                              color="primary"
                            />
                          }
                          label={
                            <Typography component={"span"}>
                              Apply paid all
                            </Typography>
                          }
                        />
                      </Grid>
                      {values.checkedAll && (
                        <Grid item xs={4} md={2} p={0}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            id="paidDateforAll"
                            label="Paid Date for all"
                            type="date"
                            name="paidDateforAll"
                            value={values.paidDateforAll}
                            InputProps={{
                              inputProps: {
                                max: `${moment(new Date()).format(
                                  "YYYY-MM-DD"
                                )}`,
                              },
                            }}
                            error={Boolean(
                              touched.paidDateforAll && errors.paidDateforAll
                            )}
                            helperText={
                              touched.paidDateforAll && errors.paidDateforAll
                            }
                            onChange={(e) => {
                              setFieldValue("paidDateforAll", e.target.value);
                              let updateAll = values.orderList.map((value) => {
                                return {
                                  ...value,
                                  paid_date: value.paid_date
                                    ? value.paid_date
                                    : e.target.value,
                                };
                              });
                              setFieldValue(`orderList`, updateAll);
                            }}
                            onBlur={handleBlur}
                            // InputProps={{ inputProps: { min: `${moment(values.fromDate).format("YYYY-MM-DD")}` } }}
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={6}
                        p={0}
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                      <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.uploadOthersForAll}
                              //  name={`orderList`}
                              //  id="commissionStatus"
                              //  aria-describedby="outlined-weight-helper-text"
                              onBlur={handleBlur}
                              onChange={(e, value) => {
                                setFieldValue("uploadOthersForAll", value);
                                if (!value) {
                                  let updateAll = values.orderList.map(
                                    (value) => {
                                      return {
                                        ...value,
                                        uploadOthers: false,
                                      };
                                    }
                                  );
                                  setFieldValue(`orderList`, updateAll);
                                  setFieldValue("uploadOthersForAll", false);
                                } else {
                                  let updateAll = values.orderList.map(
                                    (value) => {
                                      return {
                                        ...value,
                                        uploadOthers: true
                                      };
                                    }
                                  );

                                  setFieldValue(`orderList`, updateAll);
                                }
                              }}
                              color="primary"
                            />
                          }
                          label={
                            <Typography component={"span"}>
                              Upload Documents for All
                            </Typography>
                          }
                        />
                        </Grid>
                      </Grid>
                    <Grid item xs={12} p={0}>
                      <TableContainer sx={{ scrollbarWidth: "none" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ whiteSpace: "nowrap" }}>
                              Sl No
                            </TableCell>
                            <TableCell>Order Id</TableCell>
                            <TableCell>Order Type</TableCell>
                            <TableCell>Order Status</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Referral status</TableCell>
                            <TableCell>Referral Paid date</TableCell>
                            <TableCell>Document</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <FieldArray name="orderList">
                            {() =>
                              values.orderList?.map((_, index) => {
                                const touchedPaidDate = getIn(
                                  touched,
                                  `orderList.${index}.paid_date`
                                );
                                const errorPaidDate = getIn(
                                  errors,
                                  `orderList.${index}.paid_date`
                                );
                                return (
                                  <TableRow key={index}>
                                    {/** both these conventions do the same */}
                                    <TableCell>{index + 1}</TableCell>

                                    <TableCell
                                      sx={{
                                        display: "flex",
                                        gap: 1,
                                        width: "170px",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        padding: "25px 0px",
                                      }}
                                      onMouseEnter={() => {
                                        onHover();
                                        setrowIndex(
                                          values.orderList[index].transaction_id
                                        );
                                      }}
                                      onMouseLeave={() => setrowIndex()}
                                    >
                                      <Typography
                                        component={LinkBtn}
                                        to={`/app/service-order-details/${values.orderList[index].id}`}
                                        color="primary"
                                      >
                                        <Copy
                                          value={
                                            values.orderList[index].service_id
                                          }
                                        />
                                      </Typography>
                                      {rowindex ===
                                        values.orderList[index].service_id && (
                                        <Tooltip
                                          style={{ cursor: "copy" }}
                                          title={
                                            hover ? "Copied" : "Click to Copy"
                                          }
                                        >
                                          <CopytoClipboard
                                            userBasicInfo={
                                              values.orderList[index].service_id
                                            }
                                            state={hover}
                                          />
                                        </Tooltip>
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      {values.orderList[index].order_type}
                                    </TableCell>
                                    <TableCell>
                                      {values.orderList[index].order_status}
                                    </TableCell>
                                    <TableCell>
                                      <Copy
                                        value={
                                          values.orderList[index]
                                            .referral_amount
                                        }
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={
                                              values.orderList[index]
                                                .paid_status
                                            }
                                            name={`orderList.${index}.paid_status`}
                                            id="paid_status"
                                            aria-describedby="outlined-weight-helper-text"
                                            onBlur={handleBlur}
                                            onChange={(e, value) => {
                                              setFieldValue(
                                                `orderList.${index}.paid_status`,
                                                value
                                              );
                                              if (!value) {
                                                setFieldValue(
                                                  `orderList.${index}.paid_date`,
                                                  ""
                                                );
                                              }
                                            }}
                                            color="primary"
                                          />
                                        }
                                        label={
                                          <Typography component={"span"}>
                                            Paid
                                          </Typography>
                                        }
                                      />
                                    </TableCell>

                                    <TableCell sx={{ padding: "13px" }}>
                                      {values.orderList[index]
                                        .paid_status && (
                                        <TextField
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          
                                          //  InputProps={{ disableUnderline: true, }}
                                          style={{ marginTop: "8px" }}
                                          label="Referral Paid Date*"
                                          id="investorcolor"
                                          variant="outlined"
                                          type="date"
                                          size="small"
                                          disabled={
                                            !values.orderList[index]
                                              .paid_status
                                          }
                                          name={`orderList.${index}.paid_date`}
                                          value={
                                            values.orderList[index]
                                              .paid_date
                                          }
                                          autoComplete="off"
                                          fullWidth
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          error={Boolean(
                                            touchedPaidDate &&
                                              errorPaidDate
                                          )}
                                          helperText={
                                            touchedPaidDate &&
                                            errorPaidDate
                                          }
                                        />
                                      )}
                                    </TableCell>
                                    <TableCell>
                                    <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={
                                              values.orderList[index]
                                                .uploadOthers
                                            }
                                            name={`orderList.${index}.uploadOthers`}
                                            id="uploadOthers"
                                            aria-describedby="outlined-weight-helper-text"
                                            onBlur={handleBlur}
                                            onChange={(e, value) => {
                                              setFieldValue(
                                                `orderList.${index}.uploadOthers`,
                                                value
                                              );
                                              if (!value) {
                                                setFieldValue(
                                                  "uploadOthersForAll",
                                                  false
                                                );
                                              } 
                                            }}
                                            color="primary"
                                          />
                                        }
                                        label={
                                          <Typography
                                            component={"span"}
                                            sx={{ whiteSpace: "nowrap" }}
                                          >
                                            Others
                                          </Typography>
                                        }
                                      />
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                            }
                          </FieldArray>
                        </TableBody>
                      </TableContainer>
                    </Grid>
                  { values.orderList.some(order => order.uploadOthers === true) ? <Grid container sx={{ display: "flex", marginY: "1rem" }} columnGap={2}>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            margin: "8px 1rem",
                            display: "flex",
                          }}
                        >
                          <Typography> Total Commission Amount : </Typography>
                          <Copy font={"true"} value={values.totalCommission} />
                        </Typography>
                      </Grid>
                      <Grid item xs={4} mt={3}>
                      <Typography className={classes.label}>
                        Select Document{" "}
                      </Typography>
                      <OutlinedInput
                        accept="image/*"
                        // className={classes.input}
                        id="icon-button-file"
                        name="othersCopy"
                        fullWidth={true}
                        onChange={(e) => {
                          setFieldValue("othersCopy", e.target.files[0]);
                        }}
                        type="file"
                      />
                   {values.othersCopy && !errors.othersCopy ? (
                        <FormHelperText
                          onClick={() => {
                            const url = URL.createObjectURL(values.othersCopy);
                            setPreview({
                              url: url,
                              type:
                                values.othersCopy.type === "application/pdf"
                                  ? true
                                  : false,
                            });
                          }}
                          style={{
                            textDecoration: "underline",
                            fontWeight: "bold",
                          }}
                        >
                          {"Preview"}
                        </FormHelperText>
                      ) : (
                        <FormHelperText
                          style={{ color: "#F44336", paddingLeft: 16 }}
                        >
                          {errors.othersCopy}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={4} mt={3}>
                      <Typography className={classes.label}>
                        Document Name
                      </Typography>
                      <TextField
                        fullWidth
                        label=""
                        name="otherDocumentName"
                        onChange={handleChange}
                        value={values.otherDocumentName}
                        variant="outlined"
                        error={Boolean(
                          touched.otherDocumentName && errors.otherDocumentName
                        )}
                        helperText={
                          touched.otherDocumentName && errors.otherDocumentName
                        }
                      />
                    </Grid>
                    </Grid>
                  :null}
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        className={classes.popDownBtn}
                        size="medium"
                        type="submit"
                        variant="contained"
                      >
                        {loading === false ? (
                          <> {"Update"}</>
                        ) : (
                          <>
                            <img
                              alt="loader"
                              src={loadingSpinner}
                              className={classes.buttonProgress}
                            />{" "}
                            Update{" "}
                          </>
                        )}
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={6}>
                      {selectOption(
                        values.type,
                        false,
                        type,
                        setFieldValue,
                        "invoiceType",
                        "Order Type",
                        touched.invoiceType,
                        errors.invoiceType
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      {selectOption(
                        values.client,
                        false,
                        client,
                        setFieldValue,
                        "client",
                        "Channel Partner",
                        touched.client,
                        errors.client
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        helperText=""
                        label={`Search by order id`}
                        onKeyPress={(e) => handleAddTag(e, setFieldValue)}
                        name="tagName"
                        disabled={orders}
                        onChange={handleChange}
                      />
                      <div className={classes.wrap}>
                        {tags &&
                          tags.map((item, index) => (
                            <Chip
                              disabled={orders}
                              style={{ margin: "4px" }}
                              key={index}
                              tabIndex={-1}
                              label={item}
                              onDelete={() => handleDelete(index)}
                            />
                          ))}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} textAlign={"center"}>
                      <Typography>Or</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="fromDate"
                        label="From Date"
                        type="date"
                        name="fromDate"
                        disabled={orders}
                        error={Boolean(touched.fromDate && errors.fromDate)}
                        helperText={touched.fromDate && errors.fromDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputProps={{
                          inputProps: {
                            max: `${moment(new Date()).format("YYYY-MM-DD")}`,
                          },
                        }}
                        value={values.fromDate}
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <ErrorFocus />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="toDate"
                        label="End Date"
                        type="date"
                        name="toDate"
                        disabled={orders}
                        value={values.toDate}
                        InputProps={{
                          inputProps: {
                            min: values.fromDate,
                            max: `${moment(new Date()).format("YYYY-MM-DD")}`,
                          },
                        }}
                        error={Boolean(touched.toDate && errors.toDate)}
                        helperText={touched.toDate && errors.toDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        // InputProps={{ inputProps: { min: `${moment(values.fromDate).format("YYYY-MM-DD")}` } }}
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <ErrorFocus />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <FormControl
                        component="fieldset"
                        error={Boolean(touched.status && errors.status)}
                      >
                        <FormLabel component="legend">Status</FormLabel>
                        <RadioGroup
                          row
                          aria-label="payment_mode"
                          name="status"
                          value={values.status}
                          id="payment_mode"
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value={"1"}
                            control={<Radio color="primary" />}
                            label="All"
                          />
                          <FormControlLabel
                            value={"2"}
                            control={<Radio color="primary" />}
                            label="Paid"
                          />
                          <FormControlLabel
                            value={"3"}
                            control={<Radio color="primary" />}
                            label="Unpaid"
                          />
                        </RadioGroup>
                        <FormHelperText>
                          {touched.spa_type && errors.spa_type}
                        </FormHelperText>
                      </FormControl>
                      <ErrorFocus />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Button
                        color="primary"
                        disabled={
                          values.client === "" || values.invoiceType === ""
                        }
                        className={classes.popDownBtn}
                        size="medium"
                        onClick={() => getOrders(values)}
                        variant="contained"
                      >
                        {loading === false ? (
                          <> {"Get order list"}</>
                        ) : (
                          <>
                            <img
                              alt="loader"
                              src={loadingSpinner}
                              className={classes.buttonProgress}
                            />{" "}
                            Get order list{" "}
                          </>
                        )}
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </Container>
          </form>
        )}
      </Formik>
      <SessionExpiredModal open={openSessionExpiredModal} />
      <SnackBar
        open={open}
        severity={severity}
        close={handleClose}
        snackbarTitle={snackbarTitle}
      />
      <ModalCentered
        title={"Preview"}
        open={preview}
        toggle={() => setPreview(false)}
        // maxWidth="md"
        fullScreen="true"
        children={
          <Box sx={{ textAlign: "center" }}>
            {preview.type ? (
              <iframe
                title="iframe"
                style={{ width: "100%", height: "calc(100vh - 100px)" }}
                src={preview.url}
              />
            ) : (
              <ImageRotate
                alt="cool image"
                type="users"
                image={preview && preview.url}
              />
            )}
          </Box>
        }
      />
    </StyledContainer>
  );
};

export default BulkUpdateReferralReport;
