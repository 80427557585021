import { createTheme } from '@mui/material/styles';
import { colors } from '@mui/material';
import shadows from './shadows';
import typography from './typography';
const theme = createTheme({
  palette: {
    background: {
      dark: '#F4F6F8',
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: '#314B8F'
    },
    secondary: {
      main: colors.grey[600]
    },
    text: {
      primary: '#2f2f2f',
      secondary: colors.grey[800]
    }
  },
  shadows,
  typography,
  fontWeightRegular:400,
  fontWeightMedium:500,
});

export default theme;
