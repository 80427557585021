import React, { useEffect } from "react";
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import {
  Container,
  TextField,
  Grid,
  Typography,
  RadioGroup,Radio,
  FormControlLabel,
} from "@mui/material";
import { Formik } from "formik";
import { Autocomplete } from "@mui/material";
import loadingSpinner from "src/images/loading-spinner.gif";
// import Typography from "src/theme/typography";
import { getLookUpDataService } from "src/_services/lookUp";
import moment from "moment";

const PREFIX = 'AdvanceSearchForm';

const classes = {
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  buttonProgress: `${PREFIX}-buttonProgress`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.container}`]: {
    justifyContent: "center",
    // justifyItems: "center",
    marginTop: "5%",
  },

  [`& .${classes.label}`]: {
    marginTop: "10px",
    fontWeight: "bold",
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: "4px",
  }
}));

// const filteredOptions = (options, values) => {
//   if (values.id ===0) {
//     return values?.label !== "All"
//       ? options?.filter((option) =>
//           option.label.toLowerCase().includes(values?.label.toLowerCase())
//         )
//       : [options[0]];
//   } else {
//     return [options[0]];
//   }
// };

// console.log(getChannelPartner())
const renderCustomAutoComplete = (
  value,
  name,
  setFieldValue,
  options,
  label
) => {
  return (
    <Autocomplete
      value={value}
      fullWidth
      onChange={(event, newValue) => {
        setFieldValue(name, newValue);
      }}
      options={ options }
      //   freeSolo={name ==="client" ? true :false}
      // onInputChange={(e) => {
      //   setFieldValue(name, e ? { id: 0, label: e.target.value } : value);
      // }}
      disableClearable
      getOptionLabel={(option) => option?.label }
      size="small"
      //   options={options}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={label} />
      )}
    />
  );
};


function AdvanceSearchForm(props) {

  const [loading, setLoading] = React.useState(false);
  const [salesAndOperation, setSalesAndOperation] = React.useState([]);
  const [channelPartner, setChannelPartner] = React.useState([]);
  const [status, setStatus] = React.useState([]);
  const [scrips, setScrips] = React.useState([]);
  const [client, setClient] = React.useState([]);

  let allItem = [{ id: 0, label: "All" }]
  useEffect(() => {
    getLookUpDataService.getLookUpUser("SALES,OPERATION").then((res) => {
      let item = res.data.map((item) => {
        return { id: item.id, label: item.user_name };
      });
      setSalesAndOperation(allItem.concat(item));
    });
    getLookUpDataService
      .getLookUpUser("CHANNEL_PARTNER,INVESTER_CLIENT")
      .then((res) => {
        let item = res.data.map((item) => {
          return { id: item.id,
            label: `${item.user_name} ( ${item.user_code.toUpperCase()} )`,
             };
        });
        setClient(allItem.concat(item));
      });
      getLookUpDataService
      .getLookUpUser("CHANNEL_PARTNER")
      .then((res) => {
        let item = res.data.map((item) => {
          return { id: item.id,
            label: `${item.user_name} ( ${item.user_code.toUpperCase()} )`,
             };
        });
        setChannelPartner(allItem.concat(item));
      });
    getLookUpDataService.getLookUpData("service_order_status").then((res) => {
      let item = res.data[0].service_order_status.map((item) => {
        return { id: item.id, label: item.description };
      });
      setStatus(allItem.concat(item));
    });

    getLookUpDataService
      .getLookSecurity()
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            let item = res.data.map((data) => {
              return { id: data.id, label: data.name };
            });
            setScrips(allItem.concat(item));
          }
        }
      })
      .catch(console.error());
  }, []);

  const transactionType = [
    {
      id: 0,
      label: "All",
    },
    {
      id: 1,
      label: "Direct",
    },
    {
      id: 2,
      label: "Intermediate",
    },
  ];
  const orderType = [
    {
      id: 0,
      label: "All",
    },
    {
      id: 1,
      label: "Online",
    },
    {
      id: 2,
      label: "Offline",
    },
  ];

const values =props.advanceSearchForm ? props.advanceSearchForm :{
  order_type: allItem[0],
  order_status: allItem[0],
  transaction_type: allItem[0],
  serving_owner: allItem[0],
  internal_rm: allItem[0],
  from_date: "",
  client: allItem[0],
  to_date: `${moment(new Date()).format("YYYY-MM-DD")}`,
  scrips: allItem[0],
  is_nse: "no",
  channel_partner:allItem[0],
  is_facilitated:"no"
} 
  return (
    <StyledContainer>
      <Formik
        initialValues={values}
      >
        {({
          handleSubmit,
          isSubmitting,
          setFieldValue,
          values,
          handleChange,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} className={classes.container}>
              <Grid item xs={4}>
                <Typography className={classes.label}>Date Range</Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="from_date"
                  label="From Date"
                  type="date"
                  name="from_date"
                  onChange={handleChange}
                  InputProps={{
                    inputProps: {
                      max: `${moment(values.to_date).format("YYYY-MM-DD")}`,
                    },
                  }}
                  value={values.from_date}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="to_date"
                  label="To date"
                  type="date"
                  name="to_date"
                  onChange={handleChange}
                  InputProps={{
                    inputProps: {
                      max: `${moment(new Date()).format("YYYY-MM-DD")}`,
                      min: `${moment(values.from_date).format("YYYY-MM-DD")}`,
                    },
                  }}
                  value={values.to_date}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.label}>Order Type</Typography>
              </Grid>
              <Grid item xs={8}>
                {renderCustomAutoComplete(
                  values.order_type,
                  "order_type",
                  setFieldValue,
                  orderType,
                  "Select order Type"
                )}
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.label}>Order Status</Typography>
              </Grid>
              <Grid item xs={8}>
                {renderCustomAutoComplete(
                  values.order_status,
                  "order_status",
                  setFieldValue,
                  status,
                  "Select Order Status"
                )}
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.label}>
                  Transaction Type
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {renderCustomAutoComplete(
                  values.transaction_type,
                  "transaction_type",
                  setFieldValue,
                  transactionType,
                  "Select Transaction Type"
                )}
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.label}>
                  Investor/Vendor
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {renderCustomAutoComplete(
                  values.client,
                  "client",
                  setFieldValue,
                  client,
                  "Select Client"
                )}
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.label}>
                Channel Partner
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {renderCustomAutoComplete(
                  values.channel_partner,
                  "channel_partner",
                  setFieldValue,
                  channelPartner,
                  "Select Channel Partner"
                )}
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.label}>Scrip Name</Typography>
              </Grid>
              <Grid item xs={8}>
                {renderCustomAutoComplete(
                  values.scrips,
                  "scrips",
                  setFieldValue,
                  scrips,
                  "Select Scrip "
                )}
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.label}>Internal RM</Typography>
              </Grid>
              <Grid item xs={8}>
                {renderCustomAutoComplete(
                  values.internal_rm,
                  "internal_rm",
                  setFieldValue,
                  salesAndOperation,
                  "Select Internal RM"
                )}
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.label}>Serving Owner</Typography>
              </Grid>
              <Grid item xs={8}>
                {renderCustomAutoComplete(
                  values.serving_owner,
                  "serving_owner",
                  setFieldValue,
                  salesAndOperation,
                  "Select Serving Owner"
                )}
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.label}>NSE Orders</Typography>
              </Grid>
              <Grid item xs={8}>
                <RadioGroup row aria-label="is_nse" name="is_nse"
                            value={values.is_nse}
                            id="is_nse"
                            // onBlur={handleBlur}
                            onChange={handleChange}
                          >
                            <FormControlLabel value={"no"} control={<Radio color="primary" />} label="No" />
                            <FormControlLabel value={"yes"} control={<Radio color="primary" />} label="Yes" />
                          </RadioGroup>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.label}>Facilitated Orders</Typography>
              </Grid>
              <Grid item xs={8}>
                <RadioGroup row aria-label="is_facilitated" name="is_facilitated"
                            value={values.is_facilitated}
                            id="is_facilitated"
                            // onBlur={handleBlur}
                            onChange={handleChange}
                          >
                            <FormControlLabel value={"no"} control={<Radio color="primary" />} label="No" />
                            <FormControlLabel value={"yes"} control={<Radio color="primary" />} label="Yes" />
                          </RadioGroup>
              </Grid>
            </Grid>

            <Button
              color="primary"
              disabled={loading}
              className={classes.popDownBtn}
              size="medium"
              type="submit"
              onClick={() => {
                setLoading(true);
                props.formSubmit(values);
              }}
              variant="contained"
            >
              {loading === false ? (
                "Search"
              ) : (
                <>
                  <img
                    src={loadingSpinner}
                    alt="Logo"
                    className={classes.buttonProgress}
                  />{" "}
                  Search{" "}
                </>
              )}
            </Button>
          </form>
        )}
      </Formik>
    </StyledContainer>
  );
}

export default AdvanceSearchForm;
