import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/system';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MUIDataTable from "mui-datatables";
import Box from '@mui/material/Box';
import { connect } from 'react-redux';
import Page from 'src/components/Page';
import { ThemeProvider } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {  Typography, Zoom } from '@mui/material'
import AlertModal from 'src/components/AlertModal/AlertModal'
import SnackBar from "src/components/SnackBar/SnackBar";
import ModalCentered from "src/components/Modals/ModalCentered";
import AdditionalTradeCreateForm from "./AdditionalTradeForm"
import IconButton from '@mui/material/IconButton';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { additionalTradeService } from 'src/_services/additionalTrade';
import moment from 'moment'
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal"
import ServiceCard from 'src/components/Cards/ServiceCard';
import { NavLink } from 'react-router-dom';
import GetMuiTheme from 'src/components/theme';
const PREFIX = 'Index';

const classes = {
  addbutton: `${PREFIX}-addbutton`,
  editdeletbtn: `${PREFIX}-editdeletbtn`,
  fabbutton: `${PREFIX}-fabbutton`,
  bottomborder: `${PREFIX}-bottomborder`,
  iconbtn: `${PREFIX}-iconbtn`,
  card: `${PREFIX}-card`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.addbutton}`]: {
    [theme.breakpoints.down(480)]: {
      display: 'none'
    },
    position: 'absolute',
    right: '1%',
    top: '6px',
    boxShadow: 'none',
    textTransform: 'capitalize',
    [theme.breakpoints.down(960)]: {
      top: '5px',
    }
  },
  [`& .${classes.card}`]: {
    borderBottom: "none",
    padding: 0,
  },
  [`& .${classes.editdeletbtn}`]: {
    display: 'flex'
  },

  [`& .${classes.fabbutton}`]: {
    [theme.breakpoints.up(480)]: {
      display: 'none'
    },
    position: ' absolute',
    right: '6px',
    top: '6px',
    boxShadow: 'none',
    width: '36px',
    height: '36px'
  },

  [`& .${classes.bottomborder}`]: {
    backgroundColor: '#fff'
  },

  [`& .${classes.iconbtn}`]: {
    padding: theme.spacing(0.8)
  }
}));

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) { // eslint-disable-line
  return <NavLink to={props.to} {...props} />; // eslint-disable-line
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const getMuiTheme = GetMuiTheme
const AdditionalTradeList = (props) => {

  const theme = useTheme();
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [titleModal, setTitleModal] = useState("");
  const [severity, setSeverity] = useState("");
  const [type, setType] = useState("");
  const [availabilityData, setAvailabilityData] = useState([])
  const [requireMentData, setRequireMentData] = useState([])
  const [formMode, setFormMode] = useState(null);
  const [formId, setFormId] = useState(null);
  const [value, setValue] = useState(0);
  const [getValue, setGetValue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);
  const [spinner, setSpinner] = useState(false)
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)



  var ownerId = props.authReducer.auth.user.user_unique_code;
  var isAdmin = props.authReducer.auth.user.default_role_code;


  useEffect(() => {
    getAdditionalTradeData();

  }, [getValue])

  const getAdditionalTradeData = () => {
    setLoading(true)
    additionalTradeService.getAdditionalTrade().then(res => {
      setLoading(true)
      if (res) {

        if (res.status === 200) {
          setLoading(false)
          if (res.data.length > 0) {
            let requiredMent = [];
            let availability = [];
            res.data.forEach((data, index) => {
              if (data.requirement_type === "REQUIREMENT") {
                requiredMent.push(data)
              }
              else if (data.requirement_type === "AVAILABILITY") {
                availability.push(data)
              }
            })
            setRequireMentData(requiredMent)
            setAvailabilityData(availability)
          }
          else {
            if (value === 0) {
              setAvailabilityData([])
            }
            else {
              setRequireMentData([])
            }
          }
        } else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setLoading(false)
            setOpenSessionExpiredModal(true)
          }
        }
      }
    })
  }


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // const handleShow = (type) => {
  //   // setTitleType(true)
  //   setFormMode("create")
  //   if (type === "AVAILABILITY") {
  //     setType(type)
  //     setTitleModal("Add Availability");
  //     setShow(true);
  //   }
  //   else {

  //     setType(type)
  //     setTitleModal("Add Requirement");
  //     setShow(true);
  //   }
  // }

  const handleShowClose = () => {
    setShow(false);
  };

  const submitHandle = (values) => {
    // const validPrice = values.price.toFixed(2)
    const postTradeAvailability = {
      id: formId,
      price: values.price,
      owned_by: values.ownedBy.id,
      security_id: values.security.id,
      quantity: parseInt(values.quantity),
      valid_upto: values.validUpTo,
      requirement_type: values.type.toUpperCase(),
      vendor: values.vender.id || " "
    }

    if (formMode === "create") {
      additionalTradeService.postAdditionalTrade(postTradeAvailability)
        .then(res => {
          if (res) {

            if (res.status === 201) {
              getAdditionalTradeData();
              if (values.type.toUpperCase() === "AVAILABILITY") {

                setGetValue(!getValue)
                setType(type)
                setSeverity("success");
                setSnackbarTitle("New Availability Added Successfully");
                setOpen(true)
                setShow(false);
                return true
              }
              else if (values.type.toUpperCase() === "REQUIREMENT") {
                setGetValue(!getValue)
                setType(type)
                setSeverity("success");
                setSnackbarTitle("New Requirement Added Successfully");
                setOpen(true)
                setShow(false);
              }

            }
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setShow(false)
              setOpenSessionExpiredModal(true)
            }
          }
        })
    }
    else if (formMode === "edit") {
      additionalTradeService.putAdditionalTrade(postTradeAvailability)
        .then(res => {
          if (res) {

            if (res.status === 200) {
              setGetValue(!getValue)
              setSeverity("success");
              setSnackbarTitle("Updated Successfully");
              setOpen(true)
              setShow(false);
            } else {
              if (res.status === 401 && res.data.name === "TokenExpiredError") {
                setShow(false)
                setOpenSessionExpiredModal(true)
              }

            }
          }
        })
    }
    setShow(false);
    setOpen(true);
  }


  const handleDeleteTableRow = () => {
    setSpinner(true)
    additionalTradeService.deleteAdditionalTradeByID(deleteId)
      .then(res => {
        if (res) {
          if (res.status === 200) {
            getAdditionalTradeData()
            setGetValue(!getValue)
            setDeleteOpen(false)
            setSpinner(false)
            setSeverity("success")
            setSnackbarTitle("Security deleted")
            setOpen(true);
          }
          else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setDeleteOpen(false)
              setSpinner(false)
              setOpenSessionExpiredModal(true)
            } else {
              setDeleteOpen(false)
              setSpinner(false)
              setSeverity("error")
              setSnackbarTitle("Something Went Wrong!")
              setOpen(true);
            }
          }
        }
      }).catch(error => {
        setDeleteOpen(false)
        setSpinner(false)
        setSeverity("error")
        setSnackbarTitle("Invalid Token")
        setOpen(true);
      })
  }

  const handleEditAvailibity = (id) => {
    setFormMode("edit")
    setFormId(id)
    setType("AVAILABILITY")
    setShow(true)
    setTitleModal("Add Availability");

  }

  const handleEditRequirement = (id) => {
    setFormMode("edit")
    setFormId(id)
    setType("REQUIREMENT")
    setShow(true)
    setTitleModal("Add Requirement");
  }

  const requirmentColumn = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: "security_id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },

    {
      name: "cdate",
      label: "Date",
      options: {
        hint: "Date on Which Requirement was Added",
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          let cDateValue = moment(value).format("DD-MM-YYYY")
          return (
            <Typography component="span">
              {cDateValue}
            </Typography>)
        }
      }
    },
    {
      name: "security_name",
      label: "Scrips",
      options: {
        hint: "Name of the Scrips",
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {

          return (
            <div className={classes.tableEllips} >
              <Typography
                variant='body2'
                style={{ textTransform: 'capitalize' }}
                component={LinkBtn}
                noWrap
                to={`/app/security-details/${tableMeta.rowData[1]}`}
                color="primary"
              >
                {value}
              </Typography>
            </div>
          )
        }
      }
    },
    {
      name: "price",
      label: "Price",
      options: {
        hint: "Potential Share Price",
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          const validPrice = parseFloat(value)
          return (
            <Typography component="span">
              {validPrice.toFixed(2)}
            </Typography>
          )
        }

      }
    },
    {
      name: "quantity",
      label: "Quantity",
      options: {
        hint: "Potential Inventory Stock",
        filter: false,
        sort: false,
      }
    },
    {
      name: "vdate",
      label: "Valid Upto",
      options: {
        hint: "Date until Which Stock may be Available",
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          let vDateValue = moment(value).format("DD-MM-YYYY,h:mma")
          return (
            <Typography component="span">
              {vDateValue}
            </Typography>)
        }
      }
    },
    {
      name: "owners",
      label: "Owned By",
      options: {
        hint: "Internal RM Owning the Inventory",
        filter: false,
        sort: false,
      }
    },
    {
      name: "owners_code",
      label: "default",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: "",
      label: "Action",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          setType(type)
          return ownerId === tableMeta.rowData[8] || isAdmin === "ADMIN" ? (
            <span className={classes.editdeletbtn} >
              <IconButton className={classes.iconbtn} aria-label="edit" onClick={() => handleEditRequirement(tableMeta.rowData[0])}>
                <EditIcon />
              </IconButton>
              <IconButton className={classes.iconbtn} aria-label="delete"
                onClick={() => { setDeleteOpen(true); setDeleteId(tableMeta.rowData[0]) }}
              >
                <DeleteIcon />
              </IconButton>
            </span>
          ) : null;
        }
      }
    },
    {
      name: "last_updated",
      label: "Last Updated",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
  ]

  const availabilityColumn = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: "security_id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: "vendor_id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: "cdate",
      label: "Date",
      options: {
        hint: "Date on Which Availability was Added",
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          let cDateValue = moment(value).format("DD-MM-YYYY")
          return (
            <Typography component="span">
              {cDateValue}
            </Typography>)
        }
      }
    },
    {
      name: "security_name",
      label: "Scrips",
      options: {
        hint: "Name of the Scrips",
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <div className={classes.tableEllips} >
              <Typography
                variant='body2'
                style={{ textTransform: 'capitalize' }}
                component={LinkBtn}
                noWrap
                to={`/app/security-details/${tableMeta.rowData[1]}`}
                color="primary"
              >
                {value}
              </Typography>
            </div>
          )
        }
      }
    },
    // {
    //   name: "security_name",
    //   label: "Security",
    //   options: {
    //     filter: false,
    //     sort: false,
    //   }
    // },
    {
      name: "vendor",
      label: "Vendor",
      options: {
        hint: "Source Channel Partner who has the Inventory",
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <div className={classes.tableEllips} >
              <Typography
                variant='body2'
                style={{ textTransform: 'capitalize' }}
                component={LinkBtn}
                noWrap
                to={`/app/user-details/${tableMeta.rowData[2]}`}
                color="primary"
              >
                {value}
              </Typography>
            </div>
          )
        }
      }
    },
    {
      name: "price",
      label: "Price",
      options: {
        hint: "Potential Share Price",
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          const validPrice = parseFloat(value)
          return (
            <Typography component="span">
              {validPrice.toFixed(2)}
            </Typography>
          )
        }
      }
    },
    {
      name: "quantity",
      label: "Quantity",
      options: {
        hint: "Potential Inventory Stock",
        filter: false,
        sort: false,
      }
    },
    {
      name: "vdate",
      label: "Valid Upto",
      options: {
        hint: "Date until Which Stock may be Available",
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          let vDateValue = moment(value).format("DD-MM-YYYY,h:mma")
          return (
            <Typography component="span">
              {vDateValue}
            </Typography>)
        }
      }
    },
    {
      name: "owners",
      label: "Owned By",
      options: {
        hint: 'Internal RM who is tied to Channel Partner',
        filter: false,
        sort: false,
      }
    },
    {
      name: "owners_code",
      label: "Default",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: "",
      label: "Action",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return ownerId === tableMeta.rowData[10] || isAdmin === "ADMIN" ? (
            <span className={classes.editdeletbtn} >
              <IconButton className={classes.iconbtn} aria-label="edit" onClick={() => handleEditAvailibity(tableMeta.rowData[0])}>
                <EditIcon />
              </IconButton>
              <IconButton className={classes.iconbtn} aria-label="delete"
                onClick={() => { setDeleteOpen(true); setDeleteId(tableMeta.rowData[0]) }}
              >
                <DeleteIcon />
              </IconButton>
            </span>
          ) : null;
        }
      }
    },
    {
      name: "last_updated",
      label: "Last Updated",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
  ]

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    (
      <Zoom in={true}>
        <Root>
          <Page title="Unlistedkart | Additional-trade">
            <Typography variant='h6'  >Additional Trade</Typography>
            {
              value === 0 ?
                <Typography variant="body2" mb={2}>
                  Potential Scrips Available for UnlistedKart
                </Typography> :
                <Typography variant="body2" mb={2}>
                  Potential Scrips Required for UnlistedKart
                </Typography>
            }
            {/* <ServiceCard className={classes.card}> */}
            <ThemeProvider theme={getMuiTheme}>
              <ServiceCard className={classes.card}>
                <div style={{ position: 'relative' }}>
                  <div className={classes.bottomborder}>
                    <AppBar position="static">
                      <Tabs value={value} onChange={handleChange}
                        style={{
                          marginBottom: 24,
                          borderBottom: "1px solid #e8e8e8",
                        }} >
                        <Tab label="Availability" {...a11yProps(0)} />
                        <Tab label="Requirement" {...a11yProps(1)} />
                      </Tabs>
                    </AppBar>
                    {/* {
                value === 0 ?
                  <div>
                    <Button className={classes.addbutton} 
                      variant='contained'
                      color='primary'
                      onClick={() => { handleShow("AVAILABILITY") }}
                    >Add Availability
              </Button>
                    <Fab className={classes.fabbutton}
                      color="primary"
                      aria-label="add"
                      onClick={() => { handleShow("AVAILABILITY") }}
                    >
                      <AddIcon />
                    </Fab>
                  </div>
                  :
                  <div>
                    <Button className={classes.addbutton}
                      variant='contained'
                      color='primary'
                      onClick={() => { handleShow("REQUIREMENT") }}
                    >Add Requirement
              </Button>
                    <Fab className={classes.fabbutton}
                      color="primary"
                      aria-label="add"
                      onClick={() => { handleShow("REQUIREMENT") }}
                    >
                      <AddIcon />
                    </Fab>
                  </div>
              } */}
                  </div>
                  <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                  >
                    <TabPanel value={value} index={0}>
                      <ThemeProvider theme={getMuiTheme}>
                        <MUIDataTable
                          data={availabilityData}
                          columns={availabilityColumn}
                          options={{
                            search: false,
                            print: false,
                            pagination: false,
                            download: true,
                            viewColumns: false,
                            filter: false,
                            rowsPerPage: 5,
                            rowsPerPageOptions: [5, 10, 25],
                            // responsive: 'scrollMaxHeight',
                            selectableRows: "none",
                            sortOrder: {
                              name: 'last_updated',
                              direction: 'desc'
                            },
                            textLabels: {
                              body: {
                                noMatch: loading ?
                                  'Loading...' : 'Sorry, there is no matching data to display'
                              },
                            },
                          }}
                        />
                      </ThemeProvider>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <ThemeProvider theme={getMuiTheme}>
                        <MUIDataTable
                          data={requireMentData}
                          columns={requirmentColumn}
                          options={{
                            search: false,
                            print: false,
                            pagination: false,
                            download: true,
                            viewColumns: false,
                            filter: false,
                            rowsPerPage: 5,
                            rowsPerPageOptions: [25, 50, 75, 100],
                            selectableRows: "none",
                            sortOrder: {
                              name: 'last_updated',
                              direction: 'desc'
                            },
                            textLabels: {
                              body: {
                                noMatch: loading ?
                                  'Loading...' : 'Sorry, there are no matching data to display'
                              },
                            },

                          }}
                        />
                      </ThemeProvider>
                    </TabPanel>

                  </SwipeableViews>
                </div>
              </ServiceCard>
            </ThemeProvider>
            {/* </ServiceCard> */}

          </Page>
          <ModalCentered
            title={titleModal}
            open={show}
            toggle={handleShowClose}
            children={<AdditionalTradeCreateForm
              formSubmit={submitHandle}
              mode={formMode}
              type={type}
              id={formId}
            />}
          />
          <SnackBar
            open={open}
            severity={severity}
            close={handleClose}
            snackbarTitle={snackbarTitle}
          />
          <AlertModal
            title="Are you sure you want to delete this?"
            open={deleteOpen}
            setOpen={setDeleteOpen}
            toggle={() => setDeleteOpen(false)}
            onConfirm={() => handleDeleteTableRow()}
            openLoading={spinner}
          >
          </AlertModal>
          <SessionExpiredModal
            open={openSessionExpiredModal}

          />
        </Root>
      </Zoom>
    )
  );
}
// export default AdditionalTradeList

const mapStateToProps = state => {
  return ({
    force: state, // force state from reducer
    authReducer: state.authReducer,
  })
};

export default connect(mapStateToProps)(AdditionalTradeList);
