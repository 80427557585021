import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import NormalCard from "src/components/Cards/NormalCard";
import GetAppIcon from "@mui/icons-material/GetApp";
import {
  Typography,
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
  FormControl,
  Box,
  FormControlLabel,
  RadioGroup,
  Radio,
  Tooltip,
  Input,
  Checkbox,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { userService } from "src/_services/users";
import MUIDataTable from "mui-datatables";
import SnackBar from "src/components/SnackBar/SnackBar";
import ImageIcon from "@mui/icons-material/Image";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal";
import ReactSpinner from "src/components/ReactSpinner/ReactSpinner";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import VideocamIcon from "@mui/icons-material/Videocam";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import saveAs from "save-as";
import CircularProgress from "@mui/material/CircularProgress";
import * as Yup from "yup";
import { Formik } from "formik";
import ImageRotate from "./Rotate";
import { getLookUpDataService } from "src/_services/lookUp";
import GetMuiTheme from "src/components/theme";
const PREFIX = "KycAccountData";

const classes = {
  iconpdf: `${PREFIX}-iconpdf`,
  addButton: `${PREFIX}-addButton`,
  backdrop: `${PREFIX}-backdrop`,
  cursor: `${PREFIX}-cursor`,
  reactSpinner: `${PREFIX}-reactSpinner`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.iconpdf}`]: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(-1),
  },

  [`& .${classes.addButton}`]: {
    fontSize: "13px",
    marginLeft: "5px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "11px",
      padding: "4px",
    },
  },

  [`& .${classes.backdrop}`]: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  [`& .${classes.cursor}`]: {
    cursor: "pointer",
  },

  [`& .${classes.reactSpinner}`]: {
    position: "absolute",
    height: "100%",
    width: "100%",
    background: "#f4f6f870",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const theme = GetMuiTheme;

const KycAccountData = (props) => {
  const [loading, setLoading] = useState(false);
  const [severity, setSeverity] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [open, setOpen] = React.useState(false);
  const [bankData, setBankData] = React.useState();
  const [spinner, setSpinner] = useState(false);
  const [rejectId, setRejectId] = useState("");
  const [rejectValue, setRejectValue] = useState("");
  const [approvedStatus, setApprovedStatus] = useState(false);
  const [approveDialog, setApproveDialog] = useState(false);
  const kycId = "";
  const [upload, setUpload] = useState(false);
  const [localFilesChoosen, setLocalFilechoosen] = React.useState();
  const [otherValue, setOtherValue] = useState("");
  const documentId = 0;
  const [approve, setApprove] = useState(false);
  const [bank, setBank] = useState(false);
  const [backdrop, setBackDrop] = useState(false);

  const requestId = props?.requestId;
  const [view, setView] = useState({
    file: false,
    url: "",
    otp: "",
    video: false,
    hasPassword: "",
    password: "",
  });
  const [rejectDialog, setRejectDialog] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(
    false
  );
  const documentName = null;
  const [hover, setHover] = useState(false);
  const [kycData, setKYCData] = useState([]);
  const ifscCodePattern = RegExp(/[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/);
  const kycDetailsHeading = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "display_name",
      label: "Document Name",
    },
    {
      name: "user_kyc_data",
      label: "Document Copy",
      options: {
        // hint: "Client Master List that has information of the Demat account with the Broker",
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const format = tableMeta.rowIndex;
          return tableMeta.rowData[2].length === 0 ? (
            <Typography style={{ marginLeft: "2rem" }}>-</Typography>
          ) : format === 4 ? (
            <Typography
              variant="body2"
              component={"a"}
              noWrap={false}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOpen(true);
                const data = JSON.parse(value);
                setBankData(data);
              }}
              color="primary"
              // Download
              target="_blank"
            >
              <AccountBalanceIcon className={classes.iconpdf} size="small" />
              View
            </Typography>
          ) : format === 3 ? (
            <></>
          ) : (
            <Typography
              style={{ maxWidth: 300, cursor: "pointer" }}
              variant="body2"
              component={"a"}
              noWrap={false}
              // href={`${value}`}
              color="primary"
              // Download
              target="_blank"
              onClick={async () => {
                if (format === 7) {
                  setView({
                    ...view,
                    url: value,
                    file: true,
                    video: true,
                  });
                } else {
                  if (format === 0 && value.charAt(0) === "{") {
                    const data = JSON.parse(value);
                    const url = data.fileUrl?.split("amazonaws.com/");
                    const FileName = url?.length > 1 ? url[1] : data.fileUrl;
                    const getUrl = await getSignedUrl(FileName);
                    setView({
                      ...view,
                      url: getUrl,
                      file: true,
                    });
                  } else {
                    const url = value.split("amazonaws.com/");
                    const FileName = url.length > 1 ? url[1] : value;
                    const getUrl = await getSignedUrl(FileName);
                    setView({
                      ...view,
                      url: getUrl,
                      file: true,
                      hasPassword: tableMeta.rowData[4] === "1" ? true : false,
                      password: tableMeta.rowData[5],
                    });
                  }
                }
              }}
            >
              {format === 7 ? (
                <VideocamIcon className={classes.iconpdf} size="small" />
              ) : (
                <ImageIcon className={classes.iconpdf} size="small" />
              )}
              View
            </Typography>
          );
        },
      },
    },
    {
      name: "document_verification_status",
      label: "Verification Status",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const format = tableMeta.rowData[2];
          return format.length === 0 ||
            tableMeta.rowIndex === 1 ||
            tableMeta.rowIndex === 2 ||
            tableMeta.rowIndex === 5 ? (
            <Typography style={{ marginLeft: "2rem" }}>-</Typography>
          ) : value === "1" ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <CheckCircleIcon style={{ color: "green" }} />
              <span> Approved</span>
            </div>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <CancelIcon style={{ color: "red" }} />
              <span> Rejected</span>
            </div>
          );
        },
      },
    },
    {
      name: "hasPassword",
      label: "Has Password",
      options: {
        display: false,
      },
    },
    {
      name: "password",
      label: "Password",
      options: {
        display: false,
      },
    },
  ];

  const getSignedUrl = (url) => {
    setBackDrop(true);
    return getLookUpDataService.getKycUrl(url).then((res) => {
      setBackDrop(false);
      return res.data.data;
    });
  };

  const updateApprovalStatus = (id, status) => {
    setSpinner(true);
    const data = {
      id: id,
      kycStatus: status,
      from: "service",
      rejectedReason: otherValue ? otherValue : rejectValue,
    };
    try {
      userService.updateKYCStatus(data).then((res) => {
        if (res) {
          if (
            res.data.message ===
            "Something Went Wrong While Performing Get User Kyc Status"
          ) {
            setSpinner(false);
            setSeverity("error");
            setSnackbarTitle("Something went wrong, please try again");
            setOpenSnackBar(true);
            handleCloseDialog();
            setRejectValue("");
            getKYCDetails();
          } else if (res.status === 200) {
            setSpinner(false);
            setSeverity("success");
            setSnackbarTitle(status === 1 ? "Approved" : "Rejected");
            setOpenSnackBar(true);
            setRejectValue("");
            handleCloseDialog();
            getKYCDetails();
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setSpinner(false);
              handleCloseDialog();
              setRejectValue("");
              setOpenSessionExpiredModal(true);
              setSeverity("error");
              setSnackbarTitle("Something went wrong");
              setOpenSnackBar(true);
            }
          }
        }
      });
    } catch {
      handleCloseDialog();
      setRejectValue("");
      setOpenSessionExpiredModal(true);
      setSeverity("error");
      setSnackbarTitle("Something went wrong");
      setOpenSnackBar(true);
    }
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setOtherValue("");
    setUpload(false);
    setRejectDialog(false);
    setApprovedStatus(false);
    setApproveDialog(false);
    setView({
      ...view,
      file: false,
      url: "",
      video: false,
      hasPassword: "",
      password: "",
    });
    setRejectValue("");
    setRejectId("");
    setBank(false);
    setApprove(false);
    setLocalFilechoosen();
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  const getKYCDetails = () => {
    setSpinner(true);
    setLoading(true);
    const data = {
      userid: props.userID,
    };
    userService.getKYCStatus(data).then((res) => {
      setLoading(false);
      if (res) {
        setOtherValue("");
        if (res.status === 200) {
          setSpinner(false);
          setView({
            ...view,
            otp: res.data.data[2].otp,
          });
          setKYCData(res.data.data);
        } else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenSessionExpiredModal(true);
            setSpinner(true);
          }
        }
      } else {
        setSpinner(false);
        setSeverity("error");
        setSnackbarTitle("Something went wrong");
        setOpenSnackBar(true);
      }
    });
  };

  const apiResponse = (isLoading, error, message, visibility) => {
    setSpinner(isLoading);
    setSeverity(error);
    setSnackbarTitle(message);
    setOpenSnackBar(visibility);
    setLocalFilechoosen();
  };

  async function fileUpload() {
    if (localFilesChoosen !== undefined) {
      setSpinner(true);
      var infoArea = document.getElementById("file-upload-filename");
      infoArea.textContent = "";
      const formData = new FormData();
      formData.append("kyc_copy", localFilesChoosen);
      formData.append("id_type", documentId);
      formData.append("request_id", requestId);
      try {
        let data = await userService.idVerification(formData, props.userID);
        if (data.status === 200) {
          setApprove(false);
          // if (approve) {
          const data = {
            id: rejectId,
            kycStatus: approve ? 1 : 0,
            from: "service",
          };
          userService.updateKYCStatus(data).then((res) => {
            if (res) {
              if (
                res.data.message ===
                "Something Went Wrong While Performing Get User Kyc Status"
              ) {
                apiResponse(
                  false,
                  "error",
                  "Something went wrong, please try again",
                  true
                );
              } else if (res.status === 200) {
                apiResponse(false, "success", "Successfully updated", true);
                getKYCDetails();
              } else {
                if (
                  res.status === 401 &&
                  res.data.name === "TokenExpiredError"
                ) {
                  apiResponse(
                    false,
                    "error",
                    "Something went wrong, please try again",
                    true
                  );
                }
              }
            }
          });
          // } else {
          //   apiResponse(false, "success", "Successfully updated", true);
          // }
          apiResponse(false, "success", "Successfully updated", true);
          setUpload(false);
        } else if (
          data.status === 401 &&
          data.data.name === "TokenExpiredError"
        ) {
          setOpenSessionExpiredModal(true);
        } else {
          setApprove(false);
          apiResponse(false, "error", "Invalid or unclear image", true);
        }
      } catch {
        setApprove(false);
        apiResponse(
          false,
          "error",
          "Something went wrong, please try again",
          true
        );
      }
    } else {
      apiResponse(false, "error", "Please select the document", true);
    }
  }
  // const approveSignature = async () => {
  //   let data = await userService.updateKycSignatureStauts(props.userID, 1, 1);
  //   if (data.data.success) {
  //     setOpen(false);
  //     getKYCDetails();
  //   }
  // };

  // const rejectSignature = async () => {
  //   let data = await userService.updateKycSignatureStauts(props.userID, 0, 0);
  //   if (data.data.success) {
  //     setOpen(false);
  //     getKYCDetails();
  //   }
  // };
  const downloadAll = () => {
    let urls = [];
    kycData.map(async (docUrl) => {
      const url =
        docUrl.user_kyc_data && docUrl.user_kyc_data.toString().charAt(0);
      if (url === "h") {
        urls.push(docUrl.user_kyc_data);
      }
    });
    const zip = new JSZip();
    let count = 0;
    const zipFilename = `${props.userID}_${props.userName}.zip`;
    urls.forEach(async function(url) {
      const urlArr = url.split("/");
      const filename = urlArr[urlArr.length - 1];
      try {
        const file = await JSZipUtils.getBinaryContent(url);
        zip.file(filename, file, { binary: true });
        count++;
        if (count === urls.length) {
          zip.generateAsync({ type: "blob" }).then(function(content) {
            saveAs(content, zipFilename);
          });
        }
      } catch (err) {
        console.log(err);
      }
    });
  };
  async function filechoose(event) {
    const file = event.target.files[0];
    const url = file && URL.createObjectURL(event.target.files[0]);
    file && setImageUrl(url);
    setLocalFilechoosen(file);
    const selectedFileName = file && file.name;
    var infoArea = document.getElementById("file-upload-filename");
    infoArea.textContent = selectedFileName;
  }

  async function updateBank(values) {
    setSpinner(true);
    try {
      let data = await userService.pennyDropVerification(values, props.userID);
      if (data.status === 200) {
        setApprove(false);
        setOpen(false);
        setBank(false);
        // if (approve) {
        const data = {
          id: rejectId,
          kycStatus: approve ? 1 : 0,
          from: "service",
        };
        userService.updateKYCStatus(data).then((res) => {
          if (res) {
            if (
              res.data.message ===
              "Something Went Wrong While Performing Get User Kyc Status"
            ) {
              apiResponse(
                false,
                "error",
                "Something went wrong, please try again",
                true
              );
            } else if (res.status === 200) {
              apiResponse(false, "success", "Successfully updated", true);
              getKYCDetails();
            } else {
              if (res.status === 401 && res.data.name === "TokenExpiredError") {
                apiResponse(
                  false,
                  "error",
                  "Something went wrong, please try again",
                  true
                );
              }
            }
          }
        });
        // } else {
        //   apiResponse(false, "success", "Successfully updated", true);
        //   getKYCDetails();
        // }
      } else {
        if (data.status === 401 && data.data.name === "TokenExpiredError") {
          setOpenSessionExpiredModal(true);
        } else {
          apiResponse(
            false,
            "error",
            data.data.message !== ""
              ? data.data.message
              : "Entered data is invalid",
            true
          );
        }
      }
    } catch {
      setOpen(false);
      setBank(false);
      setApprove(false);
      apiResponse(
        false,
        "error",
        "Something went wrong, please try again",
        true
      );
    }
  }

  // const editButton = () => {
  //   navigate("/app/user-create?mode=edit", {
  //     replace: true,
  //     state: {
  //       userID: props.userID,
  //       userName: props.userName,
  //       requestId: requestId,
  //     },
  //   });
  // };

  useEffect(() => {
    if (props.userID !== null) {
      getKYCDetails();
    }
  }, [props.userID]);
  const type = view.url ? view.url.toString().charAt(0) : "";
  return (
    <Root>
      <NormalCard
        title="KYC Document Details"
        className={classes.cardroot}
        btn={
          <div style={{ display: "flex" }}>
            <Button
              color="primary"
              onClick={downloadAll}
              size="medium"
              type="submit"
              variant="outlined"
              className={classes.addButton}
              name="addDemat"
              value="addDemat"
            >
              <GetAppIcon />
              Download
            </Button>
            {/* {!props.edit && (
              <Button
                color="primary"
                onClick={editButton}
                size="medium"
                type="submit"
                variant="outlined"
                className={classes.addButton}
                name="addDemat"
                value="addDemat"
              >
                <EditIcon />
                Edit
              </Button>
            )} */}
          </div>
        }
      >
        {spinner && (
          <div className={classes.reactSpinner}>
            <ReactSpinner />
          </div>
        )}
        <ThemeProvider theme={theme}>
          <MUIDataTable
            data={kycData}
            columns={kycDetailsHeading}
            sx={{ padding: "1rem" }}
            options={{
              download: true,
              viewColumns: false,
              filter: false,
              pagination: false,
              search: false,
              print: false,
              rowsPerPage: 5,
              rowsPerPageOptions: [5, 10, 25],
              selectableRows: "none",
              // display: props.display,
              textLabels: {
                body: {
                  noMatch: loading
                    ? "Loading..."
                    : "Sorry, there are no matching data to display",
                },
              },
            }}
          />
        </ThemeProvider>
      </NormalCard>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
      >
        <Formik
          enableReinitialize={true}
          initialValues={bankData}
          validationSchema={Yup.object().shape({
            accountName: Yup.string()
              .nullable()
              .required("Required"),
            accountNumber: Yup.string()
              .nullable()
              .matches(/^[0-9]+$/, "Must be only digits")
              .min(8, "Must be atleast 8 numbers")
              .required("Required"),
            ifsc: Yup.string()
              .nullable()
              .matches(ifscCodePattern, "Enter Valid IFSC Code")
              .required("Required"),
          })}
          onSubmit={(values) => {
            updateBank(values);
          }}
          // onSubmit={(values, { setSubmitting }) => {
          //   setTimeout(() => {
          //     alert(JSON.stringify(values, null, 2));
          //     setSubmitting(false);
          //   }, 400);
          // }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogTitle id="form-dialog-title">
                {bank ? "Update bank details" : "Bank details "}
              </DialogTitle>
              <DialogContent>
                <TextField
                  margin="dense"
                  label="Name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="accountName"
                  value={values && values.accountName}
                  onChange={bank && handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.accountName && errors.accountName)}
                  helperText={touched.accountName && errors.accountName}
                />
                <TextField
                  margin="dense"
                  label="Account Number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="accountNumber"
                  value={values && values.accountNumber}
                  onChange={bank && handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.accountNumber && errors.accountNumber)}
                  helperText={touched.accountNumber && errors.accountNumber}
                />
                <TextField
                  margin="dense"
                  label="IFSC Code"
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="ifsc"
                  onChange={bank && handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.ifsc && errors.ifsc)}
                  helperText={touched.ifsc && errors.ifsc}
                  value={values && values.ifsc && values.ifsc.toUpperCase()}
                />
                {bank && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={approve}
                        onChange={() => {
                          if (approve === false) {
                            setApprove(true);
                          } else {
                            setApprove(false);
                          }
                        }}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Auto approve"
                  />
                )}
              </DialogContent>

              <DialogActions>
                {bank && (
                  <Button
                    disabled={spinner}
                    type="submit"
                    color="primary"
                    variant="contained"
                    style={{ textTransform: "none" }}
                  >
                    {spinner ? <CircularProgress size={25} /> : "Update"}
                  </Button>
                )}
                <Button
                  onClick={handleCloseDialog}
                  color="primary"
                  variant="contained"
                  style={{ textTransform: "none" }}
                >
                  Close
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
      <Dialog
        open={rejectDialog}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        {/* <DialogTitle id="form-dialog-title" >Reject Reason</DialogTitle> */}
        <DialogContent>
          <FormControl component="fieldset">
            {approvedStatus && (
              <div style={{ textAlign: "center" }}>
                <Typography style={{ color: "green", fontSize: "1.3rem" }}>
                  {documentName} has been approved
                </Typography>
                <Typography>Or</Typography>
                <Typography>
                  If you want to reject, Select any of the below reasons.
                </Typography>
                <br />
              </div>
            )}
            <h4 style={{ margin: "4px 0" }}>Reject Reason</h4>

            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={rejectValue}
              onChange={(e) => {
                setRejectValue(e.target.value);
                setOtherValue("");
              }}
            >
              {kycId === 5 ? (
                <>
                  <FormControlLabel
                    value="Video quality"
                    control={<Radio />}
                    label="Video quality"
                  />
                  <FormControlLabel
                    value="Wrong OTP"
                    control={<Radio />}
                    label="Wrong OTP"
                  />
                </>
              ) : kycId !== 4 ? (
                <>
                  <FormControlLabel
                    value="Image quality"
                    control={<Radio />}
                    label="Image quality"
                  />
                  <FormControlLabel
                    value="Wrong image"
                    control={<Radio />}
                    label="Wrong image"
                  />
                </>
              ) : (
                <FormControlLabel
                  value="Invalid details"
                  control={<Radio />}
                  label="Invalid details"
                />
              )}
              <FormControlLabel
                value="Others"
                control={<Radio />}
                label="Others"
              />
              {rejectValue === "Others" && (
                <TextField
                  multiline
                  value={otherValue}
                  error={otherValue === ""}
                  helperText={otherValue === "" && "Please enter the reason"}
                  onChange={(e) => setOtherValue(e.target.value)}
                ></TextField>
              )}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="primary"
            variant="contained"
            style={{ textTransform: "none" }}
          >
            Cancel
          </Button>
          <Button
            disabled={
              rejectValue === "" ||
              (rejectValue === "Others" && otherValue === "")
            }
            onClick={() => {
              updateApprovalStatus(rejectId, 2);
            }}
            style={{ textTransform: "none" }}
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        disableScrollLock
        open={view.file}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
      >
        {/* <DialogTitle id="form-dialog-title">Bank Details</DialogTitle> */}
        <DialogContent>
          {view.video ? (
            <div>
              <Typography style={{ textAlign: "center" }} variant="h4">
                OTP : {view.otp}
              </Typography>

              <video src={view.url} controls width="500" />
            </div>
          ) : type === "{" ? (
            <div>
              {JSON.parse(view.url).fileUrl?.includes("http") ? (
                <>
                  <img alt="" src={JSON.parse(view.url).fileUrl} width="645" />
                </>
              ) : (
                <>
                  <Typography>
                    {" "}
                    <Typography>Image not found</Typography>
                  </Typography>
                </>
              )}
            </div>
          ) : view.url?.includes(".pdf") ? (
            <>
              {view.hasPassword && (
                <div>
                  {" "}
                  <Typography
                    style={{ textAlign: "center", marginBottom: "10px" }}
                    variant="h4"
                  >
                    Password :
                    <Tooltip
                      style={{ cursor: "copy" }}
                      title={hover ? "Copied" : "Click to Copy"}
                    >
                      <span
                        onClick={() => {
                          setHover(true);
                          navigator.clipboard.writeText(view.password);
                        }}
                        onMouseLeave={() => setHover(false)}
                      >
                        {view.password}
                      </span>
                    </Tooltip>
                  </Typography>
                </div>
              )}
              <embed src={view.url} width="500" height="700"></embed>
            </>
          ) : (
            <div style={{ display: "block", justifyContent: "center" }}>
              {/* <image src={url}></image> */}
              <div
                style={{
                  width: 600,
                  position: "relative",
                  // overflow: "hidden"
                }}
              >
                <ImageRotate alt="cool image" image={view.url} />
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="primary"
            variant="contained"
            style={{ textTransform: "none" }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={approveDialog}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
      >
        {/* <DialogTitle id="form-dialog-title">Bank Details</DialogTitle> */}
        <DialogContent>
          <div style={{ textAlign: "center" }}>
            <Typography style={{ color: "red", fontSize: "1.3rem" }}>
              {documentName} has been Rejected
            </Typography>
            <Typography>Or</Typography>
            <Typography>
              If you want to approve, Click on approve button
            </Typography>
            <br />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => updateApprovalStatus(rejectId, 1)}
            color="primary"
            style={{ textTransform: "none" }}
            variant="contained"
          >
            Approve
          </Button>
          <Button
            onClick={handleCloseDialog}
            color="primary"
            style={{ textTransform: "none" }}
            variant="contained"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={upload}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
      >
        {/* <DialogTitle id="form-dialog-title">Bank Details</DialogTitle> */}
        <DialogContent>
          <Box style={{ border: "1px solid" }}>
            {documentId === 6 ? (
              <video
                style={{ marginBottom: "-4px" }}
                src={imageUrl}
                controls
                width={400}
                height={500}
              />
            ) : (
              <img
              alt=""
                style={{ marginBottom: "-4px" }}
                src={imageUrl}
                width={400}
                height={500}
              />
            )}
          </Box>
          <Box style={{ margin: "auto 1rem" }}>
            <Box m={3} className={classes.upload}>
              <Tooltip title="Upload from your device">
                <label htmlFor="billUpload" onChange={filechoose}>
                  <Input
                    style={{ display: "none" }}
                    fullWidth
                    type="file"
                    inputProps={{
                      accept: documentId === 6 ? "video/*" : "image/*",
                    }}
                    name="Uploadbill"
                    id="billUpload"
                  />
                  <Button
                    id="09"
                    name="billButton"
                    sx={{
                      mt: 1,
                      width: "80px",
                    }}
                    component="span"
                    fullWidth
                  >
                    <AttachFileIcon style={{ color: "#324C90" }} />
                    <Typography
                      style={{ color: "#324C90", textTransform: "none" }}
                    >
                      {" "}
                      Attach {documentName}
                    </Typography>
                  </Button>
                </label>
              </Tooltip>
            </Box>
            <Box
              m={3}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <div
                id="file-upload-filename"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "190px",
                }}
              ></div>
            </Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={approve}
                  onChange={() => {
                    if (approve === false) {
                      setApprove(true);
                    } else {
                      setApprove(false);
                    }
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Auto approve"
            />
          </Box>
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            onClick={() => fileUpload()}
            color="primary"
            style={{ textTransform: "none" }}
          >
            {spinner ? (
              <CircularProgress size={25} style={{ color: "#fff" }} />
            ) : (
              "Upload"
            )}
          </Button>
          <Button
            variant="contained"
            onClick={handleCloseDialog}
            color="primary"
            style={{ textTransform: "none" }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop className={classes.backdrop} open={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <SnackBar
        open={openSnackBar}
        severity={severity}
        close={handleClose}
        snackbarTitle={snackbarTitle}
      />

      <SessionExpiredModal open={openSessionExpiredModal} />
    </Root>
  );
};

export default KycAccountData;
