import axios from 'axios'
import { helpers } from 'src/_helpers'


export const ProcurementService = {
    getProcurement,
    postProcurement,
    getProcureBySecurityID,
    deleteProcureBySecurityID,
    putProcureStatus
}






function getProcurement() {
    
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/getprocurement`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}


function postProcurement(postProcurementData) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.post(`/procurement`,
        JSON.stringify(postProcurementData), requestOptions)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}



function getProcureBySecurityID(id,role) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.get(`/get_procurement_by_security_id/${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}



function deleteProcureBySecurityID(id) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.delete(`/procurement/${id}`, requestOptions)
        .then(data => data)
        .catch(error => error.response)
}



function putProcureStatus(putdata) {
    helpers.setHeader();
    const requestOptions = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return axios.put(`/procurement_update_status`,
        JSON.stringify(putdata), requestOptions)
        .then(data => {
            return data
        })
        .catch(error => {
            return error.response
        })
}