import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles';
import MUIDataTable from "mui-datatables";
import {  Typography, Box, AppBar, Tabs, Tab } from '@mui/material';

import { ThemeProvider, useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { NavLink } from 'react-router-dom';
import ServiceCard from 'src/components/Cards/ServiceCard'
import clsx from 'clsx';
import moment from 'moment'
import PropTypes from 'prop-types';
import { serviceOrderService } from "src/_services/serviceOrder"
import Page from 'src/components/Page';
import Chip from '@mui/material/Chip';
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal"
import GetMuiTheme from 'src/components/theme';

const PREFIX = 'InvestorClientServiceOrderList';

const classes = {
    root: `${PREFIX}-root`,
    card: `${PREFIX}-card`,
    btnright: `${PREFIX}-btnright`,
    container: `${PREFIX}-container`,
    button: `${PREFIX}-button`,
    editdeletbtn: `${PREFIX}-editdeletbtn`,
    btnsdltedt: `${PREFIX}-btnsdltedt`,
    detailsbtn: `${PREFIX}-detailsbtn`,
    active: `${PREFIX}-active`,
    inactive: `${PREFIX}-inactive`,
    closed: `${PREFIX}-closed`,
    processing: `${PREFIX}-processing`,
    completed: `${PREFIX}-completed`,
    bottomborder: `${PREFIX}-bottomborder`,
    subtitle: `${PREFIX}-subtitle`,
    stageOneProcessing: `${PREFIX}-stageOneProcessing`,
    stageOneProcessed: `${PREFIX}-stageOneProcessed`,
    stageTwoProcessed: `${PREFIX}-stageTwoProcessed`,
    stageTwoProcessing: `${PREFIX}-stageTwoProcessing`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.root}`]: {
        
        margin: theme.spacing(2),
        '& .MuiTableHead-root': {
            background: 'blue'
        }
    },

    [`& .${classes.card}`]: {
        borderBottom: "none", padding: 0
    },

    [`& .${classes.btnright}`]: {
        textAlign: 'end'
    },

    [`& .${classes.container}`]: {
        padding: '0px'
    },

    [`& .${classes.button}`]: {
        marginBottom: '1%',
        borderRadius: '14px 14px 0 0',
        boxShadow: 'none',
        textTransform: 'capitalize'
    },

    [`& .${classes.editdeletbtn}`]: {
        color: '#3363cb'
    },

    [`& .${classes.btnsdltedt}`]: {
        marginRight: theme.spacing(1)
    },

    [`& .${classes.detailsbtn}`]: {
        boxShadow: 'none',
        textTransform: 'capitalize',
        borderRadius: '0px 20px 20px 0px'
    },

    [`& .${classes.active}`]: {
        color: 'white',
        backgroundColor: "#00C914"
    },

    [`& .${classes.inactive}`]: {
        color: 'white',
        backgroundColor: "#D50000"
    },

    [`& .${classes.closed}`]: {
        color: 'white',
        backgroundColor: "#314B8F"
    },

    [`& .${classes.processing}`]: {
        color: 'white',
        backgroundColor: "#A48800"
    },

    [`& .${classes.completed}`]: {
        color: 'white',
        backgroundColor: "#A40094"
    },

    [`& .${classes.bottomborder}`]: {
        backgroundColor: '#fff',
        borderBottom: '1px solid #e8e8e8',
    },

    [`& .${classes.subtitle}`]: {
        paddingLeft:'16px'
    },
    [`& .${classes.stageOneProcessing}`]: {
        color: "white",
        backgroundColor: "#87C4FF",
        fontWeight: "bold",
      },
    
      [`& .${classes.stageOneProcessed}`]: {
        color: "white",
        backgroundColor: "#39A7FF",
        fontWeight: "bold",
      },
    
      [`& .${classes.stageTwoProcessed}`]: {
        color: "white",
        backgroundColor: "#427D9D",
        fontWeight: "bold",
      },
    
      [`& .${classes.stageTwoProcessing}`]: {
        color: "white",
        backgroundColor: "#9BBEC8",
        fontWeight: "bold",
      },
}));

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) { // eslint-disable-line
    return <NavLink to={props.to} {...props}  />; // eslint-disable-line
});



function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const InvestorClientServiceOrderList = () => {

    const theme = useTheme();
    const [sellOrderData, setSellOrderData] = React.useState([])
    const [buyOrderData, setBuyOrderData] = React.useState([])
    const [activeTab, setActiveTab] = React.useState(0);
    const [btnSwitch, setbtnSwitch] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)


    
    const getMuiTheme = GetMuiTheme

    const sellColumnData = [
        {
            name: "_id",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display: false
            }
        },
        {
            name: "service_id",
            label: "Order Id",
            options: {
                hint:'Unique Order Number',
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography
                            variant='body2'
                            component={LinkBtn}
                            noWrap={false}
                            to={`/app/investor-client-service-order-details/${tableMeta.rowData[0]}`}
                            color="primary"
                        >
                            {value}
                        </Typography>
                    )
                }
            }
        },
        {
            name: "create_datetime",
            label: "Created Date",
            options: {
                hint: 'Date of Transaction Initiation',
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let valueMoment = moment(value).format("DD-MM-YYYY LTS")
                    return (
                        <div className={classes.tableEllips} >
                            <Typography
                                variant='body2'
                                noWrap
                            >
                                {valueMoment}
                            </Typography>
                        </div>
                    )
                }
            }
        },

        // {
        //     name: "order_type_code",
        //     label: "Type",
        //     options: {
        //         filter: false,
        //         sort: false,
        //     }
        // },
        
        
        
        {
            name: "order_status_code",
            label: "Status",
            options: {
                hint: 'Transaction Service Progress',
                filter: true,
                sort: true,
                customBodyRender: (value) => {

                    return (
                        <Typography

                        >
                            <Chip size="small" label={value} className={clsx({
                                [classes.active]: value === "ACTIVE",
                                [classes.inactive]: value === "INACTIVE",
                                [classes.processing]: value === "PROCESSING",
                                [classes.completed]: value === "COMPLETED",
                                [classes.closed]: value === "CLOSED",
                                [classes.stageOneProcessing]: value === "STAGE_ONE_PROCESSING",
                                [classes.stageOneProcessed]: value === "STAGE_ONE_PROCESSED",
                                [classes.stageTwoProcessing]: value === "STAGE_TWO_PROCESSING",
                                [classes.stageTwoProcessed]: value === "STAGE_TWO_PROCESSED",
                            })} />
                        </Typography>
                    )
                }
            }
        },
        {
            name: "lead_owner",
            label: "Lead Owner",
            options: {
                hint:'Internal RM Owning Order',
                filter: false,
                sort: true,
            }
        },
        
    ];



    const buyColumnData = [
        {
            name: "_id",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display: false
            }
        },
        {
            name: "service_id",
            label: "Order Id",
            options: {
                hint: 'Unique Order Number',
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography
                            variant='body2'
                            component={LinkBtn}
                            noWrap={false}
                            to={`/app/investor-client-service-order-details/${tableMeta.rowData[0]}`}
                            color="primary"
                        >
                            {value}
                        </Typography>
                    )
                }
            }
        },
        {
            name: "create_datetime",
            label: "Created Date",
            options: {
                hint: 'Date of Transaction Initiation',
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    let valueMoment = moment(value).format("DD-MM-YYYY LTS")
                    return (
                        <div className={classes.tableEllips} >
                            <Typography
                                variant='body2'
                                noWrap
                            >
                                {valueMoment}
                            </Typography>
                        </div>
                    )
                }
            }
        },

        // {
        //     name: "order_type_code",
        //     label: "Type",
        //     options: {
        //         filter: false,
        //         sort: false,
        //     }
        // },
        
        
        {
            name: "order_status_code",
            label: "Status",
            options: {
                hint: 'Transaction Service Progress',
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {

                    return (
                        <Typography

                        >
                            <Chip size="small" label={value} className={clsx({
                                [classes.active]: value === "ACTIVE",
                                [classes.inactive]: value === "INACTIVE",
                                [classes.processing]: value === "PROCESSING",
                                [classes.completed]: value === "COMPLETED",
                                [classes.closed]: value === "CLOSED",
                                [classes.stageOneProcessing]: value === "STAGE_ONE_PROCESSING",
                                [classes.stageOneProcessed]: value === "STAGE_ONE_PROCESSED",
                                [classes.stageTwoProcessing]: value === "STAGE_TWO_PROCESSING",
                                [classes.stageTwoProcessed]: value === "STAGE_TWO_PROCESSED",
                            })} />
                        </Typography>
                    )
                }
            }
        },
        {
            name: "lead_owner",
            label: "Lead Owner",
            options: {
                hint:'Internal RM Owning Order',
                filter: false,
                sort: true,
            }
        },
        
    ];



    const getServiceOrder = () => {
        setLoading(true)
        serviceOrderService.getServiceOrder()
            .then(res => {
                if (res) {
                   
                    if (res.status === 200) {
                        const sellData = []
                        const buyData = []

                        res.data.forEach(item => {
                            if (item.order_type === "Sell") {
                                sellData.push(item)
                            } else if (item.order_type === "Buy") {
                                buyData.push(item)
                            }
                        })


                        setSellOrderData(sellData);
                        setBuyOrderData(buyData)
                        setLoading(false)


                    }else{
                        if (res.status === 401 && res.data.name === "TokenExpiredError") {
                            setOpenSessionExpiredModal(true)
                        }
                    }

                }
            }).catch(error => console.log(error))
    }


    useEffect(() => {
        getServiceOrder()
    }, [])


    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
        setbtnSwitch(!btnSwitch)
    };

    const handleChangeIndex = (index) => {
        setActiveTab(index);
    };



    return (
        <Root>
            <Page title="Unlistedkart | Order">
                <div className={classes.container}>
                    

                    <ServiceCard className={classes.card}>
                        <ThemeProvider theme={getMuiTheme}>
                            <div style={{ position: 'relative' }}>
                                <div className={classes.bottomborder}>
                                    <AppBar position="static">
                                        <Tabs value={activeTab} onChange={handleChange} style={{ marginBottom: 24, borderBottom: '1px solid #e8e8e8', }} >
                                            <Tab label="Sell Orders" {...a11yProps(0)} />
                                            <Tab label="Buy Orders" {...a11yProps(1)} />
                                        </Tabs>
                                    </AppBar>

                                </div>
                            </div>
                            <SwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={activeTab}
                                onChangeIndex={handleChangeIndex}
                            >
                                <TabPanel value={activeTab} index={0}>
                                    <ThemeProvider theme={getMuiTheme()}>
                                        <MUIDataTable

                                            data={sellOrderData}
                                            headerSort={false}
                                            columns={sellColumnData}
                                            options={{
                                                viewColumns: false,
                                                download: true,
                                                print: false,                            
                                                rowsPerPage: 10,
                                                rowsPerPageOptions: [5, 10, 25],
                                                // responsive: 'scrollMaxHeight',
                                                selectableRows: "none",
                                                sortOrder: {
                                                    name: 'create_datetime',
                                                    direction: 'desc'
                                                },
                                                textLabels: {
                                                    body: {
                                                        noMatch: loading ?
                                                            'Loading...' : 'Sorry, there are no matching data to display'
                                                    },
                                                },

                                            }}
                                        />
                                    </ThemeProvider>
                                </TabPanel>
                                <TabPanel value={activeTab} index={1}>
                                    <ThemeProvider theme={getMuiTheme()}>
                                        <MUIDataTable

                                            data={buyOrderData}
                                            headerSort={false}
                                            columns={buyColumnData}
                                            options={{
                                                viewColumns: false,
                                                download: true,
                                                print: false,                            
                                                rowsPerPage: 10,
                                                rowsPerPageOptions: [5, 10, 25],
                                                // responsive: 'scrollMaxHeight',
                                                selectableRows: "none",
                                                sortOrder: {
                                                    name: 'create_datetime',
                                                    direction: 'desc'
                                                },
                                                textLabels: {
                                                    body: {
                                                        noMatch: loading ?
                                                            'Loading...' : 'Sorry, there is no matching data to display'
                                                    },
                                                },

                                            }}
                                        />
                                    </ThemeProvider>
                                </TabPanel>
                            </SwipeableViews>
                        </ThemeProvider>
                    </ServiceCard>
                </div>
            </Page>
            <SessionExpiredModal
                open={openSessionExpiredModal}
            />
        </Root>
    );

}
export default InvestorClientServiceOrderList
