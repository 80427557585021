import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import ErrorFocus from "../../components/ErrorFocus/ErrorFocus";
import {
  Container,
  TextField,
  FormControlLabel,
  FormHelperText,
  Grid,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import { inventoryPriceService } from 'src/_services/inventoryPrice'
import loadingSpinner from 'src/images/loading-spinner.gif'
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal"
import Switch from '@mui/material/Switch';
import compareJSON from "src/components/comparision";

const PREFIX = 'InventoryPriceForm';

const classes = {
  switchBase: `${PREFIX}-switchBase`,
  checked: `${PREFIX}-checked`,
  track: `${PREFIX}-track`,
  zeroPadding: `${PREFIX}-zeroPadding`,
  cardBorderRadius: `${PREFIX}-cardBorderRadius`,
  cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
  fileInput: `${PREFIX}-fileInput`,
  imageSizes: `${PREFIX}-imageSizes`,
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  marginInputLabel: `${PREFIX}-marginInputLabel`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  resendBtnMargin: `${PREFIX}-resendBtnMargin`,
  inputMargin: `${PREFIX}-inputMargin`,
  paddingInput: `${PREFIX}-paddingInput`,
  buttonProgress: `${PREFIX}-buttonProgress`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.zeroPadding}`]: {
    padding: 0,
  },

  [`& .${classes.cardBorderRadius}`]: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },

  [`& .${classes.cardHeaderMargin}`]: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    // justifyContent:"center",
    borderRadius: "0 0  10px 0",
  },

  [`& .${classes.fileInput}`]: {
    display: "none",
  },

  [`& .${classes.imageSizes}`]: {
    width: "40%",
    margin: "auto",
    display: "block",
  },

  [`& .${classes.container}`]: {
    justifyContent: "center",
    // justifyItems: "center",

    marginTop: "10%",
  },

  [`& .${classes.label}`]: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },

  [`& .${classes.marginInputLabel}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.resendBtnMargin}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.inputMargin}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.paddingInput}`]: {
    padding: "0px 0px",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: '4px'
  }
}));

const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#22EC22',
    // '&:hover': {
    //   backgroundColor: '#22EC22',
    // },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#22EC22',
  },
}));
function InventoryPriceForm(props) {

  // const [selectVendor, setSelectVendor] = useState([]);
  // const [ownedBy, setOwnedBy] = useState([]);
  const [initialData,setInitialData] = useState()
  const [loading, setLoading] = React.useState(false);
  const [inventoryData, setInventoryData] = useState({
    vender: "",
    price: "",
    quantity: "",
    ownedBy: "",
    min_quantity:"",
    is_sold_online:""
  });
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)

  useEffect(() => {
    inventoryPriceService.getInventoryPriceById(props.id).then(res => {
      if (res) {
        if (res.status === 200) {
          let intialValue = {
            vender: res.data[0].vendor_code && { code: res.data[0].vendor_code, label: res.data[0].vendor },
            price: res.data[0].price,
            quantity:res.data[0].quantity,
            min_quantity: res.data[0].minimum_quantity_to_buy && res.data[0].minimum_quantity_to_buy,
            ownedBy: res.data[0].owned_by_code && { code: res.data[0].owned_by_code, label: res.data[0].owned_by },
            is_sold_online: res.data[0].is_sold_online && res.data[0].is_sold_online
          }
          setInventoryData(intialValue)
          setInitialData(intialValue)
        } else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenSessionExpiredModal(true)
          }
        }
      }
    })
  }, [props.id])

  

  return (
    <Root>

      <Container>
        <Formik
          enableReinitialize={true}
          initialValues={inventoryData}
          validationSchema={Yup.object().shape({
            // vender: Yup.string().nullable().required("Required"),
            // price: Yup.string().nullable().required("Required"),
            price:Yup.number().positive().nullable().required("Required"),

            quantity: Yup.number().nullable().required("Required")
            .test('test less than', 'Quantity must be greater than 0', value => value > 0 ),

            min_quantity:Yup.number().positive().nullable(),

            // ownedBy: Yup.string().nullable().required("Required"),

          })}
          onSubmit={ async(values) => {
            setLoading(true)
            const result = await compareJSON(values,initialData)
            values.price = parseFloat(Number(values.price).toFixed(2))
            values.updatedValues =JSON.stringify(result)
            props.formSubmit(values)

          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            isSubmitting,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Container className={classes.container}>
                <Grid container>
               
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        className={classes.inputMargin}
                        variant="outlined"
                        label="Price*"
                        id="price"
                        type="number"
                        step="any"
                        name="price"
                        pattern="^\d*(\.\d{0,2})?$"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        fullWidth
                        value={values.price}
                        error={Boolean(touched.price && errors.price)}
                        helperText={touched.price && errors.price}
                        aria-describedby="outlined-weight-helper-text"
                        autoComplete="off"
                      />
                      <ErrorFocus />
                    </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="quantity"
                          className={classes.inputMargin}
                          id="quantity"
                          value={values.quantity}
                          placeholder="Quantity you wish to sell"
                          label="Quantity * "
                          fullWidth
                          variant="outlined"
                          onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                          error={Boolean(touched.quantity && errors.quantity)}
                          helperText={touched.quantity && errors.quantity}
                          autoComplete="off"
                        />
                      <ErrorFocus />
                    </Grid>
                  </Grid>

                  <FormControlLabel
                    control={
                      <GreenSwitch
                        checked={values.is_sold_online}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                        error={Boolean(
                              errors.is_sold_online && touched.is_sold_online
                            )}
                        helperText={errors.is_sold_online && touched.is_sold_online}
                        name="is_sold_online"
                        value={values.is_sold_online}
                        aria-describedby="outlined-weight-helper-text"
                        onBlur={handleBlur}
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.checked,
                          track: classes.track
                        }} />
                  
                    }
                    label="Sold Online"
                  />
                  <FormHelperText style={{ color: "#F44336", paddingLeft: 16 }}>
                    {errors.is_sold_online &&
                      touched.is_sold_online &&
                      errors.is_sold_online}
                  </FormHelperText>
                </Grid>
                <Button
                  color="primary"
                  className={classes.popDownBtn}
                  size="medium"
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                >
                  {loading === false ? "Update" : <><img src={loadingSpinner} alt="Logo" className={classes.buttonProgress} /> Update </>}
                </Button>
              </Container>
            </form>
          )}
        </Formik>
      </Container>

  
      <SessionExpiredModal
        open={openSessionExpiredModal}
      />

    </Root>
  );
}

export default InventoryPriceForm;
