import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Avatar, Card, CardContent, Grid, Typography, colors } from '@mui/material';
import PeopleIcon from '@mui/icons-material/PeopleOutlined';
import { DashboardData } from 'src/_services/dashboard';
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal"
const PREFIX = 'TotalCustomers';

const classes = {
  root: `${PREFIX}-root`,
  avatar: `${PREFIX}-avatar`,
  revenueRate: `${PREFIX}-revenueRate`,
  boxHeader: `${PREFIX}-boxHeader`,
  differenceIcon: `${PREFIX}-differenceIcon`,
  italic: `${PREFIX}-italic`,
  differenceValue: `${PREFIX}-differenceValue`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    height: '100%'
  },

  [`& .${classes.avatar}`]: {
    backgroundColor: colors.green[600],
    height: 56,
    width: 56
  },

  [`& .${classes.revenueRate}`]: {
    fontWeight: "400"
  },

  [`& .${classes.boxHeader}`]: {
    fontWeight: 'normal',
  },

  [`& .${classes.differenceIcon}`]: {
    color: colors.green[900]
  },

  [`& .${classes.italic}`]: {
    fontStyle: "italic",
    fontWeight: '400',
    fontSize: '0.8rem'
  },

  [`& .${classes.differenceValue}`]: {
    color: colors.green[900],
    marginRight: theme.spacing(1)
  }
}));

const TotalCustomers = ({ className, ...rest }) => {

  const [channelPartner, setChannelPartner] = useState('')
  const [investor, setInvestor] = useState('')
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)


  useEffect(() => {
    DashboardData.getChannelPartnerData()
      .then(res => {

        if (res) {
          if (res.status === 200) {
            res.data.forEach(items => {
              items.users_count.forEach(list => {
                if (list.code === "CHANNEL_PARTNER") {
                  setChannelPartner(list.count)
                }
                else if (list.code === "INVESTER_CLIENT") {
                  setInvestor(list.count)
                }
              })
            })
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true)
            }
          }
        }
      })
  }, [])

  return (
    (<Root>
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <CardContent>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textSecondary"
                gutterBottom
                variant="h6"
                className={classes.boxHeader}
              >
                CLIENTS
            </Typography>
              <Typography
                color="textPrimary"
                variant="h4"
                className={classes.revenueRate}
              >
                {channelPartner}  <span className={classes.italic}>Channel Partner </span>
              </Typography>
              <Typography
                color="textPrimary"
                variant="h4"
                className={classes.revenueRate}
              >
                {investor}  <span className={classes.italic}>Investor/Client </span>
              </Typography>
            </Grid>
            <Grid item>
              <Avatar className={classes.avatar}>
                <PeopleIcon />
              </Avatar>
            </Grid>
          </Grid>
          {/* <Box
          mt={2}
          display="flex"
          alignItems="center"
        >
          <ArrowUpwardIcon className={classes.differenceIcon} />
          <Typography
            className={classes.differenceValue}
            variant="body2"
          >
            16%
          </Typography>
          <Typography
            color="textSecondary"
            variant="caption"
          >
            Since last month
          </Typography>
        </Box> */}
        </CardContent>
      </Card>
      <SessionExpiredModal
        open={openSessionExpiredModal}
      />
    </Root>)
  );
};

TotalCustomers.propTypes = {
  className: PropTypes.string
};

export default TotalCustomers;
