import { Button, Grow } from '@mui/material'
import { styled } from '@mui/material/styles';
import React from 'react'
import Page from 'src/components/Page'
import { Container, Grid } from '@mui/material';

import SecurityIcon from '@mui/icons-material/Security';
import PostAddIcon from '@mui/icons-material/PostAdd';
import HttpsIcon from '@mui/icons-material/Https';
import ExtensionIcon from '@mui/icons-material/Extension';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';

const PREFIX = 'PolicyPage';

const classes = {
    root: `${PREFIX}-root`,
    container: `${PREFIX}-container`,
    button: `${PREFIX}-button`,
    icon: `${PREFIX}-icon`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`&.${classes.root}`]: {
        //   backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        //   paddingBottom: theme.spacing(3),
        //   paddingTop: theme.spacing(3)
    },

    [`& .${classes.container}`]: {
        padding: 0,
        paddingTop: theme.spacing(1)
    },

    [`& .${classes.button}`]: {
        width: '100%',
        padding: '16px 4px',
        color: '#000',
        backgroundColor: ' #E0E0E0'
    },

    [`& .${classes.icon}`]: {
        marginRight: '12px'
    }
}));

const PolicyPage = () => {


    const legalAndCompliance = () => {
        window.open('https://unlistedkart.com/legal', '_blank');
    }

    const riskDiscloser = () => {
        window.open('https://unlistedkart.com/risk-disclosure', '_blank');
    }

    const termsCondition = () => {
        window.open('https://unlistedkart.com/terms-and-conditions', '_blank');
    }

    const privacy = () => {
        window.open('https://unlistedkart.com/privacy-policy', '_blank');
    }

    const noExchangeReturnPolicy = () => {
        window.open('https://unlistedkart.com/no-exchange-policy', '_blank');
    }

    return (
        <Grow in={true}>
            <Root>
                <Page
                    className={classes.root}
                    title="Unlistedkart | Policy">
                    <Container maxWidth={false} className={classes.container}>
                        <Grid container spacing={3}>
                            <Grid item lg={4} md={6} sm={6} xl={4} xs={12}>
                                <Button variant='contained' className={classes.button} onClick={legalAndCompliance}>
                                    <PostAddIcon className={classes.icon} /> Legal & Compliance
                                </Button>
                            </Grid>
                            <Grid item lg={4} md={6} sm={6} xl={4} xs={12} >
                                <Button variant='contained' className={classes.button} onClick={riskDiscloser}>
                                    <ExtensionIcon className={classes.icon} />  Risk Disclosure
                                </Button>
                            </Grid>
                            <Grid item lg={4} md={6} sm={6} xl={4} xs={12}>
                                <Button variant='contained' className={classes.button} onClick={termsCondition}>
                                    <HttpsIcon className={classes.icon} /> Terms & Conditions
                                </Button>
                            </Grid>
                            <Grid item lg={4} md={6} sm={6} xl={4} xs={12} >
                                <Button variant='contained' className={classes.button} onClick={privacy}>
                                    <SecurityIcon className={classes.icon} />  Privacy
                                </Button>
                            </Grid>
                            <Grid item lg={4} md={6} sm={6} xl={4} xs={12} >
                                <Button variant='contained' className={classes.button} onClick={noExchangeReturnPolicy}>
                                    <MoneyOffIcon className={classes.icon} />  No Return / Exchange Policy
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </Page>
            </Root>
        </Grow>
    );
}

export default PolicyPage